import CommandInterface from '@/core/application/command/CommandInterface'
import TaskRegistryEntity from '@/services/TaskEditor/domain/model/TaskRegistryEntity'

export default class TaskRegistryEntityUpdateCommand implements CommandInterface {
  public models: Array<TaskRegistryEntity>

  constructor (models: Array<TaskRegistryEntity>) {
    this.models = models
  }

  getClassName (): string {
    return 'TaskRegistryEntityUpdateCommand'
  }

  getModels (): Array<TaskRegistryEntity> {
    return this.models
  }
}
