<template>
  <Split style="height: 500px" v-loading="isConfigLoading">
    <SplitArea :size="50">
      <div style="height: 2em; padding-right: 25px;">
        <div style="float: left;">
          <el-dropdown trigger="click" class="add-button" @command="add" size="mini">
            <span class="el-dropdown-link">
              {{ $locale.main.button.add }} <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{type: 'layer'}">
                <i style="font-size: 16px;" class="el-icon-map-location"></i>
                {{ $locale.interface_editor.component_editor.map_layers.add_layer }}
              </el-dropdown-item>
              <el-dropdown-item :command="{type: 'group'}">
                <i style="font-size: 16px;" class="el-icon-folder"></i>
                {{ $locale.interface_editor.component_editor.map_layers.add_group }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="right: calc(100% / 2 + 10px); position: absolute;">
          <settings-manager
            :layers="layers"
            @import-settings="importSettings"
          ></settings-manager>
        </div>
      </div>
      <el-scrollbar :style="{height:'calc(100% - 29px)'}">
        <el-tree
          node-key="guid"
          draggable
          :data="layers"
          :props="properties"
          :expand-on-click-node="false"
          :allow-drop="allowDrop"
          @node-click="activeNode = $event"
        >
          <span :class="{'selected-node' : activeNode.guid === data.guid }" slot-scope="{ node, data }">
            <i style="font-size: 16px;" :class="{'el-icon-map-location': data.type === 'layer', 'el-icon-folder': data.type === 'group' && !node.expanded, 'el-icon-folder-opened': data.type === 'group' && node.expanded}"></i>
            <span class="label">{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }}</span>
            <el-dropdown trigger="click" class="add-button add-child-button" @command="add" v-if="data.type === 'group'" size="mini">
              <span class="el-dropdown-link">
                {{ $locale.main.button.add }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{type: 'layer', data: data}">
                  <i style="font-size: 16px;" class="el-icon-map-location"></i>
                  {{ $locale.interface_editor.component_editor.map_layers.add_layer }}
                </el-dropdown-item>
                <el-dropdown-item :command="{type: 'group', data: data}">
                  <i style="font-size: 16px;" class="el-icon-folder"></i>
                  {{ $locale.interface_editor.component_editor.map_layers.add_group }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              type="text"
              class="delete-button"
              @click.stop="remove(node, data)">
              {{ $locale.main.button.delete }}
            </el-button>
          </span>
        </el-tree>
      </el-scrollbar>
    </SplitArea>
    <SplitArea :size="50">
      <el-tabs class=tabs-style v-show="activeNode.guid">
        <el-tab-pane
          label="Основные"
        >
          <el-form size="mini" style="padding: 10px" v-if="activeNode.guid" :key="activeNode.guid" class="form">
            <el-col :span="12">
              <el-form-item label="Название">
                <el-input v-model="activeNode.name"></el-input>
              </el-form-item>
            </el-col>
            <template v-if="activeNode.type === 'layer'">
              <el-col :span="12">
                <el-form-item label="Источник">
                  <el-select
                    v-model="activeNode.properties.source.type"
                    @change="clearSource"
                  >
                    <el-option
                      v-for="(item, index) in sourceTypes"
                      :key="index"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <span style="margin-right: 10px">Включен по умолчанию</span>
                <el-checkbox v-model="activeNode.properties.showByDefault"></el-checkbox>
              </el-col>
              <el-col :span="24" v-show="activeNode.properties.showByDefault">
                <span style="margin-right: 10px">Активен по-умолчанию</span>
                <el-checkbox v-model="activeNode.properties.isActiveByDefault"></el-checkbox>
              </el-col>
              <el-col :span="24">
                <span style="margin-right: 10px">Редактируемый</span>
                <el-checkbox v-model="activeNode.properties.isEditable"></el-checkbox>
              </el-col>
              <el-col :span="24">
                <span style="margin-right: 10px">Фокус при включении</span>
                <el-checkbox v-model="activeNode.properties.fit"></el-checkbox>
              </el-col>
              <el-col :span="24" style="height: 90px;">
                <span style="margin-right: 10px;">Отображать при зуме</span>
                <div style="padding: 0 10px 0 10px;">
                  <el-slider
                    v-model="activeNode.properties.viewZoomRange"
                    range
                    :max="20"
                    :min="0"
                    :marks="marks"
                  ></el-slider>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Взять ярлычок слоя из стиля">
                  <el-select
                    v-model="activeNode.properties.iconStyleTypeId"
                  >
                    <el-option
                      v-for="(item, index) in iconStyleTypes"
                      :key="index"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <component v-if="activeNode.properties.source.type" :is="activeNode.properties.source.type" v-model="activeNode.properties.source"></component>
              </el-col>
              <el-col :span="24" v-if="activeNode.properties.isEditable">
                <span>Проверять валидность контура</span>
                <el-select
                  v-model="activeNode.properties.validation">
                  <el-option
                    v-for="(item, index) in validationOptions"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="24">
                <span>Непрозрачность слоя по умолчанию</span>
                <div style="padding: 0 10px 0 10px;">
                  <el-slider
                    v-model="activeNode.properties.opacity"
                    :max="100"
                    :min="0"
                  ></el-slider>
                </div>
              </el-col>
              <el-col>
                <paint-by-unique-values-panel
                  v-model="activeNode"
                  ></paint-by-unique-values-panel>
              </el-col>
              <el-col>
                <layer-separation-conditions
                  v-model="activeNode.properties.separation"
                  :entity-id="activeNode.properties.source.entityId"
                  :fields="activeNode.properties.interactive.standardCard.fields"
                  :source-type="activeNode.properties.source.type"
                  @set-config-loading="setConfigLoading"
                ></layer-separation-conditions>
              </el-col>
            </template>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          :lazy="true"
          label="Стили" v-if="activeNode.guid && activeNode.type === 'layer'">
          <div style="padding: 10px">
            <el-radio-group v-model="activeStyleType" size="small">
              <el-radio-button v-for="type in styleTypes"
                               :key="type.id"
                               :label="type.id">
                {{ type.name }}
              </el-radio-button>
            </el-radio-group>
            <style-form
              v-model="activeNode.properties.style[activeStyleType]"
              :mini="true"
              :type="activeStyleType"
              :key="`${activeNode.guid}_${activeStyleType}`"/>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :lazy="true"
          :label="this.$locale.interface_editor.component_editor.configurator.interactive"
          v-if="activeNode.guid && activeNode.type === 'layer' && activeNode.properties.source.type !== 'Field'">
          <interactive-panel
            :key="activeNode.guid"
            v-model="activeNode"
          ></interactive-panel>
        </el-tab-pane>
        <el-tab-pane
          :lazy="true"
          :label="'Фильтры'"
          v-if="activeNode.guid && activeNode.type === 'layer' && activeNode.properties.source.type !== 'Field'"
          :key="activeNode.guid"
          class="overflow-visible"
        >
          <el-form size="mini" style="padding: 10px" v-if="activeNode.guid" :key="activeNode.guid" class="form">
            <el-col :span="24" v-if="activeNode.properties.source.type==='Registry'">
              <el-radio-group v-model="activeFilterType" size="small">
                <el-radio-button v-for="type in filterTypes"
                                 :key="type.id"
                                 :label="type.id">
                  {{ type.name }}
                </el-radio-button>
              </el-radio-group>
              <div v-if="activeFilterType === 'filter'">
                <span style="margin: 10px">Стандартные фильтры</span>
                <editor-filters
                  :show-xref-option="false"
                  :show-equals-types="true"
                  :value="activeNode.properties.source.filters"
                  @change="$set(activeNode.properties.source ,'filters', $event)"/>
              </div>
              <div v-if="activeFilterType === 'state'">
                <span style="margin: 10px">Фильтры по состояниям</span>
                <state-filter
                  :registryId="activeNode.properties.source.entityId"
                  :stateId="activeNode.properties.source.stateId || null"
                  @change="activeNode.properties.source.stateId = $event"
                ></state-filter>
              </div>
            </el-col>
            <el-col :span="24">
              <span style="margin: 10px">Динамические фильтры</span>
              <dynamic-map-filters
                :dynamicFilters="activeNode.properties.source.dynamicFilters"
                :source="activeNode.properties.source"
                @change="$set(activeNode.properties.source, 'dynamicFilters', $event)"
                :key="activeNode.guid"
              ></dynamic-map-filters>
            </el-col>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </SplitArea>
  </Split>
</template>

<script>
import Registry from './SourceTypes/Registry'
import ExtendedObject from './SourceTypes/ExtendedObject'
import Query from './SourceTypes/Query'
import Field from './SourceTypes/Field'
import XYZ from './SourceTypes/XYZ'
import TileArcGISRest from './SourceTypes/TileArcGISRest'
import EditorFilters from '../editor-filters'
import StyleForm from '@/services/MapEditor/infrastructure/components/StyleForm/index.vue'
import { NodeLayerType } from '@/components/InterfaceEditor/components/basic/Map/NodeLayerType'
import WFS from './SourceTypes/WFS'
import WMS from './SourceTypes/WMS'
import TileWMS from './SourceTypes/TileWMS'
import InteractivePanel from '@/core/infrastructure/components/InteractivePanel/index.vue'
import PaintByUniqueValuesPanel from '@/core/infrastructure/components/PaintByUniqueValuesPanel.vue'
import LayerSeparationConditions from '@/core/infrastructure/components/LayerSeparationConditions/index.vue'
import DynamicMapFilters from '@/core/infrastructure/components/DynamicMapFilters/index.vue'
import SettingsManager from './SettingsManager'
import StateFilter from './StateFilter'

const SOURCE_TYPES = [
  { id: 'Registry', name: 'Реестр' },
  { id: 'ExtendedObject', name: 'Расширенная таблица' },
  { id: 'Query', name: 'Запрос' },
  { id: 'Field', name: 'Поле' },
  { id: 'XYZ', name: 'Внешний сервис XYZ' },
  { id: 'TileArcGISRest', name: 'TileArcGISRest' },
  { id: 'WFS', name: 'Внешний сервис WFS' },
  { id: 'WMS', name: 'Внешний сервис WMS' },
  { id: 'TileWMS', name: 'Внешний севрис TileWMS' }
]
const ICON_STYLE_TYPES = [
  { id: 'point', name: 'Точка' },
  { id: 'linestring', name: 'Линия' },
  { id: 'polygon', name: 'Полигон' }
]
const DEFAULT_NODES = {
  layer: {
    type: NodeLayerType.LAYER,
    name: 'Новый слой',
    leaf: true,
    isLoading: false,
    properties: {
      source: {
        type: null,
        entityId: null,
        geometryField: null,
        marksField: null,
        externalURL: null,
        nativeCoordinateSystemId: 3857,
        srsHandlingType: 'reproject',
        filters: [],
        dynamicFilters: [],
        stateId: null
      },
      style: {
        point: null,
        linestring: null,
        polygon: null,
        label: null,
        cluster: null
      },
      interactive: {
        type: 'nothing',
        standardCard: {
          fields: [],
          id: null,
          name: null,
          type: 'manual',
          plugin: null
        },
        popup: {
          text: null,
          css: null
        },
        card: {
          type: 'open_card',
          card: {}
        }
      },
      paint: {
        type: null,
        field: null,
        startColor: null,
        incrementColor: null
      },
      separation: {
        type: 'rules',
        rules: [],
        rule: {}
      },
      defineAddress: {
        enable: false,
        mapping: []
      },
      zIndex: 0,
      showByDefault: false,
      isActiveByDefault: false,
      isEditable: false,
      fit: false,
      opacity: 100,
      viewZoomRange: [0, 20],
      validation: 'none'
    }
  },
  group: {
    type: NodeLayerType.GROUP,
    name: 'Новая группа',
    leaf: false,
    children: [],
    properties: {}
  }
}
export default {
  name: 'configurator',
  components: {
    DynamicMapFilters,
    PaintByUniqueValuesPanel,
    Registry,
    ExtendedObject,
    Query,
    Field,
    XYZ,
    TileArcGISRest,
    EditorFilters,
    StyleForm,
    WFS,
    WMS,
    TileWMS,
    InteractivePanel,
    LayerSeparationConditions,
    SettingsManager,
    StateFilter
  },
  inject: ['getComponents'],
  props: {
    value: {
      type: Array
    }
  },
  computed: {
    sourceTypes () {
      return SOURCE_TYPES
    },
    iconStyleTypes () {
      return ICON_STYLE_TYPES
    }
  },
  data () {
    return {
      properties: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'children'
      },
      activeStyleType: 'point',
      styleTypes: [
        { id: 'point', name: 'Точка' },
        { id: 'linestring', name: 'Линия' },
        { id: 'polygon', name: 'Полигон' },
        { id: 'label', name: 'Лэйбл' }
      ],
      activeNode: {},
      layers: this.value || [],
      activeCardType: 'standard',
      marks: {},
      validationOptions: [
        { id: 'none', name: 'Нет'},
        { id: 'warning', name: 'Уведомить о невалидном контуре' },
        { id: 'error', name: 'Запретить сохранение навалидного контура' }
      ],
      isConfigLoading: false,
      dashboardComponents: this.getComponents(),
      activeFilterType: 'filter',
      filterTypes: [
        { id: 'filter', name: 'Фильтры' },
        { id: 'state', name: 'Состояния' },
      ]
    }
  },
  mounted () {
    for (let i = 2; i <= 18; i += 2) {
      this.marks[i] = '' + i
    }
    this.layers = this.actualizeLayersTreeStructure(this.layers)
  },
  methods: {
    setConfigLoading(value) {
      this.isConfigLoading = value
    },
    actualizeLayersTreeStructure (treeItems) {
      treeItems.forEach((treeItem) => {
        if (treeItem.type === 'group') {
          let children = treeItem.children
          treeItem = this.actualizeLayerStructure(treeItem, DEFAULT_NODES['group'])
          treeItem.children = this.actualizeLayersTreeStructure(children)
        } else if (treeItem.type === 'layer') {
          treeItem = this.actualizeLayerStructure(treeItem, DEFAULT_NODES['layer'])
        }
      })
      return treeItems
    },
    actualizeLayerStructure (layerItem, neededStructure) {
      for (const [neededKey, neededValue] of Object.entries(neededStructure)) {
        if (layerItem[neededKey] === undefined) {
          this.$set(layerItem, neededKey, neededValue)
        }
        if (typeof neededValue === 'object' && !Array.isArray(neededValue) && neededValue !== null) {
          layerItem[neededKey] = this.actualizeLayerStructure(layerItem[neededKey], neededValue)
        }
      }
      return layerItem
    },
    add (event) {
      let object = JSON.parse(JSON.stringify(DEFAULT_NODES[event.type]))
      if (!object) {
        return false
      }
      object.guid = this.generateGuid()
      if (event.data) {
        event.data.children.push(object)
      } else {
        this.layers.push(object)
      }
    },
    remove (node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.guid === data.guid)
      children.splice(index, 1)
    },
    clearSource () {
      this.$set(this.activeNode.properties.source, 'entityId', null)
      this.$set(this.activeNode.properties.source, 'geometryField', null)
      this.$set(this.activeNode.properties.source, 'marksField', null)
    },
    allowDrop (draggingNode, dropNode, type) {
      if (dropNode.data.type === NodeLayerType.LAYER && type === 'inner') {
        return false
      }
      return true
    },
    importSettings (importSettings) {
      let [newLayers, a, u] = this.importLayers(importSettings, this.layers)
      this.$set(this, 'value', newLayers)
      this.$message({
        type: 'success',
        message: 'Добавлено - ' + a + '. Обновлено - ' + u
      })
    },
    importLayers (newLayers, existLayers) {
      let added = 0, updated = 0
      for (let i = 0; i < newLayers.length; i++) {
        const newLayer = newLayers[i]
        // existLayers = array
        let exist = false
        for (let j = 0; j < existLayers.length; j++) {
          if (newLayer.guid === existLayers[j].guid) {
            exist = true
            if (newLayer.type !== existLayers[j].type) {
              this.$message({
                type: 'error',
                dangerouslyUseHTMLString: true,
                message: 'Ошибка! Дублирование guid (' + newLayer.guid + ')'
              })
            }
            existLayers[j] = {...newLayer}
            updated++
            if (newLayer.type === 'group') {
              updated += this.countingChildren(newLayer.children)
            }
          }
        }
        if (!exist) {
          existLayers.push({ ...newLayer })
          added++
          if (newLayer.type === 'group') {
            added += this.countingChildren(newLayer.children)
          }
        }
      }
      return [existLayers, added, updated]
    },
    countingChildren (items) {
      let count = 0
      for (let i = 0; i < items.length; i++) {
        if (items[i].type === 'group') {
          count+= 1 + this.countingChildren(items.children)
        }
        if (items[i].type === 'layer') {
          count++
        }
      }
      return count
    }
  },
  watch: {
    layers: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>
<style>
.el-color-dropdown {
  z-index: 9999999 !important;
}
</style>
<style scoped>
.tabs-style {
  height: 500px;
}
.tabs-style /deep/ .el-tabs__content {
  overflow: visible;
}
.overflow-visible {
  overflow: visible;
}
.map_config_slider /deep/ el-slider__runway {
  margin: 16px 10px 32px 10px;
}
.map_config_slider /deep/ .el-slider__marks {
  width: 80%;
  position: relative;
}
.map_config_slider /deep/ .el-slider__marks-text {
  transform: none;
  min-width: 30%;
}
.add-button {
  cursor:pointer;
  color: #409EFF;
  font-size: 12px;
  margin-left: 15px;
}
.delete-button {
  background: white;
  position: absolute;
  right: 5px;
  color: #F56C6C;
  display: none;
}
/deep/ .el-tree-node:hover>.el-tree-node__content>span>.delete-button {
  display: inline-block;
}
/deep/ .el-tree-node:hover>.el-tree-node__content>span>.add-child-button {
  display: inline-block;
}
.add-child-button {
  background: white;
  position: absolute;
  right: 60px;
  display: none;
}
.label {
  font-weight: bold;
  padding-left: 5px;
}
/deep/ .el-tree-node__children{
  border-left: 2px solid #4FA9F3;
  margin-left: 5px;
  /* padding-left: 10px; */
  /* box-shadow: 4px 1px 0px -3px rgba(27, 141, 229, 1) inset; */
}
/deep/ .el-tree-node.is-current{
  background-color: #EEF0F1;
  background-color: rgb(245 247 250);
}
/deep/ .el-tree-node.is-focusable.is-expanded{
  background-color: transparent;
}
.form /deep/ .el-form-item__label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px;
}
</style>
