<template>
  <div>
    <h1 style="margin-top: 10px">{{$locale.logic_editor.command.headline}}</h1>
    <div class="commands__item2">
      <div class="demonstration">{{$locale.menu_editor.label.name}}</div>
      <el-input size="mini" class="commands__item" v-model="form.name"></el-input>
    </div>
    <div class="commands__item2">
      <div class="demonstration">{{$locale.logic_editor.command.condition}}</div>
      <el-select
        multiple
        collapse-tags
        value-key="id"
        class="commands__item"
        v-model="form.command_states"
        size="mini"
      >
        <el-option v-for="item in listState" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="commands__item2">
      <el-row class="commands__mb">
        <el-col :span="12">
          <el-checkbox v-model="form.is_plugin">{{$locale.logic_editor.command.plugin}}</el-checkbox>
        </el-col>
        <el-col :span="12">
          <el-checkbox v-model="form.is_complex">{{$locale.logic_editor.command.is_complex}}</el-checkbox>
        </el-col>
      </el-row>
    </div>
    <div v-show="form.is_plugin && !form.is_complex" class="commands__item2">
      <div class="demonstration">{{$locale.logic_editor.command.list_plugins}}</div>
      <el-select
        value-key="id"
        class="commands__row"
        v-model="form.plugin_id"
        :placeholder="this.$locale.logic_editor.command.list_plugins"
        size="mini"
      >
        <el-option v-for="item in listPlugin" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div v-show="!form.is_plugin && !form.is_complex">
        <div class="commands__item2">
          <div class="demonstration">{{$locale.logic_editor.command.event}}</div>
          <el-select value-key="id" class="commands__item" v-model="form.event_id" size="mini">
            <el-option v-for="item in listEvent" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      <div class="commands__item2 " v-if="form.event_id === 'update_external_records_event' || form.event_id === 'run_external_command_event'">
        <div class="demonstration">{{$locale.logic_editor.command.external_state}}</div>
        <el-select
          value-key="id"
          class="commands__item"
          multiple
          v-model="form.command_external_states"
          size="mini"
        >
          <el-option-group
            v-for="(group, index) in Array.from(new Set(allStates.map(item => item.registry)))"
            :key="index"
            :label="group">
            <el-option
              v-for="item in allStates.filter(item => item.registry === group)"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div class="commands__item2 " v-if="['create_report_event', 'eds_sign_event', 'run_external_command_event'].indexOf(form.event_id) === -1">
          <div class="demonstration">{{$locale.logic_editor.command.state}}</div>
          <el-select
            v-if="form.event_id === 'add_external_record_event' || form.event_id === 'update_external_records_event'"
            value-key="id"
            class="commands__item"
            v-model="form.target_state_id"
            size="mini"
          >
            <el-option-group
              v-for="(group, index) in Array.from(new Set(allStates.map(item => item.registry)))"
              :key="index"
              :label="group">
              <el-option
                v-for="item in allStates.filter(item => item.registry === group)"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
          <el-select
            v-else
            value-key="id"
            class="commands__item"
            v-model="form.target_state_id"
            size="mini"
          >
            <el-option
              v-for="item in listTargetState"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
      </div>
      <div class="commands__item2" v-else-if="form.event_id === 'create_report_event'">
        <create-report-event
          v-if="!propslocal.isNewAction"
          :properties="form.properties || undefined"
          :logic-id="form.logic_id"
          @input="$set(form, 'properties', $event)"
        ></create-report-event>
        <span v-else>Необходимо сохранить команду, чтобы редактировать свойства</span>
      </div>
      <div class="commands__item2" v-else-if="form.event_id === 'run_external_command_event'">
        <run-external-command-event
          :properties="form.properties || undefined"
          :logic-id="form.logic_id"
          @input="$set(form, 'properties', $event)"
        ></run-external-command-event>
      </div>
      <div v-else-if="form.event_id === 'eds_sign_event' && propslocal.businesslogic.registry_id">
        <div class="commands__item3">
          <div class="demonstration">Набор подпис. данных</div>
          <select-ref-field v-model="form.eds_sign_command_fields" :object-id="propslocal.businesslogic.registry_id"></select-ref-field>
        </div>
        <div class="commands__item2">
          <eds-sign-event-form
            :properties="form.properties || undefined"
            :object-id="propslocal.businesslogic.registry_id"
            v-model="propertiesModel"
          ></eds-sign-event-form>
        </div>
      </div>
    </div>
    <div v-show="form.is_complex" class="commands__item2">
      <child-commands
        ref="childCommands"
        :logic-id="form.logic_id"
        :commands="form.child_commands"
        @open-command="openChildCommand"
        @add-child-command="addChildCommand">
      </child-commands>
    </div>
    <el-footer class="commands_footer">
      <el-button
        @click="saveCard"
        :disabled='form.name === ""'
        type="primary"
      >{{ $locale.main.button.save }}</el-button>
    </el-footer>
  </div>
</template>

<script>
import Plugins from '../Models/Plugins'
import Events from '../Models/Events'
import States from '../Models/States'
import Commands from '../Models/Commands'
import Command from './commands'
import CreateReportEvent from '@/components/LogicEditor/components/CommandProperties/CreateReportEvent'
import ChildCommands from '@/components/LogicEditor/components/ChildCommands/index'
import EdsSignEventForm from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/EdsSignEventForm'
import SelectRefField from '../../Common/SelectRefField'
import RunExternalCommandEvent from '@/components/LogicEditor/components/CommandProperties/RunExternalCommandEvent'
export default {
  name: 'command',
  components: { RunExternalCommandEvent, ChildCommands, CreateReportEvent, Command, EdsSignEventForm, SelectRefField },
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    }
  },
  data () {
    return {
      addAction: this.propslocal.listOpenAction,
      form: {
        name: '',
        command_states: [],
        command_external_states: [],
        is_plugin: false,
        event_id: null,
        plugin_id: null,
        is_complex: false,
        target_state_id: null,
        logic_id: this.propslocal.businesslogic.id,
        eds_sign_command_fields: [],
        child_commands: [],
        properties: undefined
      },
      listState: [],
      listPlugin: [],
      listEvent: [],
      listTargetState: [],
      allStates: [],
      propertiesModel: {
        is_external_object: false,
        external_object_id: null,
        external_object_field_id: null,
        sign_date_field_id: null,
        user_field_id: null,
        certificate_number_field_id: null,
        certificate_owner_field_id: null,
        inn_field_id: null,
        archive_field_id: null,
        sign_type: 'client',
        server_certificate: null
      }
    }
  },
  methods: {
    addChildCommand () {
      let props = JSON.parse(JSON.stringify(this.propslocal))
      props.isNewAction = true
      const h = this.$createElement
      this.$msgbox({
        title: '',
        message: h('command', {
          props: {
            propslocal: props
          },
          on: {
            'command-added': (command) => {
              this.addAction && this.addAction.items && this.addAction.items.push(command)
              this.$msgbox.close()
              this.form.child_commands.push({
                command_id: command.id,
                row_order: this.form.child_commands.length + 1
              })
            }
          },
          key: this.generateGuid()
        }),
        showCancelButton: false,
        showConfirmButton: false
      })
    },
    async openChildCommand (commandId) {
      let props = JSON.parse(JSON.stringify(this.propslocal))
      const data = await this.$http.get(`${this.$config.api}/logiceditor/commands/${commandId}`)
      props.getAnswer = data.data
      const h = this.$createElement
      this.$msgbox({
        title: '',
        message: h('command', {
          props: {
            propslocal: props
          },
          key: this.generateGuid()
        }),
        showCancelButton: false,
        showConfirmButton: false
      })
    },
    async saveCard () {
      // новое subAction
      if (this.form.event_id === 'eds_sign_event') {
        await this.$http
          .get(`${this.$config.api}/logiceditor/command_event_types/eds_sign_event/properties`)
          .then(response => {
            this.form.properties = []
            response.data.forEach(item => {
              this.form.properties.push({
                id: item.id,
                type: item.type,
                value: this.propertiesModel[item.id]
              })
            })
          })
      }

      if (this.form.is_complex) {
        this.form.child_commands = this.$refs.childCommands.getModel()
      }
      if (this.propslocal.isNewAction) {
        let commands = new Commands({}, this.form)
        if (this.form.is_plugin) {
          commands.event_id = null
          commands.target_state_id = null
        }
        let response = await commands.save()
        this.addAction.items && this.addAction.items.push(response)
        this.$emit('command-added', response)
      } else {
        let id
        this.propslocal.selectSubAction.id
          ? id = this.propslocal.selectSubAction.id
          : id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id
        let commands = new Commands({ id: id }, this.form)
        if (this.form.is_plugin) {
          commands.event_id = null
          commands.target_state_id = null
        }
        let response = await commands.save()
        this.addAction.items
          ? this.addAction.items.filter(el => el.id === id)[0].name = response.name
          : this.addAction.filter(el => el.id === id)[0].name = response.name
      }
      this.propslocal.isNewAction = false
    }
  },
  async mounted () {
    let states = await States.params({}).$get()
    let events = await Events.get()
    let plugins = await Plugins.get()
    this.allStates = states
    this.listState = states.filter((item) => item.logic_id === this.propslocal.businesslogic.id)
    this.listEvent = events.map(el => ({
      id: el.id,
      name: this.$locale.logic_editor.listeners.translate[el.name]
    }))
    this.listPlugin = plugins
    this.listTargetState = states.filter((item) => item.logic_id === this.propslocal.businesslogic.id)
    // редактирование
    if (!this.propslocal.isNewAction) {
      this.form = this.propslocal.getAnswer

      if (this.form.event_id === 'eds_sign_event') {
        await this.$http
          .get(`${this.$config.api}/logiceditor/command_event_types/eds_sign_event/properties`)
          .then(response => {
            response.data.forEach(item => {
              this.propertiesModel[item.id] = this.form.properties[this.form.properties.findIndex(prop => prop.id === item.id)].value
            })
          })
      }
    }
  }
}
</script>

<style scoped>
.commands__mt {
  margin-top: 50px;
}
.commands__mb {
  margin-bottom: 20px;
}
.commands__row {
  margin-bottom: 25px;
  margin-right: 25px;
  width: 35%;
}
.commands_footer {
  height: 60px;
  text-align: right;
  margin-top: 25px;
}
.commands__item2 {
  margin-top: 10px;
  width: 65%;
}
.commands__item3 {
  margin-top: 10px;
  width: 100%;
}
.command__mt {
  margin-top: 0px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.commands__item {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
</style>
