

































































































import Vue from 'vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'

import CKEditor from '@ckeditor/ckeditor5-vue'
import InlineEditor from '@/components/HTMLEditor/ckeditor'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'

export default Vue.extend({
  name: 'SendEmailEventForm',

  components: {
    ckeditor: CKEditor.component,
    TreeSelect
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    logicId: {
      type: Number
    },

    objectId: {
      type: Number
    }
  },

  data () {
    return {
      recipientTypes: [
        { value: 'constant', label: 'Константа' },
        { value: 'user_field', label: 'Ссылка на сотрудников' },
        { value: 'field', label: 'Поле реестра' },
        { value: 'users', label: 'Сотрудники' },
        { value: 'condition', label: 'По условиям' }
      ],

      editor: InlineEditor,
      model: this.value,
      rules: {
        subject: [
          { required: true, message: 'Введите тему письма', trigger: 'change' }
        ]
      },
      fields: [],
      treeProps: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id !== 'file_field'
        }
      },
      treePropsUser: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return !['xref_field', 'xref_multi_field'].includes(data.entity_type_id)
        }
      },
      treePropsFields: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name'
      },
      users: [],
      usersLogicId: null,
      listMatchTypes: [{
        id: 'all',
        name: 'При соблюдении всех условий одновременно' },
      {
        id: 'any',
        name: 'При любом условии'
      }]
    }
  },

  async mounted () {
    if (!this.fields.length && this.objectId) {
      await this.$http
        .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.objectId}&show_children=true`)
        .then((response) => {
          this.fields = response.data.data
        })
        .catch(error => console.error(error))
    }
    if (!this.users.length) {
      // await this.$http
      //   .get(`${this.$config.api}/accesseditor/users?fields=id,name,surname,midname`)
      //   .then((response) => {
      //     this.users.push(...response.data)
      //   })
      //   .catch((error) => console.error(error))
      try {
        let response = await APIClient.shared.request(new UserAPI.GetUsers([]))
        // Переменная resInterator чтобы убрать ошибку '[Symbol.iterator]()' method that returns an iterator
        let resInterator = [...[response]]
        this.users.push(...resInterator)
      } catch (error) {
        console.log({ error })
      }
    }
    if (!this.userLogicId) {
      let objId = await this.$http.get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true&show_children=true`)
      let logicId = await this.$http.get(`${this.$config.api}/logiceditor/logics?registry_id=${objId.data.data[0].id}`)
      this.usersLogicId = logicId.data[0].id
    }
  },

  methods: {
    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    },

    renderContent (h, { node, data, store }) {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
    }
  }
})
