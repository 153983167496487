<template>
  <div class="select-panel">
    <el-dialog
      :visible.sync="isCreateGeometryVisible"
      width="30%"
      height="400px"
      class="create-vertex-window"
      :modal="false">
      <el-form :rules="createVertexRules" :model="geometryDto">
        <el-form-item prop="name" :label="$locale.main.fields.type">
          <Treeselect v-model="geometryDto.name" :options="itemTypes" :normalizer="namesNormalizer" :clearable="false" node-key="id" :placeholder="$locale.main.fields.type" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-close" size="small" @click="isCreateGeometryVisible = false">{{$locale.main.button.cancel}}</el-button>
        <el-button icon="el-icon-success" size="small" @click="createGeometry" type="primary">{{$locale.main.button.save}}</el-button>
      </span>
    </el-dialog>
    <el-container class="tools">
      <el-button icon="el-icon-plus" size="mini" circle @click="showCreateGeometryWindow"></el-button>
    </el-container>
    <el-row>
      <el-col style="width: 210px;">
        <el-switch v-model="dmsMode"
          :active-text="$locale.map_editor.vertex_edit_panel.dms"
          :inactive-text="$locale.map_editor.vertex_edit_panel.decimal"
          style="margin: 10px;">
        </el-switch>
      </el-col>
      <el-col style="width: 290px; padding-top: 5px;">
        <coordinate-system
          :isDisabled="false"
          :value="srsId"
          :options="srids"
          size="mini"
          @change-cs="srsChange">
        </coordinate-system>
      </el-col>
    </el-row>
    <el-row style="height:calc(100% - 64px)">
      <el-col style="height: 100%">
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="vertices"
          stripe
          border
          ref="verticesTable"
          row-key="id"
          current-row-key="id"
          :span-method="cellSpan"
          highlight-current-row
          @current-change="changeVertex"
        >
        <el-table-column
          prop="id"
          :label="dmsMode ? $locale.map_editor.vertex_edit_panel.n_lat : 'X'"
          width="265"
        >
        <template slot-scope="scope">
          <span style="font-weight: bold">{{ scope.row.name != "Coordinate" ? $locale.map_editor.geometry_types[scope.row.name.toLowerCase()] : "" }}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="x"
          :label="dmsMode ? $locale.map_editor.vertex_edit_panel.e_lon : 'Y'"
          width="265"
        >
          <template slot-scope="scope">
            <div v-if="!dmsMode">
              <el-input v-if="scope.row.name == 'Coordinate' && unlockedRecord != null && unlockedRecord == scope.row.id" size="mini" v-model="scope.row.x" @keypress.native="validateCoordinate($event, true)" @change="decInputChange"></el-input>
              <span v-if="scope.row.name == 'Coordinate' && (unlockedRecord == null || unlockedRecord != scope.row.id)">{{scope.row.x}}</span>
            </div>
            <div v-else>
              <el-row v-if="scope.row.name == 'Coordinate' && unlockedRecord != null && unlockedRecord == scope.row.id">
                <el-col style="width: 70px;">
                  <el-input size="mini" v-model="scope.row.xD" @keypress.native="validateCoordinate($event, true)" @change="dmsInputChange">
                    <i slot="suffix">&deg;</i>
                  </el-input>
                </el-col>
                <el-col style="width: 70px;">
                  <el-input size="mini" v-model="scope.row.xM" @keypress.native="validateCoordinate($event, true)" @change="dmsInputChange">
                    <i slot="suffix">'</i>
                  </el-input>
                </el-col>
                <el-col style="width: 100px;">
                  <el-input size="mini" v-model="scope.row.xS" @keypress.native="validateCoordinate($event, true)" @change="dmsInputChange">
                    <i slot="suffix">''</i>
                  </el-input>
                </el-col>
              </el-row>
              <span v-if="scope.row.name == 'Coordinate' && (unlockedRecord == null || unlockedRecord != scope.row.id)">
                {{scope.row.xD}}&deg; {{scope.row.xM}}' {{scope.row.xS}}''
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="y"
          :label="$locale.map_editor.vertex_edit_panel.number"
          width="50"
        >
          <template slot-scope="scope">
            <div v-if="!dmsMode">
              <el-input v-if="scope.row.name == 'Coordinate' && unlockedRecord != null && unlockedRecord == scope.row.id" size="mini" v-model="scope.row.y" @keypress.native="validateCoordinate($event, true)" @change="decInputChange"></el-input>
              <span v-if="scope.row.name == 'Coordinate' && (unlockedRecord == null || unlockedRecord != scope.row.id)">{{scope.row.y}}</span>
            </div>
            <div v-else>
              <el-row v-if="scope.row.name == 'Coordinate' && unlockedRecord != null && unlockedRecord == scope.row.id">
                <el-col style="width: 70px;">
                  <el-input size="mini" v-model="scope.row.yD" @keypress.native="validateCoordinate($event, true)" @change="dmsInputChange">
                    <i slot="suffix">&deg;</i>
                  </el-input>
                </el-col>
                <el-col style="width: 70px;">
                  <el-input size="mini" v-model="scope.row.yM" @keypress.native="validateCoordinate($event, true)" @change="dmsInputChange">
                    <i slot="suffix">'</i>
                  </el-input>
                </el-col>
                <el-col style="width: 100px;">
                  <el-input size="mini" v-model="scope.row.yS" @keypress.native="validateCoordinate($event, true)" @change="dmsInputChange">
                    <i slot="suffix">''</i>
                  </el-input>
                </el-col>
              </el-row>
              <span v-if="scope.row.name == 'Coordinate' && (unlockedRecord == null || unlockedRecord != scope.row.id)">
                {{scope.row.yD}}&deg; {{scope.row.yM}}' {{scope.row.yS}}''
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="90">
          <template slot-scope="scope">
            <div v-if="scope.row.name == 'Coordinate'">{{scope.row.num}}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
        >
          <template slot-scope="scope">
            <el-button v-if="unlockedRecord != null && unlockedRecord == scope.row.id && coordinateAddAllowed.includes(scope.row.name)" icon="el-icon-plus" size="mini" circle @click="addCoordinate"></el-button>
            <el-button v-if="unlockedRecord != null && unlockedRecord == scope.row.id" size="mini" circle icon="el-icon-delete" @click="deleteVertex(scope.row)"></el-button>
          </template>
        </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script type="ts">

import { APIClient } from '@/core/infrastructure/api/APIClient'
import { CoordinateSystemAPI } from '@/services/MapEditor/infrastructure/api/CoordinateSystemAPI'
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
import MapManager from '@bingo_soft/mapmanager'
import Treeselect from '@bingosoftnn/vue-treeselect'

export default {
  name: 'VertexEditPanel',
  props: ['formId', 'parentFormId', 'projectGuid', 'type', 'map', 'layer', 'feature', 'srsId', 'srsIds'],
  components: {
    Treeselect,
    CoordinateSystem
  },
  data() {
    return {
      dmsMode: false,
      geometryDto: {
        name: 'Point'
      },
      itemTypes: [{
        id: 'Point',
        name: this.$locale.map_editor.geometry_types.point
      },{
        id: 'MultiPoint',
        name: this.$locale.map_editor.geometry_types.multipoint
      },{
        id: 'LineString',
        name: this.$locale.map_editor.geometry_types.linestring
      },{
        id: 'MultiLineString',
        name: this.$locale.map_editor.geometry_types.multilinestring
      },{
        id: 'Polygon',
        name: this.$locale.map_editor.geometry_types.polygon
      },{
        id: 'MultiPolygon',
        name: this.$locale.map_editor.geometry_types.multipolygon
      },{
        id: 'GeometryCollection',
        name: this.$locale.map_editor.geometry_types.geometrycollection
      }],
      createVertexRules: {
        name: {
          required: true,
          message: this.$locale.errors.field_required,
          trigger: 'blur'
        },
        x: {
          required: true,
          message: this.$locale.errors.field_required,
          trigger: 'blur'
        },
        y: {
          required: true,
          message: this.$locale.errors.field_required,
          trigger: 'blur'
        }
      },
      coordinateAddAllowed: ['Point', 'LineString', 'Polygon', 'Coordinate'],
      loading: false,
      isCreateGeometryVisible: false,
      verticesPageSize: 0,
      defaultverticesPageSize: 100,
      verticesCurrentPage: 0,
      verticesPageLimit: 100,
      verticesCount: 0,
      allverticesCount: 0,
      allvertices: false,
      vertices: [],
      currentVertex: null,
      addedVertex: null,
      recordId: null,
      collapsed: false,
      unlockedRecord: null,
      srids: Array.isArray(this.srsIds) ? this.srsIds : [],
      srid: this.srsId
    }
  },
  methods: {
    showCreateGeometryWindow() {
      this.geometryDto.name = 'Point';
      this.isCreateGeometryVisible = true;
    },
    addCoordinate() {
      this.geometryDto.name = 'Coordinate';
      this.geometryDto = {
        name: 'Coordinate',
        x: 0,
        y: 0,
        xD: 0,
        xM: 0,
        xS: 0,
        yD: 0,
        yM: 0,
        yS: 0
      };
      this.createCoordinate();
    },
    cellSpan(cell) {
      if (cell.columnIndex == 0 && cell.row.name == 'Coordinate') {
        return [0, 0];
      }
      if (cell.row.name != 'Coordinate' && cell.columnIndex == 2) {
        return [0, 0];
      }
    },
    namesNormalizer(node){
      return {id: node.id, label: node.name}
    },
    changeVertex(geometryDto) {
      this.currentVertex = geometryDto;
      this.unlockedRecord = geometryDto.id;
    },
    validateCoordinate(e, dotAllowed) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      if (dotAllowed) {
        keysAllowed.push('.');
      }
      if (!keysAllowed.includes(e.key)) {
        e.preventDefault();
      }
    },
    decInputChange() {
      this.calcDMS(this.vertices, this.currentVertex.id);
    },
    dmsInputChange() {
      this.calcDecimal(this.vertices, this.currentVertex.id);
    },
    async srsChange(newValue) {
      const data = await APIClient.shared.request(
        new CoordinateSystemAPI.GetCoordinateSystemBySrid(newValue)
      );
      if (Array.isArray(data)) {
        MapManager.addProjection("EPSG:" + data[0].auth_srid, data[0].proj4text);
      }
      const prevSrid = this.srid;
      this.srid = newValue;

      const units = MapManager.getProjectionUnits(this.srid);
      const prevUnits = MapManager.getProjectionUnits(prevSrid);
      if ((prevUnits == "degrees" && units != "degrees")) {
        this.swapCoordinates(this.vertices);
        this.transformDecimal(this.vertices, prevSrid, this.srid);
      }
      if ((prevUnits != "degrees" && units == "degrees")) {
        this.transformDecimal(this.vertices, prevSrid, this.srid);
        this.swapCoordinates(this.vertices);
      }
      if ((prevUnits != "degrees" && units != "degrees")) {
        this.transformDecimal(this.vertices, prevSrid, this.srid);
      }
      this.calcDMS(this.vertices);
    },
    submit(callback) {
      if (typeof callback == 'function') {
        callback();
      }
      return this.save();
    },
    save() {
      if (MapManager.getProjectionUnits(this.srid) == "degrees") {
        this.swapCoordinates(this.vertices);
      }
      if (this.type == 'edit') {
        return MapManager.updateFeatureFromVertices(this.vertices, this.feature, this.srid);
      } else {
        return MapManager.createFeatureFromVertices(this.vertices, this.layer, this.srid);
      }
    },
    collapse() {
      if (!this.collapsed && this.type == 'edit') {
        this.collapsed = true;
        MapManager.fitFeatures(this.map, MapManager.getSelectedFeatures(this.map));
      }
    },
    selectVertex(vertex) {
      let rows = this.vertices.filter(el => {
        return el.id == vertex.id
      });
      if (rows.length) {
        this.$refs.verticesTable.setCurrentRow(rows[0]);
      }
    },
    loadVertices(callback) {
      if (this.type == 'edit') {
        this.$nextTick(() => {
          this.vertices = this.feature.getVertices(this.srid);
          if (MapManager.getProjectionUnits(this.srid) == "degrees") {
            this.swapCoordinates(this.vertices);
          }
          this.calcDMS(this.vertices);
          this.calcOrder(this.vertices);
        });
      }
    },
    deleteVertex(vertex) {
      this.unlockedRecord = null;
      this.loading = true;
      this.$nextTick(() => {
        this.deleteVertexRecursively(this.vertices, vertex);
        this.calcOrder(this.vertices);
        this.loading = false;
      });
    },
    deleteVertexRecursively(vertices, vertex) {
      if (Array.isArray(vertices)) {
        for (let i = 0; i < vertices.length; i += 1) {
          if (vertices[i].id == vertex.id) {
            vertices.splice(i, 1);
            return;
          }
          if (Object.prototype.hasOwnProperty.call(vertices[i], 'children')) {
            this.deleteVertexRecursively(vertices[i].children, vertex);
          }
        }
      }
    },
    createGeometry() {
      switch(this.geometryDto.name) {
        case "MultiPoint":
        case "MultiLineString":
        case "MultiPolygon":
        case "GeometryCollection":
          this.createComplexGeometry();
          break;
        case "Polygon":
          this.createPolygonGeometry();
          break;
        case "Point":
          this.createPointGeometry();
          break;
        case "LineString":
          this.createLineStringGeometry();
          break;
        case "Point":
          this.createPointGeometry();
          break;
        default:
          break;
        }
        this.isCreateGeometryVisible = false;
    },
    createComplexGeometry() {
      if (this.vertices.length) {
        this.$message({
          message: this.$locale.main.message.invalid_operation,
          type: 'warning'
        });
        return;
      } else {
        this.vertices.push({
          id: this.generateGuid(),
          name: this.geometryDto.name,
          children: []
        });
      }
    },
    createPointGeometry() {
      this.createSimpleGeometry("MultiPoint");
    },
    createLineStringGeometry() {
      this.createSimpleGeometry("MultiLineString");
    },
    createSimpleGeometry(type) {
      if (this.vertices.length && (this.vertices[0].name == type || this.vertices[0].name == "GeometryCollection")) {
        this.vertices[0].children.push({
          id: this.generateGuid(),
          name: this.geometryDto.name,
          children: []
        });
      } else if (this.vertices.length == 0) {
        this.vertices.push({
          id: this.generateGuid(),
          name: this.geometryDto.name,
          children: []
        });
      } else {
        this.$message({
          message: this.$locale.main.message.invalid_operation,
          type: 'warning'
        });
        return;
      }
    },
    createPolygonGeometry() {
      if (this.vertices.length && (this.vertices[0].name == "MultiPolygon" || this.vertices[0].name == "GeometryCollection")) {
        this.vertices[0].children.push({
          id: this.generateGuid(),
          name: this.geometryDto.name,
          children: []
        });
      } else if (this.vertices.length == 0 || (this.vertices.length && this.vertices[0].name == "Polygon")) {
        this.vertices.push({
          id: this.generateGuid(),
          name: this.geometryDto.name,
          children: []
        });
      } else {
        this.$message({
          message: this.$locale.main.message.invalid_operation,
          type: 'warning'
        });
        return;
      }
    },
    createCoordinate() {
      if (this.currentVertex == null || !this.coordinateAddAllowed.includes(this.currentVertex.name)) {
        this.$message({
          message: this.$locale.main.message.invalid_operation,
          type: 'warning'
        });
        return;
      } else {
        let opType = this.currentVertex.name == 'Coordinate' ? 'coordinate' : 'geometry';
        this.addCoordinateRecursively(opType, this.vertices);
        this.calcOrder(this.vertices);
        if (this.dmsMode) {
          this.calcDecimal(this.vertices, this.addedVertex.id);
        } else {
          this.calcDMS(this.vertices, this.addedVertex.id);
        }
      }
    },
    addCoordinateRecursively(opType, vertices) {
      for (let i = 0; i < vertices.length; i += 1) {
        if (opType == "coordinate" && vertices[i].id == this.currentVertex.id) {
          vertices.splice(i + 1, 0, {
            id: this.generateGuid(),
            name: this.geometryDto.name,
            x: this.geometryDto.x,
            y: this.geometryDto.y,
            xD: this.geometryDto.xD,
            xM: this.geometryDto.xM,
            xS: this.geometryDto.xS,
            yD: this.geometryDto.yD,
            yM: this.geometryDto.yM,
            yS: this.geometryDto.yS
          });
          this.addedVertex = vertices[i+1];
          return;
        } else if (vertices[i].id == this.currentVertex.id) {
          vertices[i].children.push({
            id: this.generateGuid(),
            name: this.geometryDto.name,
            x: this.geometryDto.x,
            y: this.geometryDto.y,
            xD: this.geometryDto.xD,
            xM: this.geometryDto.xM,
            xS: this.geometryDto.xS,
            yD: this.geometryDto.yD,
            yM: this.geometryDto.yM,
            yS: this.geometryDto.yS
          });
          this.addedVertex = vertices[i].children[vertices[i].children.length-1];
          return;
        } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
          this.addCoordinateRecursively(opType, vertices[i].children);
        }
      }
    },
    calcOrder(vertices) {
      if (Array.isArray(vertices)) {
        for (let i = 0; i < vertices.length; i += 1) {
          if (vertices[i].name == "Coordinate") {
            vertices[i].num = i + 1;
          } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
            this.calcOrder(vertices[i].children);
        }
        }
      }
    },
    calcDMS(vertices, id) {
      for (let i = 0; i < vertices.length; i++) {
        if (vertices[i].name == "Coordinate") {
          if (id && vertices[i].id != id) {
            continue;
          }
          vertices[i].x = parseFloat(vertices[i].x);
          const xDMS = this.decimalToDMS(vertices[i].x);
          this.$set(vertices[i] ,"xD", xDMS.d);
          this.$set(vertices[i] ,"xM", xDMS.m);
          this.$set(vertices[i] ,"xS", xDMS.s);
          vertices[i].y = parseFloat(vertices[i].y);
          const yDMS = this.decimalToDMS(vertices[i].y);
          this.$set(vertices[i] ,"yD", yDMS.d);
          this.$set(vertices[i] ,"yM", yDMS.m);
          this.$set(vertices[i] ,"yS", yDMS.s);
        } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
          this.calcDMS(vertices[i].children, id);
        }
      }
    },
    calcDecimal(vertices, id) {
      for (let i = 0; i < vertices.length; i++) {
        if (vertices[i].name == "Coordinate") {
          if (id && vertices[i].id != id) {
            continue;
          }
          vertices[i].x = this.dmsToDecimal(vertices[i]["xD"], vertices[i]["xM"], vertices[i]["xS"]);
          vertices[i].y = this.dmsToDecimal(vertices[i]["yD"], vertices[i]["yM"], vertices[i]["yS"]);
        } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
          this.calcDecimal(vertices[i].children, id);
        }
      }
    },
    decimalToDMS(coordinate) {
      const d = Math.trunc(coordinate);
      const m = Math.trunc((coordinate - d) * 60);
      let s =  (coordinate - d - m / 60) * 3600;
      s = parseFloat(s.toFixed(3));
      return {d: d, m: m, s: s};
    },
    dmsToDecimal(d, m, s) {
      return parseInt(d) + parseInt(m) / 60 + parseFloat(s) / 3600;
    },
    transformDecimal(vertices, fromSrid, toSrid) {
      for (let i = 0; i < vertices.length; i++) {
        if (vertices[i].name == "Coordinate") {
          const coord = MapManager.transformCoordinates([vertices[i].x, vertices[i].y], fromSrid, toSrid);
          vertices[i].x = coord[0];
          vertices[i].y = coord[1];
        } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
          this.transformDecimal(vertices[i].children, fromSrid, toSrid);
        }
      }
    },
    swapCoordinates(vertices) {
      for (let i = 0; i < vertices.length; i++) {
        if (vertices[i].name == "Coordinate") {
            [vertices[i].x, vertices[i].y] = [vertices[i].y, vertices[i].x];
        } else if (Object.prototype.hasOwnProperty.call(vertices[i], "children")) {
          this.swapCoordinates(vertices[i].children);
        }
      }
    },
    async loadCoordinateSystems() {
      const userId = this.$store.getters['Authorization/userId'];
      if (!userId) {
          return;
      }
      try {
        const data = await APIClient.shared.request(
          new CoordinateSystemAPI.GetCoordinateSystemsByUserId(userId)
        );
        if (Array.isArray(data)) {
          data.forEach(el => {
            if (el.auth_srid) {
              this.addCoordinateSystem(el.auth_name, el.auth_srid, el.proj4text);
            }
          });
        }
      } catch(e) {
      }
    },
    addCoordinateSystem(authName, authSrid, proj4text) {
      const existing = this.srids.find((el) => { return el.srid == authSrid; });
      if (!existing) {
        let code = 'EPSG:' + authSrid;
        MapManager.addProjection(code, proj4text);
        if (authName != 'EPSG') {
          code = '(' + code + ') ' + authName;
        } else {
          const regExp = new RegExp('(?<=\\+title=)(.*?)(?=\\+)')
          let r = proj4text.match(regExp);
          if (r) {
            code = r[0] + ' (' + code + ')';
          }
        }
        this.srids.push({
          label: code,
          value: authSrid,
          srid: authSrid,
          auth_name: authName,
          auth_srid: authSrid,
          proj4text: proj4text
        });
      }
    },
  },
  mounted() {
    this.loadVertices(() => {
      this.resizePanel();
    });
    this.loadCoordinateSystems();
  }
}
</script>
