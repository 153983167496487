<template>
  <div class="element-query-builder" :class="{ 'element-query-builder-styled': styled }">
    <element-query-builder-group
      :index="0"
      :query.sync="query"
      :ruleTypes="ruleTypes"
      :rules="mergedRules"
      :maxDepth="maxDepth"
      :depth="depth"
      :styled="styled"
      :labels="mergedLabels"
      :size-control="sizeControl"
      :value-type="type"
      type="query-builder-group"
    ></element-query-builder-group>
  </div>
</template>

<script>
import ElementQueryBuilderGroup from './components/ElementQueryBuilderGroup.vue'
import deepClone from './utilities.js'

const defaultLabels = {
  matchType: 'Match Type',
  matchTypes: [
    { 'id': 'all', 'label': 'All' },
    { 'id': 'any', 'label': 'Any' }
  ],
  addRule: 'Add Rule',
  removeRule: '<i class="el-icon-delete"></i>',
  addGroup: 'Add Group',
  removeGroup: '<i class="el-icon-delete"></i>',
  textInputPlaceholder: 'value',
  rulePlaceholder: 'Select rule'
}

export default {
  name: 'ElementQueryBuilder',

  components: {
    ElementQueryBuilderGroup
  },

  props: {
    fields: {
      type: Array,
      default () {
        return []
      }
    },
    rules: Array,
    labels: {
      type: Object,
      default () {
        return defaultLabels
      }
    },
    type: {
      type: String,
      default () {
        return 'static'
      }
    },
    styled: {
      type: Boolean,
      default: true
    },
    maxDepth: {
      type: Number,
      default: 3,
      validator: function (value) {
        return value >= 1
      }
    },
    value: Object,
    sizeControl: {
      type: String,
      default: 'mini'
    }
  },

  data () {
    return {
      depth: 1,
      query: {
        logicalOperator: this.labels.matchTypes[0].id,
        children: []
      },
      ruleTypes: {
        'text': {
          operators: ['equals', 'does not equal', 'contains', 'does not contain', 'is empty', 'is not empty', 'begins with', 'ends with'],
          inputType: 'text',
          id: 'text-field'
        },
        'numeric': {
          operators: ['=', '<>', '<', '<=', '>', '>='],
          inputType: 'number',
          id: 'number-field'
        },
        'custom': {
          operators: [],
          inputType: 'text',
          id: 'custom-field'
        },
        'radio': {
          operators: [],
          choices: [],
          inputType: 'radio',
          id: 'radio-field'
        },
        'checkbox': {
          operators: [],
          choices: [],
          inputType: 'checkbox',
          id: 'checkbox-field'
        },
        'select': {
          operators: [],
          choices: [],
          inputType: 'select',
          id: 'select-field'
        },
        'multi-select': {
          operators: ['='],
          choices: [],
          inputType: 'select',
          id: 'multi-select-field'
        }
      },
      fieldTypeToOperationType: {
        string_field: [
          'eq', 'neq', 'in', 'not_in', 'is_null', 'is_not_null', 'like', 'not_like'
        ],
        text_field: [
          'eq', 'neq', 'is_null', 'is_not_null', 'like', 'not_like'
        ],
        integer_field: [
          'eq', 'neq', 'in', 'not_in', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        float_field: [
          'eq', 'neq', 'in', 'not_in', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        date_field: [
          'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        time_field: [
          'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        datetime_field: [
          'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
        ],
        boolean_field: [
          'eq', 'neq', 'is_null', 'is_not_null'
        ],
        address_field: [
          'eq', 'neq', 'is_null', 'is_not_null'
        ],
        gar_address_field: [
          'eq', 'neq', 'is_null', 'is_not_null'
        ],
      },
      operationTypes: [
        {
          'id': 'eq',
          'name': 'eq'
        },
        {
          'id': 'neq',
          'name': 'neq'
        },
        {
          'id': 'in',
          'name': 'in'
        },
        {
          'id': 'not_in',
          'name': 'not_in'
        },
        {
          'id': 'gt',
          'name': 'gt'
        },
        {
          'id': 'lt',
          'name': 'lt'
        },
        {
          'id': 'gte',
          'name': 'gte'
        },
        {
          'id': 'lte',
          'name': 'lte'
        },
        {
          'id': 'is_null',
          'name': 'is_null'
        },
        {
          'id': 'is_not_null',
          'name': 'is_not_null'
        },
        {
          'id': 'like',
          'name': 'like'
        },
        {
          'id': 'not_like',
          'name': 'not_like'
        }
      ],
      fieldTypeToPlaceholder: {
        string_field: 'Значение: "Текст..."',
        text_field: 'Значение: "Текст..."',
        integer_field: 'Число: 777',
        float_field: 'Дробное число: 99.9',
        date_field: 'Дата: 01.01.2020',
        time_field: 'Время: 08:00:00',
        datetime_field: 'Дата и время: 01.01.2020 08:00:00',
        boolean_field: null,
        address_field: 'Адрес',
        gar_address_field: 'Адрес ГАР'
      },
      fieldTypeToQueryBuilderType: {
        string_field: 'text',
        text_field: 'textarea',
        integer_field: 'integer',
        float_field: 'float',
        date_field: 'date',
        time_field: 'time',
        datetime_field: 'datetime',
        boolean_field: 'checkbox',
        address_field: 'text',
        gar_address_field: 'text'
      },
      typeList: ['static', 'dynamic']
    }
  },

  computed: {
    mergedLabels () {
      return Object.assign({}, defaultLabels, this.labels)
    },

    mergedRules () {
      var mergedRules = []
      var vm = this

      vm.rules.forEach(function (rule) {
        if (typeof vm.ruleTypes[rule.type] !== 'undefined') {
          mergedRules.push(Object.assign({}, vm.ruleTypes[rule.type], rule))
        } else {
          mergedRules.push(rule)
        }
      })

      return mergedRules
    }
  },

  mounted () {
    this.$watch(
      'query',
      newQuery => {
        if (JSON.stringify(newQuery) !== JSON.stringify(this.value)) {
          this.$emit('input', deepClone(newQuery))
        }
      }, {
        deep: true
      })

    this.$watch(
      'value',
      newValue => {
        if (JSON.stringify(newValue) !== JSON.stringify(this.query)) {
          this.query = deepClone(newValue)
        }
      }, {
        deep: true
      })

    if (typeof this.$options.propsData.value !== 'undefined') {
      this.query = Object.assign(this.query, this.$options.propsData.value)
    }
  },

  methods: {
    buildRules () {
      this.fields.forEach(field => {
        if (field.id) {
          let operators = []
          this.operationTypes.forEach(item => {
            if (this.fieldTypeToOperationType[field.entity_type_id].indexOf(item.id) !== -1) {
              operators.push({
                id: item.id,
                label: this.$locale.bi_editor.operation_types[item.name]
              })
            }
          })

          this.rules.push({
            id: field.id,
            label: field.name,
            inputType: this.fieldTypeToQueryBuilderType[field.entity_type_id],
            operators: operators,
            operands: undefined,
            placeholder: this.fieldTypeToPlaceholder[field.entity_type_id]
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .element-query-builder {
    & .vqb-group {
      padding: 5px 20px;

      & .rule-actions {
        margin-bottom: 20px;
      }

      &:first-child {
        padding-right: 20px;
      }
    }

    & .vqb-rule {
      margin-top: 15px;
      margin-bottom: 15px;
      background-color: #f5f5f5;
      border-color: #ddd;
      padding: 15px;
      position: relative;

      & label {
        margin-right: 10px;
      }
    }

    & .vqb-rule,
    & .vqb-group {
      padding-right: 0;

      &:before {
        content:"\A";
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #ddd;
        position: absolute;
        left: 0;
        top: 19px;
      }
    }

    & .vqb-group.depth-1 .vqb-rule,
    & .vqb-group.depth-2 {
      border-left: 2px solid #8bc34a;

      &:before {
        border-left-color: #8bc34a;
      }
    }

    & .vqb-group.depth-2 .vqb-rule,
    & .vqb-group.depth-3 {
      border-left: 2px solid #00bcd4;

      &:before {
        border-left-color: #00bcd4;
      }
    }

    & .vqb-group.depth-3 .vqb-rule,
    & .vqb-group.depth-4 {
      border-left: 2px solid #ff5722;

      &:before {
        border-left-color: #ff5722;
      }
    }

    & .close {
      opacity: 1;
      color: rgb(150,150,150);
    }
  }
</style>
