<template>
  <el-collapse class="editor-wrapper" value="editor-input-label">
    <el-collapse-item :title="$locale.interface_editor.component_editor.editor_open_card.label" name="editor-input-label">
      <template slot="title">
        <span class="editor-property-label">{{ $locale.interface_editor.component_editor.editor_open_card.label }}</span>
        <!-- <el-tooltip placement="top-start" :open-delay="400">
          <div slot="content" v-html="''"></div>
          <i class="el-icon-question"></i>
        </el-tooltip> -->
      </template>

      <template v-if="!query">
        <el-form :disabled="disabled">
          <el-form-item v-if="!isHeaders || sourceType === 'registry'">
            <registry-select
              :append-to-body="false"
              :label="$locale.interface_editor.component_editor.editor_open_card.select_registry"
              :value="value.card.registryId"
              @change="$set(value.card ,'registryId', $event)"
            ></registry-select>
          </el-form-item>
          <el-form-item v-if="isTreeSelect && sourceType === 'registry'">
            <span class="property_label">{{ $locale.interface_editor.component_editor.editor_open_card.select_registry }}</span>
            <treeselect
              ref="registryFieldSelect"
              :disabled="disabled"
              :options="dataTree"
              :placeholder="$locale.main.placeholder.select"
              class="custom_scrollbar"
              :value="valueTree"
              :load-options="loadOptions"
              :loading-text="$locale.main.message.loading"
              :normalizer="registryFieldsSettings.normalizer"
              :disable-branch-nodes="true"
              :clear-on-select="true"
              :clearValueText="registryFieldsSettings.clearValueText"
              :noChildrenText="registryFieldsSettings.noChildrenText"
              :noResultsText="registryFieldsSettings.noResultsText"
              :limitText="registryFieldsSettings.limitText"
              :matchKeys="registryFieldsSettings.matchKeys"
              value-format="object"
              :auto-load-root-options="false"
              @select="selectFieldCurrentTable($event)"
              :value-consists-of="registryFieldsSettings.valueConsistsOf"
            >
              <div slot="value-label" slot-scope="{ node }">{{ node.label }} <b> (id: {{ node.raw.id }})</b></div>
              <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName }" :class="labelClassName">
                {{ node.label }}(id: {{node.id}}, <b>{{ $locale.object_editor.entity_types[node.raw.entity_type_id] || node.raw.entity_type_id }}</b>)
              </label>
            </treeselect>
          </el-form-item>
          <el-form-item v-if="!isHeaders || sourceType === 'registry'">
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_open_card.constant_record_id }}</span>
            <el-tooltip placement="top-start" :open-delay="400">
              <div slot="content" v-html="$locale.interface_editor.component_editor.editor_open_card.tooltip.constant_record_id"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
            <el-input v-model="value.card.constantRecordId" :disabled="disabled"></el-input>
          </el-form-item>
          <el-form-item v-if="!isHeaders">
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_open_card.field_record_id }}</span>
            <el-tooltip placement="top-start" :open-delay="400">
              <div slot="content" v-html="$locale.interface_editor.component_editor.editor_open_card.tooltip.field_record_id"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
            <el-select
              v-if="!isHeaders && (sourceType !== 'extended_object' && sourceType !== 'query')"
              v-model="value.card.fieldId"
              clearable
            >
              <el-option
                v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
                :key="index"
                :label="(item.name.match(/attr_(.*?)_/g) || []).length ? `${item.name}` : `${item.name} ${item.properties.name}`"
                :value="item.properties.name"
              ></el-option>
            </el-select>
            <selectTree
              v-else
              :clearable="false"
              ref="entity_tree"
              :dataTable="fields"
              :disabled="disabled"
              class="custom_scrollbar"
              :appendToBody='false'
              v-model="value.card.fieldId"/>
          </el-form-item>
          <el-form-item v-if="isButton || sourceType === 'registry'">
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_open_card.source_table_attrs') }}</span>
            <editor-select
              :value="value.card.fieldId"
              @change="$set(value.card ,'fieldId', $event)"
              :options="{
              multiple: false,
              clearable: true,
              options: sourceTableAttrs
            }"
            ></editor-select>
          </el-form-item>
            <el-form-item v-if="isHeaders && sourceType !== 'registry'">
              <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.registry_id }}</span>
  <!--            <el-input type="text" size="small" v-model.trim="value.card.queryRegistry"></el-input>-->
              <editor-select
                  :value="value.card.queryRegistry"
                  @change="$set(value.card ,'queryRegistry', $event)"
                  :options="{
              multiple: false,
              clearable: true,
              options: sourceTableAttrs
            }"
              ></editor-select>
            </el-form-item>
            <el-form-item v-if="isHeaders && sourceType !== 'registry'">
              <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.card_id }}</span>
  <!--            <el-input type="text" size="small" v-model.trim="value.card.queryCard"></el-input>-->
              <editor-select
                  :value="value.card.queryCard"
                  @change="$set(value.card ,'queryCard', $event)"
                  :options="{
              multiple: false,
              clearable: true,
              options: sourceTableAttrs
            }"
              ></editor-select>
            </el-form-item>
            <el-form-item v-if="isHeaders && sourceType !== 'registry'">
              <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.field_id }}</span>
  <!--            <el-input type="text" size="small" v-model.trim="value.card.queryRecord"></el-input>-->
              <editor-select
                  :value="value.card.queryRecord"
                  @change="$set(value.card ,'queryRecord', $event)"
                  :options="{
              multiple: false,
              clearable: true,
              filterable: true,
              options: sourceTableAttrs
            }"
              ></editor-select>
            </el-form-item>
          <el-form-item v-if="!isHeaders || sourceType === 'registry'">
            <span class="property_label">{{ $locale.interface_editor.component_editor.table.card }}</span>
            <el-select
              v-model="value.card.cardId"
              clearable
              :disabled="disabled"
            >
              <el-option v-for="(item, index) in cards" :key="index" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }} (id: {{ item.id }})</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.editor_open_card.open_type }}</span>
            <el-select
              :value="value.card.type"
              value-key="id"
              filterable
              :disabled="disabled"
              @change="$set(value.card ,'type', $event )"
            >
              <el-option
                v-for="(item, index) in $locale.interface_editor.component_editor.editor_open_card.open_types"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <editor-filters
              :value="value.card.defaults"
              @change="$set(value.card ,'defaults', $event)"
              :title="$locale.main.fields.default"
              :show-xref-option="false"/>
          </el-form-item>
          <el-form-item v-if="!isButton || isHeaders">
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.frame }}</span>
            <el-select v-model="value.card.frameGuid" clearable>
              <el-option
                v-for="(item, index) in components.filter((item) => item.name === 'basic/a-frame')"
                :key="index"
                :label="item.properties.editorAlias ? item.properties.editorAlias : item.name"
                :value="item.guid"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="isButton || !isHeaders">
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_open_card.container }}</span>
            <el-tooltip placement="top-start" :open-delay="400">
              <div slot="content" v-html="$locale.interface_editor.component_editor.editor_open_card.tooltip.container"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
            <el-select v-model="value.card.containerAlias" clearable>
              <el-option
                v-for="(item, index) in containers"
                :key="index"
                :label="item.alias"
                :value="item.alias"
              ></el-option>
            </el-select>
            <el-form-item class="checkbox" v-if="value.card.containerAlias">
              <el-checkbox v-model="value.card.breadcrumbByButton"></el-checkbox>
              <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.breadcrumb }}</span>
            </el-form-item>
          </el-form-item>
        </el-form>
      </template>

      <template v-if="showSettingOpenInWindow">
        <el-form :disabled="disabled">
          <el-form-item>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.open_in_window }}</span>
            <el-checkbox v-model="value.card.isWindow"></el-checkbox>
          </el-form-item>
          <el-form-item v-if="value.card.isWindow && (value.type === 'open_card' || value.type === 'open_dashboard' || value.card.type === 'update')">
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_title }}</span>
            <el-input v-model="value.card.windowTitle" />
          </el-form-item>
          <el-form-item v-if="value.card.isWindow && (value.type === 'open_card' || value.type === 'open_dashboard' || value.card.type === 'update')">
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_width }}</span>
            <editor-select
              :value="value.card.windowWidth"
              @change="$set(value.card ,'windowWidth', $event)"
              :options="{
                  multiple: false,
                  options: [
                    { id: '25', name:'25%' },
                    { id: '50', name:'50%' },
                    { id: '75', name:'75%' },
                    { id: '100', name:'100%' },
                  ]
                }"
            ></editor-select>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_height }} (ex: 400px)</span>
            <el-input :value="value.card.windowHeight" @input="$set(value.card ,'windowHeight', $event)"></el-input>
          </el-form-item>
        </el-form>
      </template>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
// import EditorRegistrySelect from './editor-registry-select'
import EditorFilters from './editor-filters.vue'
import EditorSelect from './editor-select'
import Card from '@/components/ObjectEditor/Models/Card'
import Treeselect, { LOAD_CHILDREN_OPTIONS, LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import SelectTree from '@/components/LogicEditor/components/treeSelect'
import Registry from '@/components/LogicEditor/Models/Registry'
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
import { buildFilters } from '@/components/InterfaceEditor/components/utils'

export default {
  name: 'editor-open-card',
  components: {
    // EditorRegistrySelect,
    EditorSelect,
    Treeselect,
    SelectTree,
    EditorFilters,
    RegistrySelect
  },
  inject: {
    getContainersStore: {
      default: () => () => {}
    },
    getComponents: {
      default: () => {
        return []
      }
    }
  },
  props: {
    value: {
      type: Object
    },
    listExtendObject: {
      type: Array
    },
    listField: {
      type: Array,
      default: () => []
    },
    isTreeSelect: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: true
    },
    xrefObjectId: {
      type: Number
    },
    query: {
      type: Boolean,
      default: false
    },
    showSettingOpenInWindow: {
      type: Boolean,
      default: true
    },
    isHeaders: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: String
    },
    sourceId: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      components: this.getComponents(),
      containersStore: this.getContainersStore(),
      cards: [],
      loading: false,
      dataTree: null,
      valueTree: null,
      fields: [],
      sourceTableAttrs: [],
      registryFieldsSettings: {
        matchKeys: ['name', 'id'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noResultsText: 'Не найдено',
        limitText: count => `и еще ${count}`,
        normalizer (node) {
          return {
            id: node.id,
            label: node.name,
            children: node.children,
            isLeaf: node.leaf
          }
        }
      }
    }
  },
  computed: {
    containers () {
      if (!this.containersStore) {
        return
      }

      return this.containersStore.getFlat().filter(container => container.alias != null)
    }
  },
  watch: {
    'value.card.registryId': {
      async handler (value) {
        if (value) {
          await this.loadCards(value)
        }
      }
    }
  },
  async created () {
    console.log(this.components)
    if (this.value.card && this.value.card.registryId) {
      this.loadCards(this.value.card.registryId)
      let parent = await this.$http.get(`${this.$config.api}/objecteditor/entities/${this.value.card.registryId}`)
      this.valueTree = parent.data.data
    } else if (this.value.card && this.value.card.queryRegistry) {
      // Источник запрос
      console.log('source query')
    } else {
      this.$set(this.value, 'card', {
        registryId: null,
        type: null,
        fieldId: null,
        cardId: null,
        queryRegistry: null,
        queryCard: null,
        queryRecord: null,
        isWindow: false,
        windowWidth: 25,
        windowTitle: null,
        frameGuid: null,
        defaults: []
      })
    }
    if (!this.isButton && (this.listExtendObject || []).length === 0) {
      // Источником таблицы является реестр
      if (this.xrefObjectId) {
        this.fields = await Registry.params({ parent_id: this.xrefObjectId, show_children: true }).$get()
      }
    } else if ((this.listExtendObject || []).length > 0) {
      // Источником таблицы является расширенная таблица
      this.fields = this.listExtendObject.map((item) => {
        return {
          id: item.name,
          name: item.name
        }
      })
    }
    if (this.listField.length) {
      this.fields = this.listField
    }
    if (this.isHeaders) {
      await this.getAttrs(this.sourceId, this.sourceType)
    }
  },
  methods: {
    async loadCards (entityId) {
      this.cards = await new Card().params({ entity_id: entityId, fields: ['id', 'name'] }).$get()
    },
    selectFieldCurrentTable (e) {
      this.$set(this.value.card, 'registryId', e.id)
      // this.value.card.registryId = e.id
    },
    deepSearch (arr, multi = false) {
      for (let el of arr) {
        if (el.entity_type_id === 'registry_group') el.children = null
        // if (!el.leaf && multi) el.isDisabled = true
        // if (el.children instanceof Array && el.children.length) this.deepSearch(el.children)
      }
      return arr
    },
    async loadOptions ({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        let children = await this.$http.get(`${this.$config.api}/objecteditor/entities/${parentNode.id}/children`)
        parentNode.children = this.deepSearch(children.data.data)
        callback()
      }
      if (action === LOAD_ROOT_OPTIONS) {
        let response = await this.$http.get(`${this.$config.api}/objecteditor/entities?root=true`)
        this.dataTree = this.deepSearch(response.data.data)
        callback()
      }
    },
    async getAttrs (entityId, sourceType) {
      let result
      let response

      switch (sourceType) {
        case 'query':
          response = await this.$http.post(
            `${this.$config.api}/datawarehouseservice/query/${entityId}`,
            null,
            { hideNotification: true }
          )
          result = response.data
          this.sourceTableAttrs = Object.entries(result[0]).map(function (item) { return { id: item[0], name: item[0] } })
          break
        case 'registry':
          response = await Registry.params({ parent_id: entityId, show_children: true }).$get()
          this.sourceTableAttrs = this.parseNodes(response).flat()
          await this.loadCards(entityId)
          break
        case 'extended_object':
          response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects/${entityId}`,
            null,
            { hideNotification: true }
          )
          this.sourceTableAttrs = response.data.extended_object_fields.map(function (item) { return { id: item.name, name: item.name } })
      }
    },
    parseNodes (data) {
      let me = this
      return data.map(function (item) {
        if (item.children.length > 0) {
          return me.parseNodes(item.children)
        } else {
          return { id: `attr_${item.id}_`, name: `${item.name} (id: ${item.id})` }
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
