<template>
  <div class="raster-publish-form custom_scrollbar">
    <el-form :rules="formRules" id="form" ref="form" class="raster-form-inner-body" :model="settingsDto">
      <el-collapse v-model="activeCollapseItems">
        <el-collapse-item :title="$locale.raster_library.workspace" name="1">
          <el-card class="box-card">
            <el-form-item :label="$locale.raster_library.workspace_name">
              <el-input readonly v-model="settingsDto.workspace_name"></el-input>        
            </el-form-item>
          </el-card>
        </el-collapse-item>
        <el-collapse-item :title="$locale.raster_library.storage" name="2">
          <el-card class="box-card">
            <el-form-item :label="$locale.raster_library.storage_name" prop="storage_name">
              <el-input v-model="settingsDto.storage_name"></el-input>        
            </el-form-item>
            <el-form-item :label="$locale.raster_library.storage_description">
              <el-input v-model="settingsDto.storage_description"></el-input>        
            </el-form-item>
          </el-card>
        </el-collapse-item>
        <el-collapse-item :title="$locale.raster_library.layer" name="3">
          <el-card class="box-card">
            <el-form-item :label="$locale.raster_library.layer_name">
              <el-input v-model="settingsDto.layer_name"></el-input>        
            </el-form-item>
            <el-form-item :label="$locale.raster_library.layer_description">
              <el-input v-model="settingsDto.layer_description"></el-input>        
            </el-form-item>
          </el-card>
        </el-collapse-item>
        <el-collapse-item :title="$locale.raster_library.layer_srs" name="4">
          <el-card class="box-card" style="height: 309px;">
            <el-form-item :label="$locale.raster_library.layer_native_srs">
              <el-input readonly v-model="settingsDto.layer_native_srs"></el-input>        
            </el-form-item>
            <el-form-item :label="$locale.raster_library.layer_declared_srs">
              <el-input style="display: none;"></el-input>
              <coordinate-system
                :isDisabled="false"
                :options="srids"
                @change-cs="srsChange">
              </coordinate-system>
            </el-form-item>
            <el-form-item :label="$locale.raster_library.layer_srs_handling">
              <el-select v-model="srs_handling_options_selected">
                <el-option v-for="item in srs_handling_options" :key="item.id" :label="item.value" :value="item.id">
                </el-option>
              </el-select>        
            </el-form-item>
          </el-card>
        </el-collapse-item>
        <el-collapse-item :title="$locale.raster_library.extents" name="5" style="display: none;">
          <el-row>
            {{$locale.raster_library.native_extent}}
          </el-row>
          <el-row>
            <el-col>
              <el-form-item :label="$locale.raster_library.min_x">
                <el-input v-model="nativeExtentDto.min_x"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item :label="$locale.raster_library.min_y">
                <el-input v-model="nativeExtentDto.min_y"></el-input>        
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item :label="$locale.raster_library.max_x">
                <el-input v-model="nativeExtentDto.max_x"></el-input>        
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item :label="$locale.raster_library.max_y">
                <el-input v-model="nativeExtentDto.max_y"></el-input>        
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-link type="primary" @click="calcExtent('')">{{$locale.raster_library.calculate_from_data}}</el-link>
          </el-row>
          <el-row>
            <el-link type="primary" @click="calcExtent('')">{{$locale.raster_library.calculate_from_srs_bounds}}</el-link>
          </el-row>
          <el-row style="margin-top: 15px;">
            {{$locale.raster_library.boinding_layer_lon_lat}}
          </el-row>
          <el-row>
            <el-col>
              <el-form-item :label="$locale.raster_library.min_x">
                <el-input v-model="lonLatExtentDto.min_x"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item :label="$locale.raster_library.min_y">
                <el-input v-model="lonLatExtentDto.min_y"></el-input>        
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item :label="$locale.raster_library.max_x">
                <el-input v-model="lonLatExtentDto.max_x"></el-input>        
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item :label="$locale.raster_library.max_y">
                <el-input v-model="lonLatExtentDto.max_y"></el-input>        
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-link type="primary" @click="calcExtent('')">{{$locale.raster_library.calculate_from_native_extent}}</el-link>
          </el-row>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <el-footer>
      <el-button @click="submit">
        <span v-if="loading"><i class="el-icon-loading"></i></span>
        <span v-else>{{$locale.main.button.save}}</span>
      </el-button>
      <el-button @click="close">{{$locale.main.button.cancel}}</el-button>
    </el-footer>
  </div>
</template>

<script type="ts">
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RasterAPI } from '@/services/RasterLibrary/infrastructure/api/RasterAPI';
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

export default {
  name: 'RasterPublishForm',
  props: ['raster'],
  inject: ['getEventBus'],
  components: {
    CoordinateSystem
  },
  data() {
    return {
      settingsDto: {
        raster_guid: this.raster.getGuid(),
        workspace_name: 'workspace_name',
        storage_name: 'storage_name',
        storage_description: 'storage_description',
        layer_name: 'layer_name',
        layer_description: 'layer_description',
        layer_native_srs: this.raster.getCoordinateSystem()
      },
      nativeExtentDto: {
        min_x: 1,
        min_y: 2,
        max_x: 3,
        max_y: 4
      },
      lonLatExtentDto: {
        min_x: 6,
        min_y: 7,
        max_x: 8,
        max_y: 9 
      },
      srs_handling_options: [
        {
          id: 'force_declared',
          value: this.$locale.raster_library.srs_handling_force_declared
        },
        {
          id: 'reproject',
          value: this.$locale.raster_library.srs_handling_native_to_declared
        },
        {
          id: 'keep_native',
          value: this.$locale.raster_library.srs_handling_keep_native
        }
      ],
      srs_handling_options_selected: 'force_declared',
      activeCollapseItems: ['2', '3', '4', '5'],
      loading: false,
      srids: [],
      formRules: {
        storage_name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: ['blur']
        }
      }
    }
  },
  
  methods: {
    close() {
      this.getEventBus().$emit('showRasterPublishForm', false);
    },
    submit() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const res = await APIClient.shared.request(new RasterAPI.PublishRaster(this.settingsDto));
          this.close();
        } else {
          this.$message.error(this.$locale.raster_library.error_empty_fields);  
        }
      });
    },
    srsChange(newValue) {
      this.settingsDto.declared_srs = newValue;
    },
    async getPublishSettings() {
        const res = await APIClient.shared.request(new RasterAPI.GetRastersPublishSettings(this.raster.getGuid()));
        if (res) {
          this.settingsDto = res;
          this.settingsDto['raster_guid'] = this.raster.getGuid();
          this.settingsDto['layer_native_srs'] = this.raster.getCoordinateSystem();
          this.settingsDto['srs_handling'] = this.srs_handling_options_selected;
          console.log('this.settingsDto', this.settingsDto)
        }
    },
    async calcExtent(type) {
      const params = {
        raster_guid: this.raster.getGuid(),
        layer_declared_srs: this.settingsDto.declared_srs,
        reproject: this.srs_handling_options_selected
      }
      this.settingsDto.native_extent = await APIClient.shared.request(new RasterAPI.GetPublishExtent(params));
    }
  },
  mounted() {
    this.getPublishSettings();
  }
}
</script>