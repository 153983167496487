<template>
  <div class="editor_box object_editor">
    <el-container>
      <el-header height="">
        {{ $locale.object_editor.main.title }}
      </el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="50">
            <div class="tool_box">
              <el-tooltip
                slot="reference"
                class="item"
                effect="dark"
                :content="$locale.object_editor.tooltip.add_entity"
                placement="top">
                <el-dropdown placement="bottom-start" trigger="click" @command="addEntity($event)">
                  <el-button
                    icon="el-icon-plus"
                    size="small"
                    plain>
                    {{ $locale.main.button.add }}
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="type" v-for="(type, index) in getEntityTypes()" :key="index">{{ $locale.object_editor.entity_types[type] || type }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip>
              <el-input class="search_input"
                @keyup.enter.native="onSearch"
                clearable
                placeholder="Поиск"
                size="small"
                style="width: calc(100% - 120px)"
                v-model="searchText">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  :loading="isSearchButtonLoading"
                  :disabled="isDisableButton"
                  @click="onSearch">
                </el-button>
              </el-input>
            </div>
            <el-scrollbar :style="{height:'calc(100vh - 153px)'}" >
              <el-tree
                ref="tree"
                :key="isSearch ? 1 : 2"
                :lazy="!isSearch"
                :data="searchData"
                :props="props"
                node-key="id"
                :load="loadNode"
                :expand-on-click-node="false"
                :default-expand-all="isSearch"
                :default-expanded-keys="isSearch ? undefined : expanded"
                draggable
                @node-drop="moveRow"
                :allow-drop="allowDrop"
                :allow-drag="allowDrag">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span
                    :title="node.label.length > 33 ? node.label +' '+ data.id : ''"
                    :class="{ 'selected-node': editorModel.id === data.id }"
                    @click="openEditor(data, node)">
                    <font-awesome-icon icon="calculator" style="padding-right: 3px" v-if="getFormulaIcon(node, data)"/>
                    <i style="font-size: 16px;" :class="getTreeIcon(node, data)"></i>
                    <b :class="{'pl-5': data.entity_type_id === 'registry_group'}">{{ node.label.length > 33 ? `${node.label.substring(0, 33)}...` : node.label }}</b>
                    <span style="color: #7C838B; padding-left: 6px;"> {{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }} id {{data.id}}</span>
                  </span>
                  <span v-if="editorModel.id === data.id">
                    <el-dropdown @command="addEntity($event, data.id)" v-show="(getEntityTypes(data.entity_type_id) || []).length" trigger="click">
                      <el-button type="text" size="small"><i class="el-icon-plus el-icon--left"></i>{{ $locale.main.button.add }}</el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="type" v-for="(type, index) in getEntityTypes(data.entity_type_id)" :key="index">{{ $locale.object_editor.entity_types[type] || type }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button type="text" size="small" style="color:red" @click="deleteEntity(data.id)"><i class="el-icon-delete el-icon--left"></i>{{ $locale.main.button.delete }}</el-button>
                    <el-button v-show="data.entity_type_id === 'registry'" type="text" size="small" @click="addMainTab({name: data.name, componentType: 'Registry', payload: {id: data.id}})"><i class="el-icon-check el-icon--left"></i>{{ $locale.main.button.preview }}</el-button>
                  </span>
                </span>
              </el-tree>
            </el-scrollbar>
          </SplitArea>
          <SplitArea :size="50">
            <div v-show="showEditor" class="tool_box">
              <div class="tool_header">
                {{ nameObject }} (<b>{{ $locale.object_editor.entity_types[editorModel.entity_type_id]}}</b>)
              </div>
            </div>
            <el-tabs v-show="showEditor" v-model="activeTab">
              <el-tab-pane :label="$locale.object_editor.tabs.settings" name="settings">
                <el-scrollbar style="height: calc(100vh - 188px - 40px);">
                  <el-form ref="form" class="object_form" :model="editorModel" :rules="rules" size="mini" label-position="top">
                    <div class="start_logic" v-show="editorModel.entity_type_id === 'registry'">
                      <el-button @click="openLogicEditor(editorModel)" type="text">{{ $locale.main.button.open_logic_editor }}</el-button>
                    </div>
                    <el-form-item :label="$locale.main.fields.name" prop="name">
                      <el-input v-model="editorModel.name"></el-input>
                    </el-form-item>
                    <el-form-item :label="$locale.main.fields.description">
                      <el-input type="textarea" v-model="editorModel.description"></el-input>
                    </el-form-item>
                    <el-form-item :label="$locale.main.fields.alias">
                      <el-input v-model="editorModel.alias"></el-input>
                    </el-form-item>

                    <component
                      v-for="(property, index) in editorModel.properties"
                      :label="$locale.object_editor.settings.properties[property.id] || property.id"
                      :key="index + '_' + editorModel.id"
                      :is="property.type.trim()"
                      :disabled="property.id"
                      v-model="property.value"
                      :entity-id="editorModel.id"
                      :entity-type-id="editorModel.entity_type_id"
                      :properties="editorModel.properties">
                    </component>
                  </el-form>
                </el-scrollbar>
                <div style="text-align: right;padding-right: 10px">
                  <el-button
                    type="primary"
                    size="small"
                    @click="save()"
                    icon="el-icon-success">
                    {{ $locale.main.button.save }}
                  </el-button>
                </div>
              </el-tab-pane>
              <el-tab-pane :disabled="editorModel.entity_type_id !== 'registry'" :label="$locale.object_editor.tabs.cards" name="cards">
                <el-scrollbar style="height: calc(100vh - 188px);">
                  <div class="card_form">
                    <el-button size="small" @click="addCard(editorModel.id, $locale.object_editor.cards.default_name)">
                      <i class="el-icon-plus el-icon--left"></i> {{ $locale.main.button.add }}
                    </el-button>
                    <el-button size="small" @click="addCard(editorModel.id, $locale.object_editor.cards.default_name, true)">
                      <i class="el-icon-plus el-icon--left"></i> NEW
                    </el-button>
                    <el-table
                      :data="cards"
                      style="width: 100%">
                      <el-table-column
                        prop="id"
                        :label="$locale.main.fields.id"
                        width="80">
                      </el-table-column>
                      <el-table-column
                          prop="name"
                          :label="$locale.main.fields.name">
                        <template slot-scope="scope">
                          <span v-if="!scope.row.editing">{{ scope.row.name }}</span>
                          <el-input v-else size="small" v-model="scope.row.name"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :label="$t('main.fields.alias')"
                      >
                        <template slot-scope="scope">
                          <span v-if="!scope.row.editing">{{ scope.row.alias || null }}</span>
                          <el-input v-else size="small" v-model="scope.row.alias"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="card_roles"
                        :label="$locale.access_editor.roles">
                        <template slot-scope="scope">
                          <el-select v-model="scope.row.card_roles" size="mini" collapse-tags multiple :disabled="!scope.row.editing">
                            <el-option
                              v-for="item in cardRoles"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="card_states"
                        :label="$locale.logic_editor.state.headline">
                        <template slot-scope="scope">
                          <el-select v-model="scope.row.card_states" size="mini" collapse-tags multiple :disabled="!scope.row.editing">
                            <el-option
                              v-for="item in cardStates"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="is_default"
                        :label="$locale.main.fields.default">
                        <template slot-scope="scope">
                          <el-checkbox v-if="!scope.row.editing" :value="scope.row.is_default"></el-checkbox>
                          <el-checkbox v-else v-model="scope.row.is_default"></el-checkbox>
                        </template>
                      </el-table-column>
                      <el-table-column width="160">
                        <template slot-scope="scope">
                          <el-button
                            size="mini"
                            v-if="!scope.row.editing"
                            @click="$set(scope.row, 'editing', true)"><i class="el-icon-edit"></i></el-button>
                          <el-button
                            v-else
                            size="mini"
                            @click="$set(scope.row, 'editing', false);editCard(scope.row)"><i class="el-icon-check"></i></el-button>
                          <el-button
                            :disabled="scope.row.editing"
                            size="mini"
                            @click="openCardEditor(scope.row.id)"><i class="el-icon-right"></i></el-button>
                          <el-popover
                            placement="top"
                            width="160">
                            <p>{{ $locale.main.message.confirm }}</p>
                            <div style="text-align: right; margin: 0">
                              <el-button size="mini" type="text">{{ $locale.main.button.cancel }}</el-button>
                              <el-button type="primary" size="mini" @click="deleteCard(scope.row.id)">{{ $locale.main.button.delete }}</el-button>
                            </div>
                            <el-button
                              :disabled="scope.row.editing"
                              slot="reference"
                              size="mini"
                              type="danger"
                              icon="el-icon-delete">
                            </el-button>
                          </el-popover>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-scrollbar>
              </el-tab-pane>
            </el-tabs>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Entity from './Models/Entity'
import Node from 'element-ui/packages/tree/src/model/node'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'

import boolean from './Properties/boolean'
import varchar from './Properties/varchar'
import integer from './Properties/integer'
import coordinate_system from './Properties/coordinate_system'
import geometry_type from './Properties/geometry_type'
import field from './Properties/field'
import field_array from './Properties/field_array'
import card from './Properties/card'
import unique_fields from './Properties/unique_fields'
import partition_field from './Properties/partition_field'
import address_level from './Properties/address_level'
import object_level from './Properties/object_level'
import address_type from './Properties/address_type'
import card_field from './Properties/card_field'
import Type from './Models/Type'
import Card from './Models/Card'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RoleAPI } from '@/services/AccessEditor/infrastructure/api/RoleAPI'

library.add(faCalculator)

export default {
  name: 'ObjectEditor',

  props: {
    entityId: {
      type: Number,
      default: null
    },
    expanded: {
      type: Array,
      default () {
        return []
      }
    }
  },

  components: {
    boolean,
    varchar,
    integer,
    field,
    field_array,
    card,
    unique_fields,
    coordinate_system,
    geometry_type,
    partition_field,
    address_level,
    object_level,
    address_type,
    card_field,
    FontAwesomeIcon
  },

  inject: ['addMainTab'],

  data () {
    return {
      activeTab: 'settings',
      loading: false,
      props: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'child'
      },
      showEditor: false,
      editorModel: {},
      cards: [],
      activeNode: null,
      entityTypes: {},
      cardRoles: [],
      cardStates: [],
      rules: {
        name: [{ required: true, message: this.$locale.object_editor.rules.name, trigger: 'blur' }]
      },
      isSearch: false,
      searchText: null,
      searchData: [],
      isSearchButtonLoading: null,
      isDisableButton: true,
      defOpen: false
    }
  },

  computed: {
    nameObject () {
      let name = this.editorModel.name
      if (this.editorModel.entity_type_id === 'registry') {
        name += ` object_${this.editorModel.id}_ `
      } else if (this.editorModel.entity_type_id !== 'field_group' && this.editorModel.entity_type_id !== 'registry_group') {
        name += ` attr_${this.editorModel.id}_ `
      } else {
        name += ''
      }
      return name
    }
  },

  mounted () {
    this.loading = true

    console.log('this.entityId', this.entityId, this.expanded)

    if (this.entityId) {
      this.defOpen = true
    }

    this.loading = false
  },

  watch: {
    searchText (value) {
      if (value.length === 0) {
        this.isSearch = false
        this.isDisableButton = true
        this.isSearchButtonLoading = false
      } else {
        this.isDisableButton = false
      }
    }
  },

  methods: {
    async onSearch () {
      if (this.searchText) {
        this.isSearchButtonLoading = true
        this.isSearch = true
        this.$nextTick(async () => {
          let data = await this.$http.get(`${this.$config.api}/objecteditor/entities/search?search_param=${this.searchText}`)
          this.searchData = data.data.data
          this.isSearchButtonLoading = false
        })
      }
    },

    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(await this.loadEntities())
      } else {
        resolve(await this.loadEntities(node.data.id))
      }

      if (this.entityId && this.defOpen) {
        const n = this.$refs.tree.getNode(this.entityId)
        if (n) {
          await this.openEditor(n.data, n)
        }
      }
    },

    async loadEntities (entityId = null) {
      let data = []
      if (!entityId) {
        data = await new Entity().params({ root: true }).$get()
      } else {
        data = await new Entity({ id: entityId }).children().$get()
      }

      return data
    },

    async openEditor (data, node) {
      this.defOpen = false
      let entity = await Entity.$find(data.id)
      if (entity) {
        this.editorModel = entity
        this.showEditor = true
        this.activeNode = node
        this.activeTab = 'settings'
        if (entity.entity_type_id === 'registry') {
          this.loadCards(data.id)
        }
      }
    },

    async loadCards (entityId) {
      let cards = await new Card().params({ entity_id: entityId, order: 'id', fields: ['id', 'name', 'is_default', 'entity_id', 'guid', 'alias', 'card_roles', 'card_states'] }).$get()
      this.cards = cards.map((item) => {
        item.card_roles = JSON.parse(item.card_roles)
        item.card_states = JSON.parse(item.card_states)
        return item
      })
      if (this.cardRoles.length === 0) {
        this.loadRoles()
      }
      this.loadStates(entityId)
    },

    async loadRoles () {
      // this.$http.get(`${this.$config.api}/accesseditor/roles`).then((response) => {
      //   this.cardRoles = response.data
      // })
      try {
        this.cardRoles = await APIClient.shared.request(new RoleAPI.GetRoles([]))
      } catch (error) {
        console.log({ error })
      }
    },

    async loadStates (registryId) {
      this.cardStates = []
      let logicData = await this.$http.get(`${this.$config.api}/logiceditor/logics?registry_id=${registryId}`)
      let logicId = (logicData.data[0] || {}).id
      if (logicId) {
        let data = await this.$http.get(`${this.$config.api}/logiceditor/states?logic_id=${logicId}`)
        this.cardStates = data.data
      }
    },

    addCard (entityId, name, isNewDesigner = false) {
      if (!entityId || !name) {
        return false
      }
      let card = new Card({})
      card.entity_id = entityId
      card.name = name
      card.save().then(async (response) => {
        if (isNewDesigner) {
          card.id = response.id
          card.sync(Object.assign(response, { structure: { version: 2 } }))
        }
        this.cards.push(await new Card().params({ fields: 'id,name,alias,is_default,entity_id,guid' }).find(response.id))
      })
    },

    async deleteCard (cardId) {
      if (!cardId) {
        return false
      }
      let card = new Card({ id: cardId })
      await card.delete()
      this.cards = this.cards.filter((card) => { return card.id !== cardId })
    },

    openCardEditor (cardId) {
      if (!cardId || !this.editorModel.id) {
        return false
      }
      this.addMainTab({ name: this.cards.find((item) => { return item.id === cardId }).name,
        componentType: 'CardEditor',
        payload: {
          registry_id: this.editorModel.id,
          card_id: cardId
        } })
    },

    async editCard (card) {
      await card.save()
    },

    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!this.editorModel.id) {
            return false
          }
          let entity = new Entity({ id: this.editorModel.id })
          entity.name = this.editorModel.name
          entity.description = this.editorModel.description
          entity.alias = this.editorModel.alias
          entity.properties = this.editorModel.properties
          let response = await entity.save()
          this.$set(this.activeNode.data, 'name', response.name)
        }
      })
    },

    getEntityTypes (parentType = null) {
      if (!parentType) {
        if (!this.entityTypes['root']) {
          new Type().params({ root: true }).$get().then((data) => {
            this.$set(this.entityTypes, 'root', data.map((item) => item.name()))
          })
        }
        return this.entityTypes['root']
      } else {
        if (!this.entityTypes[parentType]) {
          new Type({ id: parentType }).children().$get().then((data) => {
            this.$set(this.entityTypes, parentType, data.map((item) => item.name()))
          })
        }
        return this.entityTypes[parentType]
      }
    },

    addEntity (type, parent = null) {
      this.$prompt(this.$locale.object_editor.add_entity.name_field, `${this.$locale.object_editor.add_entity.title} (${this.$locale.object_editor.entity_types[type] || type})`, {
        confirmButtonText: this.$locale.main.button.add,
        cancelButtonText: this.$locale.main.button.cancel,
        closeOnClickModal: false,
        inputValidator (value) {
          return !!(value || '').trim()
        }
      }).then(async ({ value }) => {
        let entity = new Entity({})
        entity.name = value
        entity.type_id = type
        entity.parent_id = parent
        let response = await entity.save()
        if (parent) {
          this.$refs.tree.append(response, parent)
        } else {
          let node = new Node({ parent: this.$refs.root, store: this.$refs.tree.store, data: response })
          node.level = 1
          this.$refs.tree.root.childNodes.push(node)
        }
      }).catch((error) => { console.log(error) })
    },

    deleteEntity (entityId) {
      if (!entityId) {
        return false
      }
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        let entity = new Entity({ id: entityId })
        await entity.delete()
        this.$refs.tree.remove(entityId)
      }).catch((error) => { console.log(error) })
    },

    allowDrag (draggingNode) {
      // нельзя захватить registry_group
      if (draggingNode.data.entity_type_id === 'registry_group') {
        return false
      }
      return true
    },

    allowDrop (draggingNode, dropNode, type) {
      // console.log('draggingNode', draggingNode.data.parent_id)
      // console.log('dropNode', dropNode)
      // console.log('type', type)
      if (dropNode.data.entity_type_id === 'registry_group') {
        return false
      } else if (draggingNode.data.object_id !== dropNode.data.object_id) {
        return false
      } else if (draggingNode.data.parent_id === null || dropNode.data.parent_id === null) {
        return false
      } else if (type === 'inner' && draggingNode.data.entity_type_id === 'registry') {
        return false
      } else if (type === 'inner' && draggingNode.data.entity_type_id !== 'registry') {
        return false
      } else {
        return true
      }
    },

    async moveRow (draggingNode, dropNode, type) {
      let rowOrder = this.mediumRowOrder(draggingNode, dropNode)
      let out = { row_order: rowOrder, parent_id: (type === 'inner' ? dropNode.data.id : dropNode.data.parent_id) }
      draggingNode.data.parent_id = out.parent_id
      draggingNode.data.row_order = out.row_order || draggingNode.data.row_order
      await this.$http.put(`${this.$config.api}/objecteditor/entities/${draggingNode.data.id}/order`, out)
    },

    // средние значение row_order
    mediumRowOrder (draggingNode, dropNode) {
      if (draggingNode.data.parent_id === dropNode.data.parent_id) {
        // console.log('перенос внутри родителя')
        const a = dropNode.parent.childNodes
        let rowOrder = this.rowOrderMethods(draggingNode, a)
        return rowOrder
      } else {
        const a = dropNode.parent.childNodes
        // console.log('вынос за родителя', a)
        let rowOrder = this.rowOrderMethods(draggingNode, a)
        return rowOrder
      }
    },

    rowOrderMethods (draggingNode, a) {
      let rowOrder
      for (let i = 0; i < a.length; i++) {
        if (draggingNode.data.id === a[i].data.id) {
          if (!a[i - 1]) {
            // console.log('сосед сверху не найден')
            rowOrder = Math.round((a[i + 1].data.row_order - 3333))
          }
          if (!a[i + 1]) {
            // console.log('сосед снизу не найден')
            rowOrder = Math.round((1001 + a[i - 1].data.row_order))
          }
          if (a[i + 1] && a[i - 1]) {
            rowOrder = Math.round((a[i + 1].data.row_order + a[i - 1].data.row_order) / 2)
          }
        }
      }
      return rowOrder
    },

    openLogicEditor (model) {
      this.addMainTab({
        name: 'Редактор бизнес-логики',
        componentType: 'LogicEditor_v2',
        payload: { registryId: model.id }
      })
    },

    getTreeIcon (node, data) {
      if (data.entity_type_id === 'registry_group' && !node.expanded) {
        return 'el-icon-folder'
      } else if (data.entity_type_id === 'registry_group' && node.expanded) {
        return 'el-icon-folder-opened'
      }
    },

    getFormulaIcon (node, data) {
      return !!(data.properties && data.properties.find(property => property.id === 'formula_id') && data.properties.find(property => property.id === 'formula_id').value)
    }
  }
}
</script>

<style>
  .object_editor .custom-tree-node {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 90%;
    /* background-color: #EEF0F1; */
  }

  .object_editor .title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-left: 14px;
    margin-bottom: 14px;
    height: 28px;
    color: #2C2D35;
  }

  .object_editor .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .object_editor .object_form,
  .object_editor .card_form {
    padding: 15px;
  }
  .object_editor .el-tree-node__children{
    border-left: 2px solid #4FA9F3;
    margin-left: 5px;
    /* padding-left: 10px; */
    /* box-shadow: 4px 1px 0px -3px rgba(27, 141, 229, 1) inset; */
  }
  .object_editor .el-tree-node.is-current{
    background-color: #EEF0F1;
    background-color: rgb(245 247 250);
  }
  .object_editor .el-tree-node.is-focusable.is-expanded{
    background-color: transparent;
  }
  .object_editor .pl-5 {
    padding-left: 5px;
  }
  .object_editor .start_logic {
    position: absolute;
    right: 40px;
    top: 10px;
    z-index: 2;
  }

  .object_editor .el-button--small {
    /*margin-bottom: 3px;*/
  }

  .object_editor .el-button.is-disabled {
    background-color: unset;
  }

  .object_editor .tool_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
</style>
