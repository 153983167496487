import { Nullable } from '@/core/domain/type/types'
import { Store } from 'vuex'

export function buildFilters (
  filterSettings: Nullable<Array<any>>,
  model: any,
  store: Store<any>,
  asQueryParams: boolean = false,
  isRegistry: boolean = true
) {
  const filters: Array<any> = []

  if (filterSettings) {
    if (asQueryParams) {
      filterSettings.forEach((item) => {
        const isXref = item.type === 'current_user' || item.isXref
        let operator = item.equalsType || 'eq'
        if (isXref) {
          if (operator === 'eq') {
            operator = 'eqx'
          } else if (operator === 'neq') {
            operator = 'neqx'
          } else if (operator === 'is_null') {
            operator = 'is_null_x'
          } else if (operator === 'is_not_null') {
            operator = 'is_not_null_x'
          }
        }
        const alias = item.alias
        if (!item.type || item.type === 'field') {
          if (!alias) {
            return
          }

          if (
            typeof model[item.attribute] === 'undefined' ||
            model[item.attribute] === null ||
            model[item.attribute] === '' ||
            (Array.isArray(model[item.attribute]) && !model[item.attribute].length)
          ) {
            return
          }

          if (operator === 'eqx' && parseInt(model[item.attribute]) === 0) {
            return
          }

          filters.push(`${alias},${operator},${model[item.attribute]}`)
        } else if (item.type === 'constant' && alias) {
          filters.push(`${alias},${operator},${item.attribute}`)
        } else if (item.type === 'current_user' && alias) {
          filters.push(`${alias},${operator},${store.getters['Authorization/userId']}`)
        }
      })
    } else {
      filterSettings.forEach((item) => {
        const isXref = item.type === 'current_user' || item.isXref
        const operator = item.equalsType === 'eq' && isXref ? 'equals_any' : item.equalsType
        const condition = {}
        const alias = isXref && isRegistry ? `${item.alias}id` : item.alias

        if (!item.type || item.type === 'field') {
          if (!alias) {
            return
          }

          if (
            typeof model[item.attribute] === 'undefined' ||
            model[item.attribute] === null ||
            model[item.attribute] === '' ||
            (Array.isArray(model[item.attribute]) && !model[item.attribute].length)
          ) {
            return
          }

          if (['is_null', 'is_not_null'].includes(operator)) {
            condition[operator] = alias
          } else if (['cs', 'ncs'].includes(operator)) {
            const aliases = {
              cs: 'like',
              ncs: 'not_like'
            }

            condition[aliases[operator]] = {}
            condition[aliases[operator]][alias] = `%${model[item.attribute]}%`
          } else {
            condition[operator] = {}
            condition[operator][alias] = model[item.attribute]
          }

          filters.push(condition)
        } else if (item.type === 'constant' && alias) {
          condition[operator] = {}
          condition[operator][alias] = item.attribute
          filters.push(condition)
        } else if (item.type === 'current_user' && alias) {
          condition[operator] = {}
          condition[operator][item.alias] = store.getters['Authorization/userId']
          filters.push(condition)
        }
      })
    }
  }

  return filters
}

/**
 * Типы фильтров
 */
export enum EFilterTypes {
  field = 'field',
  current_user = 'current_user',
  constant = 'constant'
}

/**
 * Операторы сравнения
 */
export enum EEqualTypes {
  // ApiQL Standard
  eq = 'eq',
  neq = 'neq',
  lte = 'lte',
  gte = 'gte',
  lt = 'lt',
  gt = 'gt',
  is_null = 'is_null',
  is_not_null = 'is_not_null',

  // Мн. ссылки
  equals_any = 'equals_any',
  equals_all = 'equals_all',
  not_equals_all = 'not_equals_all',
  not_equals_any = 'not_equals_any',

  // Query Params (для спецификаций в реестровом сервисе)
  is_null_x = 'is_null_x',
  is_not_null_x = 'is_not_null_x',
  eqx = 'eqx',
  cs = 'cs',
  ncs = 'ncs',
  se = 'se'
}

/**
 * Описание объекта конфигурации фильтра
 */
export interface IFilter {
  /**
   * Псевдоним поля (attr_N_, some_name)
   */
  alias: Nullable<string>;
  /**
   * Тип фильтра
   */
  type: EFilterTypes;
  /**
   * Имя свойсва компонента (если тип - поле), либо рукописное значение (если тип - константа)
   */
  attribute: Nullable<string>;
  /**
   * Определяем псевдоним как ссылочное поле (xref_field, xref_multi_field)
   */
  isXref: boolean;
  /**
   * Тип оператора сравнения
   */
  equalsType: EEqualTypes;
}

/**
 * Данные карточки
 */
export interface ICardData {
  [prop: string]: any;
}

/**
 * Список компонентов, где используются фильтры
 *
 * Нужен для определения уникальной логики
 */
export enum EComponentTypes {
  registry = 'registry',
  table = 'table',
  analyticalTable = 'analyticalTable',
  list = 'list',
  select = 'select',
  accordion = 'accordion',
  cardAutocomplete = 'cardAutocomplete',
  maps = 'maps',
  stages = 'stages',
  tasks = 'tasks',
  tree = 'tree',
  label = 'label',
  calendar = 'calendar',
  ganttNew = 'ganttNew',
  donutChart = 'donutChart',
  donutChartApiQl = 'donutChartApiQl',
  chart = 'chart',
  barChart = 'barChart',

  xrefField = 'xrefField',
  xrefMultiField = 'xrefMultiField',
  xrefOuterField = 'xrefOuterField'
}

export interface IApiQLExpression {
  /**
   * Первый вариант:
   *   eq: {
   *     some_field: 'any_value'
   *   }
   *
   * Второй вариант
   *   like: 'some_field'
   *
   * Третий вариант
   *   and: [...IApiQLExpression],
   *   or: [...IApiQLExpression]
   */
  [operator: string]: {
    [field: string]: any
  } | string | Array<IApiQLExpression>
}

/**
 * Сборщик фильтров
 */
export default class FilterBuilder {
  /**
   * Настройки фильтров
   */
  private filters: IFilter[] = []
  /**
   * Данные с карточки
   */
  private cardData: ICardData = {}
  /**
   * Инстанс Vuex
   */
  private store: Store<any>
  /**
   * Компонент для которого собираются фильтры
   */
  private componentType: EComponentTypes = null

  /**
   * @param filters
   * @param cardData
   * @param store
   * @param componentType
   */
  constructor (
    filters: IFilter[],
    cardData: ICardData,
    store: Store<any>,
    componentType: EComponentTypes
  ) {
    this.filters = filters || [] // начались костыли, ахахаха
    this.cardData = cardData
    this.store = store
    this.componentType = componentType
  }

  /**
   * Сборка фильтров для выполнения запроса
   *
   * Фильтры могут быть собраны для GET запроса с параметрами
   * Либо как JSON для POST запроса (на беке применяется ApiQL)
   *
   * @param asQueryParams
   */
  build (asQueryParams: boolean = false): IApiQLExpression[] | string[] {
    if (asQueryParams) {
      return this.buildAsQueryParams()
    } else {
      return this.buildAsApiQl()
    }
  }

  /**
   * Сборка фильтров как JSON объект стандарта ApiQL
   */
  buildAsApiQl (): IApiQLExpression[] {
    const expressions: IApiQLExpression[] = []

    this.filters.forEach((filter) => {
      const logicData = this.defineLogicData(filter)

      /**
       * Если не указан псевдоним, или не определено значение, тогда пропускаем фильтр
       */
      if (!logicData.alias || !logicData.value) {
        return
      }

      if (['is_null', 'is_not_null'].includes(logicData.operator)) {
        expressions.push({
          [logicData.operator]: logicData.alias
        })
      } else if (['cs', 'ncs'].includes(logicData.operator)) {
        const operatorAliases = {
          cs: 'like',
          ncs: 'not_like'
        }

        expressions.push({
          [operatorAliases[logicData.operator]]: {
            [logicData.alias]: `%${logicData.value}%`
          }
        })
      } else {
        expressions.push({
          [logicData.operator]: {
            [logicData.alias]: logicData.value
          }
        })
      }
    })

    return expressions
  }

  /**
   * Сборка фильтров как параметры для GET запроса
   */
  buildAsQueryParams (): string[] {
    const expressions: string[] = []

    this.filters.forEach((filter) => {
      const logicData = this.defineLogicData(filter, true)

      /**
       * Если не указан псевдоним, или не определено значение, тогда пропускаем фильтр
       */
      if (!logicData.alias || !logicData.value) {
        return
      }

      if (['is_null', 'is_not_null', 'is_null_x', 'is_not_null_x'].includes(logicData.operator)) {
        expressions.push(`${logicData.alias},${logicData.operator}`)
      } else {
        expressions.push(`${logicData.alias},${logicData.operator},${logicData.value}`)
      }
    })

    return expressions
  }

  /**
   * Проверит и определит:
   * - знаение: пустое или не пустое
   * - псевдоним: указан или нет + добавит на конце "_id" для ссылочных полей
   * - ссылка: ссылочное поле или нет
   * - смэппит операторы для QueryParams илил ApiQL
   *
   * @param filter
   * @param asQueryParams
   */
  private defineLogicData (filter: IFilter, asQueryParams: boolean = false): { isXref: boolean, operator: string, alias: string, value: any } {
    /**
     * Будет ли текущий фильтр фильтровать по ссылочному полю
     */
    let isXref = filter.isXref || false

    /**
     * Определяем оператор сравнения, учитывая особенности ссылочных полей
     */
    let operator = filter.equalsType || 'eq'

    /**
     * Определим псевдоним
     */
    let alias: string = filter.alias || null

    /**
     * Welcome to Accent 2.0 - спаси и сохрани
     *
     * Учитывая особенности каждого компонента, дабы не сломать уже настроенные на основе г-кода фильтры,
     * пишем гиганское дерево условий, с подгруппами условий...
     *
     * Приятного просмотра/рефакторинга =*
     */
    if (
      this.componentType === EComponentTypes.table ||
      this.componentType === EComponentTypes.accordion ||
      this.componentType === EComponentTypes.maps ||
      this.componentType === EComponentTypes.select ||
      this.componentType === EComponentTypes.stages ||
      this.componentType === EComponentTypes.tasks ||
      this.componentType === EComponentTypes.tree ||
      this.componentType === EComponentTypes.xrefField ||
      this.componentType === EComponentTypes.xrefMultiField ||
      this.componentType === EComponentTypes.xrefOuterField
    ) {
      if (isXref) {
        if (operator === EEqualTypes.eq) {
          operator = EEqualTypes.eqx
        } else if (operator === EEqualTypes.is_null) {
          operator = EEqualTypes.is_null_x
        } else if (operator === EEqualTypes.is_not_null) {
          operator = EEqualTypes.is_not_null_x
        } else if (operator === EEqualTypes.equals_any) {
          operator = EEqualTypes.eqx
        }
      }
    } else if (
      this.componentType === EComponentTypes.analyticalTable ||
      this.componentType === EComponentTypes.cardAutocomplete ||
      this.componentType === EComponentTypes.label ||
      this.componentType === EComponentTypes.list ||
      this.componentType === EComponentTypes.ganttNew ||
      this.componentType === EComponentTypes.donutChartApiQl ||
      this.componentType === EComponentTypes.barChart ||
      this.componentType === EComponentTypes.donutChart ||
      this.componentType === EComponentTypes.chart ||
      this.componentType === EComponentTypes.calendar
    ) {
      if (isXref) {
        if (operator === EEqualTypes.eq) {
          operator = EEqualTypes.equals_any
        } else if (operator === EEqualTypes.se) {
          operator = EEqualTypes.cs
        }
      }
    } else if (this.componentType === EComponentTypes.registry) {
      if (isXref) {
        if (operator === EEqualTypes.eq) {
          operator = EEqualTypes.equals_any
        } else if (operator === EEqualTypes.se) {
          operator = EEqualTypes.cs
        }

        if (alias) {
          alias = alias + 'id'
        }
      }
    } else {
      if (asQueryParams) {
        if (isXref) {
          if (operator === EEqualTypes.eq) {
            operator = EEqualTypes.eqx
          } else if (operator === EEqualTypes.is_null) {
            operator = EEqualTypes.is_null_x
          } else if (operator === EEqualTypes.is_not_null) {
            operator = EEqualTypes.is_not_null_x
          } else if (operator === EEqualTypes.equals_any) {
            operator = EEqualTypes.eqx
          }
        }
      } else {
        if (operator === EEqualTypes.eq) {
          operator = EEqualTypes.equals_any
        } else if (operator === EEqualTypes.se) {
          operator = EEqualTypes.cs
        }
      }
    }

    /**
     * Определеним значение для сравнения
     */
    let value: any = null
    if (filter.type === EFilterTypes.field) {
      if (!this.isEmptyCardAttribute(filter.attribute)) {
        value = this.cardData[filter.attribute]
        if (isXref) {
          try {
            value = JSON.parse(value)
          } catch (e) {
          }
        }
        if (typeof value !== 'number' && Array.isArray(value)) {
          value = value.map(item => item.id || item)
          value = value.length ? value : null
        }
      }
    } else if (filter.type === EFilterTypes.constant) {
      if (filter.attribute !== '' && filter.attribute !== null) {
        value = filter.attribute
      }
    } else if (filter.type === EFilterTypes.current_user) {
      value = this.store.getters['Authorization/userId']
    }

    return {
      isXref,
      operator,
      alias,
      value
    }
  }

  /**
   * Используется для фильтра с типом "поле"
   *
   * Проверит, выбрано ли значение в компоненте карточки,
   * Или есть ли такое свойство (атрибут) в карточке
   *
   * @param attribute
   *
   * @return boolean
   */
  private isEmptyCardAttribute (attribute: string): boolean {
    return typeof this.cardData[attribute] === 'undefined' ||
      this.cardData[attribute] === null ||
      this.cardData[attribute] === '' ||
      (Array.isArray(this.cardData[attribute]) && !this.cardData[attribute].length)
  }
}
