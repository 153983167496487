<template>
    <div class="editor-document">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.document }}</span>
        <report-select-tree v-model="document.id" @change="selectDoc" :type="this.type"></report-select-tree>
      </el-form-item>
      <template v-if="value.reports.id">
        <el-form-item v-if="type === 'document'">
          <span class="property_label">{{ $locale.interface_editor.component_editor.document.format_type }}</span>
          <el-select
            v-model="document.formatType"
            @change="selectDocFormatType($event)">
            <el-option
              v-for="item in formatTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span class="property_label">{{ $locale.interface_editor.component_editor.document.view_type }}</span>
          <el-select
            v-model="document.viewType"
            @change="selectDocType($event)">
            <el-option
              v-for="item in viewTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span class="property_label">{{ $locale.interface_editor.component_editor.document.associate_registry_field }}</span>
          <el-select
            v-model="document.assocFieldId"
            clearable
            @change="selectAssocField($event)">
            <el-option
              v-for="item in associateFields"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="document.assocFieldId !== null">
          <el-checkbox v-model="document.openSavedVersion" @change="checkedSavedVersion($event)">
            {{ $locale.interface_editor.component_editor.document.open_saved_version }}
          </el-checkbox>
        </el-form-item>
      </template>
    </div>
</template>

<script>
import Report from '@/components/ReportEditor/Models/Reports'
import ReportSelectTree from '@/components/Common/ReportSelectTree'

export default {
  name: 'editor-document',
  components: {
    ReportSelectTree
  },
  inject: {
    getRegistryId: {
      default: () => null
    }
  },
  props: {
    value: Object,
    type: String
  },
  data () {
    return {
      document: {
        id: null,
        guid: null,
        name: null,
        formatType: null,
        viewType: null,
        assocFieldId: null,
        openSavedVersion: false
      },
      documents: [],
      associateFields: [],
      formatTypes: [
        { id: 'docx', name: 'DOC' },
        { id: 'pdf', name: 'PDF' },
        { id: 'odt', name: 'ODT' }
      ],
      viewTypes: [
        { id: 'download', name: this.$locale.interface_editor.component_editor.document.document_view_type.download_file },
        { id: 'open', name: this.$locale.interface_editor.component_editor.document.document_view_type.open_file }
      ]
    }
  },
  watch: {
    'value.reports': {
      handler (value) {
        this.document = value
      }
    }
  },
  async mounted () {
    this.documents = await new Report().params({ report_type: this.type }).$get()

    this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${this.getRegistryId()}`).then(res => {
      this.associateFields = res.data.data.filter(field => field.entity_type_id === 'file_field')
    })

    if (typeof this.value.reports !== 'undefined') {
      this.document = Object.assign(this.document, this.value.reports)
    }
  },
  methods: {
    selectDoc (value) {
      let document = this.documents.find(doc => doc.id === value)

      if (document) {
        this.$set(
          this.value,
          'reports',
          {
            id: document.id,
            name: document.name,
            guid: document.guid,
            formatType: this.type === 'xml' ? 'xml' : this.document.formatType,
            viewType: this.document.viewType,
            registryId: this.getRegistryId(),
            assocFieldId: this.document.assocFieldId,
            openSavedVersion: this.document.openSavedVersion
          }
        )
      }
    },
    selectDocType (value) {
      this.$set(this.value.reports, 'viewType', value)
    },
    selectDocFormatType (value) {
      this.$set(this.value.reports, 'formatType', value)
    },
    selectAssocField (value) {
      if (value === '') {
        this.document.assocFieldId = value = null
        this.document.openSavedVersion = false

        this.$set(this.value.reports, 'openSavedVersion', this.document.openSavedVersion)
      }
      this.$set(this.value.reports, 'assocFieldId', value)
    },
    checkedSavedVersion (value) {
      this.$set(this.value.reports, 'openSavedVersion', value)
    }
  }
}
</script>

<style type="text/css"></style>
