<template>
  <el-scrollbar wrap-style="height: calc(100vh - 106px);" class="hide_scroll el-col el-col-24 el-col-sm-23 el-col-sm-offset-2 el-col-md-23 el-col-md-offset-2 el-col-lg-23 el-col-lg-offset-2 el-col-xl-23 el-col-xl-offset-1">
    <h1 style="margin-top: 10px">{{$locale.logic_editor.column_formula.headline}}</h1>
    <div class="column_formula_block__item2">
      <div class="demonstration required">{{$locale.menu_editor.label.name}}</div>
      <el-input size="mini" class="column_formula__row" v-model="columnFormula.name"></el-input>
    </div>
    <div class="column_formula_block__item" v-if="isEntityIdRequired">
      <div class="demonstration required">{{$locale.logic_editor.column_formula.label_for_list}}</div>
      <selectTree style="margin-bottom: 10px"
        :clearable="false"
        ref="entity_tree"
        :dataTable="listField"
        class="custom_scrollbar column_formula__row"
        v-model="columnFormula.entity_id"/>
    </div>
    <el-checkbox
      class="column_formula__row"
      v-model="columnFormula.is_dsl"
    >{{$locale.logic_editor.column_formula.calc}}</el-checkbox>
    <div v-show="columnFormula.is_dsl" class="column_formula">
      <el-popover placement="right" width="400" trigger="click" v-model="visible">
        <div style="padding: 10px;" class="column_formula">
          <div class="demonstration">{{$locale.logic_editor.column_formula.label_for_list}}</div>
          <selectTree style="margin-bottom: 10px'"
            :clearable="false"
            :dataTable="listField"
            :selectAlias="selectFieldCurrentTable"
            :appendToBody="false"
            class="custom_scrollbar column_formula__row"
            v-model="argumentTable.currentTable"/>
          <div class="column_formula__footer popover_footer">
            <el-button @click="cancelCurrentTable">{{ $locale.main.button.cancel }}</el-button>
            <el-button
              size="medium"
              @click="pasteArgCurrentTable"
              type="primary"
            >{{ $locale.logic_editor.column_formula.insert_cell }}</el-button>
          </div>
        </div>
        <el-button
          style="margin: 10px"
          slot="reference"
        >{{$locale.logic_editor.column_formula.current_table}}</el-button>
      </el-popover>
      <el-popover placement="right" width="400" trigger="click" v-model="OuterTableVisible">
        <div style="padding: 10px;" class="column_formula">
            <div class="demonstration">{{$locale.logic_editor.column_formula.label_for_connection}}</div>
            <el-select
              value-key="id"
              class="row_formulas__item extraLine_select1"
              v-model="dsl_statement.xref_id"
              filterable
              clearable
              size="mini"
            >
              <el-option v-for="item in listXref" :key="item.id" :label="item.name" :value="item"></el-option>
            </el-select>
            <div class="demonstration">{{$locale.logic_editor.column_formula.label_for_list}}</div>
            <selectTree style="margin-bottom: 10px; margin-left: 0;"
              :clearable="false"
              :dataTable="listXrefField"
              :selectAlias="selectFieldOuterTable"
              :appendToBody="false"
              class="custom_scrollbar column_formula__row"
              v-model="argumentTable.outerTable"/>
          <div class="column_formula__footer popover_footer">
            <el-button @click="cancelOuterTable">{{ $locale.main.button.cancel }}</el-button>
            <el-button
              size="medium"
              @click="pasteArgOuterTable"
              type="primary"
            >{{ $locale.logic_editor.column_formula.insert_cell }}</el-button>
          </div>
        </div>
        <el-button
          style="margin: 10px"
          slot="reference"
        >{{$locale.logic_editor.column_formula.out_table}}</el-button>
      </el-popover>
      <div class="column_formula__flexWrapp">
        <div style="margin-right: 9%; margin-left: 2%">
        <div class="demonstration">{{$locale.logic_editor.column_formula.function}}</div>
          <el-select
            value-key="id"
            class="row_formulas__item extraLine_select1"
            v-model="dsl_statement.function_id"
            filterable
            clearable
            size="mini"
          >
            <el-option v-for="item in listfunction" :key="item.id" :label="item.name" :value="item"></el-option>
          </el-select>
        </div>
        <div>
        <div class="demonstration">{{$locale.logic_editor.column_formula.operands}}</div>
          <el-select
            value-key="id"
            class="row_formulas__item extraLine_select1"
            v-model="dsl_statement.operands_id"
            filterable
            clearable
            size="mini"
          >
            <el-option v-for="item in listOperands" :key="item.id" :label="item.name" :value="item"></el-option>
          </el-select>
        </div>
      </div>
      <div style="position: relative">
        <div
          ref="columnFormula"
          @click="cursorPosition"
          @click.right.prevent="contexMenu"
          @keyup="cursorPosition"
          @input="performMark"
          class="row_formulas__edit"
          contenteditable="true"
          placeholder="Формула"
        ></div>
        <nav ref="ctxMenu" class="context-menu">
          <template v-if="this.flag && this.selectChips != null">
            <ul  class="context-menu__items" v-for="item in listCtxMenu" :key="item.id">
              <li v-show="item.isShow" @click="actionCtxMenu(item.id)" class="context-menu__item context-menu__link">{{item.value}}</li>
            </ul>
          </template>
          <template v-else>
              <ul  class="context-menu__items">
              <li @click="pasteCopyChips" class="context-menu__item context-menu__link">{{this.$locale.logic_editor.column_formula.paste_chip}}</li>
            </ul>
          </template>
        </nav>
      </div>
  <!-- ------- -->
  <el-button type="primary"
      size="small"
      v-clipboard:copy="develop"
      v-clipboard:success="onCopy"
      style="margin: 15px"
      v-clipboard:error="onError">{{this.$locale.logic_editor.column_formula.copy_formula}}</el-button>
  <!-- <el-checkbox
      class="column_formula__row"
      v-model="isDevelop"
    >{{$locale.logic_editor.column_formula.dev}}</el-checkbox>
      <el-input
        v-show="isDevelop"
        type="textarea"
        style="border: none; min-height: auto;"
        class="row_formulas__edit"
        :rows="6"
        readonly
        v-model="develop">
      </el-input> -->
  <!-- ------- -->
    </div>
    <el-row>
      <el-col :sm="20" :md="20" :lg="22" :xl="22">
        <div class="column_formula_block__item3">
          <div class="demonstration">{{$locale.logic_editor.column_formula.select}}</div>
          <el-input
            type="textarea"
            class="column_formula__row"
            :autosize="{ minRows: 2, maxRows: 4}"
            :readonly="columnFormula.is_dsl"
            v-model="columnFormula.sql_statements.select"
          ></el-input>
        </div>
        <div class="column_formula_block__item3">
          <div class="demonstration">{{$locale.logic_editor.column_formula.join}}</div>
          <el-input
            type="textarea"
            class="column_formula__row"
            :readonly="columnFormula.is_dsl"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model="columnFormula.sql_statements.join"
          ></el-input>
        </div>
        <div class="column_formula_block__item3">
          <div class="demonstration">{{$locale.logic_editor.column_formula.group_by}}</div>
          <el-input
            type="textarea"
            :readonly="columnFormula.is_dsl"
            class="column_formula__row"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model="columnFormula.sql_statements.group_by"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <el-checkbox
      v-if="isEntityIdRequired"
      class="column_formula__row"
      v-model="columnFormula.is_stored"
    >{{$locale.logic_editor.column_formula.checkbox_save}}</el-checkbox>
    <el-checkbox
      v-if="isEntityIdRequired"
      class="column_formula__row"
      v-model="columnFormula.is_basic"
    >{{$locale.logic_editor.column_formula.is_basic}}</el-checkbox>
    <el-footer class="column_formula__footer">
      <el-button
        @click="preCount"
        v-show="btnPreCount"
      >{{ $locale.logic_editor.column_formula.pre_count }}</el-button>
      <el-button
        @click="saveCard"
        :disabled="disabledBtnSave"
        type="primary"
      >{{ $locale.main.button.save }}</el-button>
    </el-footer>
    <el-dialog
      v-if="isCatalog"
      :title="$locale.logic_editor.column_formula.label_for_list"
      :modal="false"
      :visible.sync="isCatalog"
      :destroy-on-close='true'>
      <el-tree
        v-if="this.xrefIdFromChips"
        style="height: 50vh; overflow: auto"
        class="custom_scrollbar"
        :props="props"
        lazy
        :load="loadNode"
        node-key="id"
        ref="treeCatalog"
        show-checkbox
        check-strictly
        @check-change="selectCatalog"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
            <span style="width: 100%;">{{ node.label }} (id: {{data.id}}, <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)</span>
        </span>
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isCatalog = false">{{$locale.main.button.cancel}}</el-button>
        <el-button type="primary" :disabled="disabledBtnInCatalog" @click="pasteCatalogInFormula">{{ $locale.logic_editor.column_formula.insert_cell }}</el-button>
      </span>
    </el-dialog>
  </el-scrollbar>
</template>

<script>
import Entity from '@/components/ObjectEditor/Models/Entity'
import Formulas from '../Models/formulas'
import ExtendedObjectField from '../../BIEditor/Models/ExtendedObjectField'
import markWord from './mark.js/perfomark'
import selectTree from '@/components/LogicEditor/components/treeSelect'

export default {
  name: 'column_formula',
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      },
      getNotifyContent: Object

    },
    dialogNotify: Boolean
  },
  components: {
    selectTree
  },
  watch: {
    'columnFormula.entity_id.name': function (val) {
      if (val === '') {
        this.columnFormula.entity_id = ''
      }
    },
    'dsl_statement.operands_id': function (val) {
      if (val !== '') this.pasteMathOperands()
    },
    'dsl_statement.function_id': function (val) {
      if (val !== '') this.pasteMathFunction()
    },
    'dsl_statement.xref_id': async function (val) {
      if (val != null) {
        this.listXrefField = []
        this.dsl_statement.selectField_xref = ''
        this.argumentTable.outerTable = null
        if (this.dsl_statement.xref_id.id != null) {
          try {
            this.dsl_statement.outerField_id = this.dsl_statement.xref_id.id
            let response = await Entity.$find(this.dsl_statement.xref_id.id)
            this.typeXref = response.entity_type_id
            this.dsl_statement.target_xref_id = response.properties.filter(el => el.id === 'xref')[0].value
            if (this.dsl_statement.target_xref_id) {
              let typeOuterXref = await Entity.$find(this.dsl_statement.target_xref_id)
              this.typeOuterXref = typeOuterXref.entity_type_id
              this.dsl_statement.target_object_id = typeOuterXref.object_id
              let listXrefField = await new Entity({ id: typeOuterXref.object_id }).children().$get()
              this.listXrefField = listXrefField
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    isCatalog: async function (val) {
      if (val) {
        try {
          let response = await Entity.$find(this.listCtxMenu[0].target_field_id)
          let valueXref = response.properties.filter(el => el.id === 'xref')[0].value
          if (valueXref != null) {
            // id реестра на которую ссылается ссылка
            let typeOuterXref = await Entity.$find(valueXref)
            this.xrefIdFromChips = typeOuterXref.object_id
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  data () {
    return {
      develop: '',
      xrefIdFromChips: null,
      btnPreCount: false,
      availableEntityType: [
        'xref_field',
        'xref_outer_field',
        'xref_multi_field',
        'field_group'
      ],
      isDevelop: false,
      addAction: this.propslocal.listOpenAction,
      caretPosition: {},
      argumentTable: {
        currentTable: null,
        outerTable: null
      },
      dsl_statement: {
        field_id: '',
        xref_id: null,
        outerField_id: null,
        target_xref_id: null,
        target_object_id: null,
        selectField_xref: '',
        function_id: null,
        operands_id: null
      },
      props: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'children',
        disabled: 'isDisabled'
      },
      visible: false,
      OuterTableVisible: false,
      columnFormula: {
        name: '',
        logic_id: this.propslocal.businesslogic.id,
        entity_id: null,
        is_stored: false,
        is_basic: false,
        is_dsl: false,
        formula_type_id: 'column_formula',
        sql_statements: {
          select: null,
          join: null,
          group_by: null
        }
      },
      // список полей текущей таблицы ввиде дерева
      listField: [],
      listXref: [],
      // список полей по ссылке
      listXrefField: [],
      listfunction: [],
      listOperands: [],
      typeXref: null,
      typeOuterXref: null,
      selectChips: null,
      // тип контексткного меню
      flag: true,
      // contex menu
      copyChips: null,
      selectNodeCatalog: [],
      isCatalog: false,
      listCtxMenu: [
        {
          id: 1,
          value: this.$locale.logic_editor.column_formula.select_catalog,
          isShow: false
        },
        {
          id: 2,
          value: this.$locale.logic_editor.column_formula.copy_chip,
          isShow: true
        },
        {
          id: 3,
          value: this.$locale.logic_editor.column_formula.delete_chip,
          isShow: true
        }
      ]
    }
  },
  computed: {
    xrefType: function () {
      if (this.typeXref !== 'xref_outer_field') {
        if (this.typeXref === 'xref_multi_field') {
          return 'multi'
        } else {
          return 'single'
        }
      } else {
        if (this.typeOuterXref === 'xref_multi_field') {
          return 'multi'
        } else {
          return 'single'
        }
      }
    },
    disabledBtnInCatalog () {
      return this.selectNodeCatalog.length === 0
    },
    disabledBtnSave () {
      if (this.columnFormula.name === '' || this.columnFormula.entity_id === undefined) {
        return true
      }
      return false
    },
    isEntityIdRequired: function () {
      return !(this.$attrs.notifyFormula || this.$attrs.objectFormula)
    }
  },
  methods: {
    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(await this.loadEntities(this.xrefIdFromChips, node))
      } else {
        if (this.availableEntityType.indexOf(node.data.entity_type_id) !== -1) {
          let response = {}
          response = await Entity.$find(node.data.id)
          let propertyFieldId
          response.properties.forEach(item => {
            if (item.id === 'xref') {
              propertyFieldId = item.value !== null ? parseInt(item.value) : null
            }
          })
          if (propertyFieldId !== null) {
            response = await Entity.$find(propertyFieldId)

            resolve(await this.loadEntities(response.object_id, node))
          } else {
            console.log('Не проставлена ссылка!')
          }
        } else {
          resolve(await this.loadEntities(node.data.id, node))
        }
      }
    },
    async loadEntities (entityId, node) {
      let data = []

      data = await new Entity({ id: entityId }).children().$get()

      data.forEach(entity => {
        if (this.availableEntityType.indexOf(entity.entity_type_id) !== -1) {
          entity.leaf = false
          entity.isDisabled = true
        }

        entity.properties.forEach(property => {
          if (property.id === 'xref' && property.value === null) {
            entity.leaf = true
            entity.isDisabled = true
          }
        })
      })

      return data
    },
    // перерасчет формулы
    preCount () {
      let id
      this.propslocal.selectSubAction.id
        ? (id = this.propslocal.selectSubAction.id)
        : (id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id)
      this.$http.post(`${config.api}/logiceditor/formulas/update/stored/${id}`)
    },
    onCopy (e) {
      this.$message({
        message: this.$locale.logic_editor.column_formula.copy_save,
        type: 'success'
      })
    },
    onError (e) {
      console.log('Failed to copy texts')
    },
    deepSearch (arr, multi = false) {
      for (let el of arr) {
        if (el.leaf) delete el.children
        if (!el.leaf && multi) el.isDisabled = true
        if (el.children instanceof Array && el.children.length) this.deepSearch(el.children)
      }
      return arr
    },
    cancelCurrentTable () {
      this.dsl_statement.field_id = ''
      this.argumentTable.currentTable = null
      this.visible = false
    },
    cancelOuterTable () {
      this.dsl_statement.xref_id = null
      this.dsl_statement.selectField_xref = ''
      this.argumentTable.outerTable = null
      this.OuterTableVisible = false
    },
    selectFieldOuterTable (data) {
      this.dsl_statement.selectField_xref = data
    },
    selectCatalog (node, checked) {
      this.selectNodeCatalog = this.$refs.treeCatalog.getCheckedNodes()
    },
    selectFieldCurrentTable (data) {
      this.dsl_statement.field_id = data
    },
    async saveNotifyFormula (formulas, type, typeFormula) {
      formulas.name = this.columnFormula.name
      formulas.is_dsl = this.columnFormula.is_dsl
      formulas.entity_id = null
      formulas.logic_id = this.propslocal.businesslogic.id
      formulas.dsl_statement = this.$refs.columnFormula.innerHTML === '' ? null : this.$refs.columnFormula.innerHTML
      formulas.formula_type_id = 'notification_formula'
      for (let key in this.columnFormula.sql_statements) {
        if (this.columnFormula.sql_statements[key] === '') this.columnFormula.sql_statements[key] = null
      }
      formulas.sql_statements = this.columnFormula.sql_statements
      let response = await formulas.save()
      this.columnFormula.sql_statements = response.sql_statements
      this.$emit('update:dialogNotify', false)
      this.$attrs.value[type] = response.id
      if (formulas.is_dsl) {
        this.$emit('getValueFormula', { id: response.id, res: response.dsl_statement, typeFormula })
      } else {
        this.$emit('getValueFormula', { id: null, res: response.sql_statements.select, typeFormula })
      }
    },
    async saveObjectFormula (formulas) {
      formulas.name = this.columnFormula.name
      formulas.is_dsl = this.columnFormula.is_dsl
      formulas.entity_id = null
      formulas.logic_id = this.propslocal.businesslogic.id
      formulas.dsl_statement = this.$refs.columnFormula.innerHTML === '' ? null : this.$refs.columnFormula.innerHTML
      formulas.formula_type_id = 'extended_object_formula'
      for (let key in this.columnFormula.sql_statements) {
        if (this.columnFormula.sql_statements[key] === '') this.columnFormula.sql_statements[key] = null
      }
      formulas.sql_statements = this.columnFormula.sql_statements
      let response = await formulas.save()
      this.columnFormula.sql_statements = response.sql_statements
      this.$emit('update:dialogNotify', false)
      this.$attrs.objectFormula.eof.formula_id = response.id
      this.$attrs.objectFormula.eof.formula = response
      let eofModel = await ExtendedObjectField.find(this.$attrs.objectFormula.eof.id)
      eofModel.formula_id = response.id
      await eofModel.save()
    },
    async saveCard () {
      // сохранение из уведомлений
      if (this.$attrs.notifyFormula) {
        if (this.$attrs.notifyFormula.type === 'title') {
          if (this.propslocal.getAnswer === '') {
            let formulas = new Formulas({})
            this.saveNotifyFormula(formulas, 'title_formula_id', 'titleTextarea')
          } else {
            let formulas = new Formulas({ id: this.propslocal.getAnswer.id })
            this.saveNotifyFormula(formulas, 'title_formula_id', 'titleTextarea')
          }
        }
        if (this.$attrs.notifyFormula.type === 'content') {
          if (!this.propslocal.getNotifyContent) {
            let formulas = new Formulas({})
            this.saveNotifyFormula(formulas, 'content_formula_id', 'contentTextarea')
          } else {
            let formulas = new Formulas({ id: this.propslocal.getNotifyContent.id })
            this.saveNotifyFormula(formulas, 'content_formula_id', 'contentTextarea')
          }
        }
      } else if (this.$attrs.objectFormula) {
        if (this.propslocal.getAnswer === '') {
          let formulas = new Formulas({})
          this.saveObjectFormula(formulas)
        } else {
          let formulas = new Formulas({ id: this.$attrs.objectFormula.eof.formula.id })
          this.saveObjectFormula(formulas)
        }
      } else {
        // новое subAction
        if (this.propslocal.isNewAction) {
          let formulas = new Formulas({}, this.columnFormula)
          formulas.dsl_statement = this.$refs.columnFormula.innerHTML === '' ? null : this.$refs.columnFormula.innerHTML
          formulas.is_dsl = this.columnFormula.is_dsl
          formulas.entity_id = this.columnFormula.entity_id || null
          formulas.formula_type_id = 'column_formula'
          formulas.role_id = null
          let response = await formulas.save()
          this.btnPreCount = response.is_stored
          this.addAction.items.push(response)
        } else {
          let id
          this.propslocal.selectSubAction.id
            ? (id = this.propslocal.selectSubAction.id)
            : (id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id)
          for (let key in this.columnFormula.sql_statements) {
            if (this.columnFormula.sql_statements[key] === '') this.columnFormula.sql_statements[key] = null
          }
          let formulas = new Formulas({ id: id })
          formulas.entity_id = this.columnFormula.entity_id || null
          formulas.is_stored = this.columnFormula.is_stored
          formulas.is_basic = this.columnFormula.is_basic
          formulas.sql_statements = this.columnFormula.sql_statements
          formulas.name = this.columnFormula.name
          formulas.role_id = null
          formulas.formula_type_id = 'column_formula'
          formulas.dsl_statement = this.$refs.columnFormula.innerHTML === '' ? null : this.$refs.columnFormula.innerHTML
          formulas.is_dsl = this.columnFormula.is_dsl
          let response = await formulas.save()
          this.btnPreCount = response.is_stored
          this.columnFormula.sql_statements = response.sql_statements
          this.$refs.columnFormula.innerHTML = response.dsl_statement
          this.addAction.items
            ? (this.addAction.items.filter(el => el.id === id)[0].name = response.name)
            : (this.addAction.filter(el => el.id === id)[0].name = response.name)
        }
        this.propslocal.isNewAction = false
      }
    },
    cursorPosition (e) {
      this.develop = this.$refs.columnFormula.innerHTML
      this.$refs.ctxMenu.style.display = ''
      this.listCtxMenu[0].isShow = false
      if (e.target.className === 'accent-function' || e.target.className === 'accent-operand' || e.target.className === 'accent-argument' || e.target.className === 'accent-index') {
        return false
      }
      let sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        this.caretPosition.range = sel.getRangeAt(0)
      }
      if (e.target.className === 'remove') {
        e.target.parentElement.remove()
      }
    },
    contexMenu (e) {
      this.listCtxMenu[0].isShow = false
      if (e.target.className === 'accent-argument' || e.target.className === 'accent-index') {
        if (e.target.getAttribute('field_type') === 'xref_multi_field' || e.target.getAttribute('field_type') === 'xref_field') {
          this.listCtxMenu[0].isShow = true
          this.listCtxMenu[0].target_field_id = +e.target.getAttribute('field_id')
        }
        this.selectChips = e.target
        this.copyChips = e.target.outerHTML
        this.flag = true
        this.drawCntxMenu(e)
      }
      if (e.target.className === 'row_formulas__edit' && !this.flag) {
        this.drawCntxMenu(e)
      }
    },
    drawCntxMenu (e) {
      let ctxMenu = this.$refs.ctxMenu
      ctxMenu.style.display = 'block'
      let xPosition
      let yPosition
      xPosition = e.target.offsetLeft + 10
      yPosition = e.target.offsetTop + 20
      ctxMenu.style.left = xPosition + 'px'
      ctxMenu.style.top = yPosition + 'px'
    },
    actionCtxMenu (val) {
      this.$refs.ctxMenu.style.display = ''
      if (val === 1) {
        this.isCatalog = true
      }
      if (val === 2) {
        // контекстное меню - остался один пункт
        this.flag = false
      }
      if (val === 3) {
        this.selectChips.remove()
        this.selectChips = null
      }
    },
    pasteCopyChips () {
      this.$refs.columnFormula.focus()
      this.pasteHtmlAtCaret(this.copyChips)
      this.flag = false
      this.$refs.ctxMenu.style.display = ''
      this.caretPosition = {}
    },
    pasteMathFunction () {
      this.$refs.columnFormula.focus()
      this.pasteHtmlAtCaret(`<span class="accent-function"
      function=${this.dsl_statement.function_id.id}
      contenteditable="false">${this.dsl_statement.function_id.name}
      <i class="remove" contenteditable="false"></i></span>`)
      // this.dsl_statement.function_id = null
      this.caretPosition = {}
      this.develop = this.$refs.columnFormula.innerHTML
    },
    pasteMathOperands () {
      this.$refs.columnFormula.focus()
      this.pasteHtmlAtCaret(`<span class="accent-operator"
      statement=${this.dsl_statement.operands_id.id}
      contenteditable="false">
      ${this.dsl_statement.operands_id.name}
      <i class="remove" contenteditable="false"></i></span>`)
      // this.dsl_statement.operands_id = null
      this.caretPosition = {}
      this.develop = this.$refs.columnFormula.innerHTML
    },
    pasteArgCurrentTable () {
      if (this.dsl_statement.field_id === '') {
        return false
      }
      this.$refs.columnFormula.focus()
      let name = this.dsl_statement.field_id.name.length > 24 ? this.dsl_statement.field_id.name.substring(0, 24) + '...' : this.dsl_statement.field_id.name
      this.pasteHtmlAtCaret(`<span class="accent-argument"
          field_id=${this.dsl_statement.field_id.id || null}
          title='${this.dsl_statement.field_id.name}'
          field_type=${this.dsl_statement.field_id.entity_type_id}
          contenteditable="false">${name}
          <i class="remove" contenteditable="false"></i></span>`)
      this.cancelCurrentTable()
      this.caretPosition = {}
      this.develop = this.$refs.columnFormula.innerHTML
    },
    pasteCatalogInFormula () {
      this.isCatalog = false
      this.$refs.columnFormula.focus()
      let chip = ''
      this.selectNodeCatalog.forEach((el, index) => {
        let b = ''
        const str = `${this.selectNodeCatalog[index].name}.${this.selectNodeCatalog[index].id}`
        let name = str.length > 24 ? str.substring(0, 24) + '...' : str
        if (index === 0 || this.selectNodeCatalog[this.selectNodeCatalog.length - 1] === index) {
          b += `<span class="accent-index"
      id="${this.selectNodeCatalog[index].id}"
      title="${this.selectNodeCatalog[index].name}.${this.selectNodeCatalog[index].id}"
      contenteditable="false">${name}
      <i class="remove" contenteditable="false"></i></span>`
        } else {
          b += `,<span class="accent-index"
      id="${this.selectNodeCatalog[index].id}"
      title="${this.selectNodeCatalog[index].name}.${this.selectNodeCatalog[index].id}"
      contenteditable="false">${name}
      <i class="remove" contenteditable="false"></i></span>`
        }
        chip += b
      })
      this.pasteHtmlAtCaret(chip)
      this.caretPosition = {}
      this.develop = this.$refs.columnFormula.innerHTML
    },
    pasteArgOuterTable () {
      if (this.dsl_statement.xref_id === '' || this.dsl_statement.xref_id === null) {
        return false
      }
      this.$refs.columnFormula.focus()
      const str = `${this.dsl_statement.xref_id.name}.${this.dsl_statement.selectField_xref.name || ''}`
      let name = str.length > 24 ? str.substring(0, 24) + '...' : str
      this.pasteHtmlAtCaret(`<span class="accent-argument"
          xref_id=${this.dsl_statement.target_xref_id}
          field_id=${this.dsl_statement.selectField_xref.id || null}
          join_field_id=${this.dsl_statement.outerField_id}
          object_id=${this.dsl_statement.target_object_id}
          field_type=${this.dsl_statement.selectField_xref.entity_type_id || null}
          xref_type=${this.xrefType}
          title='${this.dsl_statement.xref_id.name}.${this.dsl_statement.selectField_xref.name || ''}'
          contenteditable="false">${name}
          <i class="remove" contenteditable="false"></i></span>`)
      this.cancelOuterTable()
      this.caretPosition = {}
      this.develop = this.$refs.columnFormula.innerHTML
    },
    pasteHtmlAtCaret (html) {
      if (window.getSelection) {
        const el = document.createElement('div')
        console.log(html + '&nbsp;')
        el.innerHTML = html + '&nbsp;'
        let frag = document.createDocumentFragment()
        let node
        let textNode = document.createTextNode('    ')
        while ((node = el.firstChild)) {
          frag.appendChild(textNode)
          frag.appendChild(node)
          frag.appendChild(textNode)
        }
        if (typeof this.caretPosition.range !== 'undefined') {
          // console.log('work range')
          // курсор в конец строки
          // const range = document.createRange()
          // range.selectNodeContents(this.$refs.columnFormula)
          // range.collapse(false)
          // const sel = window.getSelection()
          // sel.removeAllRanges()
          // sel.addRange(range)
          this.caretPosition.range.deleteContents()
          this.caretPosition.range.insertNode(frag)
        } else {
          this.$refs.columnFormula.appendChild(frag)
        }
      }
    },
    performMark () {
      markWord(this.$refs.columnFormula)
    },
    async initialize () {
      const id = this.propslocal.businesslogic.registry_id
      if (id === undefined) return false
      // список полей реестра ввиде дерева
      let registryTree = await Entity.params({ parent_id: id, show_children: true }).get()
      this.listField = this.deepSearch(registryTree.data)
      // список всех ссылок реестра
      let xref = await Entity.params({ object_id: id, type: 'xref_outer_field,xref_field,xref_multi_field' }).get()
      this.listXref = xref.data
      // список функций
      let mathFunction = await Formulas.custom('formulas/functions').get()
      let obj = this.$locale.logic_editor.column_formula.translate_function
      this.listfunction = mathFunction.filter(el => el.is_column_function).map(el => ({
        id: el.id,
        name: obj[el.name] || el.name
      }))
      // список операций
      let mathOperands = await Formulas.custom('formulas/operands').get()
      let operands = this.$locale.logic_editor.column_formula.translate_operands
      this.listOperands = mathOperands.map(el => ({ id: el.id, name: operands[el.name] || el.name }))
      // редактирование
      if (!this.propslocal.isNewAction) {
        this.columnFormula = this.propslocal.getAnswer
        if (this.columnFormula.entity_id) {
          let node = this.$refs.entity_tree.$refs.treeselect.getNode(this.columnFormula.entity_id)
          while (node.parentNode) {
            this.$refs.entity_tree.$refs.treeselect.toggleExpanded(node.parentNode)
            node = node.parentNode
          }
        }
        // if (this.columnFormula.entity_id === null) {
        //   this.columnFormula.entity_id = ''
        // }
        this.btnPreCount = this.columnFormula.is_stored
        this.$refs.columnFormula.innerHTML = this.columnFormula.dsl_statement || null
        this.develop = this.columnFormula.dsl_statement || null
        for (let key in this.columnFormula.sql_statements) {
          if (this.columnFormula.sql_statements[key] === '') this.columnFormula.sql_statements[key] = null
        }
      }
      // для формул из уведомлений
      if (this.$attrs.notifyFormula) {
        console.log(this.$attrs.notifyFormula.type)
        if (this.$attrs.notifyFormula.type === 'title') {
          if (this.propslocal.getAnswer) {
            this.columnFormula = this.propslocal.getAnswer
            this.$refs.columnFormula.innerHTML = this.columnFormula.dsl_statement || null
          }
        }
        if (this.$attrs.notifyFormula.type === 'content') {
          if (this.propslocal.getNotifyContent) {
            this.columnFormula = this.propslocal.getNotifyContent
            this.$refs.columnFormula.innerHTML = this.columnFormula.dsl_statement || null
          }
        }
        this.columnFormula.name = this.$attrs.notifyFormula.name
      } else if (this.$attrs.objectFormula) {
        this.columnFormula.name = this.$attrs.objectFormula.eof.name

        if (this.propslocal.getAnswer) {
          this.columnFormula = this.propslocal.getAnswer
          this.$refs.columnFormula.innerHTML = this.columnFormula.dsl_statement || null
        }
      }
    }
  },
  async mounted () {
    await this.initialize()
  }
}
</script>

<style lang="scss">
  @import "./style/column_formula.scss";
</style>
