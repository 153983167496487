





































import Vue from 'vue'
import ApprovalForm from '@/services/LogicEditor/infrastructure/components/forms/ApprovalForm/ApprovalForm.vue'
import LogicForm from '@/services/LogicEditor/infrastructure/components/forms/LogicForm.vue'
import StateForm from '@/services/LogicEditor/infrastructure/components/forms/StateForm/index.vue'
import LogicGroupForm from '@/services/LogicEditor/infrastructure/components/forms/LogicGroupForm.vue'
import ConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/ConstraintForm.vue'
import ViewConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/ViewConstraintForm.vue'
import DisableConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/DisableConstraintForm.vue'
import ListenerForm from '@/services/LogicEditor/infrastructure/components/forms/ListenerForm.vue'
import CommandForm from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/index.vue'
import RelatedObjectForm from '@/services/LogicEditor/infrastructure/components/forms/RelatedObjectForm/RelatedObjectForm.vue'
import FormulaForm from '@/services/LogicEditor/infrastructure/components/forms/FormulaForm.vue'
import RowFormulaForm from '@/services/LogicEditor/infrastructure/components/forms/RowFormulaForm.vue'
import TimerForm from '@/services/LogicEditor/infrastructure/components/forms/TimerForm/index.vue'
import LogicTreeElementByElementGuidQuery from '@/services/LogicEditor/application/query/LogicTreeElementByElementGuidQuery'
import LogicByGuidQuery from '@/services/LogicEditor/application/query/LogicByGuidQuery'

export default Vue.extend({
  name: 'EditorElementLayout',

  components: {
    ApprovalForm,
    LogicForm,
    StateForm,
    LogicGroupForm,
    ConstraintForm,
    ViewConstraintForm,
    DisableConstraintForm,
    ListenerForm,
    CommandForm,
    RelatedObjectForm,
    FormulaForm,
    RowFormulaForm,
    TimerForm
  },

  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },

    form: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: ''
    },

    elementType: {
      type: String,
      required: true
    }
  },

  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getTreeElementLayout', 'getEditorNode'],

  computed: {
    isNew () {
      if (typeof this.value.guid === 'undefined') {
        return true
      }

      return this.value.guid === null
    },

    showRecalculateFormula () {
      return this.elementType === 'formula' && !this.isNew
    },

    approvalCreated () {
      return this.$store.getters['Approval/getApprovalLocation']
    },

    commandCreated () {
      return this.$store.getters['Command/getCommandLocation']
    },

    constraintCreated () {
      return this.$store.getters['Constraint/getConstraintLocation']
    },

    formulaCreated () {
      return this.$store.getters['Formula/getFormulaLocation']
    },

    listenerCreated () {
      return this.$store.getters['Listener/getListenerLocation']
    },

    logicCreated () {
      return this.$store.getters['Logic/getLogicLocation']
    },

    logicGroupCreated () {
      return this.$store.getters['LogicGroup/getLogicGroupLocation']
    },

    relatedObjectCreated () {
      return this.$store.getters['RelatedObject/getRelatedObjectLocation']
    },

    rowFormulaCreated () {
      return this.$store.getters['RowFormula/getRowFormulaLocation']
    },

    stateCreated () {
      return this.$store.getters['State/getStateLocation']
    },

    timerCreated () {
      return this.$store.getters['Timer/getTimerLocation']
    },

    objectId () {
      let node = this.getEditorNode()

      return node ? node.data.entity_id : null
    },

    logicId () {
      let node = this.getEditorNode()

      return node ? node.data.logic_id || node.data.id : null
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    approvalCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/approvals/', ''))
      }
    },

    commandCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/commands/', ''))
      }
    },

    constraintCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/constraints/', ''))
      }
    },

    formulaCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/formulas/', ''))
      }
    },

    listenerCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/listeners/', ''))
      }
    },

    logicCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/logic/', ''), true)
      }
    },

    logicGroupCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/logic_groups/', ''))
      }
    },

    relatedObjectCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/related_objects/', ''))
      }
    },

    rowFormulaCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/row_formulas/', ''))
      }
    },

    stateCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/states/', ''))
      }
    },

    timerCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/timers/', ''))
      }
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    closeEditor () {
      this.getTreeElementLayout().closeEditor()
    },

    addElementToTree (guid, isLogic = false) {
      let query = !isLogic
        ? new LogicTreeElementByElementGuidQuery(guid)
        : new LogicByGuidQuery(guid)

      this.getQueryBus()
        .execute(query)
        .then((response) => {
          if (
            typeof response.code !== 'undefined' && ['invalid_guid', 'entity_not_found'].indexOf(response.code) !== -1
          ) {
            return
          }

          let parentNode = !isLogic ? this.getEditorNode() : null

          this.getTreeElementLayout().addNode(response, parentNode)
        })
    },

    recalculateFormula () {
      this.$http.post(`${this.$config.api}/v2/logiceditor/formulas/${this.value.guid}/recalculate`)
    },

    submit () {
      this.$refs.form.submit(async () => {
        if (this.isNew === false && this.elementType !== 'logic') {
          let node = await this.getTreeElementLayout().getNodeByElementGuid(this.value.guid)
          if (node) {
            node.data.name = this.value.name
            this.getTreeElementLayout().updateNode(node)
          }
        }
      })
    }
  }
})
