import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class TimerCommandDTO {
  timer_id?: number;
  command_id: number;
  row_order: number;

  constructor (
    { timer_id, command_id, row_order } : {
      timer_id?: number;
      command_id: number;
      row_order: number;
    }
  ) {
    this.timer_id = timer_id
    this.command_id = command_id
    this.row_order = row_order
  }

  static create (rowOrder: number): TimerCommandDTO {
    return new TimerCommandDTO({
      command_id: null,
      row_order: rowOrder
    })
  }
}

export class TimerDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  alias: Nullable<string>
  description: Nullable<string>
  period: Nullable<string>;
  commands: Array<object>
  logic_id?: Nullable<number>
  parent_tree_element_id?: Nullable<number>

  constructor ({ id, guid, name, alias, description, period, commands, logic_id, parent_tree_element_id }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.alias = alias
    this.description = description
    this.period = period
    this.commands = commands
    this.logic_id = logic_id
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): TimerDTO {
    return new TimerDTO({
      id: null,
      guid: null,
      name: null,
      alias: null,
      description: null,
      period: null,
      commands: [],
      logic_id: null,
      parent_tree_element_id: null
    })
  }
}

export default class Timer extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private alias: Nullable<string>
  private description: Nullable<string>
  private period: Nullable<string>
  private commands: Array<object>
  private logicId: number
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    alias: Nullable<string>,
    description: Nullable<string>,
    period: Nullable<string>,
    commands: Array<object>,
    logicId: number,
    parentTreeElementId?: Nullable<number>,
    id?: Nullable<number>,
    guid?: Nullable<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.period = period
    this.commands = commands
    this.alias = alias
    this.logicId = logicId
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: TimerDTO): Timer {
    return new Timer(
      dto.name,
      dto.alias,
      dto.description,
      dto.period,
      dto.commands,
      dto.logic_id,
      dto.parent_tree_element_id,
      dto.id,
      dto.guid
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  getPeriod (): string {
    return this.period
  }

  setPeriod (period: string): void {
    this.period = period
  }

  getCommands (): Array<object> {
    return this.commands
  }

  setCommands (commands: Array<object>): void {
    this.commands = commands
  }

  getLogicId (): number {
    return this.logicId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }
}
