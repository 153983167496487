import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import TaskRegistryEntity, { IAPITaskRegistryEntity } from '@/services/TaskEditor/domain/model/TaskRegistryEntity'

export namespace TaskRegistryEntityAPI {
  export class GetTaskRegistryEntities implements APIRequest<Array<IAPITaskRegistryEntity>> {
    response: Array<IAPITaskRegistryEntity>;
    path = `/taskeditor/task_registry_entities`;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse<Array<IAPITaskRegistryEntity>>) => TaskRegistryEntity.loadMany(data.data)
    constructor () {}
  }

  export class GetTaskRegistryEntityByProperty implements APIRequest<IAPITaskRegistryEntity> {
    response: IAPITaskRegistryEntity;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse<IAPITaskRegistryEntity>) => TaskRegistryEntity.load(data.data)
    constructor (property: string) {
      this.path = `/taskeditor/task_registry_entities/${property}`
    }
  }

  export class UpdateTaskRegistryEntities implements APIRequest<string> {
    response: string;
    path = `/taskeditor/task_registry_entities`;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: Array<TaskRegistryEntity>) {}
  }
}
