
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { XrefAPI } from '@/services/RegistryTable/infrastructure/api/XrefAPI'

export default {
  template: `<div class="ag-filter-wrapper ag-focus-managed">
  <div class="ag-filter-body-wrapper ag-set-filter-body-wrapper">

    <div class="ag-set-filter">
      <div v-show="loading" class="ag-filter-loading">Загрузка...</div>
      <!--AG-INPUT-TEXT-FIELD-->
      <div class="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div class="ag-input-field-label ag-label ag-hidden ag-text-field-label"></div>
        <div class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <input v-model="search" class="ag-input-field-input ag-text-field-input" type="text" placeholder="Поиск...">
        </div>
      </div>
      <div v-if="!loading && options.length === 0" class="ag-filter-no-matches ag-hidden">Не найдено</div>
      <div v-else-if="!loading" class="ag-set-filter-list" role="presentation">
        <div class="ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed" role="listbox">
          <div class="ag-tab-guard ag-tab-guard-top" role="presentation" tabindex="0"></div>
          <div class="ag-virtual-list-container ag-filter-virtual-list-container" ref="eContainer">
            <div style="padding: 3px 0px;" class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div v-show="options.length !== 1" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
                   @click="selectAllOptions"
              >
                <div class="ag-input-field-label ag-label ag-checkbox-label">
                  (Выбрать все)
                </div>
                <div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                     :class="{
                      'ag-checked': isAllOptionsChecked,
                      'ag-indeterminate': isIndeterminateCheckbox
                    }"
                >
                  <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
                </div>
              </div>
            </div>
            <div v-for="(item, index) in listFoundItems" :key="index" style="padding: 3px 0px;" class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div @click="item.checked = !item.checked"
                   class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                <div ref="eLabel" class="ag-input-field-label ag-label ag-checkbox-label">
                  {{ item.name  }}
                </div>
                <div ref="eWrapper"
                     class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                     :class="{'ag-checked': item.checked}"
                     role="presentation">
                  <input ref="eInput" class="ag-input-field-input ag-checkbox-input"
                         type="checkbox">
                </div>
              </div>
            </div>
          </div>
          <div class="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabindex="0"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="ag-filter-apply-panel">
    <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="apply">ОК</button>
    <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="reset">Сбросить</button>
  </div>
  </div>`,
  name: 'xrefFilter',
  data () {
    return {
      options: [],
      valueGetter: null,
      loading: false,
      error: null,
      search: ''
    }
  },
  mounted () {
    if (this.params.colDef.field && /attr_[0-9]+_/i.test(this.params.colDef.field)) {
      this.loadOptions(this.params.colDef.field.replace(/[^0-9]/g, ''))
    }
  },
  computed: {
    isAllOptionsChecked () {
      return this.options.filter((item) => item.checked).length === this.options.length
    },
    isIndeterminateCheckbox () {
      return !this.isAllOptionsChecked && this.options.filter((item) => item.checked).length > 0
    },
    listFoundItems () {
      return this.options.filter((item) => this.search.length ? ((item.name || '').toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : true)
    }
  },
  methods: {
    reset () {
      if (this.options.length === 1) {
        this.options[0].checked = false
      } else {
        this.options.forEach((item) => { item.checked = true })
      }
      this.search = ''
      this.params.filterChangedCallback()
    },
    apply () {
      if (this.search.length) {
        // выделить найденые чекбоксы
        let searchById = this.listFoundItems.filter((item) => item.checked).map(item => item.id)
        this.options.forEach(item => {
          item.checked = searchById.includes(item.id)
        })
      }

      this.params.filterChangedCallback()
    },
    selectAllOptions () {
      let flagToSet = false
      if (this.options.some((item) => !item.checked)) {
        flagToSet = true
      }
      this.options.forEach((item) => { item.checked = flagToSet })
    },
    async loadOptions (attributeId) {
      this.loading = true
      let options = await APIClient.shared.request(new XrefAPI.GetData(
        parseInt(attributeId)
      ))
      this.options = options.map((item) => {
        item.checked = true
        return item
      })
      this.loading = false
    },
    isFilterActive () {
      if (this.options.length === 1) {
        return this.options.some((item) => item.checked)
      }
      return !this.isAllOptionsChecked
    },

    getModel () {
      return { filterType: 'xref', type: this.isFilterActive() ? 'active' : 'non-active', value: this.options.filter((item) => item.checked).map((item) => item.id) }
    },

    setModel (model) {
      console.warn('setModel method in XrefFilter is disabled', model)
      /* if (model && Array.isArray(model)) {
        this.options.forEach((item) => {
          if (model.includes(item.id)) {
            item.checked = true
          }
        })
      } */
    }
  }
}
