import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import UserQueryRepositoryInterface from '../../../domain/repository/UserQueryRepositoryInterface'
import UserPasswordCheckQuery from '../../query/UserPasswordCheckQuery'

export default class UserPasswordCheckHandler implements QueryHandlerInterface
{
    private repo: UserQueryRepositoryInterface;

    constructor(repo: UserQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: UserPasswordCheckQuery): Promise<any>
    {
        return this.repo.passwordCheck(
            query.getPassword()
        );
    }
}