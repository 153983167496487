<template>
  <div>
    <el-form-item label="Поле">
      <el-select v-model="value.geometryField">
        <el-option
          v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
          :key="index"
          :label="`${item.name} (${item.properties.name})`"
          :value="item.properties.name"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Система координат">
      <coordinate-system
        :value="value.nativeCoordinateSystemId"
        @change-cs="value.nativeCoordinateSystemId = $event"
      ></coordinate-system>
    </el-form-item>
    <el-form-item label="Способ обработки">
      <el-select
        v-model="value.srsHandlingType">
        <el-option
          v-for="item in $locale.interface_editor.component_editor.map_layers.srs_handling_types"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

export default {
  name: 'Field',
  components: { CoordinateSystem },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          entityId: null,
          geometryField: null,
          marksField: null
        }
      }
    }
  },
  inject: ['getComponents'],
  data () {
    return {
      components: this.getComponents()
    }
  }
}
</script>

<style scoped>

</style>
