<template>
  <div class="menu_form">
    <el-form ref="form" :model="form" size="mini" label-position="top" :rules="rules">
      <el-form-item :label="$locale.menu_editor.label.name" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="form.is_visible" :label="$locale.menu_editor.label.visible"></el-checkbox>
      </el-form-item>
      <!--<el-form-item>
        <el-checkbox v-model="form.is_badged" :label="$locale.menu_editor.label.badged"></el-checkbox>
      </el-form-item>-->
      <el-form-item class="subMenu" :label="$locale.menu_editor.label.type">
        <el-select v-model="form.menu_type_id">
          <el-option
            v-for="item in listType"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <component @properties='getProperties' :properties='form.properties' :is="form.menu_type_id"></component>
      <el-switch v-model="isUserIcon"></el-switch>
      <span style="padding: 3px">Переключить тип иконки</span>
      <el-form-item v-if="!isUserIcon" class="subMenu" :label="$locale.menu_editor.label.tag">
            <el-select clearable v-model="form.icon_value" filterable>
              <el-option
                v-for="item in listTag"
                :key="item.value"
                :label="item.label"
                class="menu_icon"
                :value="item.value">
                <span style="float: left">{{ item.label }}</span>
                <div style="float: right;font-size: 30px;width: 22px;height: 22px" :class="item.value"></div>
              </el-option>
            </el-select>
      </el-form-item>
      <el-form-item v-else class="subMenu" :label="$locale.menu_editor.label.user_icon">
        <el-container class="iconsContainer">
          <el-upload
            :headers="getHeaders()"
            :action="`${this.$config.api}/menueditor/menu/icons`"
            list-type="picture-card"
            :file-list="listIcons"
            @handle-preview="test"
            @click.native="iconClick">
            <i class="el-icon-plus" style="vertical-align: inherit; padding-top: 5px;"></i>
          </el-upload>
        </el-container>
      </el-form-item>
      <el-form-item  class="subMenu" :label="$locale.menu_editor.label.show_menu">
        <el-select
          multiple
          collapse-tags
          clearable v-model="form.roles_denied">
          <el-option
            v-for="item in listRole"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <div style="text-align: right;">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-success"
          @click="submit">
          {{ $locale.menu_editor.button.save }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import OpenObject from '../elementSubMenu/object'
import Dashboard from '../elementSubMenu/dashboard'
import Report from '../elementSubMenu/report'
import Command from '../elementSubMenu/command'
import Editor from '../elementSubMenu/editor'
import Plugin from '../elementSubMenu/plugin'
import Url from '../elementSubMenu/url'
import separator from '../elementSubMenu/separator'
import Menu from '../Models/Menu'
import Icons from '../components/el-icons'
// import Role from '../Models/accesseditor/Role'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RoleAPI } from '@/services/AccessEditor/infrastructure/api/RoleAPI'
export default {
  name: 'ChildMenuForm',

  props: ['activeNode', 'value', 'save'],

  components: {
    OpenObject,
    Dashboard,
    Report,
    Command,
    Editor,
    Plugin,
    Url,
    separator
  },

  watch: {
    'form.menu_type_id': function (newValue) {
      if (newValue === 'object') {
        this.form.menu_type_id = 'OpenObject'
      }
      if (this.changeId === this.form.id) {
        if (this.form.menu_type_id === 'OpenObject') {
          this.form.properties = [{ 'id': null, 'type': null, 'value': null }, { 'id': null, 'type': null, 'value': null }]
        } else {
          this.form.properties = [{ 'id': null, 'type': null, 'value': null }]
        }
      }
    },

    form: function (val, old) {
      this.changeId = val.id
    }
  },

  data () {
    return {
      form: {
        roles_denied: []
      },
      tag: null,
      checked: true,
      listType: [
        /* {
          value: 'title',
          label: this.$locale.menu_editor.label.title
        },
        {
          value: 'separator',
          label: this.$locale.menu_editor.label.separator
        }, */
        {
          value: 'OpenObject',
          label: this.$locale.menu_editor.label.object
        },
        {
          value: 'dashboard',
          label: this.$locale.menu_editor.label.dashbord
        },
        {
          value: 'report',
          label: this.$locale.menu_editor.label.report
        },
        {
          value: 'command',
          label: this.$locale.menu_editor.label.command
        },
        {
          value: 'editor',
          label: this.$locale.menu_editor.label.editor
        },
        {
          value: 'plugin',
          label: this.$locale.menu_editor.label.plugin
        },
        {
          value: 'url',
          label: this.$locale.menu_editor.label.url
        }
      ],
      listTag: [],
      listRole: [],
      changeId: null,
      rules: {
        name: [{ required: true, message: this.$locale.menu_editor.rules.name, trigger: 'blur' }]
      },
      isUserIcon: false,
      listIcons: [],
      activeIcon: null
    }
  },

  async mounted () {
    this.listTag = Icons.map(el => ({ value: el, label: el }))
    this.form = Object.assign({}, this.form, this.value)
    // this.listRole = await Role.params({ fields: 'id,name' }).get()
    try {
      this.listRole = await APIClient.shared.request(new RoleAPI.GetRoles([['fields', 'id,name']]))
    } catch (error) {
      console.log({ error })
    }
    let response = await this.$http.get(`${this.$config.api}/menueditor/menu/icons`)
    this.listIcons = response.data

    if (this.form.icon_type === 'image') {
      this.form.icon_value = null
    }
  },

  methods: {
    test(file) {
      console.log(file)
    },
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.changeId = null
          if (this.form.menu_type_id === 'OpenObject') this.form.menu_type_id = 'object'
          const menu = new Menu(this.form)
          menu.icon_value = this.form.icon_value || null

          if (this.isUserIcon === true) {
            menu.icon_type = 'image'
          } else {
            menu.icon_type = 'css'
          }

          let response = await menu.save()
          this.activeNode.data = Object.assign({}, this.activeNode.data, { ...response })
        }
      })
    },

    getProperties (data) {
      this.form.properties = data
    },

    iconClick: function (event) {
      if (typeof event.target.currentSrc === 'string') {
        this.$message({
          type: 'success',
          message: `Выбрана иконка: ${event.target.currentSrc}`
        })

        this.form.icon_value = event.target.currentSrc
      }
    },

    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    }
  }
}
</script>

<style>
  .subMenu {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .subMenu .el-select.el-select--small {
    display: block;
  }

  .subMenuEditor .el-form-item.required label.el-form-item__label:before {
    content: '* ';
    color: #ff4949;
    font-weight: bold;
  }

  .iconsContainer {
    display: flex;
    flex-wrap: wrap;
    height: 150px;
    padding: 2px 2px 2px;
  }

  .iconsContainer .el-upload-list--picture-card .el-upload-list__item-actions {
    display: none;
  }

  .iconsContainer .el-upload-list__item-status-label {
    display: none;
  }

  .iconsContainer .el-upload-list__item {
    height: 40px;
    width: 40px;
  }

  .iconsContainer .el-upload--picture-card {
    height: 40px;
    width: 40px;
  }

  .iconsContainer .el-upload-list__item.is-success .el-upload-list__item-status-label {
    display: none;
  }

  .activeCustomIcon {
    border: green solid;
  }
</style>
