<template>
    <div style="height: calc(100vh - 99px);overflow-y: auto;overflow-x: hidden;padding-right: 10px;" class="row_formulas custom_scrollbar">
      <h1 style="margin-top: 10px">{{$locale.logic_editor.action.row_formulas}}</h1>
    <div class="row_formulas__item2">
      <div class="demonstration required">{{$locale.logic_editor.row_formulas.name}}</div>
      <el-input size="medium" class="row_formulas__item" v-model="form.name"></el-input>
    </div>
    <div class="row_formulas__item2">
      <div class="demonstration required">{{$locale.logic_editor.row_formulas.alias}}</div>
      <selectTree style="margin-bottom: 10px" ref="entity_tree" :dataTable="listAlias" :selectAlias="selectAlias" :value="alias" @input='changeAlias'/>
    </div>

    <div>
      <div class="demonstration">{{$locale.logic_editor.row_formulas.cells}}</div>
      <div class="row_formulas__item4">
        <div class="row_formulas__item5" style="margin-right: 10px;">
          <div class="demonstration required">{{$locale.logic_editor.row_formulas.row}}</div>
          <el-select
            value-key="name"
            filterable
            class="row_formulas__item extraLine_select1"
            v-model="form.target_alias_value"
            size="medium"
          >
            <el-option v-for="item in listRows" :key="item.id" :label="item.name" :value="item"></el-option>
          </el-select>
        </div>
        <div class="row_formulas__item5">
          <div class="demonstration required">{{$locale.logic_editor.row_formulas.column}}</div>
        <selectTree :multiple="true" :dataTable="listAlias" :value="form.target_row_formula_columns" style="margin-bottom: 10px" @input='formulaColums'/>
        </div>
      </div>
      <div class="row_formulas__item2 row_formulas__width">
        <div class="demonstration">{{$locale.logic_editor.column_formula.function}}</div>
        <el-select
          value-key="id"
          class="row_formulas__item"
          v-model="selectFunction"
          filterable
          clearable
          size="medium"
        >
          <el-option v-for="item in listfunction" :key="item.id" :label="item.name" :value="item"></el-option>
        </el-select>
      </div>
    </div>
    <div style="position: relative">
      <div ref="rowFormula" @click="cursorPosition" @click.right.prevent="contexMenu" @keyup="cursorPosition"
        class="row_formulas__edit"
        contenteditable="true"
        placeholder="Формула"></div>

        <nav ref="ctxMenu" class="context-menu">
          <template v-if="this.flag && this.selectChips != null">
            <ul  class="context-menu__items" v-for="item in listCtxMenu" :key="item.id">
              <li @click="actionCtxMenu(item.id)" class="context-menu__item context-menu__link">{{item.value}}</li>
            </ul>
          </template>
          <template v-else>
              <ul  class="context-menu__items">
              <li @click="pasteCopyChips" class="context-menu__item context-menu__link">{{this.$locale.logic_editor.column_formula.paste_chip}}</li>
            </ul>
          </template>
        </nav>
    </div>
  <!-- current table -->
      <el-dialog class="row_formula_dialog" :modal="false" :modal-append-to-body="false" :append-to-body="true" width='40%' title="Редактор формул" :visible.sync="isDialogСurrentTable">
        <el-form :model="currentTable">
          <el-form-item class="required" :label='this.$locale.logic_editor.row_formulas.row'>
            <el-select
              value-key="name"
              filterable
              class="row_formulas__item extraLine_select1"
              v-model="currentTable.alias_value"
              size="medium"
            >
              <el-option v-for="item in listRows" :key="item.id" :label="item.name" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="this.$locale.logic_editor.row_formulas.current_column">
            <selectTree :dataTable="listAlias" :selectAlias="selectCurrentAlias" :value="currentTableAlias" style="margin-bottom: 10px"  @input='changeCurrentTableAlias'/>
          </el-form-item>
          <el-form-item :label='this.$locale.logic_editor.row_formulas.filters'>
            <selectTree :multiple="true" :dataTable="listAlias" :value="currentTable.filters" style="margin-bottom: 10px" @input='currentTableFilters'/>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">{{$locale.main.button.cancel}}</el-button>
          <el-button type="primary" @click="changeSpan">{{$locale.main.button.save}}</el-button>
        </span>
      </el-dialog>
  <!-- outer table -->
      <el-dialog class="row_formula_dialog"
                width='40%'
                title="Редактор формул"
                :visible.sync="isDialogOuterTable"
                :show-close='false'
                :modal="false"
                :close-on-click-modal='false'>
        <el-form :model="outerTable">
          <el-form-item class="required" :label='this.$locale.logic_editor.row_formulas.table'>
            <el-select
              value-key="id"
              class="row_formulas__item extraLine_select1"
              v-model="outerTable.table"
              size="mini"
            >
              <el-option
                v-for="item in listOuterTables"
                :key="item.id"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="required" :label="this.$locale.logic_editor.row_formulas.outer_column">
            <selectTree :dataTable="listOuterColumn" :selectAlias="selectOuterAlias" :value="outerTableAlias" style="margin-bottom: 10px" @input='changeOuterTableAlias'/>
          </el-form-item>
          <el-form-item class="required" :label="this.$locale.logic_editor.row_formulas.row">
            <el-select
                value-key="name"
                class="row_formulas__item extraLine_select1"
                v-model="outerTable.alias_value"
                size="mini"
              >
                <el-option
                  v-for="item in listOuterRow"
                  :key="item.name"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          <el-form-item :label='this.$locale.logic_editor.row_formulas.filters'>
            <el-select
              value-key="id"
              class="row_formulas__item extraLine_select1"
              multiple
              v-model="outerTable.filters"
              size="mini"
            >
              <el-option
                v-for="item in listOuterFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">{{$locale.main.button.cancel}}</el-button>
          <el-button type="primary" :disabled="outerDisabledBtn" @click="changeSpan">{{$locale.main.button.save}}</el-button>
        </span>
      </el-dialog>

    <div style="margin: 10px">
      <!-- ------- -->
      <div>
        <el-button type="primary"
            size="small"
            v-clipboard:copy="develop"
            v-clipboard:success="onCopy"
            style="margin: 15px"
            v-clipboard:error="onError">{{this.$locale.logic_editor.column_formula.copy_formula}}</el-button>
        <el-checkbox
            class="column_formula__row"
            v-model="isDevelop"
          >{{$locale.logic_editor.column_formula.dev}}</el-checkbox>
        <el-input
          v-show="isDevelop"
          type="textarea"
          style="border: none; min-height: auto;"
          class="row_formulas__edit"
          :rows="6"
          readonly
          v-model="develop">
        </el-input>
      </div>
      <!-- ------- -->
      <!-- current table -->
      <el-popover placement="right" width="400" trigger="click" v-model="currentTableVisible">
        <div style="padding: 10px;">
          <div>
            <div class="demonstration required">{{$locale.logic_editor.row_formulas.row}}</div>
            <el-select
              value-key="id"
              filterable
              class="row_formulas__item extraLine_select1"
              v-model="currentTable.alias_value"
              size="mini"
            >
              <el-option
                v-for="item in listRows"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </div>
          <div style="margin-bottom: 10px;">
            <div class="demonstration">{{$locale.logic_editor.row_formulas.current_column}}</div>
            <selectTree :dataTable="listAlias" :selectAlias="selectCurrentAlias" :value="currentTableAlias" style="margin-bottom: 10px" @input='changeCurrentTableAlias'/>
          </div>
          <div>
            <div class="demonstration">{{$locale.logic_editor.row_formulas.filters}}</div>
            <selectTree :multiple="true" :dataTable="listAlias" :value="currentTable.filters" style="margin-bottom: 10px" @input='currentTableFilters'/>
          </div>
          <div class="row_formula__footer popover_footer">
            <el-button @click="cancelCurrentTable">{{ $locale.main.button.cancel }}</el-button>
            <el-button
              size="medium"
              :disabled='currentDisabledBtn'
              @click="pasteCarrentTable"
              type="primary"
            >{{ $locale.logic_editor.row_formulas.insert_cell }}</el-button>
          </div>
        </div>
        <el-button style="margin: 10px" slot="reference">{{ $locale.logic_editor.column_formula.current_table }}</el-button>
      </el-popover>
      <!-- outer table -->
      <el-popover placement="right" width="400" trigger="click" v-model="outerTableVisible">
        <div style="padding: 10px;">
          <div>
            <div class="demonstration required">{{$locale.logic_editor.row_formulas.table}}</div>
            <el-select
              value-key="id"
              class="row_formulas__item extraLine_select1"
              v-model="outerTable.table"
              size="mini"
            >
              <el-option
                v-for="item in listOuterTables"
                :key="item.id"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div>
            <div class="demonstration required">{{$locale.logic_editor.row_formulas.outer_column}}</div>
            <selectTree :dataTable="listOuterColumn" :selectAlias="selectOuterAlias" :value="outerTableAlias" style="margin-bottom: 10px" @input='changeOuterTableAlias'/>
          </div>
          <div>
            <div class="demonstration required">{{$locale.logic_editor.row_formulas.row}}</div>
            <el-select
              value-key="name"
              class="row_formulas__item extraLine_select1"
              v-model="outerTable.alias_value"
              size="mini"
            >
              <el-option
                v-for="item in listOuterRow"
                :key="item.name"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div>
            <div class="demonstration">{{$locale.logic_editor.row_formulas.filters}}</div>
            <el-select
              value-key="id"
              class="row_formulas__item extraLine_select1"
              multiple
              v-model="outerTable.filters"
              size="mini"
            >
              <el-option
                v-for="item in listOuterFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="row_formula__footer popover_footer">
            <el-button @click="cancelOuterTable">{{ $locale.main.button.cancel }}</el-button>
            <el-button
              size="medium"
              @click="pasteOuterTable"
              :disabled="outerDisabledBtn"
              type="primary"
            >{{ $locale.logic_editor.row_formulas.insert_cell }}</el-button>
          </div>
        </div>
        <el-button slot="reference">{{ $locale.logic_editor.column_formula.out_table }}</el-button>
      </el-popover>
    </div>
    <el-footer class="row_formulas_footer">
      <el-button
        @click="saveFormula"
        :disabled='disabledBtnSave'
        type="primary"
      >{{ $locale.main.button.save }}</el-button>
    </el-footer>
  </div>
</template>

<script>
import Registry from '../Models/Registry'
import Relates from '../Models/relates'
import Rows from '../Models/rows'
import Filters from '../Models/filters'
import Formulas from '../Models/formulas'
import selectTree from './treeSelect'
export default {
  name: 'rows',
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    },
    isNew: Boolean
  },
  components: {
    selectTree
  },
  watch: {
    'form.alias_field_id': async function (val, oldVal) {
      this.listRows = []
      let res = await this.$http.get(`${this.$config.api}/registryservice/xref/`, {
        params: {
          registry_id: this.propslocal.businesslogic.registry_id,
          attribute_id: val.id,
          distinct: true
        }
      })
      if (res) {
        this.listRows = this.filterRows(val.entity_type_id, res.data)
      }
      if (this.propslocal.getAnswer.target_alias_value !== undefined) {
        this.form.target_alias_value = this.listRows.find(el => el.name === this.propslocal.getAnswer.target_alias_value)
      }
    },
    'outerTable.table': async function (val) {
      if (val.id) {
        if (!this.isClickCtxMenu) {
          this.outerTable.alias_value = null
          this.outerTable.field_id = {}
          this.outerTable.filters = []
        }
        try {
          let listOuterColumn = await Registry.params({ parent_id: val.object_id, show_children: true, properties: { is_visible_in_grid: true } }).$get()
          this.listOuterColumn = this.deleteChildren(listOuterColumn)
          this.listOuterFilters = await Filters.params({ fields: 'id,name', related_object_id: val.id }).get()
          let res = await this.$http.get(`${this.$config.api}/registryservice/xref/`, {
            params: {
              registry_id: val.object_id,
              attribute_id: val.alias_field_id,
              distinct: true
            }
          })
          let res1 = await this.$http.get(`${this.$config.api}/objecteditor/entities/${val.alias_field_id}`)
          this.listOuterRow = this.filterRows(res1.data.data.entity_type_id, res.data)
        } catch (error) {
          console.log(error)
        }
        this.selectOuterObject = val.object_id
      }
    },
    isDialogСurrentTable: function (val) {
      if (val && this.currentTableAlias) {
        this.currentTable.field_id = this.deepSearch(this.listAlias, this.currentTableAlias)
      }
    },
    isDialogOuterTable: function (val) {
      if (val && this.outerTableAlias) {
        this.outerTable.field_id = this.deepSearch(this.listOuterColumn, this.outerTableAlias)
      }
    },
    selectFunction: function (val) {
      if (val !== '') this.pasteMathFunction()
    }
  },
  data () {
    return {
      alias: null,
      currentTableAlias: null,
      outerTableAlias: null,
      listAlias: [],
      listRows: [],
      listColumnsCurrent: [],
      listOuterTables: [],
      selectOuterObject: null,
      listOuterRow: [],
      listfunction: [],
      outerRow: [],
      listOuterColumn: [],
      listOuterFilters: [],
      selectFunction: null,
      develop: '',
      isDevelop: false,
      addAction: this.propslocal.listOpenAction,
      caretPosition: {},
      form: {
        name: '',
        alias_field_id: {},
        target_alias_value: null,
        target_row_formula_columns: [],
        logic_id: this.propslocal.businesslogic.id
      },
      currentTable: {
        alias_value: null,
        field_id: {},
        filters: []
      },
      outerTable: {
        table: {},
        alias_value: null,
        field_id: {},
        filters: []
      },
      IsVisibleSelectAlias: false,
      isVisibleTreeCurrent: false,
      isVisibleTreeCurrentDialog: false,
      IsVisibleSelectColumns: false,
      isClickCtxMenu: false,
      outerTableVisible: false,
      currentTableVisible: false,
      isOpenCurrentTable: false,
      isDialogOuterTable: false,
      copyChips: null,
      selectChips: null,
      // тип контексткного меню
      flag: true,
      isDialogСurrentTable: false,
      listCtxMenu: [
        { id: 1, value: this.$locale.logic_editor.row_formulas.edit_row },
        { id: 2, value: this.$locale.logic_editor.column_formula.copy_chip },
        { id: 3, value: this.$locale.logic_editor.column_formula.delete_chip }
      ]
    }
  },
  computed: {
    outerDisabledBtn () {
      if (!this.outerTable.alias_value || !this.outerTableAlias) {
        return true
      }
      return false
    },
    currentDisabledBtn () {
      if (!this.currentTable.alias_value) {
        return true
      }
      return false
    },
    disabledBtnSave () {
      if (this.form.name === '' || Object.keys(this.form.alias_field_id).length === 0 || this.form.target_alias_value === undefined || !this.form.target_row_formula_columns.length) {
        return true
      }
      return false
    }
  },
  methods: {
    changeAlias (data) {
      this.alias = data
    },
    formulaColums (data) {
      this.form.target_row_formula_columns = data
    },
    changeCurrentTableAlias (data) {
      this.currentTableAlias = data
    },
    currentTableFilters (data) {
      this.currentTable.filters = data
    },
    changeOuterTableAlias (data) {
      this.outerTableAlias = data
    },
    deleteChildren (arr, multi = false) {
      for (let el of arr) {
        if (el.leaf) delete el.children
        if (!el.leaf && multi) el.isDisabled = true
        if (el.children instanceof Array) this.deleteChildren(el.children)
      }
      return arr
    },
    selectAlias (data) {
      this.form.alias_field_id = data
    },
    selectCurrentAlias (data) {
      this.currentTable.field_id = data
    },
    selectOuterAlias (data) {
      this.outerTable.field_id = data
    },
    filterRows (entityType, data) {
      let arr = []
      if (entityType === 'xref_multi_field' || entityType === 'xref_field') {
        arr = data.map((el) => {
          if (JSON.parse(el.name).length) {
            return {
              name: JSON.parse(el.name)[0].name,
              id: JSON.parse(el.name)[0].id
            }
          }
        }).filter(item => item !== undefined)
      } else {
        arr = data.filter(el => el.name !== null).map((el, index) => ({ name: el.name.toString(), id: index }))
      }
      return arr
    },
    // поиск по дереву имя поля
    deepSearch (arr, argID) {
      let currentEl = null
      arr.forEach((item) => {
        if (currentEl) {
          return false
        }
        if (item.id === argID) {
          currentEl = item
        } else {
          if (item.children instanceof Array) {
            currentEl = this.deepSearch(item.children, argID)
          }
        }
      })
      return currentEl
    },
    onCopy: function (e) {
      this.$message({
        message: this.$locale.logic_editor.column_formula.copy_save,
        type: 'success'
      })
    },
    onError: function (e) {
      console.log('Failed to copy texts')
    },
    contexMenu (e) {
      // console.log({ clientX: e.clientX, clientY: e.clientY, 'width': this.$refs.rowFormula.getBoundingClientRect().width })
      if (e.target.className === 'accent-carrentTable') {
        this.currentTable.alias_value = e.target.getAttribute('alias_field_value')
        this.currentTable.filters = e.target.getAttribute('filters') !== 'null' ? e.target.getAttribute('filters').split(',').map(el => +el) : []
        this.currentTable.field_id = e.target.getAttribute('field_id') !== 'null' ? +e.target.getAttribute('field_id') : {}
        this.currentTableAlias = e.target.getAttribute('field_id') !== 'null' ? +e.target.getAttribute('field_id') : null
        this.selectChips = e.target
        this.copyChips = e.target.outerHTML
        this.flag = true
        this.isOpenCurrentTable = true
        this.drawCntxMenu(e)
      }
      if (e.target.className === 'accent-outerTable') {
        this.isClickCtxMenu = true
        this.outerTable.table.id = +e.target.getAttribute('related_object_id')
        this.outerTable.table.object_id = +e.target.getAttribute('object_id')
        this.outerTable.table.alias_field_id = +e.target.getAttribute('alias_field_id')
        this.$set(this.outerTable.table, 'name', this.listOuterTables.filter(el => el.id === this.outerTable.table.id)[0].name)
        this.outerTable.alias_value = e.target.getAttribute('alias_field_value')
        this.outerTable.filters = e.target.getAttribute('filters') !== 'null' ? e.target.getAttribute('filters').split(',').map(el => +el) : []
        this.outerTableAlias = e.target.getAttribute('field_id') !== 'null' ? +e.target.getAttribute('field_id') : null
        this.selectChips = e.target
        this.copyChips = e.target.outerHTML
        this.flag = true
        this.isOpenCurrentTable = false
        this.drawCntxMenu(e)
      }
      if (e.target.className === 'row_formulas__edit' && !this.flag) {
        this.drawCntxMenu(e)
      }
    },
    pasteCopyChips () {
      this.$refs.rowFormula.focus()
      this.pasteHtmlAtCaret(this.copyChips)
      this.flag = false
      this.$refs.ctxMenu.style.display = ''
      this.caretPosition = {}
      this.develop = this.$refs.rowFormula.innerHTML
    },
    actionCtxMenu (val) {
      this.$refs.ctxMenu.style.display = ''
      if (val === 1) {
        if (this.isOpenCurrentTable) {
          this.isDialogСurrentTable = true
        } else {
          this.isDialogOuterTable = true
          this.isClickCtxMenu = false
        }
      }
      if (val === 2) {
        // контекстное меню - остался один пункт
        this.flag = false
      }
      if (val === 3) {
        this.selectChips.remove()
        this.selectChips = null
        this.defaultValue()
      }
      this.develop = this.$refs.rowFormula.innerHTML
    },
    closeDialog () {
      this.isDialogСurrentTable = false
      this.isDialogOuterTable = false
      this.defaultValue()
    },
    changeSpan () {
      console.log(this.selectChips.attributes)
      if (this.selectChips.attributes['related_object_id'] === undefined) {
        this.selectChips.attributes['alias_field_value'].nodeValue = this.currentTable.alias_value.name || this.currentTable.alias_value
        if (this.currentTableAlias === undefined) {
          this.currentTable.field_id = {}
        }
        this.selectChips.attributes['field_id'].nodeValue = this.currentTable.field_id.id || 'null'
        this.selectChips.attributes['field_type'].nodeValue = this.currentTable.field_id.entity_type_id || 'null'
        this.selectChips.attributes['filters'].nodeValue = this.currentTable.filters.length > 0 ? this.currentTable.filters.join(',') : 'null'
        this.selectChips.attributes['title'].nodeValue = `${this.form.alias_field_id.name} ${this.currentTable.alias_value.name || this.currentTable.alias_value}`
        this.selectChips.textContent = `${this.currentTable.alias_value.name || this.currentTable.alias_value}`
        this.selectChips.insertAdjacentHTML('beforeend', '<i class="remove" contenteditable="false"></i>')
        this.isDialogСurrentTable = false
        this.develop = this.$refs.rowFormula.innerHTML
      } else {
        this.selectChips.attributes['related_object_id'].nodeValue = this.outerTable.table.id
        this.selectChips.attributes['object_id'].nodeValue = this.outerTable.table.object_id
        this.selectChips.attributes['alias_field_id'].nodeValue = this.outerTable.table.alias_field_id
        this.selectChips.attributes['alias_field_value'].nodeValue = this.outerTable.alias_value.name || this.outerTable.alias_value
        this.selectChips.attributes['field_id'].nodeValue = this.outerTable.field_id.id
        this.selectChips.attributes['field_type'].nodeValue = this.outerTable.field_id.entity_type_id
        this.selectChips.attributes['filters'].nodeValue = this.outerTable.filters.length > 0 ? this.outerTable.filters.join(',') : 'null'
        this.selectChips.attributes['title'].nodeValue = `${this.outerTable.table.name}. ${this.outerTable.alias_value.name || this.outerTable.alias_value}`
        this.selectChips.textContent = `${this.outerTable.alias_value.name || this.outerTable.alias_value}`
        this.selectChips.insertAdjacentHTML('beforeend', '<i class="remove" contenteditable="false"></i>')
        this.isDialogOuterTable = false
        this.develop = this.$refs.rowFormula.innerHTML
      }
      this.$message({
        message: this.$locale.main.message.success,
        type: 'success'
      })
      this.defaultValue()
    },
    drawCntxMenu (e) {
      let ctxMenu = this.$refs.ctxMenu
      ctxMenu.style.display = 'block'
      let xPosition
      let yPosition
      xPosition = e.target.offsetLeft + 10
      yPosition = e.target.offsetTop + 20
      ctxMenu.style.left = xPosition + 'px'
      ctxMenu.style.top = yPosition + 'px'
    },
    pasteCarrentTable () {
      if (Object.keys(this.form.alias_field_id).length === 0) {
        console.log('false')
        return false
      }
      this.$refs.rowFormula.focus()
      this.pasteHtmlAtCaret(`<span class="accent-carrentTable"
          object_id=${this.propslocal.businesslogic.registry_id}
          alias_field_id=${this.form.alias_field_id.id}
          alias_field_value='${this.currentTable.alias_value.name || this.currentTable.alias_value}'
          field_id='${this.currentTable.field_id.id || null}'
          field_type='${this.currentTable.field_id.entity_type_id || null}'
          filters='${this.currentTable.filters.length > 0 ? this.currentTable.filters : null}'
          title='${this.form.alias_field_id.name} ${this.currentTable.alias_value.name || this.currentTable.alias_value}'
          contenteditable="false">${this.currentTable.alias_value.name || this.currentTable.alias_value}
          <i class="remove" contenteditable="false"></i></span>`)
      this.defaultValue()
      this.caretPosition = {}
      this.develop = this.$refs.rowFormula.innerHTML
      this.currentTableVisible = false
    },
    cancelCurrentTable () {
      this.defaultValue()
      this.currentTableVisible = false
    },
    pasteOuterTable () {
      if (Object.keys(this.outerTable.table).length === 0) {
        console.log('false')
        return false
      }
      this.$refs.rowFormula.focus()
      this.pasteHtmlAtCaret(`<span class="accent-outerTable"
          related_object_id=${this.outerTable.table.id}
          object_id=${this.outerTable.table.object_id}
          alias_field_id=${this.outerTable.table.alias_field_id}
          alias_field_value='${this.outerTable.alias_value.name}'
          field_id='${this.outerTable.field_id.id}'
          field_type='${this.outerTable.field_id.entity_type_id}'
          filters='${this.outerTable.filters.length > 0 ? this.outerTable.filters : null}'
          title='${this.outerTable.table.name}. ${this.outerTable.alias_value.name || this.outerTable.alias_value}'
          contenteditable="false">${this.outerTable.alias_value.name || this.outerTable.alias_value}
          <i class="remove" contenteditable="false"></i></span>`)
      this.defaultValue()
      this.caretPosition = {}
      this.develop = this.$refs.rowFormula.innerHTML
      this.outerTableVisible = false
    },
    pasteMathFunction () {
      this.$refs.rowFormula.focus()
      this.pasteHtmlAtCaret(`<span class="accent-function"
      function=${this.selectFunction.id}
      contenteditable="false">${this.selectFunction.name}
      <i class="remove" contenteditable="false"></i></span>`)
      this.caretPosition = {}
      this.develop = this.$refs.rowFormula.innerHTML
    },
    cancelOuterTable () {
      this.defaultValue()
      this.outerTableVisible = false
    },
    cursorPosition (e) {
      this.develop = this.$refs.rowFormula.innerHTML
      this.$refs.ctxMenu.style.display = ''
      if (e.target.className === 'accent-function' || e.target.className === 'accent-outerTable' || e.target.className === 'accent-carrentTable') {
        return false
      }
      let sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        this.caretPosition.range = sel.getRangeAt(0)
      }
      if (e.target.className === 'remove') {
        e.target.parentElement.remove()
      }
      this.develop = this.$refs.rowFormula.innerHTML
    },
    pasteHtmlAtCaret (html) {
      if (window.getSelection) {
        const el = document.createElement('div')
        console.log(html + '&nbsp;')
        el.innerHTML = html + '&nbsp;'
        let frag = document.createDocumentFragment()
        let node
        let textNode = document.createTextNode('    ')
        while ((node = el.firstChild)) {
          frag.appendChild(textNode)
          frag.appendChild(node)
          frag.appendChild(textNode)
        }
        if (typeof this.caretPosition.range !== 'undefined') {
          console.log(this.caretPosition.range)
          this.caretPosition.range.deleteContents()
          this.caretPosition.range.insertNode(frag)
        } else {
          this.$refs.rowFormula.appendChild(frag)
        }
      }
    },
    defaultValue () {
      this.currentTable.alias_value = null
      this.currentTable.field_id = {}
      this.currentTable.filters = []
      this.currentTableAlias = null
      this.outerTableAlias = null
      this.outerTable.table = {}
      this.outerTable.alias_value = null
      this.outerTable.field_id = {}
      this.outerTable.filters = []
    },
    async saveFormula () {
      // сохранение из карточки
      if (Object.values(this.$attrs).length) {
        if (this.propslocal.getAnswer === '') {
          console.log('сохранить из реестра новая формула')
          let formulas = new Rows({}, this.form)
          formulas.value = this.$refs.rowFormula.innerHTML === '' ? null : this.$refs.rowFormula.innerHTML
          formulas.name = this.form.name
          formulas.object_id = this.propslocal.businesslogic.registry_id
          formulas.alias_field_id = this.form.alias_field_id.id
          formulas.logic_id = this.propslocal.businesslogic.id
          formulas.target_alias_value = this.form.target_alias_value.name
          let response = await formulas.save()
          this.$emit('pasteText', response)
        } else {
          console.log('редактирвоание из реестра')
          let formulas = new Rows({ id: this.propslocal.getAnswer.id })
          formulas.value = this.$refs.rowFormula.innerHTML === '' ? null : this.$refs.rowFormula.innerHTML
          formulas.name = this.form.name
          formulas.object_id = this.propslocal.businesslogic.registry_id
          formulas.alias_field_id = this.form.alias_field_id.id
          formulas.logic_id = this.propslocal.businesslogic.id
          formulas.target_alias_value = this.form.target_alias_value.name
          formulas.target_row_formula_columns = this.form.target_row_formula_columns
          let response = await formulas.save()
          this.$emit('pasteText', response)
        }
      } else {
        // новое subAction
        if (this.propslocal.isNewAction) {
          let formulas = new Rows({}, this.form)
          formulas.value = this.$refs.rowFormula.innerHTML === '' ? null : this.$refs.rowFormula.innerHTML
          formulas.name = this.form.name
          formulas.object_id = this.propslocal.businesslogic.registry_id
          formulas.alias_field_id = this.form.alias_field_id.id
          formulas.logic_id = this.propslocal.businesslogic.id
          formulas.target_alias_value = this.form.target_alias_value.name
          formulas.target_row_formula_columns = this.form.target_row_formula_columns
          let response = await formulas.save()
          this.addAction.items.push(response)
        } else {
          let id
          this.propslocal.selectSubAction.id
            ? (id = this.propslocal.selectSubAction.id)
            : (id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id)
          let formulas = new Rows({ id: id })
          formulas.value = this.$refs.rowFormula.innerHTML === '' ? null : this.$refs.rowFormula.innerHTML
          formulas.name = this.form.name
          formulas.object_id = this.propslocal.businesslogic.registry_id
          formulas.alias_field_id = this.form.alias_field_id.id
          formulas.logic_id = this.propslocal.businesslogic.id
          formulas.target_alias_value = this.form.target_alias_value.name
          formulas.target_row_formula_columns = this.form.target_row_formula_columns
          let response = await formulas.save()
          this.addAction.items
            ? (this.addAction.items.filter(el => el.id === id)[0].name = response.name)
            : (this.addAction.filter(el => el.id === id)[0].name = response.name)
        }
        this.propslocal.isNewAction = false
      }
    }
  },
  async mounted () {
    // поля текущей логики
    const id = this.propslocal.businesslogic.registry_id
    if (id === undefined) return false
    console.log('registry_id', id)
    // let listAlias = await Registry.params({ object_id: id, is_field: 'true' }).get()
    let listAliasTree = await Registry.params({ parent_id: id, show_children: true, properties: { is_visible_in_grid: true } }).get()
    this.listAlias = this.deleteChildren(listAliasTree.data)
    // список функций
    let mathFunction = await Formulas.custom('formulas/functions').get()
    let obj = this.$locale.logic_editor.column_formula.translate_function
    this.listfunction = mathFunction.filter(el => el.is_row_function).map(el => ({ id: el.id, name: obj[el.name] || el.name }))
    // строки текущего реестра
    this.$http
      .get(`${this.$config.api}/registryservice/registry/${this.propslocal.businesslogic.registry_id}/data`)
      .then(res => { this.availableRow = res.data.data })
    // список связанных объектов
    const logicId = this.propslocal.businesslogic.id
    let listRelates = await Relates.params({ fields: 'id,name,object_id,alias_field_id', logic_id: logicId }).get()
    this.listOuterTables = listRelates
    // формула из реестра
    if (Object.values(this.$attrs).length) {
      if (!this.isNew) {
        this.form = this.propslocal.getAnswer
        this.form.alias_field_id = this.deepSearch(this.listAlias, this.propslocal.getAnswer.alias_field_id)
        this.$refs.rowFormula.innerHTML = this.form.value || null
      }
      if (this.$attrs.aliasFieldId) this.form.alias_field_id = this.deepSearch(this.listAlias, this.$attrs.aliasFieldId)
      if (this.$attrs.targetAliasValue) {
        this.form.target_alias_value = this.$attrs.targetAliasValue
        this.form.name = this.$attrs.targetAliasValue
      }
    }
    // редактирование
    if (!this.propslocal.isNewAction) {
      this.form = this.propslocal.getAnswer
      this.alias = this.propslocal.getAnswer.alias_field_id
      if (this.alias) {
        let node = this.$refs.entity_tree.$refs.treeselect.getNode(this.alias)
        this.form.alias_field_id = node.raw
        while (node.parentNode) {
          this.$refs.entity_tree.$refs.treeselect.toggleExpanded(node.parentNode)
          node = node.parentNode
        }
      }
      this.$refs.rowFormula.innerHTML = this.form.value || null
    }
  }
}
</script>

<style lang="scss">
@import "./style/row_formulas.scss";
</style>
