import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import UserQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/UserQueryRepositoryInterface'
import store from '@/store'
import { GetUsers, CountUsers, GetUserByGuid, GetPassword, GetPasswordRules, PasswordCheck, GetApiKey, GetAvatar } from '@/services/AccessEditor/infrastructure/store/modules/User/action-types'

export default class UserQueryRepository extends AbstractQueryRepository implements UserQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetUsers(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountUsers(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetUserByGuid(guid));
    }

    getPassword(): Promise<any>
    {
        return store.dispatch(new GetPassword());
    }

    getApiKey(userId: number, roleId: number, systemIps: Array<string>): Promise<any>
    {
        return store.dispatch(new GetApiKey(userId, roleId, systemIps));
    }

    getAvatar(avatarId: number): Promise<any>
    {
        return store.dispatch(new GetAvatar(avatarId));
    }

    getPasswordRules(): Promise<any>
    {
        return store.dispatch(new GetPasswordRules());
    }

    passwordCheck(password: string): Promise<any>
    {
        return store.dispatch(new PasswordCheck(password));
    }
}