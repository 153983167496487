import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { RelatedObjectDTO } from '@/services/LogicEditor/domain/model/RelatedObject'

export namespace RelatedObjectAPI {
  export class GetRelatedObjectByGuid implements APIRequest<RelatedObjectDTO> {
    response: RelatedObjectDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/related_objects/${guid}`
    }
  }

  export class CreateRelatedObject implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/related_objects';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: RelatedObjectDTO) {}
  }

  export class UpdateRelatedObject implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: RelatedObjectDTO) {
      this.path = `/v2/logiceditor/related_objects/${params.guid}`
    }
  }

  export class DeleteRelatedObject implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/related_objects/${guid}`
    }
  }
}
