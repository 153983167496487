<template>
  <div class="extraLine_wrapper">
    <el-form label-position="top" ref="form" size="mini">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item>
            <el-checkbox :disabled="isEdit" style="margin-right: 20px" v-model="state.is_parameter">{{$locale.logic_editor.state.parameter}}</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="17">
          <template v-if="!state.is_parameter">
            <el-form-item>
              <selectTree
                class="extraLine_select"
                :disabled="isEdit"
                :clearable="false"
                :standartTree="true"
                ref="entity_tree"
                :dataTable="listField"
                :selectAlias="selectAlias"
                :value="field"
                size="mini"
                @input="field = $event"/>
            </el-form-item>
            <el-form-item>
              <el-select
                :disabled="isEdit"
                class="extraLine_select"
                v-model="state.state_field_type_id"
                :placeholder="$locale.logic_editor.state.type_field">
                <el-option
                  v-for="item in listFiledTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template v-if="isVisibleComponent">
                <components
                  class="extraLine_select"
                  :ref="state.state_field_type_id"
                  :is="toCamelCase(state.state_field_type_id)"
                  :chooseFiled="chooseFiled"
                  :state="state"
                  :isEdit="isEdit"
                  :listField="listField">
                </components>
              </template>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item>
              <el-input class="extraLine_select" :disabled="isEdit" v-model="nameFiled" :placeholder="$locale.logic_editor.state.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input class="extraLine_select" :disabled="isEdit" v-model="value" :placeholder="$locale.logic_editor.state.value"></el-input>
            </el-form-item>
          </template>
        </el-col>
        <el-col :span="3">
          <div style="text-align: right;">
            <slot></slot>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import Constant from '../elementStates/constant'
import Condition from '../elementStates/condition'
import Parameter from '../elementStates/parameter'
import usersTable from '../elementStates/userTable'
import currentTableField from '../elementStates/currentTableField'
import externalTableField from '../elementStates/externalTableField'
import sql from '../elementStates/sql'
import selectTree from '@/components/LogicEditor/components/treeSelect'
import Template from '../../../EtlEditor/Properties/Template'
export default {
  components: {
    Template,
    Constant,
    Condition,
    Parameter,
    usersTable,
    currentTableField,
    externalTableField,
    sql,
    selectTree
  },
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    },
    localAnswer: {
      type: Object
    },
    listField: {
      type: Array
    },
    listFiledTypes: {
      type: Array
    },
    isEdit: {
      type: Boolean
    }
  },
  watch: {
    // выбранное поле
    chooseFiled: function (val, oldval) {
      if (oldval) {
        this.state = this.defoultState
      }
      if (val.id) {
        this.nameFiled = ''
        this.value = ''
        this.state.field_id = val.id
      }
    }
  },
  computed: {
    // видимость компонента
    isVisibleComponent () {
      return !(this.state.state_field_type_id === 'current_datetime' || this.state.state_field_type_id === 'current_user')
    }
  },
  data () {
    return {
      formState: {},
      field: null,
      chooseFiled: null,
      nameFiled: '',
      value: '',
      state: {
        is_parameter: false,
        state_field_type_id: null,
        field_id: null,
        condition_field_type_id: null,
        users_field_id: null,
        parent_field_id: null,
        properties: null
      },
      defoultState: {
        is_parameter: false,
        state_field_type_id: null,
        field_id: null,
        condition_field_type_id: null,
        users_field_id: null,
        parent_field_id: null,
        properties: null
      }
    }
  },
  methods: {
    selectAlias (data) {
      this.chooseFiled = data
    },
    toCamelCase (s) {
      if (s) {
        return s.replace(/([-_][a-z])/gi, $1 => {
          return $1
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
        })
      }
    },
    getValue () {
      let date = {
        is_parameter: this.state.is_parameter,
        state_field_type_id: this.state.state_field_type_id,
        field_id: this.state.field_id,
        condition_field_type_id: null,
        users_field_id: null,
        parent_field_id: null,
        current_table_field_id: null,
        properties: null
      }
      if (!this.state.is_parameter) {
        if (this.state.state_field_type_id === 'constant') {
          date.properties = {
            value: this.$refs[this.state.state_field_type_id].getValue()
          }
        }
        if (this.state.state_field_type_id === 'parent_field') {
          date.parent_field_id = 23
        }
        if (this.state.state_field_type_id === 'parameter') {
          date.properties = {
            name: this.$refs[this.state.state_field_type_id].getValue()
          }
        }
        if (this.state.state_field_type_id === 'condition') {
          date.condition_field_type_id = this.$refs[this.state.state_field_type_id].getValue()
        }
        if (this.state.state_field_type_id === 'users_table') {
          date.users_field_id = this.$refs[this.state.state_field_type_id].getValue()
        }
        if (this.state.state_field_type_id === 'current_table_field') {
          date.current_table_field_id = this.$refs[this.state.state_field_type_id].getValue()
        }
        if (this.state.state_field_type_id === 'external_table_field') {
          date.properties = this.$refs[this.state.state_field_type_id].getValue()
        }
        if (this.state.state_field_type_id === 'sql') {
          date.properties = {
            sql: this.$refs[this.state.state_field_type_id].getValue()
          }
        }
      } else {
        date.state_field_type_id = null
        date.field_id = null
        date.condition_field_type_id = null
        date.users_field_id = null
        date.parent_field_id = null
        date.properties = null
        date.current_table_field_id = null
        date.properties = {
          name: this.nameFiled,
          value: this.value
        }
      }
      return date
    }
  },
  async mounted () {
    // редактирование
    if (this.localAnswer) {
      this.field = this.localAnswer.field_id
      // получить entity_type_id
      if (this.field) {
        this.$http.get(`${this.$config.api}/objecteditor/entities/${this.field}`)
          .then(response => {
            this.chooseFiled = response.data.data
          })
          .catch(error => console.log(error))
      }
      this.state = this.localAnswer

      this.nameFiled = this.localAnswer.properties && this.localAnswer.properties.name
        ? this.localAnswer.properties.name
        : null
      this.value = this.localAnswer.properties && this.localAnswer.properties.value
        ? this.localAnswer.properties.value
        : null
    }
  }
}
</script>

<style type="text/css">
.extraLine_wrapper {
  align-items: center;
  margin-bottom: 25px;
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-left: 2px solid #d2d45e;
}
/*
.extraLine_block {
  width: 100%;
  margin-right: 20px;
}
.extraLine_select {
  width: 80%;
  margin: 3px;
}
.extraLine_select:last-child {
  margin-right: 0px;
}*/
</style>
