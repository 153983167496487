import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class ApprovalUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private description: Nullable<string>
  private approvalStates: Array<number>
  private approvalStages: Array<object>
  private alias: Nullable<string>
  private isEnabled: boolean

  constructor (
    guid: string,
    name: string,
    description: Nullable<string>,
    approvalStates: Array<number>,
    approvalStages: Array<object>,
    alias: Nullable<string>,
    isEnabled: boolean
  ) {
    this.guid = guid
    this.name = name
    this.description = description
    this.approvalStates = approvalStates
    this.approvalStages = approvalStages
    this.alias = alias
    this.isEnabled = isEnabled
  }

  getClassName (): string {
    return 'ApprovalUpdateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getApprovalStates (): Array<number> {
    return this.approvalStates
  }

  getApprovalStages (): Array<object> {
    return this.approvalStages
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getIsEnabled (): boolean {
    return this.isEnabled
  }
}
