<template style="height: calc(100vh - 64px); padding: 0px;">
<div style="height: 100%;">
  <cards
    v-show="openedCards.length > 0"
    :opened-cards="openedCards"
    :show-card="showCard"
    @cancelChanges="cancelChanges"
    @back="openedCards = openedCards.slice(0, $event + 1)"
    @back-registry="showCard=false; openedCards = []"
    style="height: calc(100%); margin-top: 25px"
  ></cards>
  <div v-show="!showCard" class="centerNotify">
    <div class="centerNotify_title">{{$locale.main.notification.center_notify}}</div>
    <div class="centerNotify_panel">
      <el-tooltip class="item"  effect="dark" :content="$locale.main.notification.update" placement="bottom">
        <i class="el-icon-refresh-right centerNotify_update" @click="updateTable"></i>
      </el-tooltip>
      <el-radio-group v-model="radio" size="medium" @change='loadAvatarUser(true)' border style="padding-top: 10px;">
        <el-radio @click="clearFilter" label="all">{{$locale.main.notification.all}}</el-radio>
        <el-radio label="new">{{$locale.main.notification.new}}</el-radio>
      </el-radio-group>
      <el-popover
        placement="bottom"
        title="Фильтр уведомлений"
        class="centerNotify_icon"
        width="500"
        v-model="visibleFilter"
        trigger="click">
        <el-form label-width="120px">
          <el-form-item label="Отправитель">
            <el-select v-model="filter.name" style="width: 100%;" filterable clearable size="small">
              <el-option v-for="item in filterUsers" :key="item.id"  :label="item.fullName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Дата">
            <el-date-picker
              v-model="filter.date"
              style="width: 100%;"
              size="small"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="Начало"
              end-placeholder="Конец"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="Тип">
            <el-input type="text" clearable v-model="filter.type" style="width: 100%;"></el-input>
          </el-form-item> -->
        </el-form>
        <div style="float: right">
          <el-button type="primary" @click="saveFilter" :disabled="disabledBtn">{{$locale.main.notification.apply}}</el-button>
          <el-button @click="clearFilter">{{$locale.main.notification.clear_filter}}</el-button>
        </div>
        <template slot="reference">
          <el-badge :hidden='!badge' is-dot class="item">
              <i class="centerNotify_icon icon11" style="cursor: pointer"></i>
          </el-badge>
        </template>
      </el-popover>
      <el-input
        v-model="search"
        @blur="loadAvatarUser(true)"
        @clear="loadAvatarUser(true)"
        @keyup.enter.native="loadAvatarUser(true)"
        clearable
        size="medium"
        class="centerNotify_search"
        suffix-icon="el-icon-search"
        :placeholder="$locale.main.notification.notify_search">
      </el-input>
    </div>
    <el-divider></el-divider>

    <el-table
      :data="tableData"
      v-loading="loading"
      ref="notifyTable"
      class="custom_scrollbar"
      style="width: 100%; font-size: 15px;"
      :row-class-name="tableRowClassName"
      @row-click="readMessage"
      height="calc(100vh - 190px)"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <p class="centerNotify_expand" v-html="props.row.content"></p>
        </template>
      </el-table-column>:
      <el-table-column prop="avatar" align="left" width="250px">
        <template slot-scope="scope">
          <div class="centerNotify_center">
            <el-avatar
              v-if="scope.row.avatar"
              fit="cover"
              size="medium"
              :src="$config.api + '/files/' + scope.row.avatar.guid + '.'+ scope.row.avatar.extension"
            ></el-avatar>
            <i v-else-if="scope.row.notification[0].icon" :class="scope.row.notification[0].icon" style="font-size: 36px"></i>
            <el-avatar v-else fit="cover" size="medium" src></el-avatar>
            <div class="centerNotify_avatar">{{scope.row.fullName}}</div>
          </div>
        </template>
      </el-table-column>:
      <el-table-column prop="title" min-width="100%"></el-table-column>:
      <el-table-column prop="create_date" align="right" width="200px">
        <template slot-scope="scope">{{$moment(scope.row.create_date).format('H:mm, D MMM YYYY')}}</template>
      </el-table-column>
      <el-table-column align="right" width="50px">
        <template slot-scope="scope" v-if="scope.row.notification[0].interaction_type">
          <el-tooltip class="item" effect="dark" content="Открыть карточку" placement="bottom-end">
            <el-button @click.stop="openCard(scope.row)" icon="el-icon-folder-opened" circle></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="changePagination"
      :page-size="pageLimit"
      layout="total, prev, pager, next"
      :total="total"
    ></el-pagination>
  </div>
</div>

</template>

<script>
// Центр уведомлений
import Messages from '../Models/Messages'
import Cards from '@/components/Registry/Cards.vue'
import { getCardId } from '@/helpers'
import mixinsForNatification from '@/components/System/Notification/mixins/index.js'
export default {
  name: 'NotificationCenter',
  mixins: [mixinsForNatification],
  components: {
    Cards
  },
  data () {
    return {
      tableData: [],
      loading: false,
      pageLimit: 50,
      search: '',
      total: null,
      currentPage: 0,
      openedCards: [],
      showCard: false,
      users: [],
      filterUsers: [],
      radio: 'all',
      expanded: '',
      filter: {
        name: '',
        date: null,
        type: null
      },
      badge: false,
      visibleFilter: false,
      pickerOptions: {
        shortcuts: [{
          text: 'Прошлая неделя',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Прошлый месяц',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Последние 3 месяца',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    disabledBtn () {
      if (!this.filter.name && this.filter.date === null) {
        return true
      }
      return false
    },
    loadParams () {
      let params = {
        offset: this.currentPage,
        limit: this.pageLimit,
        order: 'create_date:desc',
        where: { and: [] }
      }
      if (this.radio === 'new') {
        params.where.and.push({
          is_null: 'read_date'
        })
      }
      if (this.filter.name) {
        params.where.and.push({
          eq: { 'm.author_id': this.filter.name }
        })
      }
      if (this.filter.date) {
        params.where.and.push({
          and: [{
            gte: { 'm.create_date': this.filter.date[0] },
            lte: { 'm.create_date': this.filter.date[1] }
          }]
        })
      }
      if (this.search) {
        params.where.and.push({
          or: [
            { like: { 'u.name': `%${this.search}%` } },
            { like: { 'u.midname': `%${this.search}%` } },
            { like: { 'u.surname': `%${this.search}%` } },
            { like: { 'm.title': `%${this.search}%` } },
            { like: { 'm.content': `%${this.search}%` } }
          ]
        })
      }
      return params
    }
  },
  inject: {
    addMainTab: {
      default: () => {}
    }
  },
  provide () {
    return {
      openRegistryCard: this.openRegistryCard
    }
  },
  methods: {
    openRegistryCard ({ registryId, cardId, cardName, recordId = null, initialData = {}, registry = null }) {
      if (!cardId || !registryId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true
      this.openedCards.push({
        id: cardId,
        registryId: registryId,
        recordId: recordId,
        name: cardName,
        initialData: initialData,
        registry: registry,
        readonly: registry ? (registry.readonly || false) : false
      })
    },
    async openCard (data) {
      let dataRegistryByRecord = await this.getDataRegistryByRecord({ registryId: data.notification[0].object_id, recordId: data.record_id })
      if (dataRegistryByRecord.is_deleted) {
        this.$message.error(`Запись удалена из системы`)
        return
      }
      // Открыть карточку внешнего реестра
      if (data.notification[0].interaction_type === 'open_external_card') {
        this.openCardOuter(data, dataRegistryByRecord)
        return
      }
      this.loading = true
      let card = {}
      try {
        card = await getCardId(this, { registryId: data.notification[0].object_id, recordId: data.record_id })
      } catch (error) {
        console.log({ error })
        this.loading = false
        return
      }
      this.openedCards.push({
        id: card.id,
        registryId: data.notification[0].object_id,
        recordId: data.record_id,
        name: card.name,
        initialData: {}
        // registry: registry,
        // readonly: registry ? (registry.readonly || false) : false
      })
      this.showCard = true
      this.readMessage(data)
      this.loading = false
    },
    // Открыть карточку внешнего реестра
    async openCardOuter (data, dataRegistryByRecord) {
      let { openCard, readOnly, cardId, recordXref } = await this.validationData(data, dataRegistryByRecord)
      if (!openCard) return
      // получить id карточки внешнего реестра
      const card = (cardId) ? { id: cardId } : await getCardId(this, { registryId: openCard.external_object_id, recordId: recordXref })
      this.showCard = true
      this.openedCards.push({
        id: card.id,
        registryId: openCard.external_object_id,
        recordId: recordXref,
        name: '',
        initialData: {},
        readonly: readOnly
      })
      this.showCard = true
      this.readMessage(data)
      this.loading = false
    },
    cancelChanges () {
      if (this.openedCards.length === 1) {
        this.showCard = false
        this.openedCards = []
      } else {
        this.openedCards = this.openedCards.slice(0, this.openedCards.length - 1)
      }
    },
    async updateTable () {
      this.radio = 'all'
      this.search = ''
      this.filter.name = this.filter.type = ''
      this.filter.date = null
      this.badge = false
      this.loadAvatarUser()
    },
    saveFilter () {
      this.badge = true
      this.visibleFilter = false
      this.loadAvatarUser(true)
    },
    clearFilter () {
      this.filter.name = this.filter.type = ''
      this.filter.date = null
      this.badge = false
      this.visibleFilter = false
      this.loadAvatarUser(true)
    },
    readMessage (row) {
      this.$refs.notifyTable.toggleRowExpansion(row)
      if (row.read_date !== null || this.expanded === row.id) return false
      this.$http({
        method: 'put',
        url: `${this.$config.api}/${this.$config.notification_publisher}/messages/read`,
        params: { message_id: row.id },
        hideNotification: true
      }).then(() => {
        this.$store.commit('Notify/readMsg', row.id)
        this.expanded = row.id
        this.tableData.find(el => { if (el.id === row.id) el.read_date = 1 })
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.read_date) {
        return 'read-row'
      } else {
        return 'noRead-row'
      }
    },
    updateLayout () {
      this.expanded = ''
      this.$nextTick(() => {
        this.$refs.notifyTable && this.$refs.notifyTable.doLayout()
        console.log('update layout notifyTable')
      })
    },
    async changePagination (val) {
      this.loading = true
      val--
      this.currentPage = val * this.pageLimit
      await this.loadAvatarUser()
      this.loading = false
    },
    fullName (user) {
      if (!user.midname && !user.surname) {
        return user.name
      }
      let name = user.name.substring(0, 1)
      let midname = user.midname !== null ? user.midname.substring(0, 1) : ''
      let surname = user.surname !== null ? user.surname : ''
      return `${surname} ${name}. ${midname}.`
    },
    async cashAvatar () {
      this.filterUsers.forEach(item => {
        item.fullName = this.fullName({ name: item.name, surname: item.surname, midname: item.midname })
        if (item.avatar_id) {
          this.$http.get(`${this.$config.api}/registryservice/files/${item.avatar_id}`)
            .then(res => { item.avatar = res.data })
            .then(() => {
              for (let i = 0; i < this.tableData.length; i++) {
                for (let j = 0; j < this.filterUsers.length; j++) {
                  if (this.tableData[i].author_id === this.filterUsers[j].id) {
                    this.$set(this.tableData[i], 'fullName', this.filterUsers[j].fullName)
                    this.$set(this.tableData[i], 'avatar', this.filterUsers[j].avatar)
                  }
                }
              }
            })
        }
      })
      // let count = await new Messages().params({ '*': { func: 'count' } }).get()
      let count = await this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, { '*': { func: 'count' } }, { hideNotification: true })
      this.total = count.data[0].count
      this.updateLayout()
      this.loading = false
    },
    async loadAvatarUser (filter = false) {
      this.loading = true
      try {
        if (filter) {
          this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, this.loadParams, { hideNotification: true })
            .then(response => {
              this.cashAvatar()
              this.tableData = response.data
            })
        } else {
          let notify = await new Messages().params(this.loadParams).$get()
          this.tableData = notify
          this.cashAvatar()
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    }
  },
  async mounted () {
    this.loading = true
    this.filterUsers = await new Messages().custom('messages/authors').get()
    await this.loadAvatarUser()
    if (this.$attrs.registryId) {
      this.openedCards.push({
        id: this.$attrs.cardId,
        registryId: this.$attrs.registryId,
        recordId: this.$attrs.recordId,
        name: '',
        initialData: {}
      })
      this.showCard = true
    }
    this.loading = false
  }
}
</script>

<style>
.centerNotify_title {
  color: #2c2d35;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 16px 24px;
}
.centerNotify_center {
  display: flex;
  align-items: center;
}
.centerNotify_expand {
  margin-left: 55px;
  font-size: 15px;
}
.centerNotify_avatar {
  margin-left: 10px;
  font-size: 15px;
}
.centerNotify .el-table thead {
  display: none;
}
.centerNotify_icon {
  font-size: 15px;
  margin-left: auto;
}
.centerNotify_panel {
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin-bottom: 15px;
}
.centerNotify_update {
  border: none;
  font-size: 22px;
  cursor: pointer;
  margin-right: 30px;
  padding-top: 10px;
}
.centerNotify_search {
  width: 30%;
  margin-right: 24px;
  margin-left: 20px;
}
.centerNotify .el-table .read-row {
  background: #fafafa;
}
.centerNotify .el-table .noRead-row {
  /* background: #f0f9eb; */
  font-weight: bold;
  cursor: pointer;
}
.centerNotify .el-table td {
    padding: 7px 0;
}
.centerNotify .el-radio__input {
    display: none;
}
.centerNotify .el-radio {
  margin-right: 15px;
}
.centerNotify .el-radio__label {
  font-size: 15px;
  color: rgb(125, 125, 125);
}

</style>
