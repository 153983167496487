import PluginFactory from '@/core/infrastructure/service/PluginFactory'
import Registry from '@/components/Registry/Models/Registry'
import CommandExecutor from '@/core/infrastructure/service/CommandExecutor'
import EtlImport from '@/core/infrastructure/components/EtlImportPanel/EtlImport.vue'

export default class ActionExecutor {
  static context
  static settings

  static readonly
  static pluginName
  static action
  static event

  static model?: object = {}

  static execute (context, settings: {
    readonly: boolean, pluginName?: string, action: object, event?: object
  } = {
    readonly: false, pluginName: null, action: {}, event: {}
  }, model?: object) {
    const actionTemp: any = settings.action
    const typeTemp = actionTemp?.type
    if (!typeTemp) {
      return
    }

    this.context = context
    this.settings = settings

    this.readonly = settings.readonly
    this.pluginName = settings.pluginName
    this.action = settings.action
    this.event = settings.event

    this.model = model

    return this.onClick()
  }

  static getModel () {
    if (Object.keys(this.model || {}).length > 0) {
      return this.model
    }

    return this.context.getModel()
  }

  static actions = {
    execute_plugin: ActionExecutor.executePlugin,
    open_window: ActionExecutor.openWindow,
    open_dashboard: ActionExecutor.openDashboard,
    open_report: ActionExecutor.openReport,
    open_document: ActionExecutor.openDocument,
    open_xml: ActionExecutor.openXml,
    execute_command: ActionExecutor.executeCommand,
    open_card: ActionExecutor.openCard,
    save_card: ActionExecutor.saveCard,
    close_card: ActionExecutor.closeCard,
    close_dashboard: ActionExecutor.closeDashboard,
    open_history: ActionExecutor.openHistory,
    open_url: ActionExecutor.openUrl,
    refresh_card: ActionExecutor.refreshCard,
    etl_export: ActionExecutor.etlExport,
    refresh_replication: ActionExecutor.refreshReplication,
    etl_import: ActionExecutor.etlImport
  }

  static async onClick () {
    if (this.readonly) return

    const actionType = this.action.type
    const actionFunction = this.actions[actionType]

    if (typeof actionFunction !== 'function') {
      throw new Error(`Функция для действия ${actionType} не найдена`)
    }

    return actionFunction.call(this)
  }

  /* Функции действий */

  static async executePlugin () {
    let pluginName = this.pluginName ?? this.action.plugin
    if (!pluginName) {
      console.log(`Plugin doesn't set`)
      return false
    }
    const plugin = await PluginFactory.build(this.context.$config.project, pluginName, this.context)
    plugin.execute()

    if (('is_card_close' in this.action.command) || this.action.command.is_card_close) {
      this.closeCard2()
    }
  }

  static openWindow () {
    this.openModalWindow()
  }

  static openDashboard () {
    if (!this.action.dashboard.id) {
      return
    }

    let initialData = {}
    let defaults = this.getDefaultsForDashboard()
    defaults.forEach((item) => {
      initialData[item.key] = item.value
    })

    let model = Object.assign(JSON.parse(JSON.stringify(this.getModel())), initialData)

    if (this.action.dashboard.isFullscreen) {
      this.context.openDashboardCard(this.action.dashboard.id, this.action.dashboard.window_title, null, model)
    } else {
      if (this.action.dashboard.containerAlias) {
        // Открыть в контейнере
        this.openDashboardInContainer({
          dashboardId: this.action.dashboard.id,
          title: this.action.dashboard.window_title,
          showBreadcrumbs: this.action.dashboard.breadcrumbByButton,
          initialData: initialData
        })
        return
      }
      if (this.action.dashboard.frameGuid) {
        let frame = (this.context.getDashboardComponents()[`component_${this.action.dashboard.frameGuid}`] || [])[0]
        if (!frame) {
          console.warn('frame not found', this.action.dashboard)
          return false
        }
        frame.openDashboard({
          dashboardId: this.action.dashboard.id,
          title: this.action.dashboard.window_title,
          breadcrumb: this.action.dashboard.breadcrumbByButton,
          initialData: initialData
        })
        return
      }
      const h = this.context.$createElement
      let customClass = 'custom_scrollbar '
      if (this.action.dashboard.window_width) {
        customClass += `dashboard_window_width_${this.action.dashboard.window_width}`
      }
      this.context.$msgbox({
        title: this.action.dashboard.window_title,
        customClass: customClass,
        message: h('dashboard', {
          style: {
            height: this.action?.dashboard?.windowHeight || ''
          },
          props: {
            id: this.action.dashboard.id,
            parentContext: this.context,
            model: model,
            msgbox: 'msgbox'
          },
          key: this.context.generateGuid()
        }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    }
  }

  static openDashboardInContainer ({ dashboardId, title, showBreadcrumbs, initialData = {} }) {
    // Открыть в контейнере
    const store = this.context.getContainersStore()
    if (store) {
      // Получить контейнер по алиасу
      const container = store.getByAlias(this.action.dashboard.containerAlias)
      if (container) {
        this.context.$set(container, 'contentType', 'registry')
        this.context.$set(container, 'showBreadcrumbs', showBreadcrumbs)

        console.log('openRegistryCardInContainer', 1, this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`])
        // @TODO registry_... появляется на ~5 тик - нужно разобраться. Либо поменять в v-if на v-show в новом редакторе интерфейсов
        this.context.$nextTick(() => this.context.$nextTick(() => this.context.$nextTick(() => this.context.$nextTick(() => {
          console.log('openRegistryCardInContainer', 5, this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`])

          const containerRegistry = this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`]
          containerRegistry.openDashboardCard(
            dashboardId,
            title,
            null,
            initialData
          )
        }))))
      }
    }
  }

  static openReport () {
    if (!this.action.reports.id) {
      return
    }

    this.context.addMainTab({
      name: this.action.reports.name,
      componentType: 'StimulsoftViewer',
      payload: {
        filename: `${this.action.reports.guid}.mrt`,
        variables: this.getDataFilters()
      }
    })
  }

  static openDocument () {
    if (!this.action.reports.id) {
      return
    }

    this.context.$http
      .request({
        method: 'post',
        url: `${this.context.$config.api}/reporteditor/reports/${this.action.reports.id}/document/${this.action.reports.formatType}`,
        data: {
          registry_id: this.action.reports.registryId,
          field_id: this.action.reports.assocFieldId,
          record_id: this.getModel()['id'],
          record_guid: this.getModel()['guid'],
          is_open_saved_version: this.action.reports.openSavedVersion,
          filters: this.getDataFilters()
        },
        responseType: 'json'
      })
      .then((response) => {
        if (response.status !== 200) {
          return
        }

        if (this.action.reports.viewType === 'download') {
          if (this.action.reports.formatType === 'xml') {
            const url = window.URL.createObjectURL(new Blob([response.data.content], { type: 'application/xml' }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.action.reports.name}.${this.action.reports.formatType}`)
            document.body.appendChild(link)
            link.click()
            link.remove()
          } else {
            this.context.$http
              .request({
                method: 'post',
                url: `${this.context.$config.api}/reporteditor/reports/document/download`,
                data: response.data,
                responseType: 'blob'
              })
              .then(res => {
                let formats = {
                  docx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  pdf: 'application/pdf'
                }
                const url = window.URL.createObjectURL(new Blob([res.data], { type: formats[this.action.reports.formatType] }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${this.action.reports.name}.${this.action.reports.formatType}`)
                document.body.appendChild(link)
                link.click()
                link.remove()
              })
          }
        } else {
          if (this.action.reports.formatType !== 'xml') {
            this.context.addMainTab({
              name: this.action.reports.name,
              componentType: 'DocumentViewer',
              payload: {
                guid: this.context.generateGuid(),
                filename: `${response.data.name}.${response.data.extension}`,
                isRegistryFile: response.data.is_registry_file,
                reportId: this.context.action.reports.id
              }
            })
          } else {
            // console.log('XmlViewer')
            this.context.addMainTab({
              name: this.action.reports.name,
              componentType: 'XmlViewer',
              payload: {
                guid: this.context.generateGuid(),
                content: response.data.content
              }
            })
          }
        }

        console.log('Build and opening document successful is completed!')
      })
  }

  static openXml () {
    return this.openDocument()
  }

  static async executeCommand () {
    if (!this.action.command.id) {
      return
    }

    try {
      await CommandExecutor.execute(this.context, this.action.command.id)
      this.context.$message({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: this.action.command.success_text,
        type: 'success'
      })
    } catch {
      this.context.$message({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: this.action.command.failure_text,
        type: 'error'
      })
    }
    if (this.action.command.is_save_card) {
      const card = this.context.getCard()
      await card.saveRecord()
    }

    this.closeCard2()
  }

  static async openCard () {
    /* Подготовка данных карточки */
    const openType = this.action.card.type
    const registryId = this.action.card.registryId ?? this.getModel()[this.action.card.queryRegistry]
    const initialData = {}
    const readOnly = openType === 'read'
    const action = this.action

    const defaults = this.getDefaultsForCard()
    defaults.forEach((item) => {
      initialData[item.key] = item.value
    })

    // открытие фрейма (пока здесь)
    if (action.card.frameGuid) {
      await this.openRegistryCardInFrame(action.card, readOnly, initialData)
      return
    }

    if (!registryId || !openType) {
      console.warn('wrong parameters', action.card)
      return false
    }

    let recordId = null
    let cardId = action.card.cardId

    if (action.card.queryCard) {
      cardId = this.getModel()[action.card.queryCard]
    }

    const defaultRecordId = action.card.recordId
    const constantRecordId = action.card.constantRecordId
    const fieldId = action.card.fieldId ?? action.card.queryRecord

    let isQuickAddCard = null

    const me = this.context
    const me2 = this

    /* Обработка до открытия карточки */
    if (openType === 'update' || openType === 'read') {
      if (constantRecordId) {
        recordId = constantRecordId
      } else if (defaultRecordId) {
        recordId = defaultRecordId
      } else if (fieldId) {
        recordId = this.getModel()[fieldId]
      }

      if (!recordId) {
        console.warn(`recordId doesn't set = ${fieldId}, constant = ${constantRecordId}`)
        return false
      }
    } else if (openType === 'add') {
      // console.log('add card', this.action.card, 'card', card)
      isQuickAddCard = await this.isQuickAddCard()
      const outerXrefComponent = Object.values(this.context.getDashboardComponents()).find(item => (item[0] && item[0].name === fieldId))
      if (outerXrefComponent && outerXrefComponent[0].outerXrefId && this.getModel()['id']) {
        initialData[`attr_${outerXrefComponent[0].outerXrefId}_`] = this.getModel()['id']
      }
    }

    const card = (cardId) ? { id: cardId } : await this.getCardId(registryId, recordId)

    /* Обработка открытия карточки */
    /* Добавление */
    if (openType === 'add') {
      if (this.action.card.isFastCard) {
        this.openQuickAddCardByCheckbox(this.action.card, initialData)
        return
      }

      if (isQuickAddCard && !cardId) {
        // console.log('isQuickAddCard', isQuickAddCard)
        this.openQuickAddCard(isQuickAddCard)
        return
      }

      if (this.action.card.containerAlias) {
        // Открыть в контейнере
        this.openRegistryCardInContainer({ registryId, recordId, card, initialData, readOnly, showBreadcrumbs: this.action.card.breadcrumbByButton })
        return
      }

      if (this.action.card.isWindow) {
        this.openRegistryCardInWindow(card, readOnly, recordId, initialData, registryId)
        return
      }

      if (fieldId) {
        if (!this.getModel()['id']) {
          console.warn(`record has not id`)
          // return false
        }

        this.context.openRegistryCard({
          registryId,
          cardId: card.id,
          cardName: '',
          recordId,
          initialData,
          registry: {
            readonly: readOnly,
            addRecord (recordId) {
              me.$set(me.getModel(), fieldId, recordId)
              me2.refreshReplication(me)
              me.refreshComponents(action.card.componentsGuid)
            },
            updateRecord () {
              me2.refreshReplication(me)
              me.refreshComponents(action.card.componentsGuid)
            }
          }
        })
        return
      }

      this.context.openRegistryCard({
        registryId,
        cardId: card.id,
        cardName: '',
        recordId,
        initialData,
        registry: {
          readonly: readOnly,
          addRecord () {
            me.refreshComponents(action.card.componentsGuid)
            me2.refreshReplication(me)
          },
          updateRecord (recordId) {
            me.refreshComponents(action.card.componentsGuid)
            me2.refreshReplication(me)
            if (fieldId) {
              const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === fieldId)
              if (selectField) {
                if (typeof selectField[0].reloadById === 'function') {
                  selectField[0].reloadById(recordId)
                } else if (typeof selectField[0].loadData === 'function') {
                  selectField[0].loadData()
                }
              }
            }
          }
        }
      })
      return
    }

    /* Редактирование/Просмотр */
    if (openType === 'update' || openType === 'read') {
      if (this.action.card.containerAlias) {
        // Открыть в контейнере
        this.openRegistryCardInContainer({ registryId, recordId, card, initialData, readOnly, showBreadcrumbs: this.action.card.breadcrumbByButton })
        return
      }

      if (this.action.card.isWindow) {
        this.openRegistryCardInWindow(card, readOnly, recordId, initialData, registryId)
        return
      }

      this.context.openRegistryCard({
        registryId,
        cardId: card.id,
        cardName: '',
        recordId,
        initialData,
        registry: {
          readonly: readOnly,
          addRecord () {
            me.refreshComponents(action.card.componentsGuid)
          },
          updateRecord (recordId) {
            if (action?.card.componentsGuid) {
              me.refreshComponents(action.card.componentsGuid)
            }
            if (fieldId) {
              const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === fieldId)
              if (selectField) {
                if (typeof selectField[0].reloadById === 'function') {
                  selectField[0].reloadById(recordId)
                } else if (typeof selectField[0].loadData === 'function') {
                  selectField[0].loadData()
                }
              }
            }
          }
        }
      })
    }
  }

  static async saveCard () {
    if (this.context.inCard) {
      const card = this.context.getCard()

      try {
        await card.saveRecord()

        if (this.action.saveCard?.isCloseAfterSave) {
          card.$emit('cancelChanges')
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  static closeCard () {
    if (this.context.inCard) {
      const card = this.context.getCard()
      card.$emit('cancelChanges', { confirmCloseCard: card.buttonClose.confirmCloseCard, confirmCloseCardText: card.buttonClose.confirmCloseCardText })
    }
  }

  static closeDashboard () {
    this.context.cancelChanges()
    try {
      this.context.$msgbox.close()
    } catch (e) {
    }
  }

  static openHistory () {
    if (this.context.inCard) {
      const card = this.context.getCard()
      const registryId = card.recordData.object_id
      const recordId = card.recordData.id

      this.context.openTabModalWindow('History', { registryId, recordId })
    }
  }

  static openUrl () {
    const openType = this.action.url.openType
    let url = this.action.url.url

    try {
      // Проверить корректность URL
      const temp = new URL(url)
    } catch (error) {
      // URL без протокола
      if (url.substring(0, 2) !== '//') {
        // Сделать URL абсолютным
        url = '//' + url
      }
    }

    if (openType === 'newTab') {
      window.open(url, '_blank')
      return
    }
    if (openType === 'currentTab') {
      window.open(url, '_self')
      return
    }
    if (openType === 'newWindow') {
      const win = window.open(url, url, 'menubar=yes,toolbar=yes,location=yes,status=yes,resizable=yes')
    }
  }

  static async refreshCard () {
    let message
    const card = typeof this.context.getCard === 'function' ? this.context.getCard().getCard() : undefined
    const dashboard = typeof this.context.getParentDashboard === 'function' ? this.context.getParentDashboard() : undefined

    if (this.action.refreshCard.warnMessage.onEdit) {
      message = this.action.refreshCard.warnMessage.onEdit
    } else if (this.action.refreshCard.warnMessage.onRead) {
      message = this.action.refreshCard.warnMessage.onRead
    }

    if (card || dashboard) {
      if (card) {
        if (message && card.isBlocked === false) {
          this.showMessageBeforeRefresh(message, card)
        } else if (message && card.isBlocked === true) {
          this.showMessageBeforeRefresh(message, card)
        } else {
          if (card.isBlocked === false) {
            await this.checkOnSaveBeforeRefresh(card)
          }
          await this.reopenCard(card)
        }
      } else {
        dashboard.loadData()
      }
    }
  }

  static etlExport () {
    this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/etleditor/export/${this.action.etl_export.task_id}`,
      responseType: 'arraybuffer'
    })
      .then(response => {
        let blob = new Blob([response.data], { type: response.headers['content-type'] })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      })
  }

  static async refreshReplication (me) {
    let _:{ context: any; } = this
    if (typeof me !== 'undefined') {
      _ = {
        context: me
      }
    }
    const context = _.context?.getInterfaceWrapper() || _.context

    const store = context.getContainersStore()
    if (!store) {
      return
    }

    const blockGuids = (me || this).action?.refreshReplication?.blocks
    if (!Array.isArray(blockGuids)) {
      return
    }

    const promises = []
    for (const blockGuid of blockGuids) {
      promises.push(context.refreshReplication(blockGuid))
    }

    try {
      await Promise.all(promises)
    } catch (error) {
      console.error('Ошибка тиражирования', error)
    }
  }

  /* Вспомогательные функции */

  static async getCardId (registryId, recordId = null) {
    console.log('id card not set')
    let url = `${this.context.$config.api}/registryservice/registry/${registryId}/card`
    if (recordId) {
      url = `${this.context.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
    }
    const data = await this.context.$http.get(url)

    return data.data[0]
  }

  static getDefaultsForCard () {
    const defaults = []

    if (this.action.card.defaults) {
      this.action.card.defaults.forEach(item => {
        if (!item.type || item.type === 'field') {
          if (this.getModel()[item.attribute] && item.alias) {
            defaults.push({
              key: item.alias,
              value: this.getModel()[item.attribute]
            })
          }
        } else if (item.type === 'constant' && item.alias) {
          defaults.push({
            key: item.alias,
            value: item.attribute
          })
        } else if (item.type === 'current_user') {
          defaults.push({
            key: item.alias,
            value: this.context.$store.getters['Authorization/userId']
          })
        }
      })
    }

    return defaults
  }

  static async isQuickAddCard () {
    // Проверка на карточку быстрого добавления
    const registryData = new Registry({ id: this.action.card.registryId })
    const me = this.context
    const structure = await registryData.structure().first()
      .catch(() => {
        me.error = true
      })

    if (!structure) {
      return false
    }

    const quickAddCard = (structure.properties || []).find(item => item.id === 'quick_add_card') || {}
    if (quickAddCard.value && quickAddCard.value.card_id) {
      return quickAddCard
    } else {
      return false
    }
  }

  static openQuickAddCardByCheckbox (quickAddCard, initialData = {}) {
    const h = this.context.$createElement
    let customClass = 'custom_scrollbar '
    if (quickAddCard.windowWidth) {
      customClass += `window_width_${quickAddCard.windowWidth}`
    }

    const me = this.context
    const me2 = this
    this.context.$msgbox({
      customClass: customClass,
      message: h('registry-card', {
        style: {
          height: me.action?.card?.windowHeight || ''
        },
        props: {
          cardId: me.action.card.cardId,
          registryId: this.action.card.registryId,
          parentContext: null,
          model: {},
          quick: true,
          initialData: initialData
        },
        on: {
          'quick-add': async data => {
            const cardFast = await this.getFastCard(data)
            me.openRegistryCard({
              registryId: me.action.card.registryId,
              cardId: cardFast.id,
              cardName: cardFast.name,
              recordId: null,
              initialData: data,
              registry: {
                addRecord (recordid) {
                  me.getModel()[me.action.card.fieldId] = recordid
                  me.refreshComponents(me.action.card.componentsGuid)
                  me2.refreshReplication(me)
                },
                updateRecord () {
                  me.refreshComponents(me.action.card.componentsGuid)
                  me.refreshComponents(me.action.card.componentsGuid)
                  me2.refreshReplication(me)
                }
              }
            })
            me.$msgbox.close()
          },
          cancelChanges () {
            me.$msgbox.close()
          }
        },
        key: this.context.generateGuid()
      }),
      showCancelButton: false,
      showConfirmButton: false,
      closeOnClickModal: false
    })
  }

  static openQuickAddCard (quickAddCard, initialData = {}) {
    const h = this.context.$createElement
    let customClass = 'custom_scrollbar '
    if (quickAddCard.value.width) {
      customClass += `window_width_${quickAddCard.value.width}`
    }

    const me = this.context
    const me2 = this
    this.context.$msgbox({
      customClass: customClass,
      message: h('registry-card', {
        style: {
          height: this.action?.card?.windowHeight || ''
        },
        props: {
          cardId: quickAddCard.value.card_id,
          registryId: this.action.card.registryId,
          parentContext: null,
          model: {},
          quick: true,
          initialData: initialData
        },
        on: {
          'quick-add': async data => {
            const cardFast = await this.getFastCard(data)
            me.openRegistryCard({
              registryId: me.action.card.registryId,
              cardId: cardFast.id,
              cardName: cardFast.name,
              recordId: null,
              initialData: data,
              registry: {
                addRecord (recordid) {
                  me.getModel()[me.action.card.fieldId] = recordid
                  me.refreshComponents(me.action.card.componentsGuid)
                  me2.refreshReplication(me)
                },
                updateRecord () {
                  me.refreshComponents(me.action.card.componentsGuid)
                  me2.refreshReplication(me)
                }
              }
            })
            me.$msgbox.close()
          },
          cancelChanges () {
            me.$msgbox.close()
          }
        },
        key: this.context.generateGuid()
      }),
      showCancelButton: false,
      showConfirmButton: false,
      closeOnClickModal: false
    })
  }

  static openRegistryCardInContainer ({ registryId, recordId, card, initialData, readOnly, showBreadcrumbs }) {
    // Открыть в контейнере
    const store = this.context.getContainersStore()
    if (store) {
      // Получить контейнер по алиасу
      const container = store.getByAlias(this.action.card.containerAlias)
      if (container) {
        this.context.$set(container, 'registry', { registryId })
        this.context.$set(container, 'showBreadcrumbs', showBreadcrumbs)
        this.context.$set(container, 'contentType', 'registry')

        console.log('openRegistryCardInContainer', 1, this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`])
        // @TODO registry_... появляется на ~5 тик - нужно разобраться. Либо поменять в v-if на v-show в новом редакторе интерфейсов
        this.context.$nextTick(() => this.context.$nextTick(() => this.context.$nextTick(() => this.context.$nextTick(() => {
          console.log('openRegistryCardInContainer', 5, this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`])

          const containerRegistry = this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`]

          let me = this.context
          let me2 = this

          containerRegistry.openRegistryCard({
            registryId,
            cardId: card.id,
            cardName: '',
            recordId,
            initialData,
            readonly: readOnly,
            registry: {
              addRecord () {
                me.refreshComponents(me.action.card.componentsGuid)
                me2.refreshReplication(me)
              },
              updateRecord () {
                me.refreshComponents(me.action.card.componentsGuid)
                me2.refreshReplication(me)
              }
            }
          })
        }))))

        // const containerRegistry = this.context.getInterfaceWrapper().$refs[`registry_${container.guid}`]
        // console.log('openRegistryCardInContainer', 2, containerRegistry)

        // containerRegistry.openRegistryCard({
        //   registryId,
        //   cardId: card.id,
        //   cardName: '',
        //   recordId,
        //   initialData,
        //   registry: { readonly: readOnly }
        // })
      }
    }
  }

  static async openRegistryCardInFrame (card, readOnly, initialData) {
    let registryId
    let recordId
    let cardId
    if (card.queryRegistry) {
      registryId = this.context.getModel()[card.queryRegistry]
      recordId = this.context.getModel()[card.queryRecord]
      cardId = this.context.getModel()[card.queryCard] || null
    } else {
      registryId = card.registryId
      recordId = this.context.getModel()[card.fieldId]
      cardId = card.cardId || null
    }

    if (!registryId) {
      console.warn(`registryId: ${registryId}, recordId: ${recordId}`)
      return
    }
    if (!cardId) {
      let card = await this.getCardId(registryId, recordId)
      cardId = card.id
    }

    let frame = (this.context.getDashboardComponents()[`component_${card.frameGuid}`] || [])[0]
    if (!frame) {
      console.warn('frame not found', card)
      return false
    }

    frame.openCard({
      cardId: cardId,
      registryId: registryId,
      recordId: recordId,
      readOnly: readOnly
    })
  }

  static openRegistryCardInWindow (card, readOnly, recordId, initialData, registryId) {
    const h = this.context.$createElement
    let customClass = 'custom_scrollbar '
    const action = this.action
    if (action.card.windowWidth) {
      customClass += `dashboard_window_width_${this.action.card.windowWidth}`
    }

    const me = this.context
    const me2 = this

    me.$msgbox({
      title: action?.card?.windowTitle || action?.card?.windowTitle,
      customClass: customClass,
      message: h('registry-card', {
        style: {
          height: action?.card?.windowHeight || ''
        },
        props: {
          cardId: card.id,
          registryId: registryId,
          readonly: readOnly,
          recordId: recordId,
          initialData: initialData
        },
        on: {
          cancelChanges () {
            me.$msgbox.close()
          },
          recordAdded (recordId) {
            me.refreshComponents(action.card.componentsGuid)
            me2.refreshReplication(me)
            me.$set(me.getModel(), action.card.fieldId, recordId)
          },
          recordEdited (recordId) {
            me.refreshComponents(action.card.componentsGuid)
            me2.refreshReplication(me)
            if (action.card.fieldId) {
              const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === action.card.fieldId)
              if (selectField) {
                if (typeof selectField[0].reloadById === 'function') {
                  selectField[0].reloadById(recordId)
                } else if (typeof selectField[0].loadData === 'function') {
                  selectField[0].loadData()
                }
              }
            }
          }
        },
        key: this.context.generateGuid()
      }),
      showCancelButton: false,
      showConfirmButton: false,
      closeOnClickModal: false
    })
  }

  static async getFastCard (recordData = null) {
    const data = await this.context.$http.post(
      `${this.context.$config.api}/registryservice/registry/${this.action.card.registryId}/card`,
      recordData,
      { hideNotification: true }
    )
    return data.data[0]
  }

  static getDataFilters () {
    let filters = []
    if (this.action.filters) {
      this.action.filters.forEach(item => {
        if (!item.type || item.type === 'field') {
          if (this.getModel()[item.attribute] && item.alias) {
            filters.push({
              key: item.alias,
              value: this.getModel()[item.attribute]
            })
          }
        } else if (item.type === 'constant' && item.alias) {
          filters.push({
            key: item.alias,
            value: item.attribute
          })
        }
      })
    }
    return filters
  }

  static showMessageBeforeRefresh (message, card) {
    this.context.$msgbox({
      title: 'Обновление экранной формы',
      message: message,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Продолжить',
      cancelButtonText: 'Отмена',
      closeOnClickModal: false,
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          try {
            if (card && card.isBlocked === false) {
              await this.checkOnSaveBeforeRefresh(card)
            }
            await this.reopenCard(card)
            done()
          } catch (e) {
            console.log(e)
          }
        } else {
          done()
        }
      }
    })
  }

  static async checkOnSaveBeforeRefresh (card) {
    if (this.action.refreshCard.isSaveCardBeforeRefresh) {
      card.saveRecord()
    }
  }

  static async reopenCard (card) {
    card.$emit('cancelChanges')

    await this.context.$nextTick(() => {
      this.context.openRegistryCard({
        'registryId': card.registryId,
        'cardId': card.cardId,
        'cardName': card.name,
        'recordId': parseInt(card.activeRecordId)
      })
    })
  }

  static closeCard2 () {
    let depth

    switch (this?.context?.action?.command?.close_type) {
      case 'current_card':
        depth = 1
        break
      case 'parent_card':
        depth = 2
        break
    }

    if (!('is_card_close' in this.action.command) || !this.action.command.is_card_close) {
      return
    }

    if (this.context.activeTab != null) {
      if (this.context.openedCards == null) {
        this.context.closeTab(this.context.activeTab())
      } else {
        if (depth) {
          this.context.cancelChanges({ depth: depth })
        }
      }
    } else {
      this.context.$msgbox.close()
    }
  }

  static openModalWindow () {
    if (this.action.window?.guid) {
      const viewer = this.context.getViewer()
      if (!viewer) {
        console.warn('viewer not found', this.context)
        return false
      }
      const _window = viewer.windows.find((item) => item.guid === this.action.window?.guid)
      if (!_window) {
        console.warn(`window guid = ${this.action.window?.guid} - not found in windows`, viewer.windows)
        return false
      }
      viewer.modalWindow.isPopover = this.action.window.popover
      if (viewer.modalWindow.isPopover) {
        viewer.modalWindow.position = {
          x: this.event.clientX,
          y: this.event.clientY
        }
      }
      if (typeof this.context.getCard === 'function') {
        const card = this.context.getCard()
        if (card) {
          let components = []
          for (let key in _window.structure.components) {
            if (_window.structure.components.hasOwnProperty(key)) {
              components.push(_window.structure.components[key])
            }
          }
          components.forEach((item) => {
            const initialType =
              typeof item.group !== 'undefined'
                ? `${item.group}/${item.initialType}` : item.initialType
            if (!card.data[item.properties.name] && /attr_[0-9]+_/i.test(item.properties.name)) {
              if (!(item.properties.name in card.initialData) && !(item.properties.name in card.recordData) && initialType !== 'registry/xref_outer_field') {
                console.warn(`Не найдены данные в записи по компоненту с атрибутом = ${item.properties.name}`)
              }
              card.$set(card.data, item.properties.name, card.initialData[item.properties.name] || card.recordData[item.properties.name])
              if (card.excludingComponents.includes(initialType)) {
                card.excludingAttributes.push(item.properties.name)
              }
              if (initialType === 'basic/a-file' && item.properties.name) {
                card.fileAttributes.push(item.properties.name)
              }
            }
          })
        }
      }
      viewer.modalWindow.show = true
      viewer.modalWindow.model = this.getModel()
      viewer.modalWindow.width = `${_window.width.value}${_window.width.type}`
      viewer.modalWindow.height = `${_window.height.value}${_window.height.type}`
      viewer.modalWindow.title = undefined
      if (this.action.window?.title) {
        viewer.modalWindow.title = this.action.window.title
      }
      viewer.$nextTick(() => {
        if (!viewer.modalWindow.isPopover) {
          viewer.$refs.modal_viewer.loadInterface(_window.structure)
        } else {
          viewer.$refs.modal_viewer_popover.loadInterface(_window.structure)
        }
      })
    } else {
      console.warn('window does`t set', this.action)
    }
  }

  static async etlImport () {
    let fields = []
    if (Array.isArray(this.action.etl_import.fields)) {
      fields = this.action.etl_import.fields.map(item => {
        return {
          field_id: parseInt(item.alias.match(/\d+/g).join([])),
          value: this.context.getModel()[item.attribute],
          is_key: item.isKey
        }
      })
    }

    const h = this.context.$createElement
    let me = this
    let file = null

    await this.context.$msgbox({
      title: 'Импорт',
      message: h(EtlImport, {
        on: {
          uploaded (uploadedFile) {
            file = uploadedFile.raw
          }
        },
        key: me.context.generateGuid()
      }),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Импорт',
      cancelButtonText: 'Закрыть',
      closeOnClickModal: false,
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          try {
            const form = new FormData()

            form.append('file', file)
            form.append('payload', JSON.stringify({ 'constant_fields': fields }))

            await me.context.$http.request({
              url: `${me.context.$config.api}/etleditor/import/${me.action.etl_import.task_id}`,
              method: 'POST',
              data: form,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            await me.context.refreshComponents(me.action.card.componentsGuid)
            done()
          } catch (e) {
            console.log(e)
            done()
          }
        } else {
          done()
        }
      }
    })
  }

  static getDefaultsForDashboard () {
    const defaults = []

    if (this.action.dashboard.fields) {
      this.action.dashboard.fields.forEach(item => {
        if (!item.type || item.type === 'field') {
          if (this.getModel()[item.attribute] && item.alias) {
            defaults.push({
              key: item.alias,
              value: this.getModel()[item.attribute]
            })
          }
        } else if (item.type === 'constant' && item.alias) {
          defaults.push({
            key: item.alias,
            value: item.attribute
          })
        } else if (item.type === 'current_user') {
          defaults.push({
            key: item.alias,
            value: this.context.$store.getters['Authorization/userId']
          })
        }
      })
    }

    return defaults
  }
}
