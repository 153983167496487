<template>
  <div>
    <span class="property_label" style="display: inline-block">Источник</span>
    <el-select v-model="localValue.source">
      <el-option
        v-for="item in sourceType"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-input
      v-show="localValue.source === 'string'"
      v-model="localValue.url"
      placeholder="https://yandex.ru"
      style="margin-top: 5px"
    ></el-input>
    <select-field
      v-model="localValue.fieldId"
      v-show="localValue.source === 'field'"
      class="extraLine_select"
      style="margin-top: 5px"
      :clearable="true"
      :standartTree="false"
      :dataTable="registryFields"
    />
  </div>
</template>
<script>
// Выбор поля реестра
import SelectField from '@/components/LogicEditor/components/treeSelect.vue'
const sourceType = [
  { id: 'field', name: 'Поля реестра' },
  { id: 'string', name: 'Ввод вручную' }
]
export default {
  name: 'editor-iframe',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          source: 'string',
          url: null,
          fieldId: null
        }
      }
    },
    otherProperties: {
      type: Object
    },
    registryFields: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SelectField
  },
  data () {
    return {
      sourceType,
      localValue: this.value
    }
  },
  watch: {
    localValue: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'localValue.source': {
      handler () {
        this.localValue.url = ''
        this.localValue.fieldId = null
      }
    }
  },
  mounted () {
    if (this.otherProperties.url && this.localValue.url === null) {
      this.localValue.source = 'string'
      this.localValue.url = this.otherProperties.url
    }
  }
}
</script>

<style scoped></style>
