<template>
  <el-form v-model="value">
    <el-form-item label="Включить всплывающие подсказки" class="label">
      <el-checkbox v-model="enabled" @change="enableChanged"></el-checkbox>
    </el-form-item>
    <template v-if="enabled">
      <el-form-item label="Содержимое подсказки" class="label">
        <div class="ckeditorDiv">
          <ckeditor ref="ckeditor" v-model="value.text" :editor="editor"></ckeditor>
        </div>
      </el-form-item>
      <el-form-item label="css контейнера">
        <codemirror ref="cssEditor" v-model="value.css" :options="codemirrorConfig" style="height: 150px;"></codemirror>
      </el-form-item>
    </template>
  </el-form>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import InlineEditor from '@/components/HTMLEditor/ckeditor'

export default {
  name: 'popupPanel',
  components: {
    ckeditor: CKEditor.component
  },
  data () {
    return {
      editor: InlineEditor,
      codemirrorConfig: {
        tabSize: 2,
        mode: 'text/css',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true
      },
      enabled: null,
      tmp: { text: '', css: '' }
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  mounted () {
    if (this.value.text === '' || this.value.text === null) {
      this.enabled = false
    } else {
      this.enabled = true
    }
  },
  methods: {
    enableChanged (val) {
      if (!val) {
        this.tmp.text = this.value.text
        this.tmp.css = this.value.css
        this.value.text = ''
        this.value.css = ''
      } else {
        this.value.text = this.tmp.text
        this.value.css = this.tmp.css
      }
    }
  }
}
</script>
<style>
  .ck-body-wrapper {
    z-index: 99999;
  }
</style>
<style scoped>
  .ckeditorDiv {
    border: 1px solid #dcdfe6;
    border-radius: 6px;
  }
</style>
