import { MutationTree } from 'vuex'
import { RuleState, initialState } from './state'
import {MutationTypes, RuleCreatedEvent, RuleUpdatedEvent, RuleDeletedEvent} from './mutation-types'

export const mutations: MutationTree<RuleState> = {
  [MutationTypes.ERROR]: (state, error) => {
    state.isLoading = false
    state.error = error
  },
  [MutationTypes.RULE_LOADING_EVENT]: (state) => {
    state.isLoading = true
  },
  [MutationTypes.RULE_LOADED_EVENT]: (state) => {
    state.isLoading = false
  },
  [MutationTypes.RULE_CREATED_EVENT]: (state, action: RuleCreatedEvent) => {
    state.isLoading = false
    state.location = action.location
  },
  [MutationTypes.RULE_UPDATED_EVENT]: (state, action: RuleUpdatedEvent) => {
    state.isLoading = false
  },
  [MutationTypes.RULE_DELETED_EVENT]: (state, action: RuleDeletedEvent) => {
    state.isLoading = false
    state.deletedRuleGuid = action.guid
  }
}
