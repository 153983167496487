import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { TimerDTO } from '@/services/LogicEditor/domain/model/Timer'

export namespace TimerAPI {

  export class GetTimerByGuid implements APIRequest<TimerDTO> {
    response: TimerDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/timers/${guid}`
    }
  }

  export class CreateTimer implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/timers';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: TimerDTO) {}
  }

  export class UpdateTimer implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: TimerDTO) {
      this.path = `/v2/logiceditor/timers/${params.guid}`
    }
  }

  export class DeleteTimer implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => null
    constructor (guid: string) {
      this.path = `/v2/logiceditor/timers/${guid}`
    }
  }
}
