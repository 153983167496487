<template>
  <el-form ref="form" :model="value" size="mini" :rules="rules">
    <el-row :gutter="5">
      <el-col :span="6">
        <el-form-item prop="properties.field">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content" v-html="$t('report_editor.hints.filterField')"></div>
            <el-select
              v-model="value.properties.field"
              :placeholder="$t('report_editor.filter.field')"
              :disabled="!fieldList.length"
              @change="selectedField"
            >
              <el-option
                v-for="item in fieldList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="report_filter_type_id">
          <el-tooltip class="item" effect="dark" :content="$t('report_editor.hints.filterType')" placement="top">
            <el-select
              v-model="value.report_filter_type_id"
              :placeholder="$t('report_editor.filter.type')"
              @change="selectedType"
            >
              <el-option
                v-for="item in filterTypeList"
                :key="item.id"
                :label="$t('report_editor.filter.types.' + item.name)"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>
      </el-col>

      <el-col v-if="showOperation" :span="showComponent ? 5 : 10">
        <el-form-item prop="operation_type_id">
          <el-tooltip class="item" effect="dark" :content="$t('report_editor.hints.filterOperator')" placement="top">
            <el-select
              v-model="value.operation_type_id"
              :placeholder="$t('report_editor.filter.operation_type_id')"
            >
              <el-option
                v-for="item in operationList"
                :key="item.id"
                :label="$t('report_editor.filter.operations.' + item.name)"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>
      </el-col>

      <el-col v-if="showComponent" :span="showOperation ? 5 : 10">
        <el-form-item prop="properties.value">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content" v-html="valueHints"></div>
            <component
              class="filter-value"
              v-model="value.properties.value"
              :is="typeComponent"
              :placeholder="$t('report_editor.filter.value')"
            />
          </el-tooltip>
        </el-form-item>
      </el-col>

      <el-col :span="2">
        <el-button
          style="margin: 0 10px;"
          circle
          size="mini"
          :icon="isDeleted() ? `el-icon-time` : `el-icon-delete`"
          :type="isDeleted() ? `default` : `danger`"
          @click="_remove()"
        ></el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import Condition from './typeComponents/Condition'
import Constant from './typeComponents/Constant'
import UsersTable from './typeComponents/UsersTable'
import Parameter from './typeComponents/Parameter'

export default {
  name: 'FilterForm',

  props: ['value', 'index', 'fieldList', 'filterTypeList', 'remove'],

  components: {
    Condition,
    Constant,
    UsersTable,
    Parameter
  },

  computed: {
    typeComponent () {
      return this.showComponent
        ? this.availableTypeComponent[this.value.report_filter_type_id]
        : Constant
    },

    showComponent () {
      return typeof this.availableTypeComponent[this.value.report_filter_type_id] !== 'undefined'
    },

    showOperation () {
      return this.value.report_filter_type_id !== 'condition'
    },

    valueHints () {
      const hints = {
        parameter: this.$t('report_editor.hints.parameter'),
        condition: this.$t('report_editor.hints.condition'),
        constant: this.$t('report_editor.hints.constant')
      }

      return hints[this.value.report_filter_type_id] || null
    },

    rules () {
      const validateValue = (rule, value, callback) => {
        if (!value && !['current_user', 'current_date', 'current_datetime'].includes(this.value.report_filter_type_id)) {
          callback(new Error('Введите значение'))
        } else {
          callback()
        }
      }

      const validateOperation = (rule, value, callback) => {
        if (!value && this.value.report_filter_type_id !== 'condition') {
          callback(new Error('Выберите оператор сравнения'))
        } else {
          callback()
        }
      }

      return {
        'properties.field': [{ required: true, message: 'Выберите поле (псевдоним)', trigger: 'change' }],
        report_filter_type_id: [{ required: true, message: 'Выберите тип фильтра', trigger: 'change' }],
        operation_type_id: [{ validator: validateOperation, trigger: 'change' }],
        'properties.value': [
          {
            validator: validateValue,
            trigger: this.value.report_filter_type_id === 'condition'
              ? 'change'
              : 'blur'
          }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function () {
        this.localValue = this.value
      },
      deep: true
    },

    localValue: {
      handler: function () {
        this.$emit('input', this.localValue)
      },
      deep: true
    }
  },

  data () {

    return {
      localValue: this.value,
      operationList: [
        {
          id: 'eq',
          name: 'eq'
        }, {
          id: 'neq',
          name: 'neq'
        }, {
          id: 'lt',
          name: 'lt'
        }, {
          id: 'lte',
          name: 'lte'
        }, {
          id: 'gt',
          name: 'gt'
        }, {
          id: 'gte',
          name: 'gte'
        }
      ],
      availableTypeComponent: {
        condition: Condition,
        constant: Constant,
        users_table: UsersTable,
        parameter: Parameter
      }
    }
  },

  methods: {
    selectedType (value) {
      this.localValue.properties.value = null
    },

    selectedField (value) {
      let field = this.fieldList.find(item => item.name === value);

      this.localValue.properties.type = field.entity_type_id || null
    },

    isValid () {
      let validForm = true
      this.$refs.form.validate((valid) => {
        validForm = valid
      })
      return validForm
    },

    _remove () {
      if (typeof this.localValue.id === 'undefined') {
        this.remove(this.index)
      } else {
        if (typeof this.localValue.is_deleted !== 'undefined') {
          this.$delete(this.localValue, 'is_deleted')
        } else {
          this.$set(this.localValue, 'is_deleted', true)
        }
      }
    },

    isDeleted () {
      return typeof this.localValue.is_deleted !== 'undefined'
    }
  }
}
</script>

