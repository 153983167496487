import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class TimerUpdateCommand implements CommandInterface {
  /**
   * @param guid
   * @param name
   * @param alias
   * @param description
   * @param period
   * @param commands
   */
  constructor (
    public readonly guid: string,
    public readonly name: string,
    public readonly alias: Nullable<string>,
    public readonly description: Nullable<string>,
    public readonly period: string,
    public readonly commands: Array<object>
  ) {}

  getClassName () {
    return 'TimerUpdateCommand'
  }
}
