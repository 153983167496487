<template>
  <div style="height: 100%">
    <cards
      ref="cards"
      v-show="openedCards.length > 0"
      :opened-cards="openedCards"
      :show-breadcrumb="showBreadcrumb"
      :breadcrumb-by-button="breadcrumbByButton"
      :show-card="showCard"
      @cancelChanges="cancelChanges"
      @back="openedCards = openedCards.slice(0, $event + 1)"
      @back-registry="showCard=false; openedCards = []"
      style="height: calc(100% - 15px);padding-top: 15px"
    ></cards>
    <el-form :model="interfaceModel" v-show="!showCard" ref="validateForm" v-loading="loading" style="height: 100%" @submit.native.prevent>
      <InterfaceViewer
        v-if="version === 1"
        ref="interface_viewer"
        :model="interfaceModel"
        :msgbox="msgbox"
        :dashboard-id="id"
      ></InterfaceViewer>
      <InterfaceViewerV2
        v-else-if="version === 2"
        :model="interfaceModel"
        type="dashboard"
        :style="msgbox === 'msgbox' ? 'height: 100vh' : ''"
        ref="interface_viewer_v2"
        :dashboard-id="id">
      </InterfaceViewerV2>
    </el-form>
  </div>
</template>

<script>
import InterfaceViewer from '@/components/InterfaceViewer/index.vue'
import Dashboard from '@/components/DashboardEditor/Models/Dashboard'
import Cards from '../../components/Registry/Cards.vue'
import InterfaceViewerV2 from '@/services/InterfaceViewer/index.vue'

export default {
  components: {
    InterfaceViewer,
    InterfaceViewerV2,
    Cards
  },
  props: ['id', 'model', 'main', 'parentContext', 'msgbox', 'showBreadcrumb', 'breadcrumbByButton'],
  name: 'Dashboard',
  data () {
    return {
      version: null,
      loading: true,
      openedCards: [],
      showCard: false,
      interfaceModel: this.model || {}
    }
  },
  provide () {
    return {
      getRegistryRecordId: () => null,
      getRegistryId: () => null,
      openRegistryCard: this.openRegistryCard,
      openDashboardCard: this.openDashboardCard,
      getParentContext: this.getParentContext,
      getParentDashboard: () => this,
      $computedShowCard: () => this.computedShowCard,
      cancelChanges: this.cancelChanges
    }
  },
  mounted () {
    this.loadData()
  },
  computed: {
    computedShowCard () {
      return this.showCard
    }
  },
  methods: {
    async loadData () {
      if (!this.id) {
        return false
      }
      this.loading = true
      let dashboard = await new Dashboard().find(this.id)
      const structure = JSON.parse(dashboard.structure)
      if (typeof structure.version !== 'undefined') {
        this.version = structure.version
      } else {
        this.version = 1
      }
      this.$nextTick(() => {
        this.loadInterface(structure)
      })
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    loadInterface (data) {
      if (this.version === 1) {
        this.$refs.interface_viewer.loadState(data)
      } else if (this.version === 2) {
        this.$refs.interface_viewer_v2.loadInterface(data)
      }
    },
    getParentContext () {
      return this.parentContext
    },
    confirmClosingCard (confirmCloseCardText) {
      let response = this.$confirm(confirmCloseCardText, 'Закрытие карточки', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(() => {
        return true
      }).catch(() => {
        return false
      })
      return response
    },
    /**
     * @param {object} params
     * @param {number} params.depth
     * @param {boolean} params.confirmCloseCard - В общих настройках карточки галка "Уведомлять при закрытии карточки"
     * @param {string} params.confirmCloseCardText - Текста сообщения от пользователя
     */
    async cancelChanges (params = { depth: null, confirmCloseCard: false, confirmCloseCardText: '' }) {
      let { depth, confirmCloseCard, confirmCloseCardText } = params
      if (confirmCloseCard) {
        // закрытие карточки с подтверждением юзера
        let responseUser = await this.confirmClosingCard(confirmCloseCardText)
        if (!responseUser) {
          return
        }
      }
      if (depth) {
        this.openedCards = this.openedCards.slice(0, this.openedCards.length - depth)
        if (this.openedCards.length === 0) {
          this.showCard = false
        }
      } else {
        if (this.openedCards.length === 1) {
          this.showCard = false
          this.openedCards = []
        } else {
          this.openedCards = this.openedCards.slice(0, this.openedCards.length - 1)
        }
      }
    },
    openDashboardCard (dashboardId, name, recordId = null, initialData = {}) {
      if (!dashboardId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true
      this.openedCards.push({
        id: dashboardId,
        recordId: recordId,
        name: name,
        initialData: initialData,
        isDashboard: true
      })
    },
    openRegistryCard ({
      registryId,
      cardId,
      cardName,
      recordId = null,
      initialData = {},
      registry = null
    }) {
      if (!cardId || !registryId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true
      this.openedCards.push({
        id: cardId,
        registryId: registryId,
        recordId: recordId,
        name: cardName,
        initialData: initialData,
        registry: registry,
        readonly: registry ? (registry.readonly || false) : false
      })
    }
  }
}
</script>

<style scoped>

</style>
