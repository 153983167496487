<template>
  <div>
    <div class="setting-label">Позиция /
      <string-select v-model="settings.position" :options="positions"></string-select>
      <font-awesome-icon :icon="icons.plus" @click="add"></font-awesome-icon>
    </div>

    <draggable v-model="settings.list" group="people">
      <tr
        v-for="(tab, index) in settings.list"
        :key="index"
        :class="{ tab: true, active: editingTabGuid === tab.guid }"
      >
        <td style="width: 10%" class="number">
          <b>{{ index + 1 }}.</b>
        </td>

        <td style="width: 70%">
          <span
            v-if="editingTabGuid !== tab.guid"
            class="label"
            @click="editName(tab.guid)"
          >{{ tab.name }}</span>
          <el-input
            v-else
            :ref="`editor_${tab.guid}`"
            v-model="tab.name"
            size="mini"
            class="editor"
            @blur="editingTabGuid = undefined"
            @change="editingTabGuid = undefined"
          ></el-input>
        </td>

        <td style="width: 10%" class="tab-settings">
          <el-popover v-model="dialogs.tabs[tab.guid].isShow" placement="bottom" width="300" trigger="click">
            <i slot="reference" class="el-icon-setting" style="cursor: pointer;"></i>
            <div role="dialog">
              <el-checkbox :value="tabSettingsService.getIsDefaultTab(tab.guid)" @change="tabSettingsService.setIsDefaultTab(tab.guid, $event)">Вкладка по умолчанию</el-checkbox>
              <header class="setting-label">Условия</header>
              <section>
                <header class="setting-label">Условия скрытия</header>
                <EditorConditions :value="tabSettingsService.getHiddenConditions(tab.guid)" @change="tabSettingsService.setHiddenConditions(tab.guid, $event)"></EditorConditions>
              </section>
              <section>
                <header class="setting-label">Условия блокировки</header>
                <EditorConditions :value="tabSettingsService.getBlockedConditions(tab.guid)" @change="tabSettingsService.setBlockedConditions(tab.guid, $event)"></EditorConditions>
              </section>
              <section>
                <header class="setting-label">Условия стилизации</header>
                <EditorConditions :value="tabSettingsService.getStyledConditions(tab.guid)" @change="tabSettingsService.setStyledConditions(tab.guid, $event)"></EditorConditions>
                <div class="setting-label">Стиль вкладки по условию (CSS)</div>
                <codemirror
                  ref="editorTabStyleByCondition"
                  :value="tabSettingsService.getStyle(tab.guid)"
                  :options="codeEditorOptions"
                  class="block_style_code"
                  style="height: 150px;"
                  @input="tabSettingsService.setStyle(tab.guid, $event)"
                ></codemirror>
              </section>
            </div>
          </el-popover>
        </td>

        <td style="width: 10%" class="delete">
          <i class="el-icon-delete" @click.stop="remove(index)"></i>
        </td>
      </tr>
    </draggable>

    <div class="setting-label">Стиль контейнера вкладок (CSS)</div>
    <codemirror ref="editorContainerStyle" v-model="settings.containerStyle" class="block_style_code" :options="codeEditorOptions" style="height: 150px;"></codemirror>
    <div class="setting-label">Класс для вкладок (CSS)</div>
    <codemirror ref="editorClass" v-model="settings.class" class="block_style_code px15" :options="codeEditorOptions" style="height: 15px;"></codemirror>
    <div class="setting-label">Стиль вкладки (CSS)</div>
    <codemirror ref="editorTabStyle" v-model="settings.tabStyle" class="block_style_code" :options="codeEditorOptions" style="height: 150px;"></codemirror>
    <div class="setting-label">Стиль активной вкладки (CSS)</div>
    <codemirror ref="editorActiveTabStyle" v-model="settings.activeTabStyle" class="block_style_code" :options="codeEditorOptions" style="height: 150px;"></codemirror>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import StringSelect from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/StringSelect'
import EditorConditions from '@/components/InterfaceEditor/components/editor/editor-conditions.vue'

import { faPlus } from '@fortawesome/free-solid-svg-icons'

const POSITIONS = [
  { id: 'top', name: 'Сверху' },
  { id: 'bottom', name: 'Снизу' },
  { id: 'left', name: 'Слева' },
  { id: 'right', name: 'Справа' }
]

export default {
  name: 'TabsSettings',
  components: {
    StringSelect,
    draggable,
    FontAwesomeIcon,
    EditorConditions
  },
  props: {
    settings: {
      type: Object,
      default: () => { return {} }
    },
    tabSettingsService: {
      type: Object
    }
  },
  data () {
    return {
      positions: POSITIONS,
      editingTabGuid: undefined,
      icons: {
        plus: faPlus
      },
      codeEditorOptions: {
        tabSize: 2,
        mode: 'text/css',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true
      },
      dialogs: {
        tabs: {}
      }
    }
  },
  watch: {
    settings: {
      deep: true,
      handler (value) {
        this.$emit('change', value)
      }
    },
    'settings.list': {
      handler (value) {
        if (value.length > 0) {
          this.$set(this.settings, 'use', true)
        } else {
          this.$set(this.settings, 'use', false)
        }

        this.restoreDataStructure()
        this.syncDialogsTabsInstances()
      }
    }
  },
  created () {
    this.restoreDataStructure()
    this.syncDialogsTabsInstances()
  },
  mounted () {
    this.$nextTick(() => {
      this.refreshCodemirrors()
    })
  },
  methods: {
    /**
     * Восстановить целостность структур данных (свойств объектов)
     */
    restoreDataStructure () {
      if (this.tabSettingsService && Array.isArray(this.settings.list)) {
        this.tabSettingsService.createTabSettings(this.settings.list.map(tab => tab.guid))
      }
    },
    /**
     * Синхронизировать экземпляры диалоговых окон в соответствии с каждой вкладкой
     * (На каждую вкладку, будет своё модальное окно со своими настройками)
     */
    syncDialogsTabsInstances () {
      if (!Array.isArray(this.settings.list)) {
        return
      }

      this.$set(this.dialogs, 'tabs', this.settings.list.reduce((obj, tab) => {
        if (!(tab.guid in obj)) {
          obj[tab.guid] = { isShow: false }
        }

        return obj
      }, {}))
    },

    refreshCodemirrors () {
      this.$refs.editorTabStyleByCondition?.codemirror?.refresh()
      this.$refs.editorContainerStyle.codemirror.refresh()
      this.$refs.editorClass.codemirror.refresh()
      this.$refs.editorTabStyle.codemirror.refresh()
      this.$refs.editorActiveTabStyle.codemirror.refresh()
    },

    add () {
      if (typeof this.settings.position === 'undefined') {
        this.$set(this.settings, 'position', 'top')
      }

      if (typeof this.settings.list === 'undefined') {
        this.$set(this.settings, 'list', [])
      }
      if (!this.settings?.use) {
        this.$set(this.settings, 'use', true)
      }

      const guid = this.generateGuid()
      this.settings.list.push({
        name: 'Новая вкладка',
        guid
      })

      this.$set(this.dialogs.tabs, guid, {
        isShow: false
      })
    },
    remove (index) {
      this.$delete(this.dialogs.tabs, this.settings.list[index].guid)

      this.settings.list.splice(index, 1)
    },
    editName (guid) {
      this.editingTabGuid = guid
      this.$nextTick(() => {
        if (this.$refs[`editor_${guid}`]) {
          this.$refs[`editor_${guid}`][0].focus()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tab {
  color: #949FA8;
  padding: 3px;
  display: flex;
  &:hover, &.active {
    outline: 1px solid #E9EBED;
  }
  & .number {
    color: #606266;
  }
  & .label:hover {
    color: #606266;
    cursor: pointer;
    border-bottom: 1px dashed #606266;
  }
  & .delete i:hover {
    color: #606266;
    cursor: pointer;
  }
}
::v-deep .el-input--mini .el-input__inner {
  height: 16px;
  line-height: 16px;
  border: none;
  font-size: 14px;
  padding: 0;
}
.setting-label:first-child {
  padding-top: 10px;
}
.setting-label {
  padding-top: 20px;
  & svg {
    margin-right: 5px;
    cursor: pointer;
    color: #949FA8;
    float: right;
  }
  & svg:hover {
    color: #606266;
  }
  & .additional {
    color: #949FA8;
    cursor: pointer;
  }
}
</style>
<style src="../../../main.scss" scoped lang="scss"></style>
