
import { Nullable } from '@/core/domain/type/types'

/**
 @param context - контекст компонента (this),
 @param registryId - id реестра,
 @param recordId - id записи
*/
export const getCardId = async (context: any, { registryId, recordId }:
  { registryId: number, recordId: Nullable<number> }): Promise<object> => {
  let url = `${context.$config.api}/registryservice/registry/${registryId}/card`
  if (recordId) {
    url = `${context.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
  }

  return (await context.$http.get(url)).data[0]
}

/**
 проверка на число
 @param n - Любая переменная
*/
export const isNumeric = (n: any): boolean => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

/**
 сравнение массивов учитывая порядок
 isEqualArrays([1, 2, 3], [1, '2', 3]); // false
 @param a - Любая переменная,
 @param b - Любая переменная,
*/
export const isEqualArrays = (a: any, b: any): boolean => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false
  return a.length === b.length && a.every((v, i) => v === b[i])
}
