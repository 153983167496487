<template>
  <div class="vqb-rule">
    <el-form-item :size="sizeControl" class="form-item-rule">
      <template slot="label">
        <span>{{ rule.label }} <b>{{ rule.field }}</b></span>
      </template>

      <div class="vqb-edit">
        <div class="vqb-edit__input">
          <el-select
            v-if="isValidRuleOperators"
            v-model="selectedOperator"
            @change="changeOperator"
          >
            <el-option
              v-for="operator in rule.operators"
              :key="operator.id"
              :value="operator.id"
              :label="operator.label"
            ></el-option>
          </el-select>
        </div>

        <div class="vqb-edit__input">
          <template v-if="model.filter_type === 'constant'">
            <template v-if="['in', 'not_in'].includes(model.operator) && rule.fieldTypeId !== 'xref_multi_field'">
              <MultiInput
                :component="rule.inputType"
                :placeholder="rule.placeholder"
                :xref-list="xrefList"
                v-model="model.value" />
            </template>
            <template v-else-if="['is_not_null', 'is_null'].includes(model.operator)">
              <el-input :disabled="true"></el-input>
            </template>
            <template v-else>
              <component
                :is="rule.inputType"
                :placeholder="rule.placeholder"
                :xref-list="xrefList"
                v-model="model.value" />
            </template>
          </template>
          <template v-else-if="model.filter_type === 'current_user'">
            <el-input :value="$t('main.query_builder.filter_types.current_user')" :disabled="true"></el-input>
          </template>
          <template v-else-if="model.filter_type === 'current_datetime'">
            <el-input :value="$t('main.query_builder.filter_types.current_datetime')" :disabled="true"></el-input>
          </template>
          <template v-else-if="model.filter_type === 'users_table'">
            <users-table v-model="model.value" :placeholder="$t('main.query_builder.filter_types.users_table')"></users-table>
          </template>
          <template v-else-if="model.filter_type === 'sql'">
            <sql v-model="model.value" :placeholder="$t('main.query_builder.filter_types.sql')"></sql>
          </template>
          <template v-else-if="model.filter_type === 'parameter'">
            <parameter v-model="model.value" :placeholder="$t('main.query_builder.filter_types.parameter')"></parameter>
          </template>
          <template v-else-if="model.filter_type === 'current_table_field'">
            <current-table-field v-model="model.value" :object-id="rule.objectId" :placeholder="$t('main.query_builder.filter_types.current_table_field')"></current-table-field>
          </template>
          <template v-else-if="model.filter_type === 'external_table_field'">
            <external-table-field v-model="model.value" :placeholder="$t('main.query_builder.filter_types.external_table_field')"></external-table-field>
          </template>
        </div>

        <div class="vqb-edit__actions">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('main.query_builder.button.delete_rule')" placement="top">
            <el-button
              size="mini"
              type="danger"
              circle
              @click="remove"
              v-html="labels.removeRule">
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script type="ts">
import Vue from 'vue'
import deepClone from './utilities.js'

import DateInput from '@/core/infrastructure/components/FilterType/Constant/components/DateInput.vue'
import DatetimeInput from '@/core/infrastructure/components/FilterType/Constant/components/DatetimeInput.vue'
import FlagInput from '@/core/infrastructure/components/FilterType/Constant/components/FlagInput.vue'
import FloatInput from '@/core/infrastructure/components/FilterType/Constant/components/FloatInput.vue'
import MultiInput from '@/core/infrastructure/components/FilterType/Constant/components/MultiInput.vue'
import NumberInput from '@/core/infrastructure/components/FilterType/Constant/components/NumberInput.vue'
import StringInput from '@/core/infrastructure/components/FilterType/Constant/components/StringInput.vue'
import TextInput from '@/core/infrastructure/components/FilterType/Constant/components/TextInput.vue'
import TimeInput from '@/core/infrastructure/components/FilterType/Constant/components/TimeInput.vue'
import XrefInput from '@/core/infrastructure/components/FilterType/Constant/components/XrefInput.vue'
import XrefMultiInput from '@/core/infrastructure/components/FilterType/Constant/components/XrefMultiInput.vue'

import CurrentTableField from '@/core/infrastructure/components/FilterType/CurrentTableField.vue'
import ExternalTableField from '@/core/infrastructure/components/FilterType/ExternalTableField.vue'
import Parameter from '@/core/infrastructure/components/FilterType/Parameter.vue'
import Sql from '@/core/infrastructure/components/FilterType/Sql.vue'
import UsersTable from '@/core/infrastructure/components/FilterType/UsersTable.vue'

export default Vue.extend({
  name: 'QueryBuilderRule',

  props: {
    value: {
      type: Object,
      required: true
    },

    rules: {
      type: Array,
      default () {
        return []
      }
    },

    index: {
      type: Number,
      default: 0
    },

    labels: {
      type: Object,
      required: true
    },

    sizeControl: {
      type: String,
      default: 'mini'
    }
  },

  components: {
    DateInput,
    DatetimeInput,
    FlagInput,
    FloatInput,
    MultiInput,
    NumberInput,
    StringInput,
    TextInput,
    TimeInput,
    XrefInput,
    XrefMultiInput,

    CurrentTableField,
    ExternalTableField,
    Parameter,
    Sql,
    UsersTable
  },

  computed: {
    isValidRuleOperators () {
      return Array.isArray(this.rule.operators) && this.rule.operators.length > 0
    },

    rule () {
      return this.rules.find(rule => rule.id === this.model.field)
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.model)) {
          this.model = deepClone(value)
        }
      },
      deep: true
    },

    model: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', deepClone(value))
        }
      },
      deep: true
    }
  },

  data () {
    return {
      model: {
        field: 'attr_N_',
        field_type: 'type_field',
        filter_type: 'constant',
        operator: 'eq',
        value: null
      },
      selectedOperator: 'eq',
      xrefList: []
    }
  },

  beforeMount () {
    this.model = this.value
    this.selectedOperator = this.model.operator
  },

  async mounted () {
    await this.loadXrefList()
  },

  methods: {
    async loadXrefList () {
      if (['xref_field', 'xref_multi_field'].includes(this.rule.fieldTypeId)) {
        await this.$http
          .get(`${this.$config.api}/registryservice/xref/${this.rule.fieldId}/data`)
          .then((response) => {
            this.xrefList.push(...response.data)
          })
          .catch((error) => console.log(error))
      }
    },

    changeOperator (value) {
      const selectedOperator = this.rule.operators.find(operator => operator.id === value)

      if (['in', 'not_in'].includes(selectedOperator.id)) {
        if (typeof this.model.value !== 'string' && !Array.isArray(this.model.value)) {
          this.$set(this.model, 'value', [''])
        }

        if (typeof this.model.value === 'string') {
          this.$set(this.model, 'value', this.model.value.split('; '))
        }
      } else {
        if (Array.isArray(this.model.value)) {
          if (['TextInput'].includes(this.rule.inputType)) {
            this.$set(this.model, 'value', this.model.value.join('; '))
          } else if (['NumberInput', 'FloatInput'].includes(this.rule.inputType)) {
            this.$set(this.model, 'value', 0)
          } else {
            this.$set(this.model, 'value', null)
          }
        }
      }

      this.model.operator = selectedOperator.id
    },

    remove () {
      if ((Array.isArray(this.model.value) && this.model.value.length > 0) || (!Array.isArray(this.model.value) && this.model.value !== null)) {
        this.$confirm(
          this.$t('main.message.delete_rule'),
          this.$t('main.message_title.warning'),
          {
            confirmButtonText: this.$t('main.button.delete'),
            cancelButtonText: this.$t('main.button.cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('child-deletion-requested', this.index)
        })
      } else {
        this.$emit('child-deletion-requested', this.index)
      }
    }
  }
})
</script>
