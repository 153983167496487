<template>
  <el-menu
    ref="menu"
    class="logic_editor__menu"
    @open="openProperty"
    unique-opened
  >
    <el-scrollbar wrap-style="height: calc(100vh - 133px);">
      <el-submenu v-for="(property,ind) in properties" :key="property.type" :index="ind.toString()">
        <template slot="title">
          <i
            @click.stop="createProperty(property, ind)"
            size="medium"
            class="logicEditor_icon2 hover el-icon-circle-plus"
          ></i>
          {{ property.name }}
        </template>
        <el-menu-item
          :title="item.name.length > 15 ? item.name + ' id:'+item.id : ''"
          v-for="(item,i) in property.items"
          @click="editProperty(item, property)"
          :key="i"
          :index="ind.toString() + '-' + i.toString()"
        >
          <i
            @click.stop="removeProperty(item, property)"
            size="medium"
            class="logicEditor_icon2 hoverSubBtn el-icon-remove-outline"
          ></i>
          {{item.name.length > 15 ? item.name.substring(0, 15)+'...' : item.name}} <b>(id: {{item.id}})</b>
        </el-menu-item>
      </el-submenu>
    </el-scrollbar>
  </el-menu>
</template>

<script>

export default {
  props: {
    activeLogic: {
      type: Object
    },
    propslocal: {
      type: Object
    }
  },
  data () {
    return {
      // список свойств бизнес логики
      properties: [
        {
          name: this.$locale.logic_editor.action.column_formula,
          type: 'formulas',
          formulaType: 'column_formula',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.rows,
          type: 'rows',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.states,
          type: 'states',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.listeners,
          type: 'listeners',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.commands,
          type: 'commands',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.constraint,
          type: 'constraints',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.view_constraints,
          type: 'view_constraint',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.disable_constraints,
          type: 'disable_constraint',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.approvals,
          type: 'approvals',
          items: []
        },
        {
          name: this.$locale.logic_editor.action.relates,
          type: 'relates',
          items: []
        }
      ]
    }
  },
  methods: {
    createProperty (property, index) {
      this.propslocal.type = property.type
      this.propslocal.listOpenAction = []
      this.propslocal.isNewAction = true
      this.propslocal.listOpenAction = property
      this.propslocal.selectSubAction = property.items
      this.propslocal.getAnswer.id = Math.random()
      this.$refs.menu.activeIndex = `${index.toString()}-${this.propslocal.listOpenAction.items.length.toString()}`
    },
    removeProperty (item, property) {
      this.$confirm(
        this.$locale.main.message.confirm,
        this.$locale.main.message.attention,
        {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }
      ).then(async () => {
        try {
          let currentProperty = property.type
          if (property.type === 'view_constraint' || property.type === 'disable_constraint') currentProperty = 'constraints'
          await this.$http.delete(`${this.$config.api}/logiceditor/${currentProperty}/${item.id}`)
          if (this.propslocal.listOpenAction.items) {
            console.log('item есть')
            this.propslocal.listOpenAction.items.forEach((el, ind) => {
              if (el.id === item.id) this.propslocal.listOpenAction.items.splice(ind, 1)
            })
          } else {
            this.propslocal.listOpenAction.forEach((el, ind) => {
              console.log('item нет')
              if (el.id === item.id) this.propslocal.listOpenAction.splice(ind, 1)
            })
          }
        } catch (error) {
          console.log(error)
        }
      })
    },
    async editProperty (item, property) {
      let type = property.type
      this.propslocal.type = property.type
      this.propslocal.selectSubAction = item
      this.propslocal.isNewAction = false
      if (property.type === 'view_constraint' || property.type === 'disable_constraint') type = 'constraints'
      try {
        let res = await this.$http.get(`${this.$config.api}/logiceditor/${type}/${item.id}`)
        this.propslocal.getAnswer = res.data
      } catch (error) {
        console.log(error)
      }
    },
    async openProperty (key, keyPath) {
      this.propslocal.selectSubAction = this.properties[key]
      this.$refs.menu.activeIndex = null
      this.properties[key].items = this.propslocal.listOpenAction = await this.loadProperties(this.properties[key])
      this.$refs.menu.openedMenus[0] = key.toString()
    },
    async loadProperties (property) {
      let url = ''
      if (property.type === 'formulas') {
        // столбцовые формулы
        url = `${this.$config.api}/logiceditor/${property.type}?formula_type_id=column_formula&logic_id=${this.activeLogic.id}`
      } else if (property.type === 'constraints') {
        // ограничения
        let response = await this.loadConstraints()
        return response
      } else if (property.type === 'view_constraint' || property.type === 'disable_constraint') {
        // показ и скрытие полей, блокирование полей
        url = `${this.$config.api}/logiceditor/constraints?logic_id=${this.activeLogic.id}&constraint_type_id=${property.type}`
      } else {
        url = `${this.$config.api}/logiceditor/${property.type}?logic_id=${this.activeLogic.id}`
      }

      try {
        let response = await this.$http.get(url)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async loadConstraints () {
      let updateConstraint = await this.$http.get(`${this.$config.api}/logiceditor/constraints?logic_id=${this.activeLogic.id}&constraint_type_id=update_constraint`)
      let readConstraint = await this.$http.get(`${this.$config.api}/logiceditor/constraints?logic_id=${this.activeLogic.id}&constraint_type_id=read_constraint`)
      let deleteConstraint = await this.$http.get(`${this.$config.api}/logiceditor/constraints?logic_id=${this.activeLogic.id}&constraint_type_id=delete_constraint`)
      return [...updateConstraint.data, ...readConstraint.data, ...deleteConstraint.data]
    }
  }
}
</script>

<style scoped>
.logic_editor__menu {
  width: 110%;
}
.el-submenu__title:hover .hover {
  display: inline-block;
}
.el-submenu:hover .hover {
  display: block;
}
.el-menu-item:hover .hoverSubBtn {
  display: block;
}
.el-menu-item:hover .hover {
  display: block;
}
.el-menu-item {
  white-space: normal;
  overflow: hidden;
}
.hover,
.hoverSubBtn {
  display: none;
}
.logicEditor_icon2 {
  float: right;
  line-height: 40px;
  margin-right: 10%;
}
</style>
