




























import Vue from 'vue'

export default Vue.extend({
  name: 'FieldSelect',

  props: {
    value: [Number, Array],

    logicId: {
      type: Number,
      default: null
    },

    objectId: {
      type: Number,
      default: null
    },

    file: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },

  data () {
    return {
      loading: false,
      localValue: this.multiple ? this.value || [] : this.value || null,
      treeData: [],
      props: {
        isLeaf: 'leaf',
        label: 'name',
        disabled: (data, node) => {
          if (this.file) {
            return data.entity_type_id !== 'file_field'
          }

          return false
        }
      },
      filterText: ''
    }
  },

  async mounted () {
    try {
      this.loading = true

      if (!this.objectId) {
        const logic = await this.$http.get(`${this.$config.api}/logiceditor/logics/${this.logicId}`)
        const entityId = logic.data.entity_id
        const data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${entityId}&show_children=true`)
        this.treeData = data.data.data
        this.$refs.tree.setCheckedKeys(
          this.multiple ? this.localValue : [this.localValue]
        )
      } else {
        const data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${this.objectId}&show_children=true`)
        this.treeData = data.data.data
        this.$refs.tree.setCheckedKeys(
          this.multiple ? this.localValue : [this.localValue]
        )
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  },

  methods: {
    filterNode (value, data) {
      if (!value) {
        return true
      }

      return data.name.indexOf(value) !== -1
    },

    handleCheckChange (node, checked) {
      if (this.multiple && !Array.isArray(this.localValue)) {
        this.localValue = []
      }

      if (checked) {
        if (this.multiple) {
          this.localValue.push(node.id)
          this.$refs.tree.setCheckedKeys(this.localValue)
        } else {
          this.localValue = node.id
          this.$refs.tree.setCheckedKeys([this.localValue])
        }
      } else {
        if (this.multiple) {
          if (this.localValue.includes(node.id)) {
            this.localValue.splice(this.localValue.indexOf(node.id), 1)
          }
        } else {
          if (this.localValue === node.id) {
            this.localValue = null
          }
        }
      }

      this.$emit('input', this.localValue)
    }
  }
})
