<template>
  <div>
    <el-form-item v-if="!['area', 'line'].includes(type)">
      <el-checkbox :value="activeNode.label" @change="$set(activeNode, 'label', $event)">Лейбл</el-checkbox>
    </el-form-item>
    <el-form-item>
      <el-checkbox :value="activeNode.tooltip" @change="$set(activeNode, 'tooltip', $event)">Подсказки</el-checkbox>
    </el-form-item>
    <el-form-item v-if="type !== 'line'" label="Цвет">
      <el-input :value="activeNode.fill" @input="$set(activeNode, 'fill', $event)"></el-input>
    </el-form-item>
    <el-form-item v-else label="Цвет" style="width: 50%">
      <el-input :value="activeNode.lineColor" @input="$set(activeNode, 'lineColor', $event)"></el-input>
    </el-form-item>
  </div>
</template>

<script>

export default {
  name: 'configurator-sparkline',
  props: {
    activeNode: {
      type: Object,
      default () {
        return {
          label: '',
          tooltip: '',
          fill: '',
          lineColor: ''
        }
      }
    },
    type: {
      type: String
    }
  },
  data () {
    return {

    }
  },
  mounted () {
    console.log(this.activeNode)
  }
}
</script>

<style scoped>

</style>
