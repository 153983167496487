import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { LogicTreeElementDTO } from '@/services/LogicEditor/domain/model/LogicTreeElement'

export namespace LogicTreeElementAPI {
  export class GetLogicTreeElements implements APIRequest<LogicTreeElementDTO> {
    response: LogicTreeElementDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/logiceditor/logic_tree_elements?${new URLSearchParams(payload).toString()}`
    }
  }

  export class SearchLogicTreeElements implements APIRequest<Array<LogicTreeElementDTO>> {
    response: Array<LogicTreeElementDTO>;
    path = `/v2/logiceditor/logic_tree_elements/search`;
    method = HTTPMethod.POST;
    hideNotification = true;
    parse = (data: AxiosResponse) => data.data;
    constructor (public params: object) {}
  }

  export class GetLogicTreeElementsTree implements APIRequest<any> {
    response: any;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/logiceditor/logic_tree_elements/tree?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetLogicTreeElementByGuid implements APIRequest<LogicTreeElementDTO> {
    response: LogicTreeElementDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/logic_tree_elements/${guid}`
    }
  }

  export class GetLogicTreeElementByElementGuid implements APIRequest<LogicTreeElementDTO> {
    response: LogicTreeElementDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (elementGuid: string) {
      this.path = `/v2/logiceditor/logic_tree_elements/${elementGuid}/element`
    }
  }
}
