import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class LogicDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  entity_id?: Nullable<number>
  alias: Nullable<string>
  description: Nullable<string>

  constructor ({ id, guid, entity_id, alias, description }) {
    this.id = id
    this.guid = guid
    this.entity_id = entity_id
    this.alias = alias
    this.description = description
  }

  static create (): LogicDTO {
    return new LogicDTO({
      id: null,
      guid: null,
      entity_id: null,
      alias: null,
      description: null
    })
  }
}

export default class Logic extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private entityId: number
  private alias: Nullable<string>
  private description: Nullable<string>

  constructor (
    entityId: number,
    alias: Nullable<string>,
    description: Nullable<string>,
    id?: Nullable<number>,
    guid?: Nullable<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.entityId = entityId
    this.alias = alias
    this.description = description
  }

  static create (dto: LogicDTO): Logic {
    return new Logic(
      dto.entity_id,
      dto.alias,
      dto.description,
      dto.id,
      dto.guid
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getEntityId (): number {
    return this.entityId
  }

  setEntityId (entityId: number): void {
    this.entityId = entityId
  }

  setDescription (description: Nullable<string>): void {
    this.description = description
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }
}
