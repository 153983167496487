import { MutationTree } from 'vuex'
import { TimerState } from './state'
import { MutationTypes, TimerCreatedEvent, TimerUpdatedEvent, TimerDeletedEvent } from './mutation-types'

export const mutations: MutationTree<TimerState> = {
  [MutationTypes.ERROR]: (timer, error) => {
    timer.isLoading = false
    timer.error = error
  },
  [MutationTypes.STATE_LOADING_EVENT]: (timer) => {
    timer.isLoading = true
  },
  [MutationTypes.STATE_LOADED_EVENT]: (timer) => {
    timer.isLoading = false
  },
  [MutationTypes.STATE_CREATED_EVENT]: (timer, action: TimerCreatedEvent) => {
    timer.isLoading = false
    timer.location = action.location
  },
  [MutationTypes.STATE_UPDATED_EVENT]: (timer, action: TimerUpdatedEvent) => {
    timer.isLoading = false
  },
  [MutationTypes.STATE_DELETED_EVENT]: (timer, action: TimerDeletedEvent) => {
    timer.isLoading = false
  }
}
