import store from '@/store'
import TaskRegistryEntityCommandRepositoryInterface
  from '@/services/TaskEditor/domain/repository/TaskRegistryEntityCommandRepositoryInterface'
import TaskRegistryEntity from '@/services/TaskEditor/domain/model/TaskRegistryEntity'
import { UpdateTaskRegistryEntities }
  from '@/services/TaskEditor/infrastructure/store/modules/TaskRegistryEntity/action-types'

export default class TaskRegistryEntityCommandRepository implements TaskRegistryEntityCommandRepositoryInterface {
  update (items: Array<TaskRegistryEntity>): Promise<void> {
    return store.dispatch(new UpdateTaskRegistryEntities(items))
  }
}
