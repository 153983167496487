import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetTimerByGuid } from '@/services/LogicEditor/infrastructure/store/modules/Timer/action-types'
import TimerQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/TimerQueryRepositoryInterface'

export default class TimerQueryRepository extends AbstractQueryRepository implements TimerQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetTimerByGuid(guid))
  }
}
