
import conditionsMixin from '@/components/InterfaceEditor/components/conditions_mixin.js'

export default {
  mixins: [
    conditionsMixin
  ],
  provide () {
    return {
      getModel: this.getModel,
      getDashboardComponents: this.getRefs,
      getWindows: this.getWindows,
      getViewer: this.getViewer
    }
  },
  data () {
    return {
      viewer: false,
      blocks: [],
      activeConditionGuid: false,
      activeWindowGuid: null,
      conditions: [],
      windows: [],
      originalStructure: {},
      tabSettings: {},
      tabSettingsService: {},
      currentViewer: {},
      modalWindow: {
        show: false,
        width: undefined,
        height: undefined,
        model: {},
        title: undefined,
        isPopover: false,
        position: {
          x: 0,
          y: 0
        }
      },
      requiredComponents: [],
      blockSettings: {
        hiddenConditions: {},
        blockIsHidden: null
      }
    }
  },
  watch: {
    blockHiddenConditions: {
      handler (value) {
        for (let key in value) {
          if (value.hasOwnProperty(key)) {
            this.blockSettings.blockIsHidden = this.currentViewer.getStore().getByGuid(key)
            const block = this.currentViewer.getStore().getByGuid(key)
            if (block && block.isHidden !== value[key]) {
              block.isHidden = value[key]
            }
            this.findHiddenComponents(block)
          }
        }
      },
      deep: true
    },
    model: {
      handler () {
        this.updateTabSettingService()
      },
      deep: true
    },
    tabSettings: {
      handler () {
        this.$nextTick(() => {
          this.updateTabSettingService()
        })
      },
      deep: true
    }
  },
  methods: {
    // убрать "обязательность заполнения поля", если контейнер скрыт и полей не видно
    findHiddenComponents (block) {
      if (Array.isArray(block?.children) && block.children.length > 0) {
        block.children.forEach(item => {
          for (let key in this.components) {
            if (this.components[key].guid === item.guid) {
              this.components[key].properties['isHiddenFromBlock'] = this.blockSettings.blockIsHidden.isHidden
            }
          }
          if (Array.isArray(item.children) && item.children.length > 0) {
            this.findHiddenComponents(item)
          }
        })
      }
    },
    getViewer () {
      return this
    },
    getWindows () {
      return this.windows
    },
    getRefs () {
      let refs = {}

      for (let key in this.$refs) {
        if (this.$refs.hasOwnProperty(key)) {
          refs[key] = [this.$refs[key]]
        }
      }

      return refs
    },
    getModel (component) {
      if (component && typeof component.replicationGuid !== 'undefined' && typeof component.replicationIndex !== 'undefined') {
        const replicationModel = (this.replicationData[component.replicationGuid] || {})[component.replicationIndex] || {}
        return Object.assign({}, this.model, replicationModel)
      }

      return this.model
    },
    loadInterface (structure, isCondition = false, isWindow = false) {
      this.$refs.designer.setBlocks([])
      this.requiredComponents = []
      this.components = {}
      this.settings = {}
      if (structure.windows && structure.windows.length > 0) {
        this.windows = structure.windows
      }
      if (!isCondition && !isWindow) {
        this.originalStructure = structure
      }
      if (!isCondition && structure.conditions && structure.conditions.length > 0) {
        this.conditions = structure.conditions
        if (this.viewer) {
          this.activeConditionGuid = this.actualConditionGuid
          if (this.activeConditionGuid) {
            const condition = this.conditions.find((item) => item.guid === this.activeConditionGuid)
            this.loadInterface(condition.structure, true)
            return false
          }
        }
      }
      if (structure.blocks) {
        if (!isCondition && !isWindow) {
          this.blocks = structure.blocks
        }
        if (this.viewer) {
          this.$refs.designer.setBlocks(this.prepareForReplication(structure.blocks))
        } else {
          this.$refs.designer.setBlocks(structure.blocks)
        }
      }

      let components = structure.components
      if (components && Object.keys(components).length > 0) {
        if (!isCondition && !isWindow) {
          this.originalComponents = components
        }
        for (let key in components) {
          if (components.hasOwnProperty(key)) {
            components[key].type = () => import(`@/components/InterfaceEditor/components/${components[key].group}/${components[key].initialType}.vue`)
            if (['always', 'condition'].includes(components[key].properties?.isRequired?.type)) {
              this.requiredComponents.push(components[key])
            }
          }
        }
        this.components = components
      }

      if (structure.settings && Object.keys(structure.settings).length > 0) {
        this.settings = structure.settings
        if (!isCondition && !isWindow) {
          this.originalSettings = structure.settings
        }
      }

      if (structure.tabSettings && Object.keys(structure.tabSettings).length > 0) {
        this.$set(this, 'tabSettings', structure.tabSettings)
      }
      if (structure.blockSettings?.hiddenConditions && Object.keys(structure.blockSettings.hiddenConditions).length > 0) {
        this.$set(this.blockSettings, 'hiddenConditions', structure.blockSettings.hiddenConditions)
      }
    },
    checkType (guid) {
      return typeof this._components[guid] !== 'undefined'
    },
    updateTabSettingService () {
      if (!this.tabSettings || !this.tabSettingsService) {
        return
      }

      for (const tabGuid in this.tabSettings) {
        const hiddenConditions = this.tabSettingsService.getHiddenConditions(tabGuid)
        const blockedConditions = this.tabSettingsService.getBlockedConditions(tabGuid)
        const styledConditions = this.tabSettingsService.getStyledConditions(tabGuid)

        if (hiddenConditions && Object.keys(hiddenConditions).length > 0) {
          this.tabSettingsService.setIsHidden(tabGuid, this.checkConditions(hiddenConditions))
        }

        if (blockedConditions && Object.keys(blockedConditions).length > 0) {
          this.tabSettingsService.setIsBlocked(tabGuid, this.checkConditions(blockedConditions))
        }

        if (styledConditions && Object.keys(styledConditions).length > 0) {
          this.tabSettingsService.setIsStyled(tabGuid, this.checkConditions(styledConditions))
        }
      }
    }
  },
  computed: {
    blockHiddenConditions () {
      let object = {}
      for (let key in (this.blockSettings?.hiddenConditions || {})) {
        if (this.blockSettings.hiddenConditions.hasOwnProperty(key)) {
          object[key] = this.checkConditions(this.blockSettings.hiddenConditions[key])
        }
      }

      return object
    },
    actualConditionGuid () {
      if (this.conditions.length > 0) {
        return (this.conditions.filter((item) => item.maxWidth > this.width).sort(function (a, b) {
          return a.maxWidth - b.maxWidth
        })[0] || {}).guid || null
      }

      return null
    },
    settingStyle () {
      let style = {}
      if (this.settings.urlImage) {
        if (this.settings.urlImage.startsWith('http')) {
          style['background-image'] = `url(${this.settings.urlImage})`
        } else {
          style['background-image'] = `url(${this.$config.api}${this.settings.urlImage})`
        }
        style['background-size'] = this.settings.fitImage
      }
      if (this.settings.backgroundColor) {
        style['background-color'] = this.settings.backgroundColor
      }
      if (this.activeConditionGuid && !this.viewer) {
        style['max-width'] = this.designerWidth
        style['margin'] = 'auto'
      }
      if (this.activeWindowGuid && !this.viewer) {
        style['max-height'] = this.designerHeight
        style['max-width'] = this.designerWidth
        style['margin'] = 'auto'
      }

      return style
    },
    userCssClass () {
      if (this.settings.CSS) {
        return this.settings.CSS
      }
      return ''
    }
  }
}
