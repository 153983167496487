import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import RegistryRightQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/RegistryRightQueryRepositoryInterface'
import store from '@/store'
import { GetRegistryRights } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'

export default class RegistryRightQueryRepository extends AbstractQueryRepository implements RegistryRightQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetRegistryRights(payload));
    }

}