import { Nullable } from '@/core/domain/type/types'

export default class UserUpdateCommand
{
    private guid: string;
    private roleId: number;
    private name: string;
    private midname: Nullable<string>;
    private surname: Nullable<string>;
    private avatar: Nullable<File>;
    private isAvatarDeleted: Nullable<boolean>;
    private login: Nullable<string>;
    private password: Nullable<string>;
    private email: Nullable<string>;
    private isAdmin: Nullable<boolean>;
    private isBlocked: Nullable<boolean>;
    private isSystem: Nullable<boolean>;
    private apiKey: Nullable<string>;
    private systemIps: Array<string>;
    private phone: Nullable<number>;
    private twoFactorAuthType: Nullable<string>;

    constructor(
        guid: string,
        roleId: number,
        name: string,
        midname: Nullable<string>,
        surname: Nullable<string>,
        avatar: Nullable<File>,
        isAvatarDeleted: Nullable<boolean> = false,
        login: Nullable<string>,
        password: Nullable<string>,
        email: Nullable<string>,
        isAdmin: Nullable<boolean> = false,
        isBlocked: Nullable<boolean> = false,
        isSystem: Nullable<boolean> = false,
        apiKey: Nullable<string>,
        systemIps: Array<string>,
        phone: Nullable<number>,
        twoFactorAuthType: Nullable<string>
    ) {
        this.guid = guid;
        this.roleId = roleId;
        this.name = name;
        this.midname = midname;
        this.surname = surname;
        this.avatar = avatar;
        this.isAvatarDeleted = isAvatarDeleted;
        this.login = login;
        this.password = password;
        this.email = email;
        this.isAdmin = isAdmin;
        this.isBlocked = isBlocked;
        this.isSystem = isSystem;
        this.apiKey = apiKey;
        this.systemIps = systemIps;
        this.phone = phone;
        this.twoFactorAuthType = twoFactorAuthType;
    }

    getClassName(): string
    {
        return 'UserUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getRoleId(): number
    {
        return this.roleId;
    }

    getName(): string
    {
        return this.name;
    }

    getMidname(): Nullable<string>
    {
        return this.midname;
    }

    getSurname(): string
    {
        return this.surname;
    }

    getAvatar(): Nullable<File>
    {
        return this.avatar;
    }

    getIsAvatarDeleted(): boolean
    {
        return this.isAvatarDeleted;
    }

    getLogin(): string
    {
        return this.login;
    }

    getPassword(): string
    {
        return this.password;
    }

    getEmail(): Nullable<string>
    {
        return this.email;
    }

    getIsAdmin(): boolean
    {
        return this.isAdmin;
    }

    getIsBlocked(): boolean
    {
        return this.isBlocked;
    }

    getIsSystem(): boolean
    {
        return this.isSystem;
    }

    getApiKey(): string
    {
        return this.apiKey;
    }

    getSystemIps(): Array<string>
    {
        return this.systemIps;
    }

    getPhone(): Nullable<number>
    {
        return this.phone;
    }

    getTwoFactorAuthType(): Nullable<string>
    {
        return this.twoFactorAuthType;
    }
}
