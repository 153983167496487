<template>
  <div class="bubble"
  :class="{'openCard' : val.notification[0].interaction_type}" @click="openCard(val)"
  v-loading="loading">
    <span class="title_messages">
      {{val.title}}
    </span>
    <div class="bubble__inner">
      <div class="bubble__avatar">
        <div class="avatar">
          <el-avatar fit="cover" v-if="this.avatarUser != null" size="medium" :src="this.$config.api + '/files/' + this.avatarUser.guid + '.'+ this.avatarUser.extension"></el-avatar>
          <i v-else-if="icon" :class="icon" style="font-size: 36px"></i>
          <el-avatar v-else size="medium" src=""></el-avatar>
        </div>
      </div>
        <div class="bubble__content">
          <!-- <div class="bubble-message" v-html="val.content.replace(/<a.+<\/a>/g, '').length > 125 ? val.content.substring(0, 125)+'...' : val.content"></div> -->
          <div class="bubble-message" v-html="val.content.split('<br>')[0]"></div>
        </div>
      </div>
  </div>
</template>

<script>
// Всплывашка
import store from '@/store/index'
import { getCardId } from '@/helpers'
import mixinsForNatification from '@/components/System/Notification/mixins/index.js'
export default {
  name: 'MessageNotify',
  mixins: [mixinsForNatification],
  props: ['val', 'addMainTab'],
  data () {
    return {
      avatarUser: null,
      icon: null,
      interactionType: null,
      loading: false
    }
  },
  methods: {
    // Открыть карточку внешнего реестра
    async openCardOuter (data, dataRegistryByRecord) {
      let { openCard, readOnly, cardId, recordXref } = await this.validationData(data, dataRegistryByRecord)
      if (!openCard) return
      // получить id карточки внешнего реестра
      const card = (cardId) ? { id: cardId } : await getCardId(this, { registryId: openCard.external_object_id, recordId: recordXref })
      this.addMainTab({
        name: card.name || 'Новая карточка',
        componentType: 'RegistryCard',
        payload: {
          cardId: card.id,
          initialData: {},
          readonly: readOnly,
          recordId: recordXref,
          registryId: openCard.external_object_id
        }
      })
      this.loading = false
    },
    async openCard (data) {
      this.$parent.close()
      store.commit('Notify/readMsg', data.id)
      let dataRegistryByRecord = await this.getDataRegistryByRecord({ registryId: data.notification[0].object_id, recordId: data.record_id })
      if (dataRegistryByRecord.is_deleted) {
        this.$message.error(`Запись удалена из системы`)
        return
      }
      if (!data.notification[0].interaction_type) return false
      // Открыть карточку внешнего реестра
      if (data.notification[0].interaction_type === 'open_external_card') {
        this.openCardOuter(data, dataRegistryByRecord)
        return
      }
      this.loading = true
      let card = {}
      try {
        card = await getCardId(this, { registryId: data.notification[0].object_id, recordId: data.record_id })
      } catch (error) {
        console.log({ error })
        this.loading = false
        return
      }
      this.addMainTab({
        name: card.name,
        componentType: 'RegistryCard',
        payload: {
          cardId: card.id,
          initialData: {},
          // readonly: true,
          recordId: data.record_id,
          registryId: data.notification[0].object_id
        }
      })
      this.loading = false
    }
  },
  async created () {
    try {
      let res = await this.$http.get(`${this.$config.api}/notificationeditor/notifications/${this.val.notification_id}`)
      this.icon = res.data.icon
      this.interactionType = res.data.interaction_type
      if (this.val.author[0] && this.val.author[0].avatar_id) {
        let response = await this.$http.get(`${this.$config.api}/registryservice/files/${this.val.author[0].avatar_id}`)
        this.avatarUser = response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style scoped>
.bubble {
  cursor: pointer;
}
.bubble__inner {
  display: flex;
  width: 265px;
  box-sizing: border-box;
  padding: 12px;
  padding-left: 0px;
  padding-right: 0px;
}
.bubble__avatar {
  margin-right: 10px;
}
.bubble__content {
  line-height: 15px;
}
.bubble-message {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #242A32;
}
.bubble__misc {
  margin-top: 4px;
}
.title_messages {
  font-size: 15px;
  font-weight: bold;
  color: #242A32;
}
.el-notification__content .bubble  {
  text-align: left;
}
.openCard:hover {
  cursor: pointer;
  background: rgba(52, 71, 97, 0.1);
}
</style>
