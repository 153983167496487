import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RegistryRightsCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/RegistryRightsCommandRepositoryInterface'
import RegistryRightsUpdateCommand from '@/services/AccessEditor/application/command/RegistryRightsUpdateCommand'
import { RegistryRightDTO } from '@/services/AccessEditor/domain/model/Right/RegistryRight'

export default class RegistryRightsUpdateHandler implements CommandHandlerInterface
{
    private repo: RegistryRightsCommandRepositoryInterface;

    constructor(repo: RegistryRightsCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: RegistryRightsUpdateCommand): Promise<void>
    {
        let item: RegistryRightDTO = {
            role_guid: command.getRoleGuid(),
            entity_id: command.getEntityId(),
            is_addable: command.getIsAddable(),
            is_readable: command.getIsReadable(),
            is_editable: command.getIsEditable(),
            is_deletable: command.getIsDeletable()
        };
        return this.repo.update(item);
    }
}