<template>
  <el-form label-width="120px" label-position="top" size="mini" style="padding: 10px">
    <!-- Все без группировки -->
    <!-- <div v-for="(property, index) in properties" :key="index">
      <span v-if="isShowPropertyLabel(property)" class="property_label">
        {{ property.label }}
      </span>
      <el-form-item :style="'margin-bottom: 5px;' + (property.type === 'editorCheckbox' ? 'display:inline-block;' : '')">
        <component
          v-bind="property.bind"
          :is="property.type"
          :label="property.label"
          @change="$emit('change', { name: property.name, value: $event })"
        ></component>
      </el-form-item>
    </div> -->

    <!-- Группы -->
    <el-collapse v-if="Object.keys(propertyGroups).length" v-model="activeName">
      <el-collapse-item v-for="(group, groupKey) in propertyGroups" :key="groupKey" :name="groupKey" :title="group.label">
        <!-- Свойство -->
        <el-row v-for="(property, index) in group.properties" v-show="!property.bind.hidden" :key="index">
          <!-- Надпись -->
          <span v-if="isShowPropertyLabel(property)" class="property_label">
            {{ property.label }}
          </span>
          <!-- Поле -->
          <el-form-item :style="'margin-bottom: 5px;' + (property.type === 'editorCheckbox' ? 'display:inline-block;' : '')">
            <component
              v-bind="property.bind"
              :is="property.type"
              :label="property.label"
              :registry-fields="registryFields"
              @change="$emit('change', { name: property.name, value: $event })"
              @open-modal-window-by-configurator="$emit('open-modal-window-by-configurator', $event)"
            ></component>
          </el-form-item>
        </el-row>
      </el-collapse-item>
    </el-collapse>

    <!-- Не группированные -->
    <div v-for="(property, index) in notGroupedProperties" :key="index">
      <div v-show="!property.bind.hidden">
        <!-- Надпись -->
        <span v-if="isShowPropertyLabel(property)" class="property_label">
          {{ property.label }}
        </span>
        <!-- Поле -->
        <el-form-item :style="'margin-bottom: 5px;' + (property.type === 'editorCheckbox' ? 'display:inline-block;' : '')">
          <component
            v-bind="property.bind"
            :is="property.type"
            :label="property.label"
            @change="$emit('change', { name: property.name, value: $event })"
          ></component>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import Vue from 'vue'
import EditorCheckbox from '@/components/InterfaceEditor/components/editor/editor-checkbox'
import EditorString from '@/components/InterfaceEditor/components/editor/editor-string'
import EditorRegistryHeaders from '@/components/InterfaceEditor/components/editor/editor-registry-headers'
import EditorFitImage from '@/components/InterfaceEditor/components/editor/editor-fit-image'
import EditorPlugins from '@/components/InterfaceEditor/components/editor/editor-plugins'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
import EditorColorPicker from '@/components/InterfaceEditor/components/editor/editor-color-picker'
import EditorSize from '@/components/InterfaceEditor/components/editor/editor-size'
// import EditorRegistrySelect from '@/components/InterfaceEditor/components/editor/editor-registry-select'
import EditorTable from '@/components/InterfaceEditor/components/editor/editor-table'
import EditorButtonAction from '@/components/InterfaceEditor/components/editor/editor-button-action'
import EditorConditions from '@/components/InterfaceEditor/components/editor/editor-conditions'
import EditorNumber from '@/components/InterfaceEditor/components/editor/editor-number'
import EditorLink from '@/components/InterfaceEditor/components/editor/editor-link-image.vue'
import EditorFilters from '@/components/InterfaceEditor/components/editor/editor-filters'
import EditorOptions from '@/components/InterfaceEditor/components/editor/editor-options'
import EditorBgCard from '@/components/InterfaceEditor/components/editor/editor-background-card'
import EditorShowButton from '@/components/InterfaceEditor/components/editor/editor-show-button'
import EditorXrefAlternativeSource from '@/components/InterfaceEditor/components/editor/editor-xref-alternative-source'
import EditorColorDonutchart from '@/components/InterfaceEditor/components/editor/chart/editor-color-donutchart.vue'
import editorLegendChart from '@/components/InterfaceEditor/components/editor/chart/editor-legend-chart.vue'
import editorTitleChart from '@/components/InterfaceEditor/components/editor/chart/editor-title-chart.vue'
import editorChart from '@/components/InterfaceEditor/components/editor/chart/editor-chart'
import EditorSource from '@/components/InterfaceEditor/components/editor/editor-source'
import EditorDropdownMenu from '@/components/InterfaceEditor/components/editor/editor-dropdown-menu'
import EditorControlValues from '@/components/InterfaceEditor/components/editor/editor-control-values'
import EditorTasksSource from '@/components/InterfaceEditor/components/editor/editor-tasks-source'
import EditorYandexMaps from '@/components/InterfaceEditor/components/editor/editor-yandex-maps'
import EditorTasksGroups from '@/components/InterfaceEditor/components/editor/editor-tasks-groups'
import EditorHtmlDesign from '@/components/InterfaceEditor/components/editor/editor-html-design'
import EditorTasksChangeInteractive from '@/components/InterfaceEditor/components/editor/editor-tasks-change-interactive'
import EditorAccordionSource from '@/components/InterfaceEditor/components/editor/editor-accordion-source'
import EditorCards from '@/components/InterfaceEditor/components/editor/editor-cards'
import EditorCalendar from '@/components/InterfaceEditor/components/editor/editor-calendar.vue'
import EditorStages from '@/components/InterfaceEditor/components/editor/editor-stages.vue'
import EditorList from '@/components/InterfaceEditor/components/editor/editor-list.vue'
import EditorTree from '@/components/InterfaceEditor/components/editor/editor-tree.vue'
import EditorFrames from '@/components/InterfaceEditor/components/editor/editor-frames'
import Columns from '@/components/InterfaceEditor/components/editor/Columns/index'
import MapLayers from '@/components/InterfaceEditor/components/editor/MapLayers/index'
import EditorAnalyticTableSource from '@/components/InterfaceEditor/components/editor/editor-analytic-table-source'
import MapTools from '@/components/InterfaceEditor/components/editor/MapTools/index'
import MapCoordinateSystems from '@/components/InterfaceEditor/components/editor/MapCoordinateSystems/index'
import FeatureMetrics from '@/components/InterfaceEditor/components/editor/FeatureMetrics/index'
import EditorDashboards from '@/components/InterfaceEditor/components/editor/editor-dashboards'
import EditorShowButtonAgGrid from '@/components/InterfaceEditor/components/editor/editor-show-button-agGrid'
import EditorCardAutocomplete from '@/components/InterfaceEditor/components/editor/editor-card-autocomplete'
import EditorIcon from '@/components/InterfaceEditor/components/editor/editor-icon.vue'
import EditorRemoteList from '@/components/InterfaceEditor/components/editor/editor-remote-list.vue'
import EditorOptimizeColumnsLoad from '@/components/InterfaceEditor/components/editor/editor-optimize-columns-load.vue'
import EditorInputType from '@/components/InterfaceEditor/components/editor/editor-input-type.vue'
import EditorInputLabel from '@/components/InterfaceEditor/components/editor/editor-input-label.vue'
import EditorGanttSource from '@/components/InterfaceEditor/components/editor/editor-gantt-source'
import EditorAddExisting from '@/components/InterfaceEditor/components/editor/editor-add-existing.vue'
import EditorXrefAutocomplete from '@/components/InterfaceEditor/components/editor/editor-xref-autocomplete.vue'
// Дерево выбора атрибута
import EditorTreeAttr from '@/components/InterfaceEditor/components/editor/editor-tree-attr.vue'
// Выбор источника базовых компонентов
import EditorDataSource from '@/components/InterfaceEditor/components/editor/editor-data-source.vue'
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
// Основные настройки выпадающего списка
import EditorSelectNew from '@/components/InterfaceEditor/components/editor/editor-select-new.vue'
// Настройки компонента iframe
import EditorIframe from '@/components/InterfaceEditor/components/editor/editor-iframe.vue'

import ComponentSettingsJson from './ComponentSettings.json'

export default Vue.extend({
  name: 'ComponentSettings',
  components: {
    EditorCheckbox,
    EditorString,
    EditorRegistryHeaders,
    EditorFitImage,
    EditorPlugins,
    EditorSelect,
    EditorColorPicker,
    EditorSize,
    // EditorRegistrySelect,
    EditorTable,
    EditorButtonAction,
    EditorConditions,
    EditorNumber,
    EditorLink,
    EditorFilters,
    EditorOptions,
    EditorBgCard,
    EditorShowButton,
    EditorXrefAlternativeSource,
    EditorColorDonutchart,
    editorLegendChart,
    editorTitleChart,
    editorChart,
    EditorSource,
    EditorDropdownMenu,
    EditorControlValues,
    EditorTasksSource,
    EditorYandexMaps,
    EditorTasksGroups,
    EditorHtmlDesign,
    EditorTasksChangeInteractive,
    EditorAccordionSource,
    EditorCards,
    EditorCalendar,
    EditorStages,
    EditorList,
    EditorTree,
    EditorFrames,
    Columns,
    EditorAnalyticTableSource,
    MapLayers,
    MapTools,
    FeatureMetrics,
    MapCoordinateSystems,
    EditorDashboards,
    EditorShowButtonAgGrid,
    EditorCardAutocomplete,
    EditorIcon,
    EditorRemoteList,
    EditorOptimizeColumnsLoad,
    EditorInputType,
    EditorInputLabel,
    EditorGanttSource,
    EditorAddExisting,
    EditorXrefAutocomplete,
    EditorTreeAttr,
    EditorDataSource,
    RegistrySelect,
    EditorSelectNew,
    EditorIframe
  },
  mixins: [
    ComponentSettingsJson
  ],
  props: {
    properties: {
      type: Array,
      default: () => []
    },
    componentName: {
      type: String
    },
    registryFields: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      propertyGroups: {},
      notGroupedProperties: [],
      activeName: ''
    }
  },
  watch: {
    componentName () {
      this.parseProperties()
    },
    properties: {
      handler () {
        this.parseProperties()
      },
      deep: true
    }
  },
  mounted () {
    this.parseProperties()
  },
  methods: {
    parseProperties () {
      if (!this.componentName) {
        this.propertyGroups = {}
        this.notGroupedProperties = []
        return
      }

      const properties = this.properties || []
      const componentSettings = JSON.parse(JSON.stringify(ComponentSettingsJson))
      const groupsSettings = componentSettings.groups || {}

      this.notGroupedProperties = []

      // Настройки компонента
      // регулярное выражение для исключения AnalyticTable/index
      let componentName = this.componentName.replace(/\/index/, '')
      const componentSetting = ComponentSettingsJson.components[componentName]
      if (!componentSetting) {
        // Компонент не найден в ComponentSettings.json
        console.warn('%c%s', 'color: red;', `Компонент ${componentName} не найден в ComponentSettings.json`)
        this.propertyGroups = {}
        this.notGroupedProperties = properties
        return
      }
      // Свойства компонента
      const componentPropertiesSetting = componentSetting.properties
      if (!componentPropertiesSetting) {
        // Компонент не найден в ComponentSettings.json
        console.warn('%c%s', 'color: red;', `Элемент properties компонента ${componentName} не найден в ComponentSettings.json`)
        this.propertyGroups = {}
        this.notGroupedProperties = properties
        return
      }

      // Валидировать настройки свойств компонента из ComponentSettings.json (Необязательно. Отладка мэпинга)
      this.validateUnnecessaryProperties(componentPropertiesSetting, properties, componentName)

      // Сгруппировать свойства компонента
      properties.forEach(property => {
        // Настройки свойства компонента
        if (!componentPropertiesSetting[property.name]) {
          // Настройки свойства не найдены в ComponentSettings.json
          console.warn('%c%s', 'color: red;', `${property.name} компонента ${componentName} не найден в ComponentSettings.json`)
          this.notGroupedProperties.push(property)
          return
        }
        const componentPropertySetting = componentPropertiesSetting[property.name]

        // Сопоставить настройку группы свойства компонента с возможной группой в элементе groups в ComponentSettings.json
        const group = groupsSettings[componentPropertySetting.group]
        if (!group) {
          // Настройка группы свойства компонента не найдена в списке возможных групп.
          console.warn('%c%s', 'color: red;', `${property.name} компонента ${componentName} с группой ${componentPropertySetting.group} не найден в списке возможных групп ComponentSettings.json`)
          this.notGroupedProperties.push(property)
          return
        }
        if (!Array.isArray(group.properties)) {
          // Контейнер свойств в группе
          group.properties = []
        }
        // Добавить свойство в группу
        group.properties.push(property)

        // Настройка свойства компонента нашла свойство в компоненте
        // console.warn('%c%s', 'color: lime;', `${property.name} компонента ${componentName} с группой ${componentPropertySetting.group} найден в списке возможных групп ComponentSettings.json`)
      })

      // Заполнить группы
      for (const groupSettingsKey in groupsSettings) {
        const groupSettings = groupsSettings[groupSettingsKey]

        if (!Array.isArray(groupSettings.properties) || !groupSettings.properties.length) {
          // Убрать группу без свойств
          delete groupsSettings[groupSettingsKey]
          continue
        }

        // Надписи групп
        groupSettings.label = this.$locale.interface_editor.property_groups[groupSettingsKey]
      }

      // Раскрыть первую группу
      if (!this.activeName) {
        this.activeName = Object.keys(groupsSettings)[0]
      }

      this.propertyGroups = groupsSettings
    },
    /**
     * [Для отладки мэпинга ComponentSettings.json]
     * Валидировать настройки свойств компонента в ComponentSettings.json
     * - Проверить лишние свойства в ComponentSettings.json, которых нет в компоненте
     */
    validateUnnecessaryProperties (componentPropertiesSetting, properties, componentType) {
      for (const componentPropertySettingKey in componentPropertiesSetting) {
        if (!properties.find(property => property.name === componentPropertySettingKey)) {
          // Настройка свойства компонента не нашла свойства в компоненте.
          console.warn('%c%s', 'color: red;', `Настройка свойства ${componentPropertySettingKey} компонента ${componentType} не нашла свойства в компоненте`)
        }
      }
    },
    /**
     * - Для реализации отображения надписи внутри компонента
     */
    isShowPropertyLabel (property) {
      return !['editorString', 'editorCheckbox', 'editorInputType', 'registrySelect', 'editorNumber', 'editorSelect'].includes(property.type)
    }
  }
})
</script>

<style scoped>
* /deep/ .el-collapse-item__header {
  font-size: 1.2em;
}
</style>
