import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import UserQueryRepositoryInterface from '../../../domain/repository/UserQueryRepositoryInterface'
import UserPasswordRulesQuery from '../../query/UserPasswordRulesQuery'

export default class UserPasswordRulesHandler implements QueryHandlerInterface
{
    private repo: UserQueryRepositoryInterface;

    constructor(repo: UserQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: UserPasswordRulesQuery): Promise<any>
    {
        return this.repo.getPasswordRules();
    }
}