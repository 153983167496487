<template>
  <el-collapse class="editor-wrapper" value="editor-input-label">
    <el-collapse-item :title="$locale.interface_editor.component_editor.button_action.label" name="editor-input-label">
      <template slot="title">
        <span class="editor-property-label">{{ label ? label : $locale.interface_editor.component_editor.button_action.label }}</span>
        <!-- <el-tooltip placement="top-start" :open-delay="400">
          <div slot="content" v-html="''"></div>
          <i class="el-icon-question"></i>
        </el-tooltip> -->
      </template>

      <el-form label-width="120px" label-position="top" size="mini" class="editor_button">
        <!-- <el-form-item style="margin-top: 10px">
          <span class="property_label">{{ $locale.notify_editor.label.choose_icon }}</span>
          <el-select clearable size="mini" v-model="value.icon">
            <el-option
              v-for="item in icons"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right;">
                <i style="font-size: 30px;width: 22px;height: 22px;" :class="item.value"></i>
              </span>
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.action }}</span>
          <editor-select
            :value="value.type"
            @change="$set(value, 'type', $event)"
            :options="{
              multiple: false,
              clearable: true,
              options: [
                { id: 'open_dashboard', name: $locale.interface_editor.component_editor.button_action.open_dashbord },
                { id: 'execute_plugin', name: $locale.interface_editor.component_editor.button_action.start_plugin },
                { id: 'open_report', name: $locale.interface_editor.component_editor.button_action.open_report },
                { id: 'open_document', name: $locale.interface_editor.component_editor.button_action.open_document },
                { id: 'open_xml', name: $locale.interface_editor.component_editor.button_action.open_xml },
                { id: 'execute_command', name: $locale.interface_editor.component_editor.button_action.execute_command },
                { id: 'open_card', name: $locale.interface_editor.component_editor.button_action.open_card },
                { id: 'save_card', name: $locale.interface_editor.component_editor.button_action.save_card, hidden: !this.getRegistryId() },
                { id: 'close_card', name: $locale.interface_editor.component_editor.button_action.close_card, hidden: !this.getRegistryId() },
                { id: 'close_dashboard', name: $locale.interface_editor.component_editor.button_action.close_dashboard, hidden: this.getRegistryId() },
                { id: 'open_history', name: $locale.interface_editor.component_editor.button_action.open_history, hidden: !this.getRegistryId() },
                { id: 'open_url', name: $locale.interface_editor.component_editor.button_action.open_url },
                { id: 'refresh_card', name: $locale.interface_editor.component_editor.button_action.refresh_card },
                { id: 'etl_export', name: $locale.interface_editor.component_editor.button_action.etl_export },
                { id: 'open_window', name: $t('interface_editor.component_editor.button_action.open_window')},
                { id: 'refresh_replication', name: $t('interface_editor.component_editor.button_action.refresh_replication')},
                { id: 'etl_import', name: $t('interface_editor.component_editor.button_action.execute_import')}
              ]
            }"
          ></editor-select>
        </el-form-item>

        <template v-if="value.type === 'open_card'">
          <editor-open-card
            :value="value"
            :is-headers="isHeaders"
            :source-type="sourceType"
            :source-id="sourceId"
            :is-button="isButton"
          >
          </editor-open-card>
        </template>

        <el-form-item v-if="(value.type === 'open_card' && (value.card.type === 'add' || value.card.type === 'update')) || value.type === 'etl_import'">
          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.refresh_selected_components }}</span>
          <el-select v-model="value.card.componentsGuid" clearable multiple>
            <el-option
              v-for="(item, index) in components"
              :key="index"
              :label="item.group === 'basic' ? item.properties.editorAlias : item.properties.name"
              :value="item.guid">
              <span style="float: left">{{ item.initialType }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group === 'basic' ? item.properties.editorAlias : item.properties.name }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="value.type === 'open_card'">
          <el-checkbox v-model="value.card.isFastCard"/>
          <span class="property_label" style="display: inline-block">{{ $locale.interface_editor.component_editor.button_action.fast_card }}</span>
        </el-form-item>

        <template v-if="value.type === 'execute_command'">
          <el-form-item>
            <span class="property_label">{{ $locale.logic_editor.command.headline }}</span>
            <el-select
              :value="value.command.id"
              value-key="id"
              filterable
              @change="$set(value.command ,'id', $event )"
            >
              <el-option
                v-for="(item, index) in commands"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="value.type === 'execute_command'">
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.success_text }}</span>
            <el-input v-model="value.command.success_text"/>
          </el-form-item>

          <el-form-item v-if="value.type === 'execute_command'">
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.failure_text }}</span>
            <el-input v-model="value.command.failure_text"/>
          </el-form-item>
        </template>

        <el-form-item v-if="value.type === 'execute_command' || value.type === 'execute_plugin'">
          <span class="property_label">{{ $locale.interface_editor.component_editor.table.plugin }}</span>
          <editor-plugins :value="value.plugin" @change="$set(value ,'plugin', $event)"></editor-plugins>

          <el-checkbox v-model="value.command.is_card_close"/>
          <span class="property_label" style="display: inline-block">{{ $locale.interface_editor.component_editor.button_action.close_card }}</span>
        </el-form-item>

        <el-form-item v-if="(value.command || {}).is_card_close">
          <editor-select
            :value="value.command.close_type"
            @change="$set(value.command ,'close_type', $event)"
            :options="{
            multiple: false,
            options: [
              { id: 'current_card', name: $locale.interface_editor.component_editor.button_action.current_card },
              { id: 'parent_card', name: $locale.interface_editor.component_editor.button_action.parent_card }
            ]
          }"
          ></editor-select>
        </el-form-item>

        <el-form-item v-if="value.type === 'execute_command'">
          <el-form-item>
            <el-checkbox v-model="value.command.is_save_card"/>
            <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.button_action.save_card') }}</span>
          </el-form-item>
        </el-form-item>

        <template v-if="value.type === 'open_report'">
          <editor-report :value="value" />
          <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
        </template>

        <template v-if="value.type === 'open_document'">
          <editor-document :value="value" type="document" />
          <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
        </template>

        <template v-if="value.type === 'open_xml'">
          <editor-document :value="value" type="xml" />
          <editor-filters style="margin-top: 10px" :value="value.filters" @change="$set(value ,'filters', $event)"/>
        </template>

        <template v-if="value.type === 'open_dashboard'">
          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.dashbord }}</span>
            <editor-dashboards @change="$set(value.dashboard ,'id', $event)" :value="value.dashboard.id" ></editor-dashboards>
          </el-form-item>
          <el-form-item>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_open_card.container }}</span>
            <el-tooltip placement="top-start" :open-delay="400">
              <div slot="content" v-html="$locale.interface_editor.component_editor.editor_open_card.tooltip.container"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
            <el-select v-model="value.dashboard.containerAlias" clearable>
              <el-option
                v-for="(item, index) in containers"
                :key="index"
                :label="item.alias"
                :value="item.alias"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.frame }}</span>
            <el-select v-model="value.dashboard.frameGuid" clearable>
              <el-option
                v-for="(item, index) in components.filter((item) => item.name === 'basic/a-frame')"
                :key="index"
                :label="item.name"
                :value="item.guid"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <editor-filters
              :value="value.dashboard.fields"
              @change="$set(value.dashboard ,'fields', $event)"
              :title="$locale.main.fields.default"
              :show-xref-option="false"/>
          </el-form-item>

          <el-form-item class="checkbox">
            <el-checkbox v-model="value.dashboard.breadcrumbByButton"></el-checkbox>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.breadcrumb }}</span>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_title }}</span>
            <el-input v-model="value.dashboard.window_title"/>
          </el-form-item>

          <el-form-item class="checkbox">
            <el-checkbox v-model="value.dashboard.isFullscreen"></el-checkbox>
            <span class="property_label" style="display: inline-block;">{{ $locale.etl_editor.tooltip.full_screen }}</span>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_width }}</span>
            <editor-select
              :value="value.dashboard.window_width"
              @change="$set(value.dashboard ,'window_width', $event)"
              :options="{
                multiple: false,
                options: [
                  { id: '25', name:'25%' },
                  { id: '50', name:'50%' },
                  { id: '75', name:'75%' },
                  { id: '100', name:'100%' },
                ]
              }"
            ></editor-select>

            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_height }} (ex: 400px)</span>
            <el-input :value="value.dashboard.windowHeight" @input="$set(value.dashboard ,'windowHeight', $event)"></el-input>
          </el-form-item>
        </template>

        <el-form-item v-if="value.type === 'save_card'" class="checkbox">
          <el-checkbox v-model="value.saveCard.isCloseAfterSave"></el-checkbox>
          <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.close_card }}</span>
        </el-form-item>

        <el-form-item v-if="value.type === 'open_url'">
          <span class="property_label" style="display: inline-block">{{ $locale.interface_editor.component_editor.button_action.url }}</span>
          <el-input v-model="value.url.url"/>

          <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.url_open_type }}</span>
          <el-select v-model="value.url.openType" clearable>
            <el-option
              v-for="(item, index) in urlOpenOptions"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="value.type === 'refresh_card'">
          <!-- Пока хз, нужен ли этот функционал, по умолчанию скрыто -->
          <el-form-item v-show="false">
            <el-checkbox v-model="value.refreshCard.isSaveCardBeforeRefresh"></el-checkbox>
            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.is_save_card_before_refresh }}</span>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.warn_message.on_edit }}</span>
            <el-input v-model="value.refreshCard.warnMessage.onEdit"/>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.warn_message.on_read }}</span>
            <el-input v-model="value.refreshCard.warnMessage.onRead"/>
          </el-form-item>
        </el-form-item>

        <el-form-item v-if="value.type === 'etl_export'">
          <el-select v-model="value.etl_export.task_id" clearable filterable>
            <el-option
              v-for="(item, index) in etl_tasks"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #030303">id: {{ item.id }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <template v-if="value.type === 'open_window'">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.window_settings.choose') }}</span>
            <el-select v-model="value.window.guid" clearable filterable>
              <el-option
                v-for="(item) in windows"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">
                  {{ `${item.width.value}${item.width.type} x ${item.height.value}${item.height.type}` }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.window_settings.title') }}</span>
            <el-input v-model="value.window.title"/>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="value.window.popover"/>
            <span class="property_label" style="display: inline-block">{{ $t('interface_editor.component_editor.button_action.window_settings.popover') }}</span>
          </el-form-item>
        </template>

        <template v-if="value.type === 'refresh_replication' || value.type === 'open_card' && (value.card.type === 'add' || value.card.type === 'update')">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.refresh_replication_blocks') }}</span>
            <el-select v-model="value.refreshReplication.blocks" multiple clearable>
              <el-option
                v-for="(item, index) in replicationBlocks"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>

        <template v-if="value.type === 'etl_import'">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.button_action.execute_import') }}</span>
            <el-form-item>
              <el-select v-model="value.etl_import.task_id" clearable filterable>
                <el-option
                  v-for="(item, index) in etl_tasks"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #030303">id: {{ item.id }}</span>
                </el-option>
              </el-select>

              <span class="property_label">{{ $t('etl_editor.header.constant_fields_filter_title') }}</span>
              <editor-filters
                :value="value.etl_import.fields"
                @change="$set(value.etl_import ,'fields', $event)"
                :title="$locale.main.fields.default"
                :show-xref-option="false"
                :is-import="true"/>
            </el-form-item>
          </el-form-item>
        </template>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import EditorSelect from './editor-select'
import EditorDashboards from './editor-dashboards'
import EditorFilters from './editor-filters'
import EditorOpenCard from './editor-open-card.vue'
import EditorDocument from './editor-document'
import EditorReport from './editor-report'
import Icons from '@/components/MenuEditor/components/el-icons'
import EditorPlugins from '@/components/InterfaceEditor/components/editor/editor-plugins'

export default {
  name: 'editor-button-action',
  components: {
    EditorPlugins,
    EditorSelect,
    EditorDashboards,
    EditorFilters,
    EditorOpenCard,
    EditorDocument,
    EditorReport
  },
  inject: {
    getRegistryId: {
      default: () => null
    },
    getComponents: {
      default: () => {
        return []
      }
    },
    getWindows: {
      default: () => () => []
    },
    getContainersStore: {
      default: () => () => null
    }
  },

  props: {
    value: {
      type: [Array, Object],
      default () {
        return {}
      }
    },
    options: {
      type: Object
    },
    label: {
      type: String
    },
    sourceId: {
      type: [Number, String]
    },
    isHeaders: {
      type: Boolean
    },
    sourceType: {
      type: String
    },
    oldDashboardAction: {
      type: [Object, Array]
    },
    oldActionCard: {
      type: [Object, Array]
    },
    isButton: {
      type: Boolean
    }
  },

  data () {
    return {
      components: this.getComponents(),
      containersStore: this.getContainersStore(),
      commands: [],
      windows: this.getWindows(),
      icons: Icons.map(el => ({ value: el, label: el })),
      urlOpenOptions: [
        {
          name: 'Новая вкладка',
          value: 'newTab'
        },
        {
          name: 'Текущая вкладка',
          value: 'currentTab'
        },
        {
          name: 'Новое окно',
          value: 'newWindow'
        }
      ],
      etl_tasks: null
    }
  },

  computed: {
    containers () {
      if (!this.containersStore) {
        return
      }

      return this.containersStore.getFlat().filter(container => container.alias != null)
    },
    replicationBlocks () {
      if (!this.getContainersStore()) {
        return []
      }

      const store = this.getContainersStore()

      const blocks = store.getFlat()

      return blocks.reduce((array, block) => {
        if (block.replication && block.replication !== 'None') {
          array.push({
            name: block.alias || block.guid,
            value: block.guid
          })
        }

        return array
      }, [])
    }
  },

  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.type': {
      handler (value) {
        this.$set(this.value, 'filters', [])
        if (value === 'execute_command') {
          this.$set(this.value, 'command', {
            id: null,
            success_text: null,
            failure_text: null,
            is_card_close: null,
            close_type: null,
            is_save_card: null,
            is_reopen_card: null
          })
        } else if (value === 'open_dashboard') {
          this.$set(this.value, 'dashboard', {
            id: null,
            isFullscreen: false,
            window_width: 25,
            windowHeight: null,
            window_title: null,
            breadcrumbByButton: true
          })
        } else if (value === 'execute_plugin') {
          this.$set(this.value, 'command', {
            is_card_close: null
          })
        } else if (value === 'open_card') {
          this.$set(this.value, 'card', {
            registryId: null,
            recordId: null,
            type: null,
            fieldId: null,
            queryRegistry: null,
            queryCard: null,
            queryRecord: null,
            constantRecordId: null,
            cardId: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: [],
            isFastCard: false,
            componentsGuid: []
          })
          this.$set(this.value, 'refreshReplication', {
            blocks: []
          })
        } else if (value === 'open_report') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null
          })
        } else if (value === 'open_document') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: null,
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          })
        } else if (value === 'open_xml') {
          this.$set(this.value, 'reports', {
            id: null,
            guid: null,
            name: null,
            formatType: 'xml',
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          })
        } else if (value === 'saveCard') {
          if (!('saveCard' in this.value)) {
            this.$set(this.value, 'saveCard', {
              isCloseAfterSave: false
            })
          }
        } else if (value === 'open_url') {
          if (!('url' in this.value)) {
            this.$set(this.value, 'url', {
              url: null,
              openType: null
            })
          }
        } else if (value === 'refresh_card') {
          if (!('refreshCard' in this.value)) {
            this.$set(this.value, 'refreshCard', {
              warnMessage: {
                onEdit: null,
                onRead: null
              },
              isSaveCardBeforeRefresh: false
            })
          }
        } else if (value === 'etl_export') {
          if (!('etl_export' in this.value)) {
            this.$set(this.value, 'etl_export', {
              etl_export: {
                task_id: null,
                tasks: []
              }
            })
          }
        } else if (value === 'open_window') {
          if (!('window' in this.value)) {
            this.$set(this.value, 'window', {
              guid: null
            })
          }
        } else if (value === 'refresh_replication') {
          if (!('window' in this.value)) {
            this.$set(this.value, 'refreshReplication', {
              blocks: []
            })
          }
        } else if (value === 'etl_import') {
          this.$set(this.value, 'etl_import', {
            task_id: null,
            fields: [],
            componentsGuid: []
          })
        }
      }
    }
  },

  created () {
    this.restoreInvalidDataStructure()
  },

  async mounted () {
    if (this.getRegistryId()) {
      this.$http.get(`${this.$config.api}/logiceditor/logics?registry_id=${this.getRegistryId()}`)
        .then((response) => {
          if (response.data[0]) {
            this.$http.get(`${this.$config.api}/logiceditor/commands?logic_id=${response.data[0].id}`)
              .then((res) => {
                this.commands = res.data
              })
          }
        })
    }
    this.$http.get(`${this.$config.api}/etleditor/tasks?order=name:asc`)
      .then((response) => {
        this.etl_tasks = response.data
      })
  },

  methods: {
    restoreInvalidDataStructure () {
      if (!('saveCard' in this.value)) {
        this.$set(this.value, 'saveCard', {
          isCloseAfterSave: false
        })
      }
      if (this.value.type === 'open_card') {
        if (!('refreshReplication' in this.value)) {
          this.$set(this.value, 'refreshReplication', {
            blocks: []
          })
        }
      }
    }
  }
}
</script>

<style type="text/css">
  .el-select {
    width: 100%;
  }
  .editor_button .checkbox .el-form-item__content {
    display: inline-block;
}
</style>
