import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import TimerUpdateCommand from '@/services/LogicEditor/application/command/TimerUpdateCommand'
import TimerCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/TimerCommandRepositoryInterface'

export default class TimerUpdateHandler implements CommandHandlerInterface {
    private repository: TimerCommandRepositoryInterface;

    constructor (repository: TimerCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TimerUpdateCommand): Promise<void> {
      return this.repository.update({
        guid: command.guid,
        name: command.name,
        description: command.description,
        alias: command.alias,
        period: command.period,
        commands: command.commands
      })
    }
}
