<template>
  <div class="window" :style="{zIndex: zIndex}">
    <div class="overlay">
      <div class="content" :style="{width: this.onFullScreen.width, height: this.onFullScreen.height}">
        <div class="header">Мастер экспорта</div>
        <el-button class="continue_button" @click="onFullScreenChange($event)" size="small">
          <font-awesome-icon :icon="onFullScreen.state ? 'window-minimize' : 'window-maximize'"></font-awesome-icon>
        </el-button>
        <el-button class="continue_button" @click="closeWindow()" type="danger" icon="el-icon-error" size="small">Закрыть</el-button>
        <el-button class="continue_button" @click="activateStep('next')" v-show="currentTab !== '8'" type="success" icon="el-icon-right" size="small">Вперед</el-button>
        <el-button class="continue_button" @click="activateStep('back')" v-show="currentTab !== '1'" type="success" icon="el-icon-back" size="small">Назад</el-button>
        <el-divider></el-divider>
        <el-tabs style="height: 100%" v-model="currentTab">
          <el-tab-pane
            :label="$t('syncservice.structure_configuration.object_editor_elements')" style="height: 100%"
            name="1"
            :disabled="true"
          >
            <Split class="split-area">
              <SplitArea :size="50">
                <el-scrollbar style="height: inherit">
                  <el-tree
                      class="split-tree"
                      node-key="id"
                      :data="objectEditorEntities"
                      :props="treeProps"
                      :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.type + ' - ' + data.id }})</b></span>
                      <el-button
                          v-if="data.alias"
                          class="split-tree-node-button"
                          size="mini"
                          @click="transferEntity(node, 'ObjectEditor')"
                      >
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
              <SplitArea :size="50" class="split-area">
                <el-scrollbar style="height: inherit">
                  <el-tree
                      class="split-tree"
                      node-key="id"
                      :data="selectedObjectEditorEntities"
                      :props="treeProps"
                      :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.type + ' - ' + data.id }})</b></span>
                      <el-button
                          class="split-tree-node-trash-button"
                          size="mini"
                          @click="remove(node, data)"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
            </Split>
          </el-tab-pane>
          <el-tab-pane
              :label="$t('syncservice.structure_configuration.logic_editor_elements')" style="height: 100%"
              name="2"
              :disabled="true"
          >
            <Split class="split-area">
              <SplitArea :size="50">
                <el-scrollbar style="height: inherit">
                  <el-tree
                      class="split-tree"
                      node-key="id"
                      :data="logicEditorEntities"
                      :props="treeProps"
                      :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }}
                        <b>
                          ({{ data.type ? data.type + ' - id - ' + data.id : 'id - ' + data.id }})
                        </b>
                      </span>
                      <el-button
                        v-if="data.hasOwnProperty('type') && data.alias"
                        class="split-tree-node-button"
                        size="mini"
                        @click="transferEntity(node, 'LogicEditor')"
                      >
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                      </el-button>
                      <el-button
                        v-if="!data.hasOwnProperty('type')"
                        class="split-tree-node-button"
                        size="mini"
                        @click="transferLogicEditorGroupEntity(node)"
                      >
                        <font-awesome-icon icon="copy"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
              <SplitArea :size="50" class="split-area">
                <el-scrollbar style="height: inherit">
                  <el-tree
                      class="split-tree"
                      node-key="id"
                      :data="selectedLogicEditorEntities"
                      :props="treeProps"
                      :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.type + ' - id - ' + data.id }})</b></span>
                      <el-button
                        class="split-tree-node-trash-button"
                        size="mini"
                        @click="remove(node, data)"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
            </Split>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('syncservice.structure_configuration.etl_tasks')" style="height: 100%"
            name="3"
            :disabled="true"
          >
            <Split class="split-area">
              <SplitArea :size="50">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="id"
                    :data="etlEditorEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }}
                        <b>
                          ({{ 'id - ' + data.id }})
                        </b>
                      </span>
                      <el-button
                        v-if="data.alias"
                        class="split-tree-node-button"
                        size="mini"
                        @click="transferEntity(node, 'EtlEditor')"
                      >
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
              <SplitArea :size="50" class="split-area">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="id"
                    :data="selectedEtlEditorEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ 'id - ' + data.id }})</b></span>
                      <el-button
                        class="split-tree-node-trash-button"
                        size="mini"
                        @click="remove(node, data)"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
            </Split>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('syncservice.structure_configuration.bi_editor_elements')" style="height: 100%"
            name="4"
            :disabled="true"
          >
            <Split class="split-area">
              <SplitArea :size="50">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="guid"
                    :data="biEditorEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.guid === data.guid }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }}
                        <b>
                          ({{ data.type }})
                        </b>
                      </span>
                      <el-button
                        v-if="data.alias"
                        class="split-tree-node-button"
                        size="mini"
                        @click="transferEntity(node, 'BiEditor')"
                      >
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
              <SplitArea :size="50" class="split-area">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="guid"
                    :data="selectedBiEditorEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.guid === data.guid }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.type }})</b></span>
                      <el-button
                        class="split-tree-node-trash-button"
                        size="mini"
                        @click="remove(node, data)"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
            </Split>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('syncservice.structure_configuration.interface_editor_editor_elements_dashboards')" style="height: 100%"
            name="5"
            :disabled="true"
          >
            <Split class="split-area">
              <SplitArea :size="50">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="id"
                    :data="interfaceEditorDashboardEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.guid }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }}
                        <b>
                          ({{ data.type ? data.type + ' - id - ' + data.id : 'id - ' + data.id }})
                        </b>
                      </span>
                      <el-button
                        v-if="data.alias"
                        class="split-tree-node-button"
                        size="mini"
                        @click="transferEntity(node, 'InterfaceEditorDashboard')"
                      >
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
              <SplitArea :size="50" class="split-area">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="guid"
                    :data="selectedInterfaceEditorDashboardEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }}
                        <b>
                          ({{ data.type ? data.type + ' - id - ' + data.id : 'id - ' + data.id }})
                        </b>
                      </span>
                      <el-button
                        class="split-tree-node-trash-button"
                        size="mini"
                        @click="remove(node, data)"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
            </Split>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('syncservice.structure_configuration.interface_editor_editor_elements_cards')" style="height: 100%"
            name="6"
            :disabled="true"
          >
            <Split class="split-area">
              <SplitArea :size="50">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="id"
                    :data="interfaceEditorCardEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.guid }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }}
                        <b>
                          ({{ data.type ? data.type + ' - id - ' + data.id : 'id - ' + data.id }})
                        </b>
                      </span>
                      <el-button
                        v-if="data.type !== 'Реестр' && data.alias"
                        class="split-tree-node-button"
                        size="mini"
                        @click="transferEntity(node, 'InterfaceEditorCard')"
                      >
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
              <SplitArea :size="50" class="split-area">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="guid"
                    :data="selectedInterfaceEditorCardEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }}
                        <b>
                          ({{ data.type ? data.type + ' - id - ' + data.id : 'id - ' + data.id }})
                        </b>
                      </span>
                      <el-button
                        class="split-tree-node-trash-button"
                        size="mini"
                        @click="remove(node, data)"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
            </Split>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('syncservice.structure_configuration.registry_elements')" style="height: 100%"
            name="7"
            :disabled="true"
          >
            <Split class="split-area">
              <SplitArea :size="50">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="id"
                    :data="registryEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.type + ' - ' + data.id }})</b></span>
                      <el-button
                        v-if="data.alias && data.type !== 'registry_group'"
                        class="split-tree-node-button"
                        size="mini"
                        @click="transferEntity(node, 'Registry')"
                      >
                        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
              <SplitArea :size="50" class="split-area">
                <el-scrollbar style="height: inherit">
                  <el-tree
                    class="split-tree"
                    node-key="id"
                    :data="selectedRegistryEntities"
                    :props="treeProps"
                    :expand-on-click-node="false"
                  >
                    <span :class="{'active-node' : activeNode.id === data.id }" slot-scope="{ node, data }" class="split-tree-node">
                      <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.type + ' - ' + data.id }})</b></span>
                      <el-button
                        class="split-tree-node-trash-button"
                        size="mini"
                        @click="remove(node, data)"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </el-button>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </SplitArea>
            </Split>
          </el-tab-pane>
          <el-tab-pane
              :label="$t('syncservice.structure_configuration.unload')" style="height: 100%"
              name="8"
              :disabled="true"
          >
            <span class="unloading-span-info">
              Выбрано элементов редактора объектов: {{ objectEditorEntitiesId.length }}
            </span>
            <span class="unloading-span-info">
              Выбрано элементов редактора логики: {{ logicEditorEntitiesId.length }}
            </span>
            <span class="unloading-span-info">
              Выбрано элементов редактора экспорта/импорта: {{ etlEditorEntitiesId.length }}
            </span>
            <span class="unloading-span-info">
              Выбрано элементов редактора бизнес-анализа: {{ biEditorEntitiesId.length }}
            </span>
            <span class="unloading-span-info">
              Выбрано дашбордов: {{ interfaceEditorDashboardEntitiesId.length }}
            </span>
            <span class="unloading-span-info">
              Выбрано карточек: {{ interfaceEditorCardEntitiesId.length }}
            </span>
            <span class="unloading-span-info">
              Выбрано реестров с данными: {{ registryEntitiesId.length }}
            </span>
            <el-button
              class="unload_data_button"
              @click="exportConfigurationElements"
            >
              {{$locale.syncservice.export_master.unload_data}}
            </el-button>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faWindowMinimize,
  faFilter,
  faTrash,
  faWindowMaximize,
  faArrowRight,
  faCopy
} from '@fortawesome/free-solid-svg-icons'

import { PopupManager } from 'element-ui/src/utils/popup'
import ConfigurationElementsEditorEntitiesQuery from '@/services/SyncService/application/query/ConfigurationElementsEditorEntitiesQuery'
import ConfigurationElementsExportQuery from '@/services/SyncService/application/query/ConfigurationElementsExportQuery'
library.add(faWindowMinimize, faFilter, faTrash, faWindowMaximize, faArrowRight, faCopy)

export default {
  name: 'ExportConfigurationElementsWindow',
  inject: ['getEventBus', 'getQueryBus'],
  components: {
    FontAwesomeIcon
  },
  data () {
    return {
      isClickedOnContent: false,
      zIndex: 0,
      treeProps: {
        label: 'name',
        isLeaf: 'is_leaf',
        children: 'children'
      },
      exportFiles: [],
      loading: false,
      currentTab: '1',
      onFullScreen: {
        state: false,
        width: '70%',
        height: '70%'
      },
      editors: [
        'object_editor',
        'logic_editor',
        'etl_editor',
        'bi_editor',
        'interface_editor',
        'registry'
      ],
      objectEditorEntities: [],
      selectedObjectEditorEntities: [],
      logicEditorEntities: [],
      selectedLogicEditorEntities: [],
      etlEditorEntities: [],
      selectedEtlEditorEntities: [],
      biEditorEntities: [],
      selectedBiEditorEntities: [],
      interfaceEditorDashboardEntities: [],
      interfaceEditorCardEntities: [],
      selectedInterfaceEditorDashboardEntities: [],
      selectedInterfaceEditorCardEntities: [],
      registryEntities: [],
      selectedRegistryEntities: [],
      activeNode: {}
    }
  },
  computed: {
    objectEditorEntitiesId () {
      return this.extractObjectEditorEntitiesId(this.selectedObjectEditorEntities)
    },
    logicEditorEntitiesId () {
      return this.extractLogicEditorEntitiesId(this.selectedLogicEditorEntities)
    },
    etlEditorEntitiesId () {
      return this.extractEtlEditorEntitiesId(this.selectedEtlEditorEntities)
    },
    biEditorEntitiesId () {
      return this.extractBiEditorEntitiesId(this.selectedBiEditorEntities)
    },
    interfaceEditorDashboardEntitiesId () {
      return this.extractInterfaceEditorDashboardEntitiesId(this.selectedInterfaceEditorDashboardEntities)
    },
    interfaceEditorCardEntitiesId () {
      return this.extractInterfaceEditorCardEntitiesId(this.selectedInterfaceEditorCardEntities)
    },
    registryEntitiesId () {
      return this.extractRegistryEntitiesId(this.selectedRegistryEntities)
    }
  },
  mounted () {
    this.zIndex = PopupManager.nextZIndex()
    this.editors.forEach((editor) => {
      this.getEditorEntities(editor)
    })
  },
  methods: {
    async getEditorEntities (editorName) {
      await this.getQueryBus().execute(
        new ConfigurationElementsEditorEntitiesQuery(editorName)
      ).then(data => {
        switch (editorName) {
          case 'object_editor':
            this.objectEditorEntities = this.buildObjectEditorTree(data)
            break
          case 'logic_editor':
            this.logicEditorEntities = this.buildLogicEditorTree(data)
            this.logicEditorEntities = this.buildRootLogicEditorTree(data)
            break
          case 'etl_editor':
            this.etlEditorEntities = this.buildEtlEditorTree(data)
            break
          case 'bi_editor':
            this.biEditorEntities = this.buildBiEditorTree(data)
            break
          case 'interface_editor':
            this.interfaceEditorDashboardEntities = this.buildInterfaceEditorDashboardsTree(data)
            this.interfaceEditorCardEntities = this.buildInterfaceEditorCardsTree(data)
            break
          case 'registry':
            this.registryEntities = this.buildRegistryTree(data)
        }
      })
    },
    closeWindow () {
      this.$emit('update:active', false)
    },
    buildObjectEditorTree (elements, parentId = null) {
      let data = []
      elements.forEach((el, index) => {
        if (el.parent_id === parentId) {
          data.push({
            id: el.id,
            type: el.type,
            name: el.name,
            children: this.buildObjectEditorTree(elements, el.id),
            alias: el.alias
          })
        }
      })
      return data
    },
    buildEtlEditorTree (elements) {
      let data = []
      elements.forEach((el, index) => {
        data.push({
          id: el.id,
          name: el.name,
          alias: el.alias
        })
      })
      return data
    },
    buildBiEditorTree (elements, parentGuid = null) {
      let data = []
      elements.forEach((el, index) => {
        if (el.parent_guid === parentGuid) {
          data.push({
            id: el.guid,
            type: el.type,
            name: el.name,
            children: this.buildBiEditorTree(elements, el.guid),
            alias: el.alias
          })
        }
      })
      return data
    },
    buildInterfaceEditorDashboardsTree (elements, parentGuid = null) {
      let data = []

      elements.dashboards.forEach((el, index) => {
        if (el.parent_guid === parentGuid) {
          data.push({
            id: el.id,
            type: el.type,
            name: el.name,
            children: this.buildInterfaceEditorDashboardsTree(elements, el.guid),
            alias: el.alias,
            guid: el.guid
          })
        }
      })

      return data
    },
    buildInterfaceEditorCardsTree (elements) {
      return [...new Map(elements.cards.map(registry =>
        [
          registry['registry_id'],
          {
            id: registry.registry_id,
            type: 'Реестр',
            name: registry.registry_name,
            children: elements.cards.filter(card => card.registry_id === registry.registry_id)
          }
        ]
      )).values()]
    },
    buildLogicEditorTree (elements, parentId = null) {
      let data = []

      elements.data.forEach((el) => {
        if (el.parent_id === parentId) {
          data.push({
            id: el.id,
            logic_id: el.logic_id,
            type: el.type,
            name: el.name,
            children: this.buildLogicEditorTree(elements, el.id),
            alias: el.alias
          })
        }
      })

      return data
    },
    buildRootLogicEditorTree (elements) {
      let data = []

      elements.logics.forEach((rootNode, index) => {
        let childes = this.logicEditorEntities.filter(el => el.logic_id === rootNode.id)
        data.push({
          id: rootNode.id,
          name: rootNode.name,
          children: childes
        })
      })

      return data
    },
    buildRegistryTree (elements, parentId = null) {
      let data = []
      elements.forEach((el, index) => {
        if (el.parent_id === parentId) {
          data.push({
            id: el.id,
            type: el.type,
            name: el.name,
            children: this.buildRegistryTree(elements, el.id),
            alias: el.alias
          })
        }
      })
      return data
    },
    extractObjectEditorEntitiesId (elements) {
      let data = []
      elements.forEach((el) => {
        data.push(el.id)
        if (el.children.length > 0) {
          let temp = this.extractObjectEditorEntitiesId(el.children)
          temp.forEach((el) => {
            data.push(el)
          })
        }
      })
      return data
    },
    extractLogicEditorEntitiesId (elements) {
      let data = []
      elements.forEach((el) => {
        data.push(el.id)
        if (el.children?.length > 0) {
          let temp = this.extractLogicEditorEntitiesId(el.children)
          temp.forEach((el) => {
            data.push(el)
          })
        }
      })
      return data
    },
    extractEtlEditorEntitiesId (elements) {
      let data = []
      elements.forEach((el) => {
        data.push(el.id)
      })
      return data
    },
    extractBiEditorEntitiesId (elements) {
      let data = []
      elements.forEach((el) => {
        data.push(el.id)
        if (el.children.length > 0) {
          let temp = this.extractBiEditorEntitiesId(el.children)
          temp.forEach((el) => {
            data.push(el)
          })
        }
      })
      return data
    },
    extractInterfaceEditorDashboardEntitiesId (elements) {
      let data = []
      elements.forEach((el) => {
        data.push(el.guid)
        if (Array.isArray(el.children) && el.children.length > 0) {
          let temp = this.extractInterfaceEditorDashboardEntitiesId(el.children)
          temp.forEach((el) => {
            data.push(el)
          })
        }
      })
      return data
    },
    extractInterfaceEditorCardEntitiesId (elements) {
      let data = []
      elements.forEach((el) => {
        data.push(el.id)
      })
      return data
    },
    extractRegistryEntitiesId (elements) {
      let data = []
      elements.forEach((el) => {
        data.push(el.id)
      })
      return data
    },
    activateStep (action) {
      if (action === 'next') {
        this.currentTab = (parseInt(this.currentTab) + 1).toString()
      } else {
        this.currentTab = (this.currentTab - 1).toString()
      }
    },
    onFullScreenChange (event) {
      this.onFullScreen.state = !this.onFullScreen.state

      if (this.onFullScreen.state) {
        this.onFullScreen.width = '100%'
        this.onFullScreen.height = '100%'
      } else {
        this.onFullScreen.width = '70%'
        this.onFullScreen.height = '70%'
      }
    },
    transferEntity (node, editorName) {
      this[`selected${editorName}Entities`].push(JSON.parse(JSON.stringify(node.data)))
    },
    transferLogicEditorGroupEntity (node) {
      node.data.children.map(el => {
        if (el.alias) {
          this[`selectedLogicEditorEntities`].push(JSON.parse(JSON.stringify(el)))
        }
      })
    },
    remove (node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    },
    async exportConfigurationElements () {
      await this.getQueryBus().execute(
        new ConfigurationElementsExportQuery({
          object_editor: this.objectEditorEntitiesId,
          logic_editor: this.logicEditorEntitiesId,
          etl_editor: this.etlEditorEntitiesId,
          bi_editor: this.biEditorEntitiesId,
          interface_editor: {
            cards: this.interfaceEditorCardEntitiesId,
            dashboards: this.interfaceEditorDashboardEntitiesId
          },
          registry: this.registryEntitiesId
        })
      ).then(data => {
        this.closeWindow()
        this.$message({
          message: data,
          type: 'success'
        })
      })
    },
    toCamelCase (string) {
      return string.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
          .toUpperCase()
          .replace('-', '')
          .replace('_', '')
      )
    }
  }
}
</script>

<style scoped>
</style>
<style scoped src="../../../exportConfigurationElementsForm.scss" lang="scss"></style>
