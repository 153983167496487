import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class TimerCreateCommand implements CommandInterface {
  /**
   * @param name
   * @param alias
   * @param description
   * @param period
   * @param commands
   * @param logicId
   * @param parentTreeElementId
   */
  constructor (
    public readonly name: string,
    public readonly alias: Nullable<string>,
    public readonly description: Nullable<string>,
    public readonly period: string,
    public readonly commands: Array<object>,
    public readonly logicId: number,
    public readonly parentTreeElementId: Nullable<number>
  ) {}

  getClassName () {
    return 'TimerCreateCommand'
  }
}
