import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import TaskRegistryEntity from '@/services/TaskEditor/domain/model/TaskRegistryEntity'

export enum ActionTypes {
  GET_TASK_REGISTRY_ENTITIES = 'get_task_registry_entities',
  GET_TASK_REGISTRY_ENTITY_BY_PROPERTY = 'get_task_registry_entity_by_property',
  UPDATE_TASK_REGISTRY_ENTITIES = 'update_task_registry_entities'
}

export class GetTaskRegistryEntities implements FluxStandardAction {
  type = 'TaskRegistryEntity/' + ActionTypes.GET_TASK_REGISTRY_ENTITIES
  constructor () {}
}

export class GetTaskRegistryEntityByProperty implements FluxStandardAction {
  type = 'TaskRegistryEntity/' + ActionTypes.GET_TASK_REGISTRY_ENTITY_BY_PROPERTY
  constructor (public property: string) {}
}

export class UpdateTaskRegistryEntities implements FluxStandardAction {
  type = 'TaskRegistryEntity/' + ActionTypes.UPDATE_TASK_REGISTRY_ENTITIES
  constructor (public payload: Array<TaskRegistryEntity>) {}
}
