<template>
  <div>
    <el-col :span="24">
      <span style="margin-right: 10px">Разбить слой по условию</span>
      <el-checkbox v-model="value.enable"></el-checkbox>
    </el-col>
    <template v-if="value.enable">
    <el-button type="text" @click="addRule">Добавить</el-button>
      <el-tree
        node-key="guid"
        draggable
        :data="value.rules"
        :expand-on-click-node="false"
        :allow-drop="allowDrop"
      >
            <span slot-scope="{ node, data }">
              <span class="label">{{ data.name }}</span>
              <el-button
                type="text"
                class="edit-button"
                @click.stop="edit(node, data)">
                {{ me.$locale.main.button.edit }}
              </el-button>
              <el-button
                type="text"
                class="delete-button"
                @click.stop="remove(node, data)">
                {{ me.$locale.main.button.delete }}
              </el-button>
            </span>
      </el-tree>
    </template>
    <rule-config
      :value="ruleConfig.value"
      :queryBuilderSettings="ruleConfig.queryBuilderSettings"
      :sourceType="sourceType"
      ref="rule-config"
    ></rule-config>
  </div>
</template>

<script>
import RuleConfig from './RuleConfigurator'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ObjectEditorAPI } from '@/core/infrastructure/api/modules/ObjectEditorAPI'
import { StateAPI } from '@/services/LogicEditor/infrastructure/api/StateAPI'

const DEFAULT_RULE = {
  guid: null,
  name: 'Новый подслой',
  rule: { logical_operator: 'and', children: [] },
  query: {},
  script: '',
  style: {}
}

export default {
  name: 'LayerSeparationConditions',
  components: { RuleConfig },
  props: {
    value: {
      type: Object
    },
    entityId: {
      default: null
    },
    fields: {
      default: null
    },
    sourceType: {
      default: null
    }
  },
  data: function () {
    return {
      me: this,
      tmpValue: { type: 'rules', rules: [] },
      autoFillSettings: { field: null, startColor: null, endColor: null },
      ruleConfig: {
        value: { style: { point: {} } },
        queryBuilderSettings: { fields: [] }
      }
    }
  },
  methods: {
    addRule () {
      let rule = JSON.parse(JSON.stringify(DEFAULT_RULE))
      rule.guid = this.generateGuid()
      this.value.rules.splice(this.value.rules.length, 0, rule)
    },
    allowDrop (draggingNode, dropNode, type) {
      return type !== 'inner'
    },
    async edit (node, data) {
      this.$emit('set-config-loading', true)
      let operationTypes = []
      let stateOperationTypeEntityTypes = []
      let fields = []
      if (this.sourceType === 'Registry') {
        operationTypes = await APIClient.shared.request(
          new StateAPI.GetStateOperationTypes()
        )
        stateOperationTypeEntityTypes = await APIClient.shared.request(
          new StateAPI.GetStateOperationTypeEntityTypes()
        )
        fields = await this.getEntityFields(this.entityId)
        fields = this.buildFieldList(fields.data)
      }
      let settings = {
        stateOperationTypeEntityTypes: stateOperationTypeEntityTypes,
        operationTypes: operationTypes,
        fields: fields
      }
      if (Array.isArray(data.rule)) {
        data.rule = {}
      }
      this.$set(this, 'ruleConfig', {
        value: data,
        queryBuilderSettings: settings,
        sourceType: this.sourceType
      })
      this.$emit('set-config-loading', false)
      this.showRuleConfig()
    },
    showRuleConfig () {
      this.$refs['rule-config'].showRuleConfig()
    },
    async getEntityFields (entityId) {
      let fields = await APIClient.shared.request(
        new ObjectEditorAPI.GetEntities({
          'parent_id': entityId,
          'show_children': true,
          'properties[is_visible_in_grid]': true
        })
      )
      return fields
    },
    remove (node, data) {
      this.value.rules.splice(this.value.rules.findIndex((item) => item.guid === data.guid), 1)
    },
    buildFieldList (fields) {
      let fieldList = []
      fields.forEach((field) => {
        if ([
          'string_field', 'text_field', 'integer_field',
          'float_field', 'date_field', 'time_field',
          'datetime_field', 'boolean_field', 'xref_field',
          'xref_multi_field', 'field_group'
        ].indexOf(field.entity_type_id) !== -1) {
          let id = `attr_${field.id}_`
          if (field.entity_type_id === 'xref_field') {
            id += 'id'
          }
          let qbField = {
            id: id,
            fieldId: field.id,
            objectId: field.object_id,
            name: field.name,
            fieldTypeId: field.entity_type_id,
            children: []
          }

          if (field.entity_type_id === 'field_group' && field.children.length) {
            qbField.children.push(...this.buildFieldList(field.children))
          }

          fieldList.push(qbField)
        }
      })
      return fieldList
    }
  }
}
</script>
<style scoped>
  .edit-button {
    cursor:pointer;
    color: #409EFF;
    font-size: 12px;
    margin-left: 15px;
    float: right;
  }
  .delete-button {
    cursor:pointer;
    font-size: 12px;
    margin-left: 15px;
    float: right;
    color: #F56C6C;
  }
  .el-tree /deep/ .el-tree-node__content {
    display: inline;
    width: calc(100% - 20px);
  }
</style>
<style>
  .rule-config-msgbox {
    min-width: 600px;
  }
</style>
