
























































import Vue from 'vue'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'

import TaskRegistryEntity from '@/services/TaskEditor/domain/model/TaskRegistryEntity'
import TaskRegistryEntityUpdateCommand from '@/services/TaskEditor/application/command/TaskRegistryEntityUpdateCommand'

export default Vue.extend({
  name: 'TaskRegistryForm',

  props: {
    value: Array
  },

  components: {
    RegistrySelectTree
  },

  inject: ['getCommandBus'],

  computed: {
    isLoading () {
      return this.$store.getters['TaskRegistryEntity/isTaskRegistryEntitiesLoading']
    },

    rules () {
      return {
        registry: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.registry')}"`, trigger: 'change' }
        ],
        create_date: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.create_date')}"`, trigger: 'change' }
        ],
        author: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.author')}"`, trigger: 'change' }
        ],
        executors: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.executors')}"`, trigger: 'change' }
        ],
        type: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.type')}"`, trigger: 'change' }
        ],
        status: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.status')}"`, trigger: 'change' }
        ],
        plan_date: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.plan_date')}"`, trigger: 'change' }
        ],
        name: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.name')}"`, trigger: 'change' }
        ],
        content: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.content')}"`, trigger: 'change' }
        ],
        fact_date: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.fact_date')}"`, trigger: 'change' }
        ],
        status_id: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.status_id')}"`, trigger: 'change' }
        ],
        rule_id: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.rule_id')}"`, trigger: 'change' }
        ]
      }
    }
  },

  data () {
    return {
      model: {
        registry: null,
        create_date: null,
        author: null,
        executors: null,
        type: null,
        status: null,
        plan_date: null,
        name: null,
        content: null,
        fact_date: null,
        status_id: null,
        rule_id: null
      }
    }
  },

  mounted () {
    this.value.forEach((item) => {
      this.model[item.id] = item.entity_id
    })
  },

  methods: {
    submit (callback: Function): void {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let input = []

          for (const prop in this.model) {
            if (this.model.hasOwnProperty(prop)) {
              input.push(
                TaskRegistryEntity.load({
                  id: prop,
                  name: prop,
                  entity_id: this.model[prop]
                })
              )
            }
          }

          this.$emit('input', input)

          this.getCommandBus()
            .execute(
              new TaskRegistryEntityUpdateCommand(input)
            )
            .then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    }
  }
})
