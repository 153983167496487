<template>
  <el-select
    value-key="id"
    :value="value"
    @input="$emit('input', $event)"
    size="mini"
  >
    <el-option-group
      v-for="(group, index) in Array.from(new Set(commands.map(item => item.registry)))"
      :key="index"
      :label="group">
      <el-option
        v-for="item in commands.filter(item => item.registry === group)"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
import Commands from '@/components/LogicEditor/Models/Commands'

export default {
  name: 'Command',
  props: ['value'],
  data () {
    return {
      commands: []
    }
  },
  async mounted () {
    this.commands = await Commands.params({}).$get()
  }
}
</script>

<style scoped>

</style>
