import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class StateUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private description: Nullable<string>
  private isComplex: boolean
  private properties: Nullable<object>
  private stateFields: Array<object>
  private alias: Nullable<string>

  constructor (
    guid: string,
    name: string,
    description: Nullable<string>,
    isComplex: boolean,
    stateFields: Array<object>,
    properties: Nullable<object>,
    alias: Nullable<string>
  ) {
    this.guid = guid
    this.name = name
    this.description = description
    this.isComplex = isComplex
    this.properties = properties
    this.stateFields = stateFields
    this.alias = alias
  }

  getClassName (): string {
    return 'StateUpdateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getIsComplex (): boolean {
    return this.isComplex
  }

  getProperties (): Nullable<object> {
    return this.properties
  }

  getStateFields (): Array<object> {
    return this.stateFields
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
