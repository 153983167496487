import {APIRequest} from "@/core/infrastructure/api/APIRequest";
import {HTTPMethod} from "@/core/infrastructure/api/APIClient";

export namespace LogicEditorAPI {

  export class getStatesByRegistryId  implements APIRequest<string> {
    path: string;
    response: string;
    method = HTTPMethod.GET;

    constructor(registryId: number) {
      this.path = `/logiceditor/states/${registryId}/registry`
    }
  }
}
