import QueryInterface from '@/core/application/query/QueryInterface'

export default class UserPasswordCheckQuery implements QueryInterface
{
    private password: string;

    constructor(password: string)
    {
        this.password = password;
    }

    getClassName(): string
    {
        return 'UserPasswordCheckQuery';
    }

    getPassword(): string
    {
        return this.password;
    }
}