import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ListenerDTO } from '@/services/LogicEditor/domain/model/Listener'

export namespace ListenerAPI {
  export class GetListenerByGuid implements APIRequest<ListenerDTO> {
    response: ListenerDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/listeners/${guid}`
    }
  }

  export class CreateListener implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/listeners';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: ListenerDTO) {}
  }

  export class UpdateListener implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: ListenerDTO) {
      this.path = `/v2/logiceditor/listeners/${params.guid}`
    }
  }

  export class DeleteListener implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/listeners/${guid}`
    }
  }
}
