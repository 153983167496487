<script type="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowLeft, faArrowRight)
import ElHeader from 'element-ui/packages/header/src/main.vue'
import ElFooter from 'element-ui/packages/footer/src/main.vue'
import ElMain from 'element-ui/packages/main/src/main.vue'
import ElButton from 'element-ui/packages/button/src/button.vue'
import ElRow from 'element-ui/packages/row/src/row.js'
import ElBreadcrumb from 'element-ui/packages/breadcrumb/src/breadcrumb.vue'
import ElBreadcrumbItem from 'element-ui/packages/breadcrumb/src/breadcrumb-item.vue'
import LayerForm from '@/services/MapEditor/infrastructure/components/LayerForm/index.vue'
import LayerSelectPanel from '@/services/MapEditor/infrastructure/components/LayerSelectPanel/index.vue'
import SourceForm from '@/services/MapEditor/infrastructure/components/SourceForm/index.vue'
import SourceSelectPanel from '@/services/MapEditor/infrastructure/components/SourceSelectPanel/index.vue'
import PointForm from '@/services/MapEditor/infrastructure/components/PointForm/index.vue'
import LinestringForm from '@/services/MapEditor/infrastructure/components/LinestringForm/index.vue'
import PolygonForm from '@/services/MapEditor/infrastructure/components/PolygonForm/index.vue'
import LabelForm from '@/services/MapEditor/infrastructure/components/LabelForm/index.vue'
import PointStyleImageForm from '@/services/MapEditor/infrastructure/components/PointStyleImageForm/index.vue'
import LinestringStylePatternForm from '@/services/MapEditor/infrastructure/components/LinestringStylePatternForm/index.vue'
import ProjectSelectPanel from '@/services/MapEditor/infrastructure/components/ProjectSelectPanel/index.vue'
import StyleSelectPanel from '@/services/MapEditor/infrastructure/components/StyleSelectPanel/index.vue'
import CoordinateSystemSelectPanel from '@/services/MapEditor/infrastructure/components/CoordinateSystemSelectPanel/index.vue'
import CoordinateSystemForm from '@/services/MapEditor/infrastructure/components/CoordinateSystemForm/index.vue'
import AffineTransformationSelectPanel from '@/services/MapEditor/infrastructure/components/AffineTransformationSelectPanel/index.vue'
import AffineTransformationForm from '@/services/MapEditor/infrastructure/components/AffineTransformationForm/index.vue'
import GeometryTextEditorForm from '@/services/MapEditor/infrastructure/components/GeometryTextEditorForm/index.vue'
import VertexEditPanel from '@/services/MapEditor/infrastructure/components/VertexEditPanel/index.vue'

export default {
  name: "FormSet",
  props: ['projectId', 'projectGuid', 'defaultItems', 'parentNodeId', 'parentPanelId', 'cancelEvent'],
  inject: ['getEventBus'],
  data() {
    return {
      items: [],
      mode: "full", // mini
      itemTemplates: {
        "project_select": {
          type: "project_select",
          action: this.$locale.map_editor.layer_form.select_project_action,
          title: this.$locale.map_editor.layer_form.select_project_op  
        },
        "layer": {
          type: "layer",
          action: this.$locale.map_editor.layer_form.save_layer_action,
          title: this.$locale.map_editor.layer_form.add_layer_op  
        },
        "layer_edit": {
          type: "layer",
          action: this.$locale.map_editor.layer_form.save_layer_action,
          title: this.$locale.map_editor.layer_form.edit_layer_op  
        },
        "source": {
          type: "source",
          action: this.$locale.map_editor.layer_form.save_source_action,
          title: this.$locale.map_editor.layer_form.add_source_op  
        },
        "source_edit": {
          type: "source",
          action: this.$locale.map_editor.layer_form.save_source_action,
          title: this.$locale.map_editor.layer_form.edit_source_op  
        },
        "source_select": {
          type: "source_select",
          action: this.$locale.map_editor.layer_form.select_source_action,
          title: this.$locale.map_editor.layer_form.select_source_op  
        },
        "coordinate_system": {
          type: "coordinate_system",
          action: this.$locale.map_editor.layer_form.save_coordinate_system_action,
          title: this.$locale.map_editor.layer_form.add_coordinate_system_op  
        },
        "coordinate_system_edit": {
          type: "coordinate_system",
          action: this.$locale.map_editor.layer_form.save_coordinate_system_action,
          title: this.$locale.map_editor.layer_form.edit_coordinate_system_op  
        },
        "coordinate_system_select": {
          type: "coordinate_system_select",
          action: this.$locale.map_editor.layer_form.select_coordinate_system_action,
          title: this.$locale.map_editor.layer_form.select_coordinate_system_op  
        },
        "affine_transformation": {
          type: "affine_transformation",
          action: this.$locale.map_editor.layer_form.save_affine_transformation_action,
          title: this.$locale.map_editor.layer_form.add_affine_transformation_op  
        },
        "affine_transformation_select": {
          type: "affine_transformation_select",
          action: this.$locale.map_editor.layer_form.select_affine_transformation_action,
          title: this.$locale.map_editor.layer_form.select_affine_transformation_op  
        },
        "style_select": {
          type: "style_select",
          action: this.$locale.map_editor.layer_form.select_style_action,
          title: this.$locale.map_editor.layer_form.select_style_op  
        },
        "point": {
          type: "point",
          action: this.$locale.map_editor.layer_form.save_point_action,
          title: this.$locale.map_editor.layer_form.add_point_op  
        },
        "point_edit": {
          type: "point",
          action: this.$locale.map_editor.layer_form.save_point_action,
          title: this.$locale.map_editor.layer_form.edit_point_op  
        },
        "linestring": {
          type: "linestring",
          action: this.$locale.map_editor.layer_form.save_linestring_action,
          title: this.$locale.map_editor.layer_form.add_linestring_op  
        },
        "linestring_edit": {
          type: "linestring",
          action: this.$locale.map_editor.layer_form.save_linestring_action,
          title: this.$locale.map_editor.layer_form.edit_linestring_op  
        },
        "polygon": {
          type: "polygon",
          action: this.$locale.map_editor.layer_form.save_polygon_action,
          title: this.$locale.map_editor.layer_form.add_polygon_op  
        },
        "polygon_edit": {
          type: "polygon",
          action: this.$locale.map_editor.layer_form.save_polygon_action,
          title: this.$locale.map_editor.layer_form.edit_polygon_op  
        },
        "label": {
          type: "label",
          action: this.$locale.map_editor.layer_form.save_label_action,
          title: this.$locale.map_editor.layer_form.add_label_op  
        },
        "label_edit": {
          type: "label",
          action: this.$locale.map_editor.layer_form.save_label_action,
          title: this.$locale.map_editor.layer_form.edit_label_op  
        },
        "point_style_image": {
          type: "point_style_image",
          action: this.$locale.map_editor.layer_form.save_point_style_image_action,
          title: this.$locale.map_editor.layer_form.add_point_style_image_op  
        },
        "linestring_style_pattern": {
          type: "linestring_style_pattern",
          action: this.$locale.map_editor.layer_form.save_linestring_style_pattern_action,
          title: this.$locale.map_editor.layer_form.add_linestring_style_pattern_op  
        },
        "geometry_text_editor_form": {
          type: "geometry_text_editor_form",
          action: this.$locale.map_editor.layer_form.save,
          title: this.$locale.map_editor.layer_panel.geometry_text_editor
        },
        "vertex_edit_panel": {
          type: "vertex_edit_panel",
          action: this.$locale.map_editor.layer_form.save,
          title: this.$locale.map_editor.layer_panel.vertex_edit_panel
        }
      }
    }
  },
  methods: {
    createHeader() {
      return this.$createElement(ElHeader, {
        props: {},
        class: {
          "layer-form-header": true
        }
      }, [this.createRow()]);
    },
    createRow() {
      return this.$createElement(ElRow, {
        props: {}
      }, [this.createNavigation()]);
    },
    createNavigation() {
      return this.$createElement(ElBreadcrumb, {
        props: {
            separatorClass: "el-icon-arrow-right"
        }
      }, [this.createBackNavigation(), ...this.createNavigationItems()]);
    },
    createBackNavigation() {
      return this.$createElement(ElBreadcrumbItem, {
      },[
        this.$createElement("a", {
          on: {
            click: () => {
              this.goBack(this.items.length - 1);
            }
          }
        },this.$locale.main.button.back)
      ]);
    },
    createNavigationItems() {
      let items = [];
      for (let i = 0; i < this.items.length; i += 1) {
        items.push(
          this.createNavigationItem(i, this.items[i].title)
        );
      }
      return items;
    },
    createNavigationItem(index, title) {
      if (index < this.items.length - 1) {
        return this.$createElement(ElBreadcrumbItem, {
          },[
            this.$createElement("a", {
              on: {
                click: () => {
                    return this.goBack(index + 1)
                }
              }
            },title)
        ]);
      }
      return this.$createElement(ElBreadcrumbItem, {
      },[title]);
    },
    createForms() {
      return [
        this.$createElement(ElMain, {
          props: {},
          class: {
            "layer-form-body": true
          },
          style: {
            "width": "100%"
          }
        },[...this.createFormItems()])
      ];
    },
    createFormItems() {
      let items = [];
      if (this.items && this.items.length) {      
        for (let i = 0; i < this.items.length; i += 1) {
          items.push(
            this.createFormItem(i, this.items[i].type, this.items[i].params)
          );
        }
      }
      return items;
    },
    createFormItem(index, type, params) {
      switch(type) {
        case "project_select":
          return this.createProjectSelectPanel(index, params);
        case "layer":
          return this.createLayerForm(index, params);
        case "listof_layers":
          return this.createLayerSelectPanel(index, params);
        case "source":
          return this.createSourceForm(index, params);
        case "source_select":
        case "listof_sources":
          return this.createSourceSelectPanel(index, params);
        case "coordinate_system":
          return this.createCoordinateSystemForm(index, params);
        case "coordinate_system_select":
        case "listof_coordinate_systems":
          return this.createCoordinateSystemSelectPanel(index, params);
        case "affine_transformation":
          return this.createAffineTransformationForm(index, params);
        case "affine_transformation_select":
        case "listof_affine_transformations":
          return this.createAffineTransformationSelectPanel(index, params);
        case "point":
          return this.createPointForm(index, params);
        case "linestring":
          return this.createLinestringForm(index, params);
        case "polygon":
          return this.createPolygonForm(index, params);
        case "label":
          return this.createLabelForm(index, params);
        case "style_select":
          return this.createStyleSelectPanel(index, params);
        case "point_style_image":
          return this.createPointStyleImageForm(index, params);
        case "linestring_style_pattern":
          return this.createLinestringStylePatternForm(index, params);
        case "geometry_text_editor_form":
          return this.createGeometryTextEditorForm(index, params);
        case "vertex_edit_panel":
          return this.createVertexEditPanel(index, params); 
        default:
          return null;
      }
    },
    createLayerForm(index, params) {
      return this.$createElement(LayerForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          parentNodeId: this.parentNodeId,
          parentPanelId: this.parentPanelId,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createProjectSelectPanel(index, params) {
      return this.$createElement(ProjectSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all_projects !== 'undefined' && this.items[index].hide_all_projects,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createLayerSelectPanel(index, params) {
      return this.$createElement(LayerSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all_layers !== 'undefined' && this.items[index].hide_all_layers,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createSourceForm(index, params) {
      return this.$createElement(SourceForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createPointForm(index, params) {
      return this.$createElement(PointForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          mini: false,
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createLinestringForm(index, params) {
      return this.$createElement(LinestringForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          mini: false,
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createPolygonForm(index, params) {
      return this.$createElement(PolygonForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          mini: false,
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createLabelForm(index, params) {
      return this.$createElement(LabelForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          mini: false,
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createPointStyleImageForm(index, params) {
      return this.$createElement(PointStyleImageForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createLinestringStylePatternForm(index, params) {
      return this.$createElement(LinestringStylePatternForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createGeometryTextEditorForm(index, params) {
      return this.$createElement(GeometryTextEditorForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createVertexEditPanel(index, params) {
      return this.$createElement(VertexEditPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createCoordinateSystemForm(index, params) {
      return this.$createElement(CoordinateSystemForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createAffineTransformationForm(index, params) {
      return this.$createElement(AffineTransformationForm, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid, 
          ...params
        },
        ref: 'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createSourceSelectPanel(index, params) {
      return this.$createElement(SourceSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all_sources !== 'undefined' && this.items[index].hide_all_sources,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createStyleSelectPanel(index, params) {
      return this.$createElement(StyleSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all_styles !== 'undefined' && this.items[index].hide_all_styles,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createCoordinateSystemSelectPanel(index, params) {
      return this.$createElement(CoordinateSystemSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all_coordinate_systems !== 'undefined' && this.items[index].hide_all_coordinate_systems,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createAffineTransformationSelectPanel(index, params) {
      return this.$createElement(AffineTransformationSelectPanel, {
        props: {
          formId: 'form' + index,
          projectId: this.projectId,
          projectGuid: this.projectGuid,
          hideAll: typeof this.items[index].hide_all !== 'undefined' && this.items[index].hide_all,
          ...params
        },
        ref:  'item' + index,
        class: {
          "layer-hidden-form": (index < (this.items.length - 1))
        }
      });
    },
    createFooter() {
      let buttons = [];
      if (this.items.length && (typeof this.items[this.items.length-1].hide_submit == 'undefined' || !this.items[this.items.length-1].hide_submit)) {
        buttons.push(
          this.$createElement(ElButton, {
            props: {
              plain: true,
              type: "primary",
              size: "medium"
            },
            on: {
              click: () => {
                this.submitForm();
                this.getEventBus().$emit('updateMapSize', 'full');
              }
            }
          }, [this.getSubmitButtonText()])
        );
      }
      buttons.push(
        this.$createElement(ElButton, {
          props: {
            plain: true,
            size: "medium"
          },
          on: {
            click: () => {
              this.goBack(this.items.length - 1);
            }
          }
        }, [this.$locale.main.button.cancel]) 
      );
      buttons.push(
        this.$createElement(ElButton, {   
          props: {
            plain: true,
            size: "small",
            icon: this.mode == "full" ? "el-icon-back" : "el-icon-right",
          },
          on: {
            click: () => {
              const prevMode = this.mode;
              this.mode = prevMode == "full" ? "mini" : "full";
              this.getEventBus().$emit('updateMapSize', prevMode, () => {
                this.collapseForm();
              });              
            }
          }
        }, [])
      );
      return this.$createElement(ElFooter, {
        props: {},
        class: {
          "layer-form-footer": true
        }
      }, buttons);
    },
    getSubmitButtonText() {
      return this.items[this.items.length-1].action;
    },
    async submitForm() {
      let childForm = this.$refs['item' + (this.items.length - 1)];
      await childForm.submit(() => {
        this.goBack(this.items.length - 1);
      });      
    },
    collapseForm() {
      let childForm = this.$refs['item' + (this.items.length - 1)];
      if (typeof childForm.collapse == "function") {
        childForm.collapse();
      }
    },
    goBack(index) {
      if (index == 0) {
        this.getEventBus().$emit(this.cancelEvent);
        this.getEventBus().$emit('updateMapSize', 'full');
      } else {
        this.items = this.items.slice(0, index);
      }
    }, 
    createFormEvent(type, params) {
      let formTemplate = this.itemTemplates[type]; 
      formTemplate.params = params;
      this.items.push(formTemplate);
    }
  },
  render (createElement) {
    return createElement("div", {
      class: {
        "layer-form-set": true
      },
      style: {
        "width": this.mode == "full" ? "100%" : "40%"
      }
    }, [this.createHeader(), ...this.createForms(), this.createFooter()]);
  },
  mounted() {
    if (this.defaultItems && this.defaultItems.length) {
      this.items = this.defaultItems.slice();
    }
    this.getEventBus().$emit('updateMapSize', 'mini');
    this.getEventBus().$on('createForm', this.createFormEvent); 
  }
}

</script>

<style scoped>
.button {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: white;
  cursor: pointer;
}
.button svg {
  color: #6E7B87;
  font-size: 16px;
  padding: 14px;
}
.button:hover {
  background-color: #ecf5ff;
}
.button:hover svg {
  color: #409EFF;
}
.button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.button:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
</style>