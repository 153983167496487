import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'
import ApprovalCreateCommand from '@/services/LogicEditor/application/command/ApprovalCreateCommand'
import ApprovalCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalCommandRepositoryInterface'

export default class ApprovalCreateHandler implements CommandHandlerInterface {
  private repository: ApprovalCommandRepositoryInterface

  constructor (repository: ApprovalCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ApprovalCreateCommand): Promise<void> {
    let item: ApprovalDTO = {
      name: command.getName(),
      description: command.getDescription(),
      alias: command.getAlias(),
      approval_states: command.getApprovalStates(),
      approval_stages: command.getApprovalStages(),
      logic_id: command.getLogicId(),
      parent_tree_element_id: command.getParentTreeElementId(),
      is_enabled: command.getIsEnabled()
    }

    return this.repository.insert(item)
  }
}
