<template>
  <div>
    <div>{{label}}</div>
    <tree-select
      ref="treeselect"
      class="custom_scrollbar"
      :value="selectedRegistry"
      :title="selectedRegistry"
      placeholder="Выбрать реестр"
      :loading-text="$locale.main.message.loading"
      :clearable="true"
      :clear-on-select="true"
      :disable-branch-nodes="true"
      :async="true"
      :disable-immediate-search="true"
      :cache-options="false"
      :append-to-body="appendToBody"
      :searchable="true"
      :options="dataRegistry"
      :load-options="loadOptions"
      :normalizer="registryFieldsSettings.normalizer"
      :clear-value-text="registryFieldsSettings.clearValueText"
      :no-children-text="registryFieldsSettings.noChildrenText"
      :no-options-text="registryFieldsSettings.noOptionsText"
      :no-results-text="registryFieldsSettings.noResultsText"
      :match-keys="registryFieldsSettings.matchKeys"
      :value-consists-of="registryFieldsSettings.valueConsistsOf"
      :defaultExpandLevel="registryFieldsSettings.isDefaultExpanded"
      @select="selectField($event)"
      @open="onMenuOpen"
      @search-change="onSearch"
      @input="input"
    >

      <template slot="before-list">
        <div v-show="showSearchTip" class="vue-treeselect__tip vue-treeselect__seacrh-promt-tip">
          <div class="vue-treeselect__icon-container">
            <span class="vue-treeselect__icon-warning" />
          </div>
          <span class="vue-treeselect__tip-text vue-treeselect__seacrh-promt-tip-text">
            {{ registryFieldsSettings.pressEnterToSearchText }}
          </span>
        </div>
      </template>

      <div slot="value-label" slot-scope="{ node }">{{ node.id }}</div>
      <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
          {{ node.label }} (ID: {{node.id}},  <b>{{ $locale.object_editor.entity_types[node.raw.entity_type_id]}}</b>)
      </label>
    </tree-select>
  </div>
</template>

<script>
// Описание. Компонент для отображения реестрового дерева. С поиском.
import Treeselect, { LOAD_CHILDREN_OPTIONS, LOAD_ROOT_OPTIONS, ASYNC_SEARCH } from '@bingosoftnn/vue-treeselect'
import '@bingosoftnn/vue-treeselect/dist/vue-treeselect.css'

// API
import Entity from '@/components/ObjectEditor/Models/Entity'
export default {
  name: 'registry-tree-with-search',
  props: {
    value: {
      type: Number
    },
    label: {
      type: String,
      default: ''
    },
    appendToBody: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'tree-select': Treeselect
  },
  data () {
    return {
      registryId: this.value,
      selectedRegistry: null,
      search: null,
      searchLoaded: false,
      searchLoading: false,
      dataRegistry: null,
      registryFieldsSettings: {
        matchKeys: ['name', 'id', 'label'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noResultsText: 'Не найдено',
        pressEnterToSearchText: 'Для поиска нажмите Enter',
        isDefaultExpanded: 0,
        normalizer (node) {
          return {
            id: node.id,
            label: node.name,
            children: node.children,
            isLeaf: node.leaf
          }
        }
      }
    }
  },
  computed: {
    showSearchTip () {
      return this.search && !this.searchLoading && !this.searchLoaded
    }
  },
  async mounted () {
    if (this.registryId) {
      let entities = await new Entity({ id: this.registryId }).expanded().$first()
      this.selectedRegistry = `${entities.name} (id: ${entities.id})`
    }
  },
  methods: {
    onSearch (value) {
      this.registryFieldsSettings.isDefaultExpanded = 0
      this.search = value
      this.searchLoaded = false
    },
    async loadOptions ({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        let children = await new Entity({ id: parentNode.id }).children().$get()
        // console.log({ children })
        parentNode.children = this.deepSearch(children)
        callback()
      }
      if (action === LOAD_ROOT_OPTIONS) {
        this.registryFieldsSettings.isDefaultExpanded = 0
        let response = await new Entity().params({ root: true }).$get()
        // console.log(response)
        this.dataRegistry = this.deepSearch(response)
        callback()
      }
      if (action === ASYNC_SEARCH) {
        this.searchLoaded = false
        this.searchLoading = true
        let response = await this.$http.get(`${this.$config.api}/objecteditor/entities/search?search_param=${encodeURIComponent(this.search)}`)
          .catch(() => {
            this.loading = false
          })
        this.dataRegistry = this.deepSearch(response.data.data)
        if (response.data?.data?.length === 1) {
          // Предположим что введен id поля и выберем сразу найденное значение, раскрыв дерево на один уровень
          // выбрать группу полей и реестр нельзя
          if (Number.isInteger(+this.search) && response.data.data[0].entity_type_id === 'registry_group') {
            const node = this.$refs.treeselect.getNode(response.data.data[0].id)
            node.isExpanded = true
            this.$set(this.registryFieldsSettings, 'isDefaultExpanded', 1)
            this.selectedRegistry = `${this.search}`
          }
        } else {
          this.registryFieldsSettings.isDefaultExpanded = 0
        }

        callback(null, this.dataRegistry)
        this.searchLoading = false
        this.searchLoaded = true
      }
    },
    deepSearch (arr) {
      for (let el of arr) {
        if (el.leaf || el.entity_type_id === 'registry') delete el.children
        if (el.entity_type_id === 'registry_group') el.children = null
        if (el.children instanceof Array) this.deepSearch(el.children)
      }

      return arr
    },
    async onMenuOpen () {
      this.registryFieldsSettings.isDefaultExpanded = 0
      let response = await new Entity().params({ root: true }).$get()
      this.dataRegistry = this.deepSearch(response)
    },
    selectField (e) {
      this.registryFieldsSettings.isDefaultExpanded = 0
      this.registryId = e.id
      this.selectedRegistry = `${e.name} (id: ${e.id})`
      this.search = ''
      // console.log('Посылаю emit change:', this.content)
      this.$emit('change', this.registryId)
    },
    input () {
      this.registryFieldsSettings.isDefaultExpanded = 0
      if (!this.$refs.treeselect.getValue()) {
        this.selectedRegistry = ``
        this.search = ''
        this.registryId = null
        this.$emit('change', this.registryId)
      }
    }
  }
}

</script>
