import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class PointStyleImageDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    extension?: Nullable<string>;
    image?: Nullable<File>;

    constructor({ id, guid, name, extension, image}: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, extension?: Nullable<string>, image?: Nullable<File>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.extension = extension;
        this.image = image;
    }
}

export default class PointStyleImage extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name?: Nullable<string>;
    private extension?: Nullable<string>;
    private image?: Nullable<File>;

    constructor(id: Nullable<number>, guid: Nullable<string>, name: Nullable<string>, extension: Nullable<string>, image: Nullable<File>)
    {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.extension = extension;
        this.image = image;
    }

    static create(dto: PointStyleImageDTO): PointStyleImage
    {
        return new PointStyleImage(dto.id, dto.guid, dto.name, dto.extension, dto.image);
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    setExtension(extension: Nullable<string>): void
    {
        this.extension = extension;
    }

    getExtension(): Nullable<string>
    {
        return this.extension;
    }

    setImage(image: File): void
    {
        this.image = image;
    }

    getImage(): File
    {
        return this.image;
    }
}