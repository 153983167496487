import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import UserCardQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/UserCardQueryRepositoryInterface'
import store from '@/store'
import { GetUserCard } from '@/services/AccessEditor/infrastructure/store/modules/User/action-types'

export default class UserCardQueryRepository extends AbstractQueryRepository implements UserCardQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetUserCard());
    }
}