import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RoleQueryRepositoryInterface from '../../../domain/repository/RoleQueryRepositoryInterface'
import RoleProfileQuery from '../../query/RoleProfileQuery'

export default class RoleProfileHandler implements QueryHandlerInterface
{
    private repo: RoleQueryRepositoryInterface;

    constructor(repo: RoleQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: RoleProfileQuery): Promise<any>
    {
        return this.repo.getProfile(query.getRoleId());
    }
}