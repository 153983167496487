<template>
<div>
  <el-input placeholder="URL" v-model="local_value">
    <template slot="append">
      <el-upload
        :headers="getHeaders()"
        class="upload-demo"
        ref="upload"
        :action="$config.api + '/registryservice/files/'"
        :on-change="numberFiles"
        :file-list="fileList"
        :http-request="save"
        :show-file-list="false">
          <el-button size="small"  icon="el-icon-download" type="primary" :title="this.$locale.interface_editor.toolbar.upload_image"></el-button>
      </el-upload>
    </template>
  </el-input>
</div>

</template>

<script>
export default {
  name: 'editor-link',
  props: ['value'],
  data () {
    return {
      local_value: this.value,
      fileList: []
    }
  },
  watch: {
    local_value: {
      handler (value) {
        this.$emit('change', value)
      }
    }
  },
  methods: {
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },
    numberFiles (file, fileList) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
        this.$refs.upload.uploadFiles = []
        this.$message.error(this.$locale.access_editor.users_item.error_avatar)
        return false
      }
    },
    async save () {
      if (this.$refs.upload.uploadFiles.length) {
        let fileObj = this.$refs.upload.uploadFiles[0].raw
        const fd = new FormData()
        fd.append('file', fileObj)
        let res = await this.$http.post(this.$config.api + '/registryservice/files', fd)
        let res1 = await this.$http.get(`${this.$config.api}/registryservice/files/${res.data.id}`)
        this.local_value = `/files/${res1.data.guid}.${res1.data.extension}`
      }
    }
  }
}
</script>
<style scoped>

</style>
