import { Nullable } from '@/core/domain/type/types'

export default class RoleUpdateCommand
{
    private guid: string;
    private name: string;
    private alias: string;
    private isReadableDefault: boolean;
    private isAddableDefault: boolean;
    private isEditableDefault: boolean;
    private isDeletableDefault: boolean;
    private isMenuVisibleDefault: boolean;
    private isProfileAvailable: boolean;
    private rootMenuId: Nullable<number>;
    private dashboards: Array<number>;
    private availableRoles: Array<number>;

    constructor(
        guid: string,
        name: string,
        alias: string,
        isReadableDefault: boolean,
        isAddableDefault: boolean,
        isEditableDefault: boolean,
        isDeletableDefault: boolean,
        isMenuVisibleDefault: boolean,
        isProfileAvailable: boolean,
        rootMenuId: Nullable<number>,
        dashboards: Array<number>,
        availableRoles: Array<number>
    ) {
        this.guid = guid;
        this.name = name;
        this.alias = alias;
        this.isReadableDefault = isReadableDefault;
        this.isAddableDefault = isAddableDefault;
        this.isEditableDefault = isEditableDefault;
        this.isDeletableDefault = isDeletableDefault;
        this.isMenuVisibleDefault = isMenuVisibleDefault;
        this.isProfileAvailable = isProfileAvailable;
        this.rootMenuId = rootMenuId;
        this.dashboards = dashboards;
        this.availableRoles = availableRoles;
    }

    getClassName(): string
    {
        return 'RoleUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getAlias(): string
    {
        return this.alias;
    }

    getIsReadable(): boolean
    {
        return this.isReadableDefault;
    }

    getIsAddable(): boolean
    {
        return this.isAddableDefault;
    }

    getIsEditable(): boolean
    {
        return this.isEditableDefault;
    }

    getIsDeletable(): boolean
    {
        return this.isDeletableDefault;
    }

    getIsMenuVisible(): boolean
    {
        return this.isMenuVisibleDefault;
    }

    getIsProfileAvailable(): boolean
    {
        return this.isProfileAvailable;
    }

    getRootMenuId(): Nullable<number>
    {
        return this.rootMenuId;
    }

    getDashboards(): Array<number>
    {
        return this.dashboards;
    }

    getAvailableRoles(): Array<number>
    {
      return this.availableRoles;
    }
}
