<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

    <el-dialog :title="$locale.interface_editor.registry_headers.title" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="editor_registry_header">
      <el-row style="    margin-top: -20px;">
        <el-col :span="12">
          <h2>{{ $locale.interface_editor.registry_headers.available_columns }}</h2>
          <el-scrollbar style="height: 500px; overflow-x: hidden">
            <el-tree
              ref="tree"
              node-key="id"
              :data="registryColumns"
              :props="treeProps"
            >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>(id: {{data.id}})</b></span>
              <span>
                <el-button
                  :disabled="selectedColumnIds.includes(data.id)"
                  @click="addColumn(JSON.parse(JSON.stringify(data)))"
                  type="text"
                  size="small"
                >
                  {{ $locale.main.button.add }}
                </el-button>
              </span>
            </span>
            </el-tree>
          </el-scrollbar>
        </el-col>
        <el-col :span="12">
          <h2>{{ $locale.interface_editor.registry_headers.visible_columns }}
            <el-button style="float: right" @click="addCustomColumn()">{{ $locale.main.button.add }}</el-button>
          </h2>
          <el-scrollbar style="height: 500px; overflow-x: hidden">
            <el-tree
              ref="tree"
              node-key="id"
              :data="localModel"
              :props="treeProps"
              draggable
              :allow-drop="allowDrop"
            >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>(id: {{data.id}})</b></span>
              <span>
                <el-button
                  type="text"
                  size="small"
                  @click="deleteColumn(JSON.parse(JSON.stringify(data)))"
                >
                  {{ $locale.main.button.delete }}
                </el-button>
                <el-button
                        icon="el-icon-plus"
                        @click="addCustomColumn(data.id)"
                />
                <el-popover
                  placement="right"
                  width="500"
                  trigger="click"
                  v-model="visiblePopover[data.id]"
                  @show="showPopover(data.id)">
                  <el-row>
                  <el-scrollbar wrap-style="overflow-x:hidden;" style="height: 700px;">
                    <el-form size="mini" label-width="150px">
                      <el-form-item :label="$locale.main.fields.name">
                    <el-input placeholder="title" v-model.lazy="data.text"></el-input>
                      </el-form-item>
                      <el-form-item v-if="data.isCustom" :label="$locale.interface_editor.component_editor.registry_headers.attr">
                        <el-input v-model="data.value"></el-input>
                      </el-form-item>
                      <el-form-item v-if="data.isCustom" :label="$locale.interface_editor.component_editor.registry_headers.type">
                        <el-select
                          style="width: 100%"
                          v-model="data.type"
                        >
                          <el-option
                            v-for="(item, index) in columnTypes"
                            :key="index"
                            :label="item.name"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.editor">
                  <el-checkbox v-model="data.isEdit"></el-checkbox>
                      </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.fix">
                  <el-checkbox
                    v-model="data.fixed"
                  ></el-checkbox>
                      </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.stretch">
                  <el-checkbox
                    v-model="data.extended"
                  ></el-checkbox>
                      </el-form-item>
                      <el-form-item v-if="(data.children || []).length > 0" :label="$locale.interface_editor.component_editor.registry_headers.fast_edit">
                  <el-checkbox
                          v-model="data.fastEdit"
                  ></el-checkbox>
                      </el-form-item>
                        <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.parent_horizon">
                  <el-select
                    style="width: 100%"
                    v-model="data.horizontalParent"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in columns.filter(item => item.parentId === data.parentId)"
                      :key="index"
                      :label="item.text"
                      :value="item">
                    </el-option>
                  </el-select>
                        </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.hide_if">
                        <el-tooltip class="item" effect="dark" :open-delay="300" placement="top-start">
                          <div slot="content">Атрибут в одинарных скобках "{ }" <br>
                            Основное операторы: <br>
                            == - РАВНО<br>
                            || - ИЛИ<br>
                            && - И<br>
                            != - НЕ РАВНО<br>
                            Пример: {attr_N_}!=42 || {user_role}==1
                          </div>
                          <el-input v-model="data.hidden_condition" placeholder="{Атрибут} == 1"></el-input>
                        </el-tooltip>
                      </el-form-item>
                      <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.under_label">
                        <el-input v-model="data.underLabel"></el-input>
                      </el-form-item>
                    <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.style">
                      <el-input
                        class="htmlTemplate"
                        type="textarea"
                        :rows="2"
                        v-model="data.css">
                      </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('interface_editor.component_editor.registry_headers.gantt_column_width')" v-if="isGantt">
                      <el-input
                        class="number"
                        :rows="2"
                        v-model="data.ganttColumnWidth">
                      </el-input>
                    </el-form-item>
                     <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.fixed_number" v-if="data.type === 'floatField'">
                      <el-input
                        type="number"
                        v-model="data.fixedNumber">
                      </el-input>
                    </el-form-item>
                    <el-form-item v-if="data.type==='htmlField'" :label="$locale.interface_editor.component_editor.registry_headers.html_template">
                      <el-input
                        class="htmlTemplate"
                        type="textarea"
                        :rows="2"
                        v-model="data.htmlTemplate">
                      </el-input>
                    </el-form-item>
                    <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.css_header">
                      <el-input
                        class="htmlTemplate"
                        type="textarea"
                        :rows="2"
                        v-model="data.cssHeader">
                      </el-input>
                    </el-form-item>
                      <editor-button-action
                        :value='data.action'
                        :is-headers="true"
                        :is-button="false"
                        :source-type="sourceType"
                        :source-id="sourceId"
                        :old-action-card="value[0].card"
                        :old-dashboard-action="value[0].dashboard"
                        @change="$set(data, 'action', $event)">
                      </editor-button-action>
                      <!-- Открывается редактор старых действий если они есть -->
                      <el-collapse-item
                        v-if="value[0].card || value[0].dashboard"
                        :title="$t('interface_editor.component_editor.button_action.action_old_title')"
                        class="editor-wrapper">
                        <el-form>
                          <el-form-item>
                            <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.click_column">
                        <editor-select
                          style="width: 100%"
                          :value="data.clickType"
                          @change="$set(data, 'clickType', $event);$set(data, 'dashboard', {})"
                          :options="{
                            multiple: false,
                            disabled: true,
                            options: [
                              { id: 'none', name: $locale.interface_editor.component_editor.registry_headers.none },
                              { id: 'open_dashboard', name: $locale.interface_editor.component_editor.button_action.open_dashbord },
                              { id: 'open_card', name: $locale.interface_editor.component_editor.button_action.open_card },
                            ]
                          }"
                        ></editor-select>
                      </el-form-item>
                            <template v-if="data.clickType === 'open_dashboard'">
                          <el-form-item>
                            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.dashbord }}</span>
                            <editor-dashboards @change="$set(data.dashboard ,'id', $event)" :value="data.dashboard.id" :disabled="true"></editor-dashboards>
                          </el-form-item>

                          <el-form-item>
                            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.frame }}</span>
                            <el-select v-model="data.dashboard.frameGuid" clearable disabled>
                              <el-option
                                v-for="(item, index) in components.filter((item) => item.name === 'basic/a-frame')"
                                :key="index"
                                :label="item.name"
                                :value="item.guid"
                              ></el-option>
                            </el-select>
                          </el-form-item>

                          <el-form-item class="checkbox">
                            <el-checkbox v-model="data.dashboard.breadcrumbByButton" :disabled="true"></el-checkbox>
                            <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.breadcrumb }}</span>
                          </el-form-item>

                          <el-form-item>
                            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_title }}</span>
                            <el-input v-model="data.dashboard.window_title" :disabled="true"/>
                          </el-form-item>

                          <el-form-item class="checkbox">
                            <el-checkbox v-model="data.dashboard.isFullscreen" :disabled="true"></el-checkbox>
                            <span class="property_label" style="display: inline-block;">{{ $locale.etl_editor.tooltip.full_screen }}</span>
                          </el-form-item>

                          <el-form-item>
                            <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_width }}</span>
                            <editor-select
                              :value="data.dashboard.window_width"
                              @change="$set(data.dashboard ,'window_width', $event)"
                              :options="{
                                multiple: false,
                                disabled: true,
                                options: [
                                  { id: '25', name:'25%' },
                                  { id: '50', name:'50%' },
                                  { id: '75', name:'75%' },
                                  { id: '100', name:'100%' },
                                ]
                              }"
                            ></editor-select>
                          </el-form-item>
                          <el-form-item>
                              <editor-options
                                :disabled="true"
                                :value="data.dashboard.defaults"
                                @change="$set(data.dashboard ,'defaults', $event)"
                                :title="$locale.main.fields.default"/>
                          </el-form-item>
                        </template>
                            <editor-open-card
                        v-if="data.clickType === 'open_card' && otherProperties.list.type !== 'requests'"
                        :disabled="true"
                        :isTreeSelect="true"
                        :value="data"
                        :isButton="false"
                        :listExtendObject="listExtendObject"
                        :xrefObjectId="xrefObjectId"></editor-open-card>
                            <div v-if="otherProperties.list.type === 'requests' && data.clickType === 'open_card'">
                          <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.registry_id">
                            <el-input type="text" size="small" v-model="data.requestRegistry" :disabled="true"></el-input>
                          </el-form-item>
                          <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.card_id">
                            <el-input type="text" size="small" v-model="data.requestCard" :disabled="true"></el-input>
                          </el-form-item>
                          <el-form-item :label="$locale.interface_editor.component_editor.editor_calendar.field_id">
                            <el-input type="text" size="small" v-model="data.requestRecord" :disabled="true"></el-input>
                          </el-form-item>
                        </div>
                          </el-form-item>
                        </el-form>
                      </el-collapse-item>
                  </el-form>
                  </el-scrollbar>
                </el-row>
                  <el-button icon="el-icon-s-tools" slot="reference"></el-button>
                </el-popover>
              </span>
            </span>
            </el-tree>
          </el-scrollbar>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import EditorSelect from './editor-select'
import EditorOpenCard from './editor-open-card'
import EditorDashboards from './editor-dashboards'
import EditorOptions from './editor-options.vue'
import EditorButtonAction from './editor-button-action'
import Separator from '@/components/MenuEditor/elementSubMenu/separator'
export default {
  name: 'editor-registry-headers',
  components: {
    Separator,
    EditorSelect,
    EditorOpenCard,
    EditorDashboards,
    EditorOptions,
    EditorButtonAction
  },
  inject: {
    getComponents: {
      default: () => {
        return []
      }
    }
  },
  props: ['other-properties', 'value', 'options', 'isGantt'],
  data () {
    return {
      components: this.getComponents(),
      dialogSettingsVisible: false,
      visiblePopover: {},
      treeProps: {
        children: 'children',
        label: 'text'
      },
      localModel: this.value || [],
      registryColumns: [],
      xrefObjectId: null,
      listExtendObject: null,
      columns: [],
      columnTypes: [
        { value: 'booleanField', name: 'Логическое' },
        { value: 'dateField', name: 'Дата' },
        { value: 'datetimeField', name: 'Дата+Время' },
        { value: 'floatField', name: 'Дробное' },
        { value: 'integerField', name: 'Число' },
        { value: 'stringField', name: 'Строка' },
        { value: 'fileField', name: 'Файл' },
        { value: 'textField', name: 'Текст' },
        { value: 'timeField', name: 'Время' },
        { value: 'indicatorField', name: 'Индикатор' },
        { value: 'progressField', name: 'Прогресс' },
        { value: 'monthField', name: 'Месяц' },
        { value: 'htmlField', name: 'HTML' }
      ]
    }
  },
  computed: {
    selectedColumnIds () {
      return this.columns.map(item => item.id)
    },
    sourceType () {
      if (this.otherProperties && this.otherProperties.list) {
        if (this.otherProperties.list.extendObjectId) {
          return 'extended_object'
        } else if (this.otherProperties.list.requestsId) {
          return 'query'
        }
      }
      return 'registry'
    },
    sourceId () {
      if (this.xrefObjectId) {
        return this.xrefObjectId
      } else if (this.otherProperties && this.otherProperties.list) {
        if (this.otherProperties.list.extendObjectId) {
          return this.otherProperties.list.extendObjectId
        } else if (this.otherProperties.list.requestsId) {
          return this.otherProperties.list.requestsId
        }
      } else if ((this.options || {}).objectId) {
        return this.options.objectId
      }
      return ''
    }
  },
  watch: {
    options: {
      handler () {
        this.loadRegistryColumns()
      },
      deep: true
    }
  },
  async mounted () {
    if (!this.otherProperties.list) {
      this.$set(this.otherProperties, 'list', { type: '' })
    }

    await this.loadRegistryColumns()
  },
  methods: {
    async loadRegistryColumns () {
      this.columns = []
      if (this.otherProperties && this.otherProperties.list && this.otherProperties.list.extendObjectId) {
        let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects/${this.otherProperties.list.extendObjectId}`)
        this.listExtendObject = response.data.extended_object_fields
      }
      this.parseTree(this.localModel)
      let xrefObjectId = null
      if ((this.options || {}).objectId) {
        xrefObjectId = this.options.objectId
      } else {
        if (!(this.otherProperties || {}).registry_properties) {
          return false
        }
        let xref = this.otherProperties.registry_properties.find((item) => item.id === 'xref')
        if (!xref || !xref.value) {
          return false
        }
        let settings = await this.$http.get(`${this.$config.api}/objecteditor/entities/${xref.value}`)
        xrefObjectId = settings.data.data.object_id
      }
      if (!xrefObjectId) {
        return false
      }
      let fields = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${xrefObjectId}&show_children`)
      fields = fields.data.data
      this.xrefObjectId = xrefObjectId
      this.registryColumns = this.buildOptions(fields)
    },
    showPopover (id) {
      for (let key in this.visiblePopover) {
        this.visiblePopover[key] = false
      }
      this.visiblePopover[id] = true
    },
    addCustomColumn (parentId = null) {
      this.addColumn({
        id: 'c_' + parseInt(Math.max(...(
          this.columns.filter(item => item.isCustom).map(item => parseInt(item.id.match(/\d+/g)[0])).length > 0
            ? this.columns.filter(item => item.isCustom).map(item => parseInt(item.id.match(/\d+/g)[0])) : [0]
        )) + 1),
        parentId: parentId,
        isCustom: true,
        text: 'Новый столбец',
        type: 'stringField',
        value: null,
        underLabel: null
      })
    },
    allowDrop (draggingNode, dropNode, type) {
      return type !== 'inner'
    },
    deleteColumn (column, buildTree = true) {
      if (column.children && column.children.length > 0) {
        column.children.forEach((item) => this.deleteColumn(item, false))
      }

      this.columns = this.columns.filter(item => item.id !== column.id)

      if (buildTree) {
        this.localModel = this.buildTree(JSON.parse(JSON.stringify(this.columns)))
      }
      this.onChange()
    },
    addColumn (column, buildTree = true) {
      if (column.children && column.children.length > 0) {
        column.children.forEach((item) => this.addColumn(item, false))
      }
      column.children = []
      if (!this.columns.find(item => item.id === column.id)) {
        this.columns.push(column)
      }

      if (buildTree) {
        this.localModel = this.buildTree(JSON.parse(JSON.stringify(this.columns)))
      }
      this.onChange()
    },
    buildTree (data) {
      let build = function (node) {
        let toDelete = []
        data.forEach((item, index, array) => {
          if (node.id === item.parentId && item.id !== node.id) {
            node.children = (node.children || []).concat(item)
            toDelete.push(item.id)
          }
        })
        data = data.filter(item => !toDelete.includes(item.id))
        if (node.children && node.children.length > 0) {
          node.children.forEach((item) => build(item))
        }
      }

      data.forEach((item) => build(item))
      return data
    },
    parseTree (data) {
      let me = this
      data.forEach((item) => {
        let a = JSON.parse(JSON.stringify(item))

        if (item.children && item.children.length > 0) {
          me.parseTree(item.children)
        }
        a.children = []
        me.columns.push(Object.assign({}, a))
      })
    },
    buildOptions (options, parentId = null) {
      let me = this
      return options.map((column) => {
        let obj = {
          id: column.id,
          text: column.name,
          underLabel: column.underLabel || null,
          type: me.toCamelCase(column.entity_type_id),
          value: `attr_${column.id}_`,
          parentId: parentId
        }
        if (column.children.length > 0) {
          obj.children = me.buildOptions(column.children, column.id)
        }

        return obj
      })
    },
    onChange () {
      this.$emit('change', this.localModel)
    },
    toCamelCase (s) {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    }
  }
}
</script>

<style>
.editor_registry_header > .el-dialog {
  width: 60%;
}
.htmlTemplate textarea {
    color: rgb(30, 51, 175);
    /* font-family: 'Courier New'; */
    font-size: 14px;
    word-break: break-word;
}
@media screen and (max-width: 1400px) {
.editor_registry_header > .el-dialog{
    width: 90%;
  }
}
</style>
