import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import RoleQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/RoleQueryRepositoryInterface'
import store from '@/store'
import { GetRoles, CountRoles, GetRoleByGuid, GetProfile } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'


export default class RoleQueryRepository extends AbstractQueryRepository implements RoleQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetRoles(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountRoles(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetRoleByGuid(guid));
    }

    getProfile(roleId: number): Promise<any>
    {
        return store.dispatch(new GetProfile(roleId));
    }
}