import { Nullable } from '@/core/domain/type/types'

export interface INotification {
  id?: number;
  guid?: string;
  name: string;
  object_id: number;
  condition_type: string;
  condition_match_type: string
  notification_condition_states: number[];
  title_formula_id: Nullable<number>;
  content_formula_id: Nullable<number>;
  author_type: string;
  author_field_id: Nullable<number>;
  author_state_id: Nullable<number>;
  author_id: Nullable<number>;
  recipient_type: string;
  recipient_match_type: Nullable<string>;
  notification_recipient_states: number[];
  notification_recipient_fields: number[];
  notification_recipients: number[];
  period_type_id: Nullable<string>;
  icon: Nullable<string>;
  email_type: Nullable<string>;
  email_field_id: Nullable<number>;
  email_value: Nullable<string>;
  interaction_type: Nullable<string>;
  card_operation_type: Nullable<string>;
  external_object_id: Nullable<number>;
  external_card_id: Nullable<number>;
  current_field_id: Nullable<number>;
  shows_popup_message: boolean;
  group_id: Nullable<number>;
  current_card_id: Nullable<number>;

  title_formula: Nullable<object>;
  content_formula: Nullable<object>;
}

export default class NotificationDTO {
  public id?: number;
  public guid?: string;

  public name: string;

  public object_id: number;

  public condition_type: string;
  public condition_match_type: string
  public notification_condition_states: number[];

  public title_formula_id: Nullable<number>;
  public content_formula_id: Nullable<number>;

  public author_type: string;
  public author_field_id: Nullable<number>;
  public author_state_id: Nullable<number>;
  public author_id: Nullable<number>;

  public recipient_type: string;
  public recipient_match_type: Nullable<string>;
  public notification_recipient_states: number[];
  public notification_recipient_fields: number[];
  public notification_recipients: number[];

  public period_type_id: string;

  public icon: Nullable<string>;

  public email_type: Nullable<string>;
  public email_field_id: Nullable<number>;
  public email_value: Nullable<string>;

  public interaction_type: Nullable<string>;
  public card_operation_type: Nullable<string>;
  public external_object_id: Nullable<number>;
  public external_card_id: Nullable<number>;
  public current_field_id: Nullable<number>;

  public shows_popup_message: boolean;

  public group_id: Nullable<number>;
  public current_card_id: Nullable<number>;

  public title_formula: Nullable<object>;
  public content_formula: Nullable<object>;

  constructor (data: INotification) {
    this.id = data?.id
    this.guid = data?.guid

    this.name = data.name

    this.object_id = data.object_id

    this.condition_type = data.condition_type
    this.condition_match_type = data.condition_match_type
    this.notification_condition_states = data.notification_condition_states

    this.title_formula_id = data.title_formula_id
    this.content_formula_id = data.content_formula_id

    this.author_type = data.condition_type
    this.author_field_id = data.author_field_id
    this.author_state_id = data.author_state_id
    this.author_id = data.author_id

    this.recipient_type = data.recipient_type
    this.recipient_match_type = data.recipient_match_type
    this.notification_recipient_states = data.notification_recipient_states
    this.notification_recipient_fields = data.notification_recipient_fields
    this.notification_recipients = data.notification_recipients

    this.period_type_id = data.period_type_id

    this.icon = data.icon

    this.email_type = data.email_type
    this.email_field_id = data.email_field_id
    this.email_value = data.email_value

    this.interaction_type = data.interaction_type
    this.card_operation_type = data.card_operation_type
    this.external_object_id = data.external_object_id
    this.external_card_id = data.external_card_id
    this.current_field_id = data.current_field_id

    this.shows_popup_message = data.shows_popup_message

    this.group_id = data.group_id
    this.current_card_id = data.current_card_id

    this.title_formula = data.title_formula
    this.content_formula = data.title_formula
  }

  static create (groupId: Nullable<number> = null): NotificationDTO {
    return new NotificationDTO({
      name: null,

      object_id: null,

      condition_type: 'state',
      condition_match_type: 'any',
      notification_condition_states: [],

      title_formula_id: null,
      content_formula_id: null,

      author_type: 'user',
      author_field_id: null,
      author_state_id: null,
      author_id: null,

      recipient_type: 'field',
      recipient_match_type: 'any',
      notification_recipient_states: [],
      notification_recipient_fields: [],
      notification_recipients: [],

      period_type_id: 'once',

      icon: null,

      email_type: null,
      email_field_id: null,
      email_value: null,

      interaction_type: null,
      card_operation_type: null,
      external_object_id: null,
      external_card_id: null,
      current_field_id: null,
      current_card_id: null,

      shows_popup_message: false,

      group_id: groupId,

      title_formula: null,
      content_formula: null
    })
  }
}
