import TimerCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/TimerCommandRepositoryInterface'
import store from '@/store'
import { CreateTimer, UpdateTimer, DeleteTimer } from '@/services/LogicEditor/infrastructure/store/modules/Timer/action-types'
import { TimerDTO } from '@/services/LogicEditor/domain/model/Timer'

export default class TimerCommandRepository implements TimerCommandRepositoryInterface {
  insert (item: TimerDTO): Promise<void> {
    return store.dispatch(new CreateTimer(item))
  }

  update (item: TimerDTO): Promise<void> {
    return store.dispatch(new UpdateTimer(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteTimer(guid))
  }
}
