import { ActionTree } from 'vuex'
import { TimerState, initialState } from './state'
import { ActionTypes } from './action-types'
import {
  MutationTypes,
  TimerUpdatedEvent,
  TimerCreatedEvent,
  TimerLoadingEvent,
  TimerDeletedEvent,
  TimerLoadedEvent
} from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { TimerAPI } from '@/services/LogicEditor/infrastructure/api/TimerAPI'

export const actions: ActionTree<TimerState, any> = {
  [ActionTypes.GET_STATE_BY_GUID]: async ({ commit, dispatch }, data) => {
    commit(new TimerLoadingEvent())

    return APIClient.shared.request(new TimerAPI.GetTimerByGuid(data.guid))
      .then((response) => {
        commit(new TimerLoadedEvent())
        return response
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.CREATE_STATE]: async ({ commit, dispatch }, data) => {
    commit(new TimerLoadingEvent())

    return APIClient.shared.request(new TimerAPI.CreateTimer(data.payload))
      .then((response: string) => {
        commit(new TimerCreatedEvent(response))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.UPDATE_STATE]: async ({ commit, dispatch }, data) => {
    commit(new TimerLoadingEvent())

    return APIClient.shared.request(new TimerAPI.UpdateTimer(data.payload))
      .then((response: string) => {
        commit(new TimerUpdatedEvent(response))
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  },
  [ActionTypes.DELETE_STATE]: async ({ commit, dispatch }, data) => {
    commit(new TimerLoadingEvent())

    return APIClient.shared.request(new TimerAPI.DeleteTimer(data.guid))
      .then((response) => {
        commit(new TimerDeletedEvent())
      })
      .catch((reason) => {
        commit(MutationTypes.ERROR, reason)

        throw reason
      })
  }
}
