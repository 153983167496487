import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import TimerCreateCommand from '@/services/LogicEditor/application/command/TimerCreateCommand'
import TimerCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/TimerCommandRepositoryInterface'

export default class TimerCreateHandler implements CommandHandlerInterface {
    private repository: TimerCommandRepositoryInterface;

    constructor (repository: TimerCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TimerCreateCommand): Promise<void> {
      return this.repository.insert({
        name: command.name,
        description: command.description,
        alias: command.alias,
        period: command.period,
        commands: command.commands,
        logic_id: command.logicId,
        parent_tree_element_id: command.parentTreeElementId
      })
    }
}
