<template>
  <div class="user-panel">
    <el-dialog
        title="Импорт пользователей из Excel-файлов"
        :visible.sync="isUploadUsersWindowVisible"
        class="upload-users-window"
        @close="closeUploadUserWindow"
        :close-on-click-modal="true"
      >
      <el-row>
        <treeselect
            v-model="userUploadDto.role_id"
            style="width:100%"
            :placeholder="$locale.access_editor.users_item.role"
            :normalizer="(node) => {return {id: node.id, label: node.name}}"
            :options="rolesList"
            :clear-value-text="$locale.main.message.clear"
            :loading-text="$locale.main.message.loading"
            :disable-immediate-search="true"
            :async="true"
            :cache-options="false"
            :append-to-body="false"
            :load-options="getRolesList"
            :clearable="false"
            :delete-removes="false"
            :backspace-removes="false"
            @open="getRolesList"
        ></treeselect>
      </el-row>
      <el-row class="dynamical-login">
        <el-checkbox v-model="userUploadDto.is_login_dynamic" :label="$locale.access_editor.users_item.is_login_dynamic" name="is_login_dynamic"></el-checkbox>
      </el-row>
      <el-row class="footer">
        <el-upload
            ref="upload_users"
            :auto-upload="false"
            :multiple="false"
            action="/"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >

            <el-button size="small" type="primary">{{$locale.main.button.choose_file}}</el-button>
        </el-upload>
        <el-button size="small" type="success" @click="importUsers">{{$locale.main.button.upload}}</el-button>
      </el-row>
    </el-dialog>
    <div v-show="visiblePanel.list">
      <el-container class="tools">
        <div class="controls">
          <el-button icon="icon-plus-blue" @click="createUser"></el-button>
          <el-button icon="icon-edit-blue" @click="updateUser"></el-button>
          <el-button icon="icon-delete-blue" @click="deleteUser"></el-button>
          <span class="accent-splitter">|</span>
          <el-button icon="icon-upload-blue" @click="uploadUsers"></el-button>
        </div>
        <div class="filters">
          <div class="role-filter">
            <treeselect
                v-model="usersFilters.role"
                :placeholder="$locale.access_editor.users_item.role"
                :normalizer="(node) => {return {id: node.id, label: node.name}}"
                :options="rolesList"
                :clear-value-text="$locale.main.message.clear"
                :loading-text="$locale.main.message.loading"
                :disable-immediate-search="true"
                :async="true"
                :cache-options="false"
                :append-to-body="false"
                :load-options="getRolesList"
                :clearable="true"
                :delete-removes="false"
                :backspace-removes="false"
                @open="getRolesList"
                @input="applyFilters('role', usersFilters.role)"
            ></treeselect>
          </div>
          <div class="text-filter">
            <el-input v-model="usersFilters.fio" autocomplete="off" clearable @change="applyFilters('fio', usersFilters.fio)"></el-input>
          </div>
        </div>
      </el-container>
      <el-row class="main-table user-table">
        <el-col class="wrapper">
          <el-table
              :indent="0"
              class="registry custom_scrollbar"
              v-loading="loading"
              :data="users"
              stripe
              border
              ref="users_table"
              row-key="guid"
              current-row-key="guid"
              highlight-current-row
              @current-change="changeUser"
          >
            <el-table-column
                prop="id"
                :label="$locale.main.fields.id"
                width="60"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="surname"
                :label="$locale.access_editor.users_item.surname"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="name"
                :label="$locale.access_editor.users_item.name"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="midname"
                :label="$locale.access_editor.users_item.midname"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="email"
                :label="$locale.access_editor.users_item.email"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="role_name"
                :label="$locale.access_editor.users_item.role"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="is_admin"
                :label="$locale.access_editor.users_item.admin"
                header-align="center"
                align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.is_admin ? 'checkbox-filled' : 'checkbox-empty'"></span>
              </template>
            </el-table-column>
            <el-table-column
                prop="is_system"
                :label="$locale.access_editor.users_item.system"
                header-align="center"
                align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.is_system ? 'checkbox-filled' : 'checkbox-empty'"></span>
              </template>
            </el-table-column>
            <el-table-column
                prop="is_blocked"
                :label="$locale.access_editor.users_item.block"
                header-align="center"
                align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.is_blocked ? 'checkbox-filled' : 'checkbox-empty'"></span>
              </template>
            </el-table-column>
          </el-table>
          <el-footer>
            <el-pagination
                class="user-pagination"
                :page-size="usersPageSize"
                :layout="'total, prev, pager, next'"
                :total="usersCount"
                @current-change="handleUsersPageChange"
            ></el-pagination>
          </el-footer>
        </el-col>
      </el-row>
    </div>
    <div v-show="visiblePanel.edit">
      <UserMainEditPanel
          :rolesList="rolesList"
          :cardParams="cardParams"
      ></UserMainEditPanel>
      <hr>
    </div>
  </div>
</template>

<script>
import Treeselect from '@bingosoftnn/vue-treeselect'
import User, { UserDTO, UserUploadDTO } from '@/services/AccessEditor/domain/model/User/User'
import UserDeleteCommand from '@/services/AccessEditor/application/command/UserDeleteCommand'
import UsersUploadFromFileCommand from '@/services/AccessEditor/application/command/UsersUploadFromFileCommand'
import UsersQuery from '@/services/AccessEditor/application/query/UsersQuery'
import UsersCountQuery from '@/services/AccessEditor/application/query/UsersCountQuery'
import UserMainEditPanel from '@/services/AccessEditor/infrastructure/components/UserPanel/UserMainEditPanel/index.vue'


export default {
  name: 'UserPanel',
  components: {
    Treeselect,
    UserMainEditPanel
  },
  props: {
    rolesList: {
      type: Array,
      required: true
    },
    cardParams: {
      type: Object,
      required: true
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      visiblePanel: {
        "list": true,
        "edit": false
      },
      loading: false,
      users: [],
      user: null,
      userDto: null,
      usersPageSize: 0,
      usersCurrentPage: 0,
      usersPageLimit: 100,
      usersCount: 0,
      allUsersCount: 0,
      userUploadDto: new UserUploadDTO({}),
      isUploadUsersWindowVisible: false,
      usersFilters: {
        role: null,
        fio: ''
      }
    }
  },
  computed: {
    userCreated() {
      return this.$store.getters['User/getLocation'];
    },
    getSaveRoleError() {
      return this.$store.getters['User/getError'];
    }
  },
  watch: {
    rolesList: {
      handler: function(data) {

      },
      deep: true
    },
    userCreated: function (location) {
      this.usersCurrentPage = 0;
      this.loadPageUsers().then(async () => {
        if (this.users.length > 0) {
          this.$refs.users_table.setCurrentRow(this.users[0]);
        }
      });
    },
  },
  methods: {
    getRolesList() {
      return this.rolesList;
    },
    loadUsers(callback) {
      if (this.allUsersCount == 0) {
        this.countAndLoadUsers(callback);
      }
      else {
        this.loadPageUsers(callback);
      }
    },
    async countAndLoadUsers(callback) {
      await this.getQueryBus().execute(
        new UsersCountQuery(this.prepareUrlQueryParams(true))
      ).then(data => {
        this.usersCount = data[0].count;
        this.loadPageUsers(callback);
      });
    },
    async loadPageUsers(callback) {
      await this.getQueryBus().execute(
        new UsersQuery(this.prepareUrlQueryParams())
      ).then(data => {
        this.usersPageSize = this.usersPageLimit;
        this.users = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    handleUsersPageChange(val) {
      val--;
      this.usersCurrentPage = (val * this.usersPageLimit);
      this.loadUsers();
    },
    changeUser(selectedUser) {
      this.user = User.create(selectedUser);
      this.userDto = selectedUser;
    },
    createUser() {
      this.userDto = new UserDTO({});
      this.getEventBus().$emit('editUserEvent', this.userDto);
      this.visiblePanel.list = false;
      this.visiblePanel.edit = true;
    },
    updateUser() {
      if (this.user !== null) {
        this.getEventBus().$emit('editUserEvent', this.userDto);
        this.visiblePanel.list = false;
        this.visiblePanel.edit = true;
      }
      else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    deleteUser() {
      if (this.user == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
      else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new UserDeleteCommand(
              this.user.getGuid()
            )
          ).then(async () => {
            this.user = null;
            this.userDto = new UserDTO({});
            this.$refs.users_table.setCurrentRow(null);
            this.loadUsers();
          });
        }).catch((error) => { console.log(error); })
      }
    },
    uploadUsers () {
      this.userUploadDto = new UserUploadDTO({});
      this.isUploadUsersWindowVisible = true;
    },
    importUsers () {
      let me = this;
      if (this.$refs.upload_users.uploadFiles.length) {
        this.$refs.upload_users.uploadFiles.forEach((file) => {
          me.getCommandBus().execute(
            new UsersUploadFromFileCommand(
              me.userUploadDto.role_id,
              me.userUploadDto.is_login_dynamic,
              file.raw
            )
          ).then((response) => {
            me.user = null;
            me.$refs.users_table.setCurrentRow(null);
            me.userUploadDto = new UserUploadDTO({});
            me.isUploadUsersWindowVisible = false;
            me.loadUsers();
          });
        });
      }
    },
    closeUploadUserWindow() {
      this.isUploadUsersWindowVisible = false;
      this.userUploadDto = new UserUploadDTO({});
    },
    applyFilters(type, value) {
      if (type == 'role') {
        this.usersFilters.role = value;
        if (typeof this.usersFilters.role == 'undefined') {
          this.usersFilters.role = null
        }
      }
      if (type == 'fio') {
        this.usersFilters.fio = value;
        if (typeof this.usersFilters.fio == 'undefined') {
          this.usersFilters.fio = ''
        }
      }
      this.loadUsers();
    },
    prepareUrlQueryParams(forCount = false) {
      let params = {};
      if (!forCount) {
        params['limit'] = this.usersPageLimit;
        params['offset'] = this.usersCurrentPage;
      }
      if (this.usersFilters.role != null) {
        params['role_id'] = this.usersFilters.role;
      }
      if (this.usersFilters.fio != '') {
        params['filter_fio'] = this.usersFilters.fio;
      }
      return params;
    }
  },
  mounted () {
    this.loadUsers();
    this.getEventBus().$on('returnToUserListEvent', (data) => {
      this.visiblePanel = data
    });
    this.getEventBus().$on('userIsUpdated', (data) => {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].guid == data.guid) {
          this.users[i] = data;
          break;
        }
      }
    });
  }
}
</script>
