import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import UserCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/UserCommandRepositoryInterface'
import UserCreateCommand from '@/services/AccessEditor/application/command/UserCreateCommand'
import { UserDTO } from '@/services/AccessEditor/domain/model/User/User'

export default class UserCreateHandler implements CommandHandlerInterface
{
    private repo: UserCommandRepositoryInterface;

    constructor(repo: UserCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: UserCreateCommand): Promise<void>
    {
        let item: UserDTO = {
            role_id: command.getRoleId(),
            name: command.getName(),
            midname: command.getMidname(),
            surname: command.getSurname(),
            avatar: command.getAvatar(),
            login: command.getLogin(),
            password: command.getPassword(),
            email: command.getEmail(),
            is_admin: command.getIsAdmin(),
            is_blocked: command.getIsBlocked(),
            is_system: command.getIsSystem(),
            api_key: command.getApiKey(),
            system_ips: command.getSystemIps(),
            phone: command.getPhone(),
            two_factor_auth_type: command.getTwoFactorAuthType()
        };
        return this.repo.insert(item);
    }
}
