import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class ApprovalStageDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name?: Nullable<string>
  description?: Nullable<string>
  approval_id?: Nullable<number>
  close_commands?: Nullable<Array<number>>
  approve_commands?: Nullable<Array<number>>
  approve_button_id?: Nullable<number>
  approve_text?: Nullable<string>
  cancel_commands?: Nullable<Array<number>>
  cancel_button_id?: Nullable<number>
  cancel_text?: Nullable<string>
  approval_cancel_stages?: Nullable<Array<number>>
  approval_blocking_stages?: Nullable<Array<number>>
  approval_stage_type_id?: Nullable<string>
  approval_stage_members?: Nullable<Array<number>>
  member_field_id?: Nullable<number>
  member_state_id?: Nullable<number>
  return_stage_id?: Nullable<number>
  is_approvable_by_all?: Nullable<boolean>

  constructor ({
    id = undefined,
    guid = undefined,
    name,
    description,
    approval_id,
    close_commands,
    approve_commands,
    approve_button_id,
    approve_text,
    cancel_commands,
    cancel_button_id,
    cancel_text,
    approval_cancel_stages,
    approval_blocking_stages,
    approval_stage_type_id,
    approval_stage_members,
    member_field_id,
    member_state_id,
    return_stage_id,
    is_approvable_by_all
  }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.approval_id = approval_id
    this.close_commands = close_commands
    this.approve_commands = approve_commands
    this.approve_button_id = approve_button_id
    this.approve_text = approve_text
    this.cancel_commands = cancel_commands
    this.cancel_button_id = cancel_button_id
    this.cancel_text = cancel_text
    this.approval_cancel_stages = approval_cancel_stages
    this.approval_blocking_stages = approval_blocking_stages
    this.approval_stage_type_id = approval_stage_type_id
    this.approval_stage_members = approval_stage_members
    this.member_field_id = member_field_id
    this.member_state_id = member_state_id
    this.return_stage_id = return_stage_id
    this.is_approvable_by_all = is_approvable_by_all
  }

  static create (name: Nullable<string> = null, approvalId: Nullable<number> = null) {
    return new ApprovalStageDTO({
      name: name,
      description: null,
      approval_id: approvalId,
      close_commands: [],
      approve_commands: [],
      approve_button_id: null,
      approve_text: null,
      cancel_commands: [],
      cancel_button_id: null,
      cancel_text: null,
      approval_cancel_stages: [],
      approval_blocking_stages: [],
      approval_stage_type_id: 'field',
      approval_stage_members: [],
      member_field_id: null,
      member_state_id: null,
      return_stage_id: null,
      is_approvable_by_all: false
    })
  }
}

export default class ApprovalStage extends AbstractBaseEntity {
  constructor (
    private name: string,
    private description: Nullable<string>,
    private approvalId: number,
    private memberFieldId: Nullable<number>,
    private memberStateId: Nullable<number>,
    private approvalStageTypeId: string,
    private approveText: Nullable<string>,
    private cancelText: Nullable<string>,
    private approveButtonId: Nullable<number>,
    private cancelButtonId: Nullable<number>,
    private isApprovableByAll: boolean,
    private approveCommands: number[],
    private cancelCommands: number[],
    private closeCommands: number[],
    private approvalStageMembers: number[],
    private blockingStages: number[],
    private cancelStages: number[],
    private returnStageId: Nullable<number>,
    private id?: Nullable<number>,
    private guid?: Nullable<string>
  ) {
    super()
  }

  static create (dto: ApprovalStageDTO): ApprovalStage {
    return new ApprovalStage(
      dto.name,
      dto.description,
      dto.approval_id,
      dto.member_field_id,
      dto.member_state_id,
      dto.approval_stage_type_id,
      dto.approve_text,
      dto.cancel_text,
      dto.approve_button_id,
      dto.cancel_button_id,
      dto.is_approvable_by_all,
      dto.approve_commands,
      dto.cancel_commands,
      dto.close_commands,
      dto.approval_stage_members,
      dto.approval_blocking_stages,
      dto.approval_cancel_stages,
      dto.return_stage_id,
      dto.id,
      dto.guid
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  setDescription (description: Nullable<string>): void {
    this.description = description
  }

  /**
   * @return number
   */
  getApprovalId (): number {
    return this.approvalId
  }

  setApprovalId (approvalId: number): void {
    this.approvalId = approvalId
  }

  /**
   * @return string
   */
  getApprovalStageTypeId (): string {
    return this.approvalStageTypeId
  }

  setApprovalStageTypeId (type: string): void {
    this.approvalStageTypeId = type
  }

  /**
   * @return string|null
   */
  getCancelText (): Nullable<string> {
    return this.cancelText
  }

  setCancelText (text: Nullable<string>): void {
    this.cancelText = text
  }

  /**
   * @return number|null
   */
  getCancelButtonId (): Nullable<number> {
    return this.cancelButtonId
  }

  setCancelButtonId (buttonId: Nullable<number>): void {
    this.cancelButtonId = buttonId
  }

  /**
   * @return number|null
   */
  getApproveButtonId (): Nullable<number> {
    return this.approveButtonId
  }

  setApproveButtonId (buttonId: Nullable<number>): void {
    this.approveButtonId = buttonId
  }

  /**
   * @return string|null
   */
  getApproveText (): Nullable<string> {
    return this.approveText
  }

  setApproveText (text: Nullable<string>): void {
    this.approveText = text
  }

  /**
   * @return number|null
   */
  getMemberFieldId (): Nullable<number> {
    return this.memberFieldId
  }

  setMemberFieldId (fieldId: Nullable<number>): void {
    this.memberFieldId = fieldId
  }

  /**
   * @return number|null
   */
  getMemberStateId (): Nullable<number> {
    return this.memberStateId
  }

  setMemberStateId (stateId: Nullable<number>): void {
    this.memberStateId = stateId
  }

  /**
   * @return number[]
   */
  getApprovalStageMembers (): number[] {
    return this.approvalStageMembers
  }

  setApprovalStageMembers (members: number[]): void {
    this.approvalStageMembers = members
  }

  /**
   * @return number[]
   */
  getApproveCommands (): number[] {
    return this.approveCommands
  }

  setApproveCommands (commands: number[]): void {
    this.approveCommands = commands
  }

  /**
   * @return number[]
   */
  getBlockingStages (): number[] {
    return this.blockingStages
  }

  setBlockingStages (stages: number[]): void {
    this.blockingStages = stages
  }

  /**
   * @return number[]
   */
  getCancelCommands (): number[] {
    return this.cancelCommands
  }

  setCancelCommands (commands: number[]): void {
    this.cancelCommands = commands
  }

  /**
   * @return number[]
   */
  getCloseCommands (): number[] {
    return this.closeCommands
  }

  setCloseCommands (commands: number[]): void {
    this.closeCommands = commands
  }

  /**
   * @return number[]
   */
  getCancelStages (): number[] {
    return this.cancelStages
  }

  setCancelStages (stages: number[]): void {
    this.cancelStages = stages
  }

  getReturnStageId (): Nullable<number> {
    return this.returnStageId
  }

  setReturnStageId (returnStageId: Nullable<number>): void {
    this.returnStageId = returnStageId
  }

  /**
   * @return bool
   */
  getIsApprovableByAll (): boolean {
    return this.isApprovableByAll
  }

  setIsApprovableByAll (is: boolean): void {
    this.isApprovableByAll = is
  }
}
