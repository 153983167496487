<template>
  <div>
    <h1 style="margin-top: 10px">{{$locale.logic_editor.constraint.disabled_constraints.headline}}</h1>
    <div class="constraint_block__item2">
      <div class="demonstration">{{$locale.logic_editor.constraint.name}}</div>
      <el-input size="mini" class="constraint__row" v-model="constraint.name"></el-input>
    </div>
    <div class="constraint_block__item">
      <div class="constraint_block__flex">
        <div class="demonstration">{{$locale.logic_editor.constraint.condition}}</div>
        <el-switch
          :active-text="$locale.logic_editor.constraint.allow"
          :inactive-text="$locale.logic_editor.constraint.ban"
          style="margin-left: 10px; height: 15px;"
          v-model="constraint.is_allow"></el-switch>
      </div>
      <el-select
        multiple
        collapse-tags
        clearable
        filterable
        value-key="id"
        class="constraint__row"
        v-model="constraint.constraint_states"
        size="mini"
      >
        <el-option v-for="item in listState" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="constraint_block__item">
      <div class="demonstration">{{$locale.logic_editor.constraint.card}}</div>
      <el-select
        multiple
        collapse-tags
        clearable
        filterable
        value-key="id"
        class="constraint__row"
        v-model="constraint.constraint_cards"
        size="mini"
      >
        <el-option v-for="item in listCards" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="constraint_block__item">
      <div class="demonstration">{{$locale.logic_editor.constraint.roles}}</div>
      <el-select
        multiple
        collapse-tags
        clearable
        filterable
        value-key="id"
        class="constraint__row"
        v-model="constraint.constraint_roles"
        size="mini"
      >
        <el-option v-for="item in listRoles" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="constraint_block__item">
      <div class="demonstration">{{$locale.logic_editor.constraint.disabled_constraints.disabled_field}}</div>
      <el-select
        multiple
        collapse-tags
        clearable
        filterable
        value-key="id"
        class="constraint__row"
        v-model="constraint.disable_fields"
        size="mini"
      >
        <el-option v-for="item in listFields" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <el-footer class="constraint__footer">
      <el-button
        @click="saveCard"
        :disabled='constraint.name === ""'
        type="primary"
      >{{ $locale.main.button.save }}</el-button>
    </el-footer>
  </div>
</template>

<script>
// import Role from '../Models/accesseditor/Role'
import States from '../Models/States'
import Card from '../Models/interfaceeditor/Card'
import Сonstraints from '../Models/Constraints'
import Registry from '../Models/Registry'
// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RoleAPI } from '@/services/AccessEditor/infrastructure/api/RoleAPI'
export default {
  name: 'disable_constraint',
  props: {
    propslocal: {
      // новое действие
      isNewAction: {
        type: Boolean,
        required: true
      },
      // выбранная бизнес логика
      businesslogic: {
        type: Object,
        required: true
      },
      // выбранная SubAction
      selectSubAction: {
        type: Object
      },
      // открытое действие
      listOpenAction: {
        type: Object
      },
      // значение полей от сервера
      getAnswer: {
        type: Object
      }
    }
  },
  data () {
    return {
      // открытое действие
      addAction: this.propslocal.listOpenAction,
      constraint: {
        name: '',
        logic_id: this.propslocal.businesslogic.id,
        constraint_type_id: 'disable_constraint',
        is_allow: false,
        disable_fields: [],
        constraint_cards: null,
        constraint_states: null,
        constraint_roles: null
      },
      listState: [],
      listRoles: [],
      listCards: [],
      listFields: []
    }
  },
  methods: {
    async saveCard () {
      // новое subAction
      if (this.propslocal.isNewAction) {
        let constraints = new Сonstraints({}, this.constraint)
        constraints.is_allow = this.constraint.is_allow
        let response = await constraints.save()
        this.addAction.items.push(response)
      } else {
        let id
        this.propslocal.selectSubAction.id
          ? id = this.propslocal.selectSubAction.id
          : id = this.propslocal.selectSubAction[this.propslocal.selectSubAction.length - 1].id
        let constraints = new Сonstraints({ id: id }, this.constraint)
        constraints.is_allow = this.constraint.is_allow
        let response = await constraints.save()
        this.addAction.items
          ? (this.addAction.items.filter(el => el.id === id)[0].name = response.name)
          : (this.addAction.filter(el => el.id === id)[0].name = response.name)
      }
      this.propslocal.isNewAction = false
    }
  },
  async mounted () {
    let idBusinesslogic = this.propslocal.businesslogic.id
    const id = this.propslocal.businesslogic.registry_id
    // список состояний текущей бизнес логики
    let states = await States.params({ logic_id: idBusinesslogic }).get()
    this.listState = states
    // список карточек реестра
    let card = await Card.params({ entity_id: id }).get()
    this.listCards = card
    // список ролей
    // let role = await Role.params({ fields: 'id,name' }).get()
    let role = []
    try {
      role = await APIClient.shared.request(new RoleAPI.GetRoles([['fields', 'id,name']]))
    } catch (error) {
      console.log({ error })
    }
    this.listRoles = role
    // поля текущей логики
    let currentdObject = await Registry.params({ object_id: id, is_field: 'true' }).get()
    this.listFields = currentdObject.data
    // редактирование
    if (!this.propslocal.isNewAction) {
      this.constraint = this.propslocal.getAnswer
    }
  }
}
</script>

<style scoped>
.constraint_block__item2 {
  margin-top: 30px;
  width: 65%;
}
.constraint_block__item {
  margin-top: 15px;
  width: 65%;
}
.constraint_block__item3 {
  margin-top: 15px;
  width: 71%;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
.constraint__row {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
.constraint__footer {
  text-align: right;
  margin-top: 35px;
  margin-right: 25%;
}
.constraint_block__flex {
  display: flex;
}
/* .constraint__row2 {
  display: inline-block;
  position: relative;
  width: 29%;
  margin: 10px;
  margin-top: 0px;
} */
</style>
