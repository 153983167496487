import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { UserDTO, UserUploadDTO } from '@/services/AccessEditor/domain/model/User/User'

export enum ActionTypes {
    ADD_USER = 'add_user',
    UPDATE_USER = 'update_user',
    DELETE_USER = 'delete_user',
    GET_USERS = 'get_users',
    COUNT_USERS = 'count_users',
    GET_USER_BY_GUID = 'get_user_by_guid',
    GET_PASSWORD = 'get_password',
    GET_PASSWORD_RULES = 'get_password_rules',
    PASSWORD_CHECK = 'password_check',
    GET_API_KEY = 'get_api_key',
    GET_AVATAR = 'get_avatar',
    UPLOAD_USERS = 'upload_users',
    GET_USER_CARD = 'get_user_card'
}

export class AddUser implements FluxStandardAction {
    type = 'User/' + ActionTypes.ADD_USER
    constructor(public payload: UserDTO){}
}

export class UpdateUser implements FluxStandardAction {
    type = 'User/' + ActionTypes.UPDATE_USER
    constructor(public payload: UserDTO){}
}

export class DeleteUser implements FluxStandardAction {
    type = 'User/' + ActionTypes.DELETE_USER
    constructor(public guid: string){}
}

export class GetUsers implements FluxStandardAction {
    type = 'User/' + ActionTypes.GET_USERS
    constructor(public payload: object){}
}

export class CountUsers implements FluxStandardAction {
    type = 'User/' + ActionTypes.COUNT_USERS
    constructor(public payload: object){}
}

export class GetUserByGuid implements FluxStandardAction {
    type = 'User/' + ActionTypes.GET_USER_BY_GUID
    constructor(public guid: string){}
}

export class GetPassword implements FluxStandardAction {
    type = 'User/' + ActionTypes.GET_PASSWORD
    constructor(){}
}

export class GetPasswordRules implements FluxStandardAction {
    type = 'User/' + ActionTypes.GET_PASSWORD_RULES
    constructor(){}
}

export class PasswordCheck implements FluxStandardAction {
    type = 'User/' + ActionTypes.PASSWORD_CHECK
    constructor(public password: string){}
}

export class GetApiKey implements FluxStandardAction {
    type = 'User/' + ActionTypes.GET_API_KEY
    constructor(public userId: number, public roleId: number, public systemIps: Array<string>){}
}

export class GetAvatar implements FluxStandardAction {
    type = 'User/' + ActionTypes.GET_AVATAR
    constructor(public avatarId: number){}
}

export class UploadUsers implements FluxStandardAction {
    type = 'User/' + ActionTypes.UPLOAD_USERS
    constructor(public payload: UserUploadDTO){}
}

export class GetUserCard implements FluxStandardAction {
    type = 'User/' + ActionTypes.GET_USER_CARD
    constructor(){}
}