import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class ApprovalStageDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name?: Nullable<string>
  close_commands?: Nullable<Array<number>>
  approve_commands?: Nullable<Array<number>>
  approve_button_id?: Nullable<number>
  approve_text?: Nullable<string>
  cancel_commands?: Nullable<Array<number>>
  cancel_button_id?: Nullable<number>
  cancel_text?: Nullable<string>
  approval_cancel_stages?: Nullable<Array<number>>
  approval_blocking_stages?: Nullable<Array<number>>
  approval_stage_type_id?: Nullable<string>
  approval_stage_members?: Nullable<Array<number>>
  member_field_id?: Nullable<number>
  member_state_id?: Nullable<number>
  is_approvable_by_all?: Nullable<boolean>

  constructor (
    { id, guid, name, close_commands, approve_commands, approve_button_id, approve_text, cancel_commands, cancel_button_id, cancel_text, approval_cancel_stages, approval_blocking_stages, approval_stage_type_id, approval_stage_members, member_field_id, member_state_id, is_approvable_by_all }
    :
      {
        id?: Nullable<number>,
        guid?: Nullable<string>,
        name?: Nullable<string>,
        close_commands?: Nullable<Array<number>>,
        approve_commands?: Nullable<Array<number>>,
        approve_button_id?: Nullable<number>,
        approve_text?: Nullable<string>,
        cancel_commands?: Nullable<Array<number>>,
        cancel_button_id?: Nullable<number>,
        cancel_text?: Nullable<string>,
        approval_cancel_stages?: Nullable<Array<number>>,
        approval_blocking_stages?: Nullable<Array<number>>,
        approval_stage_type_id?: Nullable<string>,
        approval_stage_members?: Nullable<Array<number>>,
        member_field_id?: Nullable<number>,
        member_state_id?: Nullable<number>,
        is_approvable_by_all?: Nullable<boolean>,
      }
  ) {
    this.id = id
    this.guid = guid
    this.name = name
    this.close_commands = close_commands
    this.approve_commands = approve_commands
    this.approve_button_id = approve_button_id
    this.approve_text = approve_text
    this.cancel_commands = cancel_commands
    this.cancel_button_id = cancel_button_id
    this.cancel_text = cancel_text
    this.approval_cancel_stages = approval_cancel_stages
    this.approval_blocking_stages = approval_blocking_stages
    this.approval_stage_type_id = approval_stage_type_id
    this.approval_stage_members = approval_stage_members
    this.member_field_id = member_field_id
    this.member_state_id = member_state_id
    this.is_approvable_by_all = is_approvable_by_all
  }

  static create (name: Nullable<string> = null) {
    return new ApprovalStageDTO({
      name: name,
      close_commands: [],
      approve_commands: [],
      approve_button_id: null,
      approve_text: null,
      cancel_commands: [],
      cancel_button_id: null,
      cancel_text: null,
      approval_cancel_stages: [],
      approval_blocking_stages: [],
      approval_stage_type_id: 'field',
      approval_stage_members: [],
      member_field_id: null,
      member_state_id: null,
      is_approvable_by_all: false
    })
  }
}

export class ApprovalDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: string
  description: Nullable<string>
  approval_states: Array<number>
  approval_stages: Array<object>
  alias: Nullable<string>
  logic_id?: number
  parent_tree_element_id?: Nullable<number>
  is_enabled?: boolean

  constructor ({ id, guid, name, description, approval_states, approval_stages, alias, logic_id, parent_tree_element_id, is_enabled }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.approval_states = approval_states
    this.approval_stages = approval_stages
    this.alias = alias
    this.logic_id = logic_id
    this.parent_tree_element_id = parent_tree_element_id
    this.is_enabled = is_enabled
  }

  static create (): ApprovalDTO {
    return new ApprovalDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      approval_states: [],
      approval_stages: [],
      logic_id: null,
      alias: null,
      parent_tree_element_id: null,
      is_enabled: true
    })
  }
}

export default class Approval extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private approvalStates: Array<number>
  private approvalStages: Array<object>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId?: Nullable<number>
  private isEnabled: boolean

  constructor (
    name: string,
    description: Nullable<string>,
    approvalStates: Array<number>,
    approvalStages: Array<object>,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId?: Nullable<number>,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    isEnabled: boolean = true
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.approvalStates = approvalStates
    this.approvalStages = approvalStages
    this.alias = alias
    this.logicId = logicId
    this.parentTreeElementId = parentTreeElementId
    this.isEnabled = isEnabled
  }

  static create (dto: ApprovalDTO): Approval {
    return new Approval(
      dto.name,
      dto.description,
      dto.approval_states,
      dto.approval_stages,
      dto.logic_id,
      dto.alias,
      dto.parent_tree_element_id,
      dto.id,
      dto.guid,
      dto.is_enabled
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  getApprovalStates (): Array<number> {
    return this.approvalStates
  }

  setApprovalStates (approvalStates: Array<number>): void {
    this.approvalStates = approvalStates
  }

  getApprovalStages (): Array<object> {
    return this.approvalStages
  }

  setApprovalStages (approvalStages: Array<object>): void {
    this.approvalStages = approvalStages
  }

  getLogicId (): number {
    return this.logicId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getIsEnabled (): boolean {
    return this.isEnabled
  }

  setIsEnabled (value: boolean): void {
    this.isEnabled = value
  }
}
