import { FluxStandardAction } from '@/core/domain/type/types'
import { TimerDTO } from '@/services/LogicEditor/domain/model/Timer'

export enum ActionTypes {
  GET_STATE_BY_GUID = 'get_timer_by_guid',
  CREATE_STATE = 'create_timer',
  UPDATE_STATE = 'update_timer',
  DELETE_STATE = 'delete_timer'
}

export class GetTimerByGuid implements FluxStandardAction {
  type = 'Timer/' + ActionTypes.GET_STATE_BY_GUID
  constructor (public guid: string) {}
}

export class CreateTimer implements FluxStandardAction {
  type = 'Timer/' + ActionTypes.CREATE_STATE
  constructor (public payload: TimerDTO) {}
}

export class UpdateTimer implements FluxStandardAction {
  type = 'Timer/' + ActionTypes.UPDATE_STATE
  constructor (public payload: TimerDTO) {}
}

export class DeleteTimer implements FluxStandardAction {
  type = 'Timer/' + ActionTypes.DELETE_STATE
  constructor (public guid: string) {}
}
