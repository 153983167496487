import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'
import ApprovalStageCreateCommand from '@/services/LogicEditor/application/command/ApprovalStageCreateCommand'
import ApprovalStageCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalStageCommandRepositoryInterface'

export default class ApprovalStageCreateHandler implements CommandHandlerInterface {
    private repository: ApprovalStageCommandRepositoryInterface;

    constructor (repository: ApprovalStageCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ApprovalStageCreateCommand): Promise<void> {
      let item: ApprovalStageDTO = {
        name: command.getName(),
        description: command.getDescription(),
        approval_id: command.getApprovalId(),
        approval_stage_members: command.getApprovalStageMembers(),
        approval_stage_type_id: command.getApprovalStageTypeId(),
        approve_button_id: command.getApproveButtonId(),
        approve_commands: command.getApproveCommands(),
        approve_text: command.getApproveText(),
        approval_blocking_stages: command.getBlockingStages(),
        cancel_button_id: command.getCancelButtonId(),
        cancel_commands: command.getCancelCommands(),
        approval_cancel_stages: command.getCancelStages(),
        cancel_text: command.getCancelText(),
        close_commands: command.getCloseCommands(),
        is_approvable_by_all: command.getIsApprovableByAll(),
        member_field_id: command.getMemberFieldId(),
        member_state_id: command.getMemberStateId(),
        return_stage_id: command.getReturnStageId()
      }

      return this.repository.insert(item)
    }
}
