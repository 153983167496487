import CommandInterface from '@/core/application/command/CommandInterface'

export default class TimerDeleteCommand implements CommandInterface {
  /**
   * @param guid
   */
  constructor (
    public readonly guid: string
  ) {}

  getClassName () {
    return 'TimerDeleteCommand'
  }
}
