import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class ApprovalCreateCommand implements CommandInterface {
  private name: string
  private description: Nullable<string>
  private approvalStates: Array<number>
  private approvalStages: Array<object>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId: Nullable<number>
  private isEnabled: boolean

  constructor (
    name: string,
    description: Nullable<string>,
    approvalStates: Array<number>,
    approvalStages: Array<object>,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId: Nullable<number>,
    isEnabled: boolean
  ) {
    this.name = name
    this.description = description
    this.approvalStates = approvalStates
    this.approvalStages = approvalStages
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
    this.isEnabled = isEnabled
  }

  getClassName (): string {
    return 'ApprovalCreateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getName (): string {
    return this.name
  }

  getApprovalStates (): Array<number> {
    return this.approvalStates
  }

  getApprovalStages (): Array<object> {
    return this.approvalStages
  }

  getLogicId (): number {
    return this.logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  getIsEnabled (): boolean {
    return this.isEnabled
  }
}
