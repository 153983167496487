<template>
  <div>
    <div>
      <span class="label">{{ $locale.logic_editor.command.run_external_command.external_command }}</span>
      <property type="command" :property="properties.find((item) => item.id === 'external_command_id')"></property>
    </div>
  </div>
</template>

<script>
import Property from '@/components/LogicEditor/components/CommandProperties/Property'
export default {
  name: 'RunExternalCommandEvent',
  components: { Property },
  props: {
    logicId: Number,
    properties: {
      type: Array,
      default: () => {
        return [{ 'id': 'external_command_id', 'type': 'command', 'value': null }]
      }
    }
  },
  data () {
    return {
      reports: [],
      topics: []
    }
  },
  watch: {
    properties: {
      deep: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>
.label {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
</style>
