<template>
  <div class="field-array">
    <el-input
      v-show="!loading"
      v-model="filterText"
      placeholder="Фильтрация"
      size="mini"
    ></el-input>

    <el-tree
      ref="tree"
      v-loading="loading"
      :props="props"
      :data="treeData"
      show-checkbox
      check-strictly
      node-key="id"
      :filter-node-method="filterNode"
      @check-change="handleCheckChange"
      :expand-on-click-node="false"
      default-expand-all
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }} (id: {{data.id}}, <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)</span>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default {
  name: 'FieldArray',
  props: {
    value: Array,
    logicId: {
      type: Number,
      default: null
    },
    objectId: {
      type: Number,
      default: null
    },
    file: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  data () {
    return {
      loading: true,
      localValue: this.value || [],
      treeData: [],
      props: {
        isLeaf: 'leaf',
        label: 'name',
        disabled: (data, node) => {
          if (this.file) {
            return data.entity_type_id !== 'file_field'
          }

          return false
        }
      },
      filterText: ''
    }
  },
  async mounted () {
    if (!this.objectId) {
      let registryData = await this.$http.get(`${this.$config.api}/logiceditor/logics/${this.logicId}`)
      const registryId = registryData.data.entity_id
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
      this.treeData = data.data.data
      this.$refs.tree.setCheckedKeys(this.localValue)
    } else {
      this.$http
        .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.objectId}&show_children=true`)
        .then((response) => {
          this.treeData = response.data.data
          this.$refs.tree.setCheckedKeys(this.localValue)
          this.loading = false
        })
    }
  },
  methods: {
    filterNode (value, data) {
      if (!value) {
        return true
      }

      return data.name.indexOf(value) !== -1
    },

    handleCheckChange () {
      this.$emit('input', this.$refs.tree.getCheckedKeys())
    }
  }
}
</script>

<style lang="scss">
  .field-array {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    padding: 10px;

    .el-input {
      margin-bottom: 15px;

      .el-input__inner {
        border-color: #e1e1e1;
      }
    }

    .el-tree {
      height: 200px;
      overflow-y: scroll;
      border-radius: 4px;
      border: 1px solid #e1e1e1;
      padding: 5px;
    }
  }
</style>
