import Vue from 'vue'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin.js'
import RegistryCard from '@/components/RegistryCard'
import clickMixin from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/clickMixin'
export default Vue.extend({
  mixins: [openCardMixin, clickMixin],
  components: { RegistryCard },
  name: 'addressField',
  template: `
    <div :class="{'hover_effect': clickTable}" :style="this.params.colDef.cssStyle">
      <span @click="clickTable ? btnClickedHandler() : ''">
        {{ this.params.value }}
      </span>
    </div>
  `
})
