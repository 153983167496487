import { FluxStandardAction, Nullable } from '@/core/domain/type/types'
import { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'
import { TopicRightDTO } from '@/services/AccessEditor/domain/model/Right/TopicRight'
import { RegistryRightDTO } from '@/services/AccessEditor/domain/model/Right/RegistryRight'
import { RoleProfileRightDTO } from '@/services/AccessEditor/domain/model/Right/RoleProfileRight'

export enum ActionTypes {
    ADD_ROLE = 'add_role',
    UPDATE_ROLE = 'update_role',
    DELETE_ROLE = 'delete_role',
    GET_ROLES = 'get_roles',
    COUNT_ROLES = 'count_roles',
    GET_ROLE_BY_GUID = 'get_role_by_guid',
    GET_TOPIC_RIGHTS = 'get_topic_rights',
    COUNT_TOPIC_RIGHTS = 'count_topic_rights',
    UPDATE_TOPIC_RIGHT = 'update_topic_right',
    GET_ROOT_MENU = 'get_root_menu',
    GET_DASHBOARDS = 'get_dashboards',
    GET_REGISTRY_RIGHTS = 'get_registry_rights',
    UPDATE_REGISTRY_RIGHTS = 'update_registry_rights',
    GET_ROLE_PROFILE = 'get_role_profile',
    UPDATE_ROLE_PROFILE = 'update_role_profile'
}

export class AddRole implements FluxStandardAction {
    type = 'Role/' + ActionTypes.ADD_ROLE
    constructor(public payload: RoleDTO){}
}

export class UpdateRole implements FluxStandardAction {
    type = 'Role/' + ActionTypes.UPDATE_ROLE
    constructor(public payload: RoleDTO){}
}

export class DeleteRole implements FluxStandardAction {
    type = 'Role/' + ActionTypes.DELETE_ROLE
    constructor(public guid: string){}
}

export class GetRoles implements FluxStandardAction {
    type = 'Role/' + ActionTypes.GET_ROLES
    constructor(public payload: object){}
}

export class CountRoles implements FluxStandardAction {
    type = 'Role/' + ActionTypes.COUNT_ROLES
    constructor(public payload: object){}
}

export class GetRoleByGuid implements FluxStandardAction {
    type = 'Role/' + ActionTypes.GET_ROLE_BY_GUID
    constructor(public guid: string){}
}

export class GetTopicRights implements FluxStandardAction {
    type = 'Role/' + ActionTypes.GET_TOPIC_RIGHTS
    constructor(public payload: object){}
}

export class CountTopicRights implements FluxStandardAction {
    type = 'Role/' + ActionTypes.COUNT_TOPIC_RIGHTS
    constructor(public payload: object){}
}

export class UpdateTopicRight implements FluxStandardAction {
    type = 'Role/' + ActionTypes.UPDATE_TOPIC_RIGHT
    constructor(public payload: TopicRightDTO){}
}

export class GetRootMenu implements FluxStandardAction {
    type = 'Role/' + ActionTypes.GET_ROOT_MENU
    constructor(){}
}

export class GetDashboards implements FluxStandardAction {
    type = 'Role/' + ActionTypes.GET_DASHBOARDS
    constructor(public payload: object){}
}

export class GetRegistryRights implements FluxStandardAction {
    type = 'Role/' + ActionTypes.GET_REGISTRY_RIGHTS
    constructor(public payload: object){}
}

export class UpdateRegistryRights implements FluxStandardAction {
    type = 'Role/' + ActionTypes.UPDATE_REGISTRY_RIGHTS
    constructor(public payload: RegistryRightDTO){}
}

export class GetProfile implements FluxStandardAction {
    type = 'Role/' + ActionTypes.GET_ROLE_PROFILE
    constructor(public roleId: number){}
}

export class UpdateRoleProfileRight implements FluxStandardAction {
    type = 'Role/' + ActionTypes.UPDATE_ROLE_PROFILE
    constructor(public payload: RoleProfileRightDTO){}
}