export default {
  data () {
    return {
      replicationComponents: {},
      replicationData: {},
      replicationBlocks: {},
      replicationFilters: {
        blocks: { /* [someGuid]: loadParams } */ },
        unwatches: { /* [someGuid]: unwatchFn } */ }
      },
      modelUnwatches: {
        /*
        id: { blocks: { [someGuid]: unwatchFn } },
        attr_N: { blocks: { [someGuid]: unwatchFn } }
        */
      },
      lastReplications: {},
      currentViewer: {}
    }
  },
  computed: {
    _components () {
      return Object.assign({}, this.components, this.replicationComponents)
    }
  },
  watch: {
    preview () {
      this.replicationComponents = {}
      this.replicationData = {}
    }
  },
  beforeDestroy () {
    // Отменить наблюдение за параметрами загрузки
    for (const unwatch of Object.values(this.replicationFilters.unwatches)) {
      unwatch()
    }

    // Отменить наблюдение за атрибутами в model
    for (const modelAttr of Object.values(this.modelUnwatches)) {
      // Атрибут
      for (const modelAttrGroup of Object.values(modelAttr)) {
        // Группа наблюдения за атрибутом
        for (const unwatch of Object.values(modelAttrGroup)) {
          // Элемент группы
          unwatch()
        }
      }
    }
  },
  methods: {
    isReplicationContainer (settings) {
      if (!settings) {
        return false
      }
      return (settings.blockGuid && typeof settings.index !== 'undefined')
    },

    getReplicationModel (settings) {
      if (this.replicationData[settings.blockGuid] && this.replicationData[settings.blockGuid].length > 0) {
        return this.replicationData[settings.blockGuid][settings.index] || {}
      }
      return {}
    },

    onBlockReplication (event) {
      if (event.oldGuid) {
        if (event.guid) {
          this.$set(this.replicationBlocks, event.guid, {
            blockGuid: event.replicationBlockGuid,
            index: event.replicationIndex
          })
        }
        if (this.components[event.oldGuid]) {
          const component = JSON.parse(JSON.stringify(this.components[event.oldGuid]))
          this.$set(this.replicationComponents, event.guid, Object.assign(component, {
            replication: {
              blockGuid: event.replicationBlockGuid,
              index: event.replicationIndex
            },
            guid: event.guid,
            type: () => import(`@/components/InterfaceEditor/components/${component.group}/${component.initialType}.vue`)
          }))
        }
      }
    },

    prepareForReplication (blocks) {
      for (const block of blocks) {
        const settings = block.replication?.additionalData
        if (settings?.sourceType && settings?.sourceId) {
          const blockGuid = block.guid
          const blockGuidForWatch = blockGuid.replaceAll('-', '_')

          switch (settings.sourceType) {
            case 'Registry':
              block.replication.function = async (queryParams = {}) => {
                // Получить параметры загрузки
                const params = this.getLoadParams({ settings, queryParams, block })
                const oldParams = this.replicationFilters.blocks[blockGuidForWatch]
                if (this.isLoadParamsChanged(params, oldParams)) {
                  // Обновились значения параметров загрузки
                  this.$set(this.replicationFilters.blocks, blockGuidForWatch, params)
                }

                if (!(blockGuid in this.replicationFilters.unwatches)) {
                  // Задать наблюдатель параметров загрузки. Запомнить функцию отмены наблюдения
                  this.$set(
                    this.replicationFilters.unwatches,
                    blockGuid,
                    this.$watch(`replicationFilters.blocks.${blockGuidForWatch}`, () => this.onChangeReplicationFilters({ block, eventType: 'loadParams' }), { deep: true })
                  )
                }

                const response = await this.$http.post(`${this.$config.api}/registryservice/registry/${settings.sourceId}`, params, {
                  hideNotification: true
                })

                this.$set(this.replicationData, blockGuid, [])
                if (response.data.data && response.data.data.length > 0) {
                  this.replicationData[blockGuid].push(...response.data.data)

                  const setZeroIndex = (block, replicationGuid) => {
                    this.$set(this.replicationBlocks, block.guid, {
                      blockGuid: replicationGuid,
                      index: 0
                    })
                    if (this.components[block.guid]) {
                      this.$set(this.components[block.guid], 'replication', {
                        blockGuid: replicationGuid,
                        index: 0
                      })
                    }
                    if (block.children && block.children.length > 0) {
                      block.children.forEach((_) => {
                        setZeroIndex(_, replicationGuid)
                      })
                    }
                  }
                  setZeroIndex(block, block.guid)
                }

                return response.data.data
              }
              break
            case 'extended_object':
              block.replication.function = async (queryParams = {}) => {
                // Получить параметры загрузки
                const params = this.getLoadParams({ settings, queryParams, block, type: 'extended_object' })
                const oldParams = this.replicationFilters.blocks[blockGuidForWatch]
                if (this.isLoadParamsChanged(params, oldParams)) {
                  // Обновились значения параметров загрузки
                  this.$set(this.replicationFilters.blocks, blockGuidForWatch, params)
                }

                if (!(blockGuid in this.replicationFilters.unwatches)) {
                  // Задать наблюдатель параметров загрузки. Запомнить функцию отмены наблюдения
                  this.$set(
                    this.replicationFilters.unwatches,
                    blockGuid,
                    this.$watch(`replicationFilters.blocks.${blockGuidForWatch}`, () => this.onChangeReplicationFilters({ block, eventType: 'loadParams' }), { deep: true })
                  )
                }

                const response = await this.$http.post(`${this.$config.api}/datawarehouseservice/extended_object/${settings.sourceId}`, params, {
                  hideNotification: true
                })

                this.$set(this.replicationData, blockGuid, [])
                if (response.data && response.data.length > 0) {
                  this.replicationData[blockGuid].push(...response.data)

                  const setZeroIndex = (block, replicationGuid) => {
                    this.$set(this.replicationBlocks, block.guid, {
                      blockGuid: replicationGuid,
                      index: 0
                    })
                    if (this.components[block.guid]) {
                      this.$set(this.components[block.guid], 'replication', {
                        blockGuid: replicationGuid,
                        index: 0
                      })
                    }
                    if (block.children && block.children.length > 0) {
                      block.children.forEach((_) => {
                        setZeroIndex(_, replicationGuid)
                      })
                    }
                  }
                  setZeroIndex(block, block.guid)
                }

                return response.data
              }
              break
          }
        }

        if (block.children && block.children.length > 0) {
          block.children = this.prepareForReplication(block.children)
        }
      }

      return blocks
    },

    getLoadParams ({ settings = {}, queryParams = {}, block, type = '' }) {
      const filters = this.getFilters({ settings, block })

      let params = {
        offset: queryParams.offset,
        limit: settings.limit,
        state_id: settings.stateId
      }

      if (type === 'extended_object') {
        params = {
          offset: queryParams.offset,
          limit: settings.limit
        }
      }

      if (filters.length > 0) {
        this.$set(params, 'where', {
          and: [...filters]
        })
      }

      return params
    },

    getFilters ({ settings = {}, block }) {
      const filters = []
      const replicationFilters = settings.filters

      if (Array.isArray(replicationFilters)) {
        for (const filter of replicationFilters) {
          const object = {}
          // Ранее не было списка (equalsType) и применялся чекбокс (ссылка). Учитывать оба варианта, но список приоритетнее
          let equalsType = 'eq'
          switch (filter.equalsType /* Список */) {
            case 'eq': // Равно
              equalsType = (filter.isXref /* Чекбокс (ссылка) */) ? 'equals_any' : 'eq'
              break
            case 'eqx': // Ссылка
              equalsType = 'equals_any'
              break
            case 'se': // Поиск
              equalsType = 'like'
              break
          }
          this.$set(object, equalsType, {})

          if (!filter.alias) {
            return
          }

          const isXref = filter.isXref /* Чекбокс (ссылка) */ || (filter.equalsType /* Список */ === 'eqx' /* Ссылка */)
          const alias = isXref ? `${filter.alias}id` : filter.alias

          // Обработка типов фильтра
          if (!filter.type || filter.type === 'field') {
            const model = this.getModel()
            let value = null
            if (filter.attribute in model) {
              value = model[filter.attribute]
              if (value /* Фильтрация всех ложных условий, включая пустые строки и false */ && equalsType === 'like') {
                value = `%${value}%`
              }
            } else {
              // Простой компонент (атрибут) может не быть в model, если его значение не менялось
              console.warn('Не найден атрибут для фильтра тиражирования блока', filter.attribute)
            }

            if (value) {
              // Фильтрация всех ложных условий, включая пустые строки и false
              this.$set(object[equalsType], alias, value)
            }

            /* Обработка наблюдения за атрибутом model */
            if (!this.modelUnwatches[filter.attribute]) {
              // Группа наблюдения блоков за атрибутом
              this.$set(this.modelUnwatches, filter.attribute, { blocks: {} })
            }
            if (block.guid) {
              if (!this.modelUnwatches[filter.attribute].blocks) {
                this.$set(this.modelUnwatches[filter.attribute], 'blocks', {})
              }
              if (!(block.guid in this.modelUnwatches[filter.attribute].blocks)) {
                // Задать наблюдатель атрибута model
                this.$set(
                  this.modelUnwatches[filter.attribute].blocks,
                  block.guid,
                  this.$watch(
                    `model.${filter.attribute}`,
                    (newValue, oldValue) => this.onChangeReplicationFilters({ block, eventType: 'model', attribute: filter.attribute, value: newValue, oldValue })
                  )
                )
              }
            }
            /* Конец обработки наблюдения за атрибутом model */
          } else if (filter.type === 'constant') {
            let value = filter.attribute
            if (value /* Фильтрация всех ложных условий, включая пустые строки и false */ && equalsType === 'like') {
              value = `%${value}%`
            }
            this.$set(object[equalsType], alias, filter.attribute)
          } else if (filter.type === 'current_user') {
            this.$set(object[equalsType], alias, this.$store.getters['Authorization/userId'])
          }

          if (Object.keys(object[equalsType]).length > 0) {
            filters.push(object)
          }
        }
      }

      return filters
    },

    onChangeReplicationFilters ({ block, eventType, attribute, value, oldValue }) {
      const model = this.getModel()
      const paginationOffset = model?.pagination?.value
      this.refreshReplication(block.guid, { offset: paginationOffset })
    },

    /**
     * - Репликация имеет очередь, чтобы был правильный порядок выполнения
     */
    async refreshReplication (guid, options = {}) {
      if (!Array.isArray(this.lastReplications[guid])) {
        this.$set(this.lastReplications, guid, [])
      }

      if (this.lastReplications[guid].length > 0) {
        // Ожидать очередь выполнения тиражирования по этому блоку. Должен быть хотя бы один Promise, чтобы не было бесконечного ожидания
        await Promise.all(this.lastReplications[guid])
      }

      this.clearReplication(guid)
      const replicateBlockPromise = this.replicateBlock(guid, options)

      // Поставить запрос тиражирования в очередь
      this.lastReplications[guid].push(replicateBlockPromise)

      await replicateBlockPromise

      // Убрать запрос из очереди (вначале массива)
      if (this.lastReplications[guid].length > 1) {
        // swap & pop быстрее - сложность O(1), чем shift с сложностью O(n)
        this.$set(this.lastReplications[guid], 0, this.lastReplications[guid].pop())
      } else {
        this.lastReplications[guid].shift()
      }
    },

    clearReplication (guidToClear) {
      // Удалить репликационные компоненты блоков
      const components = this.replicationComponents
      for (const guid of Object.keys(components)) {
        if (components[guid].replication.blockGuid === guidToClear) {
          delete components[guid]
        }
      }

      // Удалить репликационные блоки, кроме первого (в нём информация репликации)
      const blocks = this.replicationBlocks
      for (const guid of Object.keys(blocks)) {
        const block = blocks[guid]
        if (block.blockGuid === guidToClear && block.index !== 0) {
          delete blocks[guid]
          this.currentViewer.removeBlock(guid)
        }
      }
    },

    replicateBlock (guid, options = {}) {
      const block = this.currentViewer.getRefByGuid(guid)
      if (!block) {
        // Блок не доступен, если он в ранее не открытой вкладке
        console.error('Ошибка получения тиражируемого блока', guid)
        return
      }

      return block.prepareReplication(options)
    },

    isLoadParamsChanged (params1, params2) {
      params1 = this.copyObject(params1 || {})
      params2 = this.copyObject(params2 || {})

      /*
        Не учитывать model, чтобы не срабатывало второй раз из-за $watch на атрибуте.
        Если на атрибут настроен фильтр, то он отслеживается через $watch в getFilters
      */
      delete params1.model
      delete params2.model

      return this.isObjectsDiff(params1, params2)
    },

    isObjectsDiff (object1, object2) {
      const object1String = JSON.stringify(object1)
      const object2String = JSON.stringify(object2)

      return object1String !== object2String
    },

    copyObject (object) {
      return JSON.parse(JSON.stringify(object))
    }
  }
}
