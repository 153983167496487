<template>
  <div class="vqb-group" :class="classObject">
    <el-form-item :size="sizeControl" class="vqb-group-body">
      <template slot="label">
        <span>{{ labels.groupLabel }}</span>
      </template>

      <div class="vqb-edit">
        <div class="vqb-edit__input">
          <el-select v-model="model.logical_operator">
            <el-option
              v-for="(label, index) in labels.matchTypes"
              :key="index"
              :label="label.label"
              :value="label.id"
            ></el-option>
          </el-select>
        </div>

        <div class="vqb-edit__actions">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('main.query_builder.button.add_rule')" placement="top">
            <el-popover
              popper-class="vqb-add-rule-form"
              placement="left"
              width="700"
              trigger="click"
            >
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item>
                    <el-select
                      v-model="selectedRule"
                      value-key="id"
                      filterable
                      :placeholder="labels.rulePlaceholder"
                    >
                      <el-option
                        v-for="item in rules"
                        :key="item.id"
                        :label="item.label"
                        :value="item"
                      >
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">
                            <span>{{ $t('object_editor.entity_types.' + item.fieldTypeId) }}</span>
                          </span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item>
                    <el-select v-model="selectedFilterType" :placeholder="labels.filterTypePlaceholder">
                      <el-option
                        v-for="item in filterTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-button
                    style="width: 100%;"
                    @click="addRule"
                    size="mini"
                    type="default"
                    v-html="labels.addRule"
                  ></el-button>
                </el-col>
              </el-row>

              <el-button
                slot="reference"
                circle
                size="mini"
                type="success"
                icon="el-icon-plus"
              ></el-button>
            </el-popover>
          </el-tooltip>

          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('main.query_builder.button.add_group')" placement="top">
            <el-button
              @click="addGroup"
              size="mini"
              type="primary"
              circle
              v-html="labels.addGroup"
              v-if="depth < maxDepth"
            ></el-button>
          </el-tooltip>

          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('main.query_builder.button.delete_group')" placement="top">
            <el-button
              @click="remove"
              circle
              size="mini"
              type="danger"
              v-html="labels.removeGroup"
              v-if="depth > 1">
            </el-button>
          </el-tooltip>
        </div>
      </div>

      <div class="vqb-children">
        <component
          v-for="(child, index) in model.children"
          v-model="model.children[index].query"
          :key="index"
          :is="objectTypeToComponentName[child.type]"
          :rules="rules"
          :index="index"
          :maxDepth="maxDepth"
          :depth="depth + 1"
          :labels="labels"
          :type="type"
          :size-control="sizeControl"
          class="vqb-child"
          v-on:child-deletion-requested="removeChild"
        ></component>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import Vue from 'vue'
import TreeSelect from './../TreeSelect.vue'
import QueryBuilderRule from './QueryBuilderRule.vue'
import deepClone from './utilities.js'

export default Vue.extend({
  name: 'QueryBuilderGroup',

  components: {
    QueryBuilderRule,
    TreeSelect
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    rules: {
      type: Array,
      default () {
        return []
      }
    },

    depth: {
      type: Number,
      default: 1
    },

    maxDepth: {
      type: Number,
      default: 5
    },

    index: {
      type: Number,
      default: 0
    },

    labels: {
      type: Object,
      required: true
    },

    type: {
      type: String,
      default: 'state'
    },

    sizeControl: {
      type: String,
      default: 'mini'
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.model)) {
          this.model = deepClone(value)
        }
      },
      deep: true
    },

    model: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', deepClone(value))
        }
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value,
      treeSelectProps: {
        isLeaf: 'leaf',
        disabled: (data, node) => {
          return !data.leaf
        }
      },
      selectedRule: null,
      selectedFilterType: 'constant',
      filterTypeList: [
        {
          value: 'constant',
          label: this.$t('main.query_builder.filter_types.constant')
        },
        {
          value: 'current_user',
          label: this.$t('main.query_builder.filter_types.current_user')
        },
        {
          value: 'current_datetime',
          label: this.$t('main.query_builder.filter_types.current_datetime')
        },
        {
          value: 'users_table',
          label: this.$t('main.query_builder.filter_types.users_table')
        },
        {
          value: 'parameter',
          label: this.$t('main.query_builder.filter_types.parameter')
        },
        {
          value: 'current_table_field',
          label: this.$t('main.query_builder.filter_types.current_table_field')
        },
        {
          value: 'external_table_field',
          label: this.$t('main.query_builder.filter_types.external_table_field')
        },
        {
          value: 'sql',
          label: this.$t('main.query_builder.filter_types.sql')
        }
      ],
      objectTypeToComponentName: {
        condition: 'query-builder-rule',
        condition_group: 'query-builder-group'
      }
    }
  },

  computed: {
    classObject () {
      var classObject = {}

      classObject['depth-' + this.depth.toString()] = true

      return classObject
    }
  },

  methods: {
    addRule () {
      if (!this.selectedRule) {
        this.$alert('Выберите поле!', 'Ошибка', {
          confirmButtonText: 'OK'
        })

        return
      }

      this.model.children.push({
        type: 'condition',
        query: {
          field: this.selectedRule.id,
          field_type: this.selectedRule.fieldTypeId,
          filter_type: this.selectedFilterType,
          operator: this.selectedRule.operators[0].id,
          value: this.getDefaultValue(this.selectedRule)
        }
      })
    },

    getDefaultValue (rule) {
      if (['in', 'not_in'].indexOf(rule.operators[0].id) !== -1) {
        return []
      }

      if (rule.fieldTypeId === 'boolean_field') {
        return false
      }

      return null
    },

    addGroup () {
      if (this.depth < this.maxDepth) {
        this.model.children.push({
          type: 'condition_group',
          query: {
            logical_operator: this.labels.matchTypes[0].id,
            children: []
          }
        })
      }
    },

    remove () {
      if (this.model.children.length > 0) {
        this.$confirm(
          this.$t('main.message.delete_group'),
          this.$t('main.message_title.warning'),
          {
            confirmButtonText: this.$t('main.button.delete'),
            cancelButtonText: this.$t('main.button.cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('child-deletion-requested', this.index)
        })
      } else {
        this.$emit('child-deletion-requested', this.index)
      }
    },

    removeChild (index) {
      this.model.children.splice(index, 1)
    }
  }
})
</script>

<style lang="scss">
  .vqb-group .vqb-group-body,
  .vqb-group-body .form-item-group,
  .vqb-add-rule-form .el-form-item,
  .vqb-group .vqb-children > .vqb-child:last-child {
    margin-bottom: 0;
  }

  .vqb-group-body .form-item-group .el-form-item__label {
    max-width: 50%;
  }

  .vqb-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 15px;
  }

  .vqb-edit__input {
    width: 100%;
    margin-right: 10px;
  }

  .vqb-edit__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    .el-tooltip + .el-tooltip {
      margin-left: 10px;
    }
  }
</style>
