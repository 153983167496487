import Vue from 'vue'
import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin'
import clickMixin from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/clickMixin'
export default Vue.extend({
  mixins: [openCardMixin, clickMixin],
  name: 'integerField',
  template: `
        <div :class="{'hover_effect': clickTable}" :style="this.params.colDef.cssStyle">
          <span @click="clickTable ? btnClickedHandler() : ''">
            {{normalizedValue}}
          </span>
      </div>
    `,
  computed: {
    normalizedValue () {
      if (this.params.value) {
        return this.params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      return null
    }
  }
})
