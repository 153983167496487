<template>
  <el-popover trigger="click" placement="bottom" width="380" v-model="visible">
    <div class="el-dropdown-link" :title="$locale.main.notification.notify" slot="reference">
      <el-badge :value="countNotify" :max="9" class="item" v-if="countNotify > 0">
        <i class="el-icon-bell" style="font-size: 25px"></i>
      </el-badge>
      <i v-else class="el-icon-bell" style="font-size: 25px"></i>
    </div>

    <div class="icon-bell_listMessages">
        <el-badge is-dot class="item" :type="socket ? 'success' : 'danger'">
          <span class="notify_header__title">{{ $locale.main.notification.notify }}</span>
        </el-badge>
        <span>
          <i @click="readMessages($event)" class="el-icon-view sizeIcon" :title="$locale.main.notification.read_all"></i>
        </span>
      </div>

      <div class="dropdowm_item custom_scrollbar">
        <div
          v-for="item in listNotify"
          :class="{
          'notifications-item': !item.read_date,
          'notification_border' : item.notification[0].interaction_type
          }"
          :key="item.guid"
          @click="openCard(item); !item.read_date ? readMessages($event, item) : ''"
        >
          <div class="notifications-item__inner">
            <div class="notifications-item__avatar">
              <div class="avatar avatar__service-circle">
                <el-avatar
                  v-if="item.avatar"
                  fit="cover"
                  size="medium"
                  :src="$config.api + '/files/' + item.avatar.guid + '.'+ item.avatar.extension"
                ></el-avatar>
                <i v-else-if="item.notification && item.notification[0].icon" :class="item.notification[0].icon" style="font-size: 36px"></i>
                <el-avatar v-else fit="cover" size="medium" src></el-avatar>
              </div>
            </div>
            <div class="notifications-item__content">
              <div class="notifications-item__misc">
                <span class="relative-date">{{$moment(item.create_date).format('D MMM H:mm')}}</span>
              </div>
              <div class="notifications_title" v-if="item.title">{{item.title.length > 80 ? item.title.substring(0, 80)+'...' : item.title}}</div>
              <!-- <div class="notification-message" v-if="item.content" v-html="item.content.replace(/<a.+<\/a>/g, '').length > 125 ? item.content.substring(0, 125)+'...' : item.content"></div> -->
              <div class="notification-message" v-if="item.content" v-html="item.content.split('<br>')[0]"></div>
              <div class="notity_icon" v-show="!item.read_date">
                <i
                  @click.stop="readMessages($event, item)"
                  class="el-icon-view sizeIcon"
                  :title="$locale.main.notification.read_notify"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p @click="openCenterNotify" v-loading="loading" class="openCenterNotify">{{$locale.main.notification.go_to_notify}}</p>
  </el-popover>
</template>

<script>
// Компонент: выпадашка с уведомлениями в колокольчике
// MessageNotify - всплывашка
import MessageNotify from '@/components/System/Notification/MessageNotify.vue'
import Messages from '../Models/Messages'
import { getCardId } from '@/helpers'
import mixinsForNatification from '@/components/System/Notification/mixins/index.js'
export default {
  name: 'Notification',
  mixins: [mixinsForNatification],
  components: {
    MessageNotify
  },
  props: ['addMainTab'],
  data () {
    return {
      key: 1,
      listNotify: [],
      visible: false,
      countNotify: null,
      loading: false
    }
  },
  watch: {
    message: {
      handler: async function (val, oldval) {
        console.log(val)
        try {
          if (val) {
            await new Messages().params({ limit: 1 }).$get()
            this.loadNotifyes(val)
            if (val.shows_popup_message) {
              const h = this.$createElement
              this.$notify({
                message: h(MessageNotify, { key: this.key++, props: { val, addMainTab: this.addMainTab } }),
                position: 'bottom-right',
                duration: 5000
              })
            }
          }
        } catch (error) {
          this.$store.dispatch('WSDisconnect')
          console.log(error, 'not messages')
        }
      }
    },
    getNotify: {
      handler: function (val) {
        let obj = { id: val }
        this.readMessages(null, obj)
      }
    }
  },
  computed: {
    message () {
      return this.$store.getters['message']
    },
    socket () {
      return this.$store.getters['isConnected']
    },
    getNotify () {
      return this.$store.getters['Notify/getNotify']
    }
  },
  methods: {
    // Открыть карточку внешнего реестра
    async openCardOuter (data, dataRegistryByRecord) {
      let { openCard, readOnly, cardId, recordXref } = await this.validationData(data, dataRegistryByRecord)
      if (!openCard) return
      // получить id карточки внешнего реестра
      const card = (cardId) ? { id: cardId } : await getCardId(this, { registryId: openCard.external_object_id, recordId: recordXref })
      this.addMainTab({
        name: card.name || 'Новая карточка',
        componentType: 'RegistryCard',
        payload: {
          cardId: card.id,
          initialData: {},
          readonly: readOnly,
          recordId: recordXref,
          registryId: openCard.external_object_id
        }
      })
      this.loading = false
    },
    async openCard (data) {
      let dataRegistryByRecord = await this.getDataRegistryByRecord({ registryId: data.notification[0].object_id, recordId: data.record_id })
      if (dataRegistryByRecord.is_deleted) {
        this.$message.error(`Запись удалена из системы`)
        return
      }
      if (!data.notification[0].interaction_type) return false
      // Открыть карточку внешнего реестра
      if (data.notification[0].interaction_type === 'open_external_card') {
        this.openCardOuter(data, dataRegistryByRecord)
        return
      }

      this.loading = true
      try {
        let card = await getCardId(this, { registryId: data.notification[0].object_id, recordId: data.record_id })
        this.addMainTab({
          name: card.name,
          componentType: 'NotificationCenter',
          payload: {
            cardId: card.id,
            initialData: {},
            // readonly: true,
            recordId: data.record_id,
            registryId: data.notification[0].object_id
          }
        })
        this.loading = false
        this.visible = false
      } catch (error) {
        this.loading = false
        this.visible = false
      }
    },
    async loadNotifyes (val) {
      val.create_date = val.create_date.split('.')[0]
      if (val.author[0] && val.author[0].avatar_id) {
        let res = await this.$http.get(`${this.$config.api}/registryservice/files/${val.author[0].avatar_id}`)
        val.avatar = res.data
      }
      this.listNotify.unshift(val)
      let count = await this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, { '*': { func: 'count' }, where: { is_null: 'read_date' } }, { hideNotification: true })
      this.countNotify = count.data[0].count
    },
    async loadAvatarUser () {
      try {
        let params = {
          limit: 4,
          offset: 0,
          order: 'create_date:desc'
        }
        let notify = await new Messages().params(params).$get()
        this.listNotify = notify
        let usersWithAvatar = []
        let uniqueUsers = []
        usersWithAvatar = notify.reduce((acc, item) => {
          if (item.author[0] && item.author[0].avatar_id) {
            acc.push({
              author_id: item.author_id,
              avatar_id: item.author[0].avatar_id
            })
          }
          return acc
        }, [])
        // оставить уникальных юзеров
        uniqueUsers = usersWithAvatar.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.author_id === item.author_id
          ))
        )
        uniqueUsers.forEach(item => {
          this.$http.get(`${this.$config.api}/registryservice/files/${item.avatar_id}`).then((res) => {
            this.$set(item, 'avatar', res.data)
          }).then(() => {
            for (let i = 0; i < this.listNotify.length; i++) {
              for (let j = 0; j < uniqueUsers.length; j++) {
                if (this.listNotify[i].author_id === uniqueUsers[j].author_id) {
                  // this.$set(this.listNotify[i], 'fullName', this.users[j].fullName)
                  this.$set(this.listNotify[i], 'avatar', uniqueUsers[j].avatar)
                }
              }
            }
          }).catch((error) => {
            this.loading = false
            console.log(error)
          })
        })
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    async readMessages (e, val = null) {
      if (val) {
        this.listNotify.find(item => { if (item.id === val.id) item.read_date = true })
        await this.$http.put(`${this.$config.api}/${this.$config.notification_publisher}/messages/read`, { message_id: val.id }, { hideNotification: true })
      } else {
        this.listNotify.forEach(element => { element.read_date = true })
        await this.$http.put(`${this.$config.api}/${this.$config.notification_publisher}/messages/read-all`)
      }
      let count = await this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, { '*': { func: 'count' }, where: { is_null: 'read_date' } }, { hideNotification: true })
      this.countNotify = count.data[0].count
    },
    openCenterNotify () {
      this.addMainTab({ name: 'Центр уведомлений', componentType: 'NotificationCenter' })
      this.visible = false
    }
  },
  async created () {
    let count = await this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, { '*': { func: 'count' }, where: { is_null: 'read_date' } }, { hideNotification: true })
    this.countNotify = count.data[0].count
    await this.loadAvatarUser()
  }
}
</script>

<style>
.notify_header .el-popover{
  padding: 0px;
}
.notification_border {
  cursor: pointer;
}
.notification_border:hover {
  color: #409EFF;
}
</style>
