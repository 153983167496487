<template>
  <div>
    <div class="user-main-edit-panel">
      <div class="wrapper">
        <el-form v-if="userDto != null && profile != null" :rules="createUserRules" :model="userDto" :disabled="isUserLoading" label-position="left">
          <el-row class="profile-main-form-wrapper">
            <el-col class="left-side">
              <div class="main-edit-title">{{$locale.access_editor.titles.main_data}}</div>
              <div class="avatar-wrapper">
                <div class="avatar">
                  <fieldset v-show="profile.avatar_id.is_readable">
                    <el-upload
                        class="users upload-demo"
                        ref="upload"
                        action=""
                        list-type="picture-card"
                        :on-change="changeAvatar"
                        :on-remove="removeAvatar"
                        :file-list="avatar"
                        :auto-upload="false"
                        :disabled="!profile.avatar_id.is_editable"
                    >
                      <i class="icon-upload-blue" :title="$locale.access_editor.users_item.download_avatar"></i>
                    </el-upload>
                  </fieldset>
                  <span class="avatar-legend">{{$locale.access_editor.users_item.avatar_profile}}</span>
                </div>
              </div>
              <div class="user-main-info-wrapper">
                <div class="fio">
                  <div v-if="!isFIOEdit" class="wrapper">
                    <div v-if="!userDto.id" class="fio-text">
                      <span class="text">{{$locale.access_editor.users_item.surname}}</span>
                      <span class="text">{{$locale.access_editor.users_item.name}}</span>
                      <span class="text">{{$locale.access_editor.users_item.midname}}</span>
                    </div>
                    <div v-if="userDto.id" class="fio-text">
                      <span class="text" v-if="userDto.surname">{{userDto.surname}}</span>
                      <span class="text" v-if="userDto.name">{{userDto.name}}</span>
                      <span class="text" v-if="userDto.midname">{{userDto.midname}}</span>
                    </div>
                  </div>
                  <div v-if="isFIOEdit" class="wrapper">
                    <el-form-item prop="surname">
                      <el-input v-model="userDto.surname" ref="user_surname_input" :placeholder="$locale.access_editor.users_item.surname" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="name">
                      <span class="name-required-star">*</span>
                      <el-input v-model="userDto.name" :placeholder="$locale.access_editor.users_item.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="midname">
                      <el-input v-model="userDto.midname" :placeholder="$locale.access_editor.users_item.midname" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                  <el-button :icon="iconFIOEdit" size="small" @click="switchFIOEdit" v-show="profile.name.is_editable"></el-button>
                </div>
                <el-form-item prop="rolename" :label="$locale.access_editor.users_item.role">
                  <el-input v-model="roleName" autocomplete="off" readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item prop="email" :label="$locale.access_editor.users_item.email" v-show="profile.email.is_readable">
                  <el-input v-model="userDto.email" autocomplete="off" :readonly="!profile.email.is_editable"></el-input>
                </el-form-item>
                <el-form-item prop="phone" :label="$locale.access_editor.users_item.phone" v-show="profile.phone.is_readable">
                  <el-input v-model="userDto.phone" autocomplete="off" :readonly="!profile.phone.is_editable"></el-input>
                </el-form-item>
                <el-form-item prop="role_id" :label="$locale.access_editor.users_item.role" hidden="hidden">
                  <el-input v-model="userDto.role_id" autocomplete="off" readonly></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col class="right-side">
            <el-row>
              <el-col>
                <el-form-item prop="login" class="login" :label="$locale.access_editor.users_item.login" v-show="profile.login.is_readable">
                  <el-input v-model="userDto.login" autocomplete="off" :readonly="!profile.login.is_editable"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item prop="password" :label="$locale.access_editor.users_item.password" v-show="profile.password.is_readable">
                  <el-input v-model="userDto.password" class="password" :readonly="passwordControl['inputLocked']" autocomplete="off" @input="passwordCheck"></el-input>
                  <el-tooltip class="item" effect="dark" :content="$locale.access_editor.users_item.change_password" placement="bottom">
                    <el-button icon="icon-lock" class="password-lock-button" size="small" @click="switchPasswordLocker" :disabled="!profile.password.is_editable"></el-button>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col v-if="passwordControl['isValid'] === false">
                <ul class="password-control-list">
                  <li v-if="passwordControl['lengthValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordLength}}: {{passwordRules['length']['min']}}-{{passwordRules['length']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                  <li v-if="passwordControl['uppercaseValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordUppercase}}: {{passwordRules['uppercase']['min']}}-{{passwordRules['uppercase']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                  <li v-if="passwordControl['lowercaseValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordLowercase}}: {{passwordRules['lowercase']['min']}}-{{passwordRules['lowercase']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                  <li v-if="passwordControl['numbersValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordNumbers}}: {{passwordRules['numbers']['min']}}-{{passwordRules['numbers']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                  <li v-if="passwordControl['symbolsValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordSpecChars}}: {{passwordRules['symbols']['min']}}-{{passwordRules['symbols']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                </ul>
              </el-col>
              <el-col v-if="passwordControl['isValid'] === false">
                <el-button icon="el-icon-refresh" class="password-generate-button" @click="generatePassword">{{$locale.access_editor.users_item.generate_password}}</el-button>
              </el-col>
            </el-row>
            <span class="dialog-footer">
                <el-button class="save-button" :disabled="saveButtonLocker()" @click="saveUser">{{$locale.main.button.save}}</el-button>
              </span>
          </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div v-if="userCard != null" class="user-card-edit-panel custom_scrollbar">
      <div class="main-edit-title">{{$locale.access_editor.titles.extended_data}}</div>
        <div class="left-side">
          <CardsWrapper
            ref="cardsWrapper"
            :show-back-breadcrumb="false"
            :show-block-btn="false"
            :show-breadcrumb-empty='false'
            :prevent-user-card="true"
          ></CardsWrapper>
        <!-- <registry-card
            :card-id="userCard.id"
            :registry-id="userCard.registryId"
            :initial-data="{}"
            v-bind:record-id.sync="userCard.recordId"
            :show-block-btn="false"
            ref="user_registry_card_data"
        >
        </registry-card> -->
      </div>
    </div>
  </div>
</template>

<script>
import User, { UserDTO } from '@/services/AccessEditor/domain/model/User/User'
import UserAvatarQuery from '@/services/AccessEditor/application/query/UserAvatarQuery'
import UserCreateCommand from '@/services/AccessEditor/application/command/UserCreateCommand'
import UserUpdateCommand from '@/services/AccessEditor/application/command/UserUpdateCommand'
import UserPasswordQuery from '@/services/AccessEditor/application/query/UserPasswordQuery'
import UserPasswordRulesQuery from '@/services/AccessEditor/application/query/UserPasswordRulesQuery'
import UserPasswordCheckQuery from '@/services/AccessEditor/application/query/UserPasswordCheckQuery'
import RoleProfileQuery from '@/services/AccessEditor/application/query/RoleProfileQuery'
import Registry from '@/components/Registry/Models/Registry'
import RegistryCard from '@/components/RegistryCard/index.vue'
import CardsWrapper from '@/components/Registry/CardsWrapper.vue'

export default {
  name: 'UserProfilePanel',
  components: {
    RegistryCard,
    Registry,
    CardsWrapper
  },
  props: {
    rolesList: {
      type: Array,
      required: true
    },
    cardParams: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      userDto: null,
      avatar: [],
      createUserRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        role_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        login: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
      passwordRules: [],
      passwordControl: {
        "isValid": true,
        "locked": true,
        "inputLocked": true,
        "lengthValid": false,
        "uppercaseValid": false,
        "lowercaseValid": false,
        "numbersValid": false,
        "symbolsValid": false
      },
      isSystemUser: null,
      inputIpVisible: false,
      inputIpValue: '',
      isFIOEdit: false,
      userCard: null,
      profile: null,
      iconFIOEdit: "icon-edit-blue",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  created: function () {
    this.userDto = new UserDTO({});
    this.userDto.id = this.currentUser.id;
    this.userDto.guid = this.currentUser.guid;
    this.userDto.role_id = this.currentUser.role_id;
    this.userDto.name = this.currentUser.name;
    this.userDto.midname = this.currentUser.midname;
    this.userDto.surname = this.currentUser.surname;
    this.userDto.avatar_id = this.currentUser.avatar_id;
    this.userDto.login = this.currentUser.login;
    this.userDto.password = "";
    this.userDto.email = this.currentUser.email;
    this.userDto.phone = this.currentUser.phone;
    this.userDto.is_admin = this.currentUser.is_admin;
    this.userDto.is_blocked = this.currentUser.is_blocked;
    this.userDto.is_system = this.currentUser.is_system;
    this.passwordControl['locked'] = true;
    this.passwordControl['inputLocked'] = true;
    this.getAvatar();
    this.userCard = this.getUserCard(this.userDto);
  },
  mounted () {
    if (Object.keys(this.cardParams).length) {
      this.$refs.cardsWrapper.openRegistryCard({
        registryId: this.cardParams.registryId,
        cardId: this.cardParams.cardId,
        cardName: '',
        recordId: this.currentUser.id
      })
    }
  },
  watch: {
    userDto: {
      handler: async function(curDto) {
        if (this.profile == null) {
          this.loadProfile();
        }
        this.isSystemUser = curDto !== null ? curDto.is_system : null;
        this.userCard = this.getUserCard(curDto);
      },
      deep: true
    },
    isUserLoading: (state) => state,
    getSaveUserError: function (err) {

    }
  },
  computed: {
    isUserLoading() {
      return this.$store.getters['User/isLoading'];
    },
    getSaveUserError() {
      return this.$store.getters['User/getError'];
    },
    roleName() {
      let roleName = 'Роль не найдена';
      let role = this.rolesList.find(item => item.id == this.currentUser.role_id);
      if (typeof role != 'undefined') {
        roleName = role.name;
      }
      return roleName;
    }
  },
  methods: {
    getRolesList() {
      return this.rolesList;
    },
    async loadProfile() {
      let roleId = 0;
      if (this.currentUser != null) {
        roleId = this.currentUser.role_id;
      }
      await this.getQueryBus().execute(
        new RoleProfileQuery(roleId)
      ).then(data => {
        if (data.length > 0) {
          this.profile = {};
          for (let i = 0; i < data.length; i++) {
            this.profile[data[i].user_field_id] = {
              is_readable: data[i].is_readable,
              is_editable: data[i].is_editable
            };
          }
        }
      });
    },
    switchFIOEdit() {
      this.isFIOEdit = !this.isFIOEdit;
      this.isFIOEdit ? this.iconFIOEdit = "icon-ok-green" : this.iconFIOEdit = "icon-edit-blue";
      if (this.isFIOEdit == true) {
        this.$nextTick(() => {
          this.$refs.user_surname_input.focus();
        });
      }
    },
    async getAvatar() {
      if (this.userDto != null) {
        let avatarId = this.userDto.avatar_id;
        if (avatarId != null) {
          this.avatar = [];
          await this.getQueryBus().execute(
            new UserAvatarQuery(avatarId)
          ).then(data => {
            this.avatar.push({
              name: `${data.name}`,
              url: `${this.$config.api}/files/${data.directory ? data.directory + '/' : ''}${data.guid}.${data.extension}`
            });
          });
        }
      }
    },
    changeAvatar(file) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
        this.removeAvatar();
        this.$message.error(this.$locale.access_editor.users_item.error_avatar);
        return false;
      }
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1);
      }
      this.userDto.avatar = this.$refs.upload.uploadFiles[0].raw;
      this.userDto.is_avatar_deleted = false;
    },
    removeAvatar() {
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = [];
      }
      if (this.userDto !== null) {
        this.userDto.avatar = null;
        this.userDto.is_avatar_deleted = true;
      }
      this.avatar = [];
    },
    switchPasswordLocker() {
      this.passwordControl['locked'] = !this.passwordControl['locked'];
      this.passwordControl['inputLocked'] = !this.passwordControl['inputLocked'];
      if (typeof this.passwordRules['length']['min'] == 'undefined' || this.passwordRules['length']['min'] == null) {
        this.getPasswordRules()
          .then(data => {
            this.passwordControl['isValid'] = !this.passwordControl['isValid'];
          });
      }
      else {
        this.passwordControl['isValid'] = !this.passwordControl['isValid'];
      }
    },
    async getPasswordRules() {
      await this.getQueryBus().execute(
        new UserPasswordRulesQuery()
      ).then(data => {
        this.passwordRules = data.rules;
      });
    },
    async generatePassword () {
      await this.getQueryBus().execute(
        new UserPasswordQuery()
      ).then(data => {
        this.userDto.password = data.password;
        this.passwordCheck();
      });
    },
    async passwordCheck() {
      await this.getQueryBus().execute(
        new UserPasswordCheckQuery(this.userDto.password)
      ).then(data => {
        this.passwordControl['isValid'] = data['valid'];
        this.passwordControl['lengthValid'] = data['lengthValid'];
        this.passwordControl['uppercaseValid'] = data['uppercaseValid'];
        this.passwordControl['lowercaseValid'] = data['lowercaseValid'];
        this.passwordControl['numbersValid'] = data['numbersValid'];
        this.passwordControl['symbolsValid'] = data['symbolsValid'];
      });
    },
    saveButtonLocker() {
      if (this.userDto == null) {
        return true;
      }
      if (this.isUserLoading) {
        return true;
      }
      if (this.userDto.is_system === true) {
        return false;
      }
      if (!this.passwordControl['isValid']) {
        return true;
      }
      if (typeof this.userDto.login == 'undefined' || this.userDto.login == null) {
        return true;
      }
      if (this.userDto.login.length < 5 || this.userDto.login.length > 32) {
        return true;
      }
      if (typeof this.userDto.name == 'undefined' || this.userDto.name == null) {
        return true;
      }
      if (this.userDto.name.length < 1 || this.userDto.name.length > 255) {
        return true;
      }
      return false;
    },
    saveUser() {
      if (this.isEmailValid()) {
        let user = User.create(this.userDto);
        if (user.getId() == null) {
          this.$message({
            message: this.$locale.main.message.invalid_operation,
            type: 'warning'
          });
        }
        else {
          this.getCommandBus().execute(
            new UserUpdateCommand(
              user.getGuid(),
              user.getRoleId(),
              user.getName(),
              user.getMidname(),
              user.getSurname(),
              user.getAvatar(),
              user.getIsAvatarDeleted(),
              user.getLogin(),
              user.getPassword(),
              user.getEmail(),
              user.getIsAdmin(),
              user.getIsBlocked(),
              user.getIsSystem(),
              user.getApiKey(),
              user.getSystemIps(),
              user.getPhone()
            )
          ).then(data => {
            // this.$refs.user_registry_card_data.saveRecord();
            let cards = this.$refs.cardsWrapper.$refs.cards.$refs.registryCard
            cards[cards.length - 1].saveRecord()
          });
        }
      }
      else {
        this.$message({
          message: this.$locale.access_editor.users_item.email_must_be_valid,
          type: 'warning'
        })
      }
    },
    getUserCard(userDto) {
      if (userDto != null) {
        return {
          id: this.cardParams.cardId,
          registryId: this.cardParams.registryId,
          recordId: userDto.id
        };
      }
      return null;
    },
    isEmailValid () {
      if (this.userDto.email != null) {
        if ((this.userDto.email).trim() == '') {
          this.userDto.email = null;
        }
      }
      return (this.userDto.email == null) ? true : (this.reg.test(this.userDto.email)) ? true : false;
    }
  }
}
</script>
