<template>
  <component :is="component">
    <template v-slot:loginForm="slotProps">
      <div class="loginPage_title">{{ $locale.login.title }}</div>

      <el-form :rules="rules" :model="model" ref="form" @submit.native.prevent="submit">
        <el-form-item prop="login">
          <el-input
            v-model="model.login"
            class="loginPage_input"
            autocomplete="off"
            focus
            :placeholder="$locale.access_editor.users_item.login"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="model.password"
            class="loginPage_input"
            type="password"
            autocomplete="off"
            :placeholder="$locale.access_editor.users_item.password"
          ></el-input>
        </el-form-item>
        <el-checkbox v-if="ldap" v-model="model.ldap">{{ $locale.login.ldap }}</el-checkbox>
        <div style="margin-top: 10px">
          <div v-html="slotProps.esiaText"></div>
          <el-link v-if="esia && !slotProps.hideEsia" @click="esiaLogin" type="primary">{{ $locale.login.esia }}
          </el-link>
        </div>
        <el-form-item>
          <el-button
            :loading="loading"
            class="loginPage_btn"
            native-type="submit"
            :disabled="!!slotProps.disabledSubmitBtn"
          >{{ $locale.login.submit }}
          </el-button>
        </el-form-item>
      </el-form>
      <el-alert v-show="error" :title="error" type="error" :closable="false" show-icon></el-alert>
      <SMSAuthentication
        v-if="twoFactorAuthType === 'sms'"
        @close="twoFactorAuthType = null"
        :sms-auth-data="twoFactorAuthData"
      ></SMSAuthentication>
      <TOTPAuthentication
        v-if="twoFactorAuthType === 'totp'"
        @close="twoFactorAuthType = null"
        :auth-data="twoFactorAuthData"
      ></TOTPAuthentication>
    </template>
  </component>
</template>

<script>
import defaultPage from './defaultPage/index.vue'
import Template from '@/components/EtlEditor/Properties/Template'
import SMSAuthentication from '@/components/LoginPage/SMSAuthentication'
import TOTPAuthentication from '@/components/LoginPage/TOTPAuthentication'

export default {
  name: 'login-page',
  components: {
    TOTPAuthentication,
    SMSAuthentication,
    Template,
    defaultPage
  },
  data () {
    return {
      model: {
        login: null,
        password: null,
        ldap: !!this.$config.ldap_enabled
      },
      twoFactorAuthType: null,
      twoFactorAuthData: {},
      error: null,
      loading: false,
      component: null,
      rules: {
        login: {
          required: true,
          message: this.$locale.login.errors.login_required,
          trigger: 'change'
        },
        password: {
          required: true,
          message: this.$locale.login.errors.password_required,
          trigger: 'change'
        }
      }
    }
  },
  computed: {
    project () {
      return this.$config.project
    },
    esia () {
      return this.$config.esia_enabled
    },
    ldap () {
      return this.$config.ldap_enabled
    }
  },
  methods: {
    esiaLogin () {
      this.$store.dispatch(`Authorization/EsiaLogin`)
    },
    submit () {
      this.error = null
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          const { login, password, ldap } = this.model
          let type = ldap ? 'LDAP' : 'default'
          this.$store.dispatch(`Authorization/${type}Login`, { login, password, ldap })
            .then((res) => {
              if (res?.two_factor_auth_type) {
                if (res.two_factor_auth_type === 'sms') {
                  this.twoFactorAuthType = 'sms'
                  this.twoFactorAuthData = res
                } else if (res.two_factor_auth_type === 'totp') {
                  this.twoFactorAuthType = 'totp'
                  this.twoFactorAuthData = res
                } else {
                  this.$router.push('/')
                }
              } else {
                this.$router.push('/')
              }
            })
            .catch((error) => {
              if (error.response?.data?.code === 'sms_verification_service_error') {
                this.error = 'Ошибка отправки СМС для двухфакторной аутентификации'
              } else {
                this.error = this.$locale.login.errors.wrong_user
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      })
    }
  },
  async mounted () {
    console.log(this.$config.project)
    localStorage.clear()

    try {
      await submodule.getPage('LoginPage')
      this.component = () => submodule.getPage('LoginPage')
    } catch (e) {
      try {
        await import(`@/plugins/${this.$config.project}/infrastructure/components/LoginPage`)
        this.component = () => import(`@/plugins/${this.$config.project}/infrastructure/components/LoginPage`)
      } catch (e) {
        try {
          await import(`@/components/LoginPage/${this.$config.project}`)
          this.component = () => import(`@/components/LoginPage/${this.$config.project}`)
        } catch (error) {
          this.component = 'defaultPage'
          console.log(error)
        }
      }
    }
  }
}
</script>

<style>
.loginPage_wrapper {
  display: flex;
  height: 100%;
  background: #e5e5e5;
}

.loginPage_box {
  margin: auto;
  width: 380px;
  height: auto;
}

.loginPage_logo {
  height: 68px;
}

.loginPage_title {
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 21px;
  line-height: 24px;
  color: #2c2d35;
}

.loginPage_btn {
  width: 100%;
  margin-top: 28px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  background: #2139C4;
  line-height: 25px;
}

.loginPage_btn:hover,
.loginPage_btn:active,
.loginPage_btn:focus {
  background: #2139C4;
  color: #ffffff;
}

.loginPage_input .el-input__inner {
  height: 50px;
  font-size: 15px;
  line-height: 50px;
}

.project_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  /* background: #F4F5F6; */
}

.project_title {
  font-weight: bold;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  color: #004099;
}

.PublicHearing_background {
  background: url(./PublicHearing/resources/background.jpg) no-repeat;
}

.Rgis_background {
  background: url(./Rgis/resources/background.jpg) no-repeat;
  background-size: cover;
}
</style>
