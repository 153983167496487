import RegistryRightsCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/RegistryRightsCommandRepositoryInterface'
import { RegistryRightDTO } from '@/services/AccessEditor/domain/model/Right/RegistryRight'
import store from '@/store'
import { UpdateRegistryRights } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'

export default class RegistryRightsCommandRepository implements RegistryRightsCommandRepositoryInterface
{
    update(item: RegistryRightDTO): Promise<void>
    {
        return store.dispatch(new UpdateRegistryRights(item));
    }
}
