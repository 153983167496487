import {Nullable} from "@/core/domain/type/types";

export default class RegistryRightsUpdateCommand
{
    private roleGuid: string;
    private entityId: number;
    private isAddable: Nullable<boolean>;
    private isReadable: Nullable<boolean>;
    private isEditable: Nullable<boolean>;
    private isDeletable: Nullable<boolean>;

    constructor(
        roleGuid: string,
        entityId: number,
        isAddable: Nullable<boolean>,
        isReadable: Nullable<boolean>,
        isEditable: Nullable<boolean>,
        isDeletable: Nullable<boolean>
    ) {
        this.roleGuid = roleGuid;
        this.entityId = entityId;
        this.isAddable = isAddable;
        this.isReadable = isReadable;
        this.isEditable = isEditable;
        this.isDeletable = isDeletable;
    }

    getClassName(): string
    {
        return 'RegistryRightsUpdateCommand';
    }

    getRoleGuid(): string
    {
        return this.roleGuid;
    }

    getEntityId(): number
    {
        return this.entityId;
    }

    getIsAddable(): Nullable<boolean>
    {
        return this.isAddable;
    }

    getIsReadable(): Nullable<boolean>
    {
        return this.isReadable;
    }

    getIsEditable(): Nullable<boolean>
    {
        return this.isEditable;
    }

    getIsDeletable(): Nullable<boolean>
    {
        return this.isDeletable;
    }

}