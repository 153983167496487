import { Nullable } from '@/core/domain/type/types'
import { ValueFormatterParams } from 'ag-grid-community'

export default class ValueFormatters {
  static dateField (params: ValueFormatterParams): Nullable<string> {
    if (!params.value) {
      return null
    }
    return ValueFormatters.formatDate(params.value)
  }

  private static formatDate (date: string): string {
    const dateFormatter = new Intl.DateTimeFormat('ru')
    return dateFormatter.format(new Date(date))
  }

  static xrefField (params: ValueFormatterParams): Nullable<string> {
    const value = typeof params.value === 'string'
      ? JSON.parse(params.value)
      : params.value

    if (value && Array.isArray(value)) {
      return value.map((item) => item.name).join(', ')
    }

    return null
  }

  static fileField (params: ValueFormatterParams): Nullable<string> {
    const files = JSON.parse(params.value || '[]')
    if (files instanceof Array && files.length > 0) {
      const result = files.map(file => {
        return `<a href='/files/${file.directory ? file.directory + '/' : ''}${file.guid}.${file.extension}' download><i class="el-icon-document"> ${file.name}.${file.extension}</i></a>`
      })
      return result.join('<br>')
    }

    return null
  }

  static dateTimeField (params: ValueFormatterParams): Nullable<string> {
    if (params.value && params.value.length > 0) {
      const value = new Date(params.value).toLocaleString()
      if (value !== 'Invalid Date') {
        return value
      }
    }

    return params.value
  }

  static floatField (params): Nullable<number> {
    if (params.data && typeof params.data[params.colDef.field] === 'string') {
      return +parseFloat(params.data[params.colDef.field]).toFixed(2)
    }
    return null
  }
  static sparkline (params): Nullable<number[]> {
    // console.log(params)
    if (params.data && typeof params.data[params.colDef.field] === 'string') {
      const valueParse = params.data[params.colDef.field]
      let value = null
      try {
        value = JSON.parse(valueParse)
        if (Array.isArray(value)) {
          return value
        } else {
          console.warn('не массив', value)
          return null
        }
      } catch (error) {
        console.warn('неверный формат', value)
        return null
      }
    }
  }
}
