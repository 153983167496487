import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { CommandDTO, CommandEventTypeDTO, CommandEventTypePropertyDTO } from '@/services/LogicEditor/domain/model/Command'

export namespace CommandAPI {
  export class GetCommand implements APIRequest<CommandDTO> {
    response: CommandDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/logiceditor/commands?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetCommandByGuid implements APIRequest<CommandDTO> {
    response: CommandDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/commands/${guid}`
    }
  }

  export class GetCommandEventTypes implements APIRequest<CommandEventTypeDTO[]> {
    response: CommandEventTypeDTO[];
    path: string;
    method: HTTPMethod = HTTPMethod.GET;

    constructor () {
      this.path = `/v2/logiceditor/command_event_types`
    }

    parse (data: AxiosResponse) {
      return data.data
    }
  }

  export class GetCommandEventTypeProperties implements APIRequest<CommandEventTypePropertyDTO> {
    response: CommandEventTypePropertyDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (eventId: string) {
      this.path = `/v2/logiceditor/command_event_types/${eventId}/properties`
    }
  }

  export class CreateCommand implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/commands';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: CommandDTO) {}
  }

  export class UpdateCommand implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: CommandDTO) {
      this.path = `/v2/logiceditor/commands/${params.guid}`
    }
  }

  export class DeleteCommand implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/commands/${guid}`
    }
  }
}
