<template>
  <el-form-item class="subMenu" :label="$locale.menu_editor.label.select_editor">
    <el-select v-model="localProperties[0].value">
      <el-option
        v-for="item in listOpenEditor"
        :key="item.id"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  name: 'editor',
  props: ['properties'],
  watch: {
    localProperties: {
      handler: function (val) {
        this.$emit('properties', [{ 'id': 'editor_id', 'type': 'editor', 'value': val[0].value }])
      },
      deep: true
    }
  },
  data () {
    return {
      localProperties: this.properties || [],
      listOpenEditor: [
        {
          value: 'ProcessEditor',
          label: this.$locale.main.system.processeditor
        },
        {
          value: 'InterfaceEditor',
          label: this.$locale.main.system.interface_editor
        },
        {
          value: 'CardEditor',
          label: this.$locale.main.system.card_editor
        },
        {
          value: 'ObjectEditor',
          label: this.$locale.main.system.object_editor
        },
        {
          value: 'MenuEditor',
          label: this.$locale.main.system.menu_editor
        },
        {
          value: 'AccessEditor_v2',
          label: this.$locale.main.system.access_editor
        },
        {
          value: 'LogicEditor',
          label: this.$locale.main.system.logic_editor
        },
        {
          value: 'LogicEditor_v2',
          label: this.$locale.main.system.logic_editor_v2
        },
        {
          value: 'ReportEditor',
          label: this.$locale.main.system.report_editor
        },
        {
          value: 'EtlEditor',
          label: this.$locale.main.system.etl_editor
        },
        {
          value: 'EtlEditor_v2',
          label: this.$locale.main.system.etl_editor_v2
        },
        {
          value: 'BIEditor',
          label: this.$locale.main.system.bi_editor
        },
        {
          value: 'DashboardEditor',
          label: this.$locale.main.system.dashboard_editor
        },
        {
          value: 'NotificationEditor',
          label: this.$locale.main.system.notification_editor
        },
        {
          value: 'MapEditor',
          label: this.$locale.main.system.map_editor
        },
        {
          value: 'RasterLibrary',
          label: this.$locale.main.system.raster_library
        },
        {
          value: 'TaskEditor',
          label: this.$locale.main.system.task_editor
        },
        {
          value: 'Databus',
          label: this.$locale.main.system.databus
        },
        {
          value: 'SyncService',
          label: this.$locale.main.system.sync_service
        },
        {
          value: 'AddressService',
          label: this.$locale.main.system.address_service
        },
        {
          value: 'ActivityService',
          label: this.$locale.main.system.activity_service
        },
        {
          value: 'GarAddressService',
          label: this.$locale.main.system.gar_address_service
        },
        {
          value: 'ReleaseEditor',
          label: this.$locale.main.system.release_editor
        },
        {
          value: 'WelcomePage',
          label: this.$locale.main.system.welcome_page
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
