<template>
  <div class="interface_editor">
    <!-- скрытый инпут для загрузки файла -->
    <input hidden accept=".json" type="file" id="file" ref="json" @change="onInputChange"/>
    <component :is="'style'" v-if="userCssClass">{{ userCssClass }}</component>
    <vue-context ref="menu" class="el-dropdown-menu  el-dropdown-menu--small tab-menu" v-slot="{ data }">
      <div class="el-dropdown-menu__item" @click="copyBlock(data)">
        <i class="el-icon-document-copy"></i>{{ $locale.interface_editor.container_settings.copy }}
      </div>
    </vue-context>
    <toolbar
      :type="type"
      :registry-id="registryId"
      :conditions="conditions"
      :active-block="activeBlock"
      :blocks="blocks"
      :components="components"
      :currentViewer="currentViewer"
      :windows="windows"
      :status="status"
      :block-windows="!!activeConditionGuid"
      :block-conditions="!!activeWindowGuid"
      @set-active-block="setActiveBlockByGuid"
      @set-active-tab="setActiveTabByGuid"
    ></toolbar>
    <div :class="{'main_block': true, 'dragging': isDragging}" @paste.stop="onPasteBlock">
      <el-form :class="{'designer': true, 'preview': preview}">
        <responsive-designer
          v-show="!preview"
          ref="designer"
          style="height: 100%"
          :style="settingStyle"
          :show-hidden="showHidden"
          :tab-settings="tabSettings"
          @start-drag="isDragging = true;setActive($event)"
          @contextmenu="showContextMenu"
          @stop-drag="isDragging = false"
          @click.native="clearActive"
          @block-remove="onBlockRemove"
        >
          <template v-slot:content="{ block }">
            <!-- @TODO Заменить на Cards -->
            <CardsWrapper
              v-if="block.contentType === 'registry'"
              :ref="'registry_' + block.guid"
              :show-breadcrumbs="block.showBreadcrumbs"
              @close="$set(block, 'contentType', null)"
            ></CardsWrapper>
            <component
              v-else-if="checkType(block.guid)"
              v-bind="components[block.guid].properties"
              :ref="'component_' + block.guid"
              :openModalWindowByConfigurator="openModalWindowByConfigurator"
              :guid="block.guid"
              :is="components[block.guid].type"
              v-model="model[components[block.guid].properties.name]"
              @rendered="components[block.guid].rendered = true"
              @toggle-hide="toggleHideBlock(block.guid, $event)"
              @change-property="updateComponentProperty(block.guid, $event.name, $event.value)"
            >
            </component>
            <div :class="{ 'component-placeholder': true, 'component-placeholder_highlight': block.isHighlight }" v-if="!block.isEditing"></div>
            <div class="tools_panel">
              <div class="locker" @click="lockToggle" v-if="components[block.guid]
            && componentsWithLock.includes(components[block.guid].initialType)">
                <i :class="{'el-icon-lock': !block.isEditing, 'el-icon-unlock': block.isEditing}"></i>
              </div>
              <div :class="{'sticky': true, 'isset': block.stickyTo && block.stickyTo.guid}"
                   v-if="block.sticky === 'tl'"
                   @click="stickyToggle(block)"
              >
                <i :class="{'el-icon-share': true}"></i>
              </div>
              <div class='replication' v-if="isReplicationBlock(block)">
                <i class="el-icon-s-grid"></i>
              </div>
            </div>
          </template>
        </responsive-designer>
        <responsive-previewer
          v-if="preview"
          ref="previewer"
          style="height: 100%"
          :style="settingStyle"
          :replication-callback="onBlockReplication"
          :tab-settings="tabSettings"
          @tab-settings-update="tabSettings = $event"
          @click="onClick"
        >
          <template v-slot:content="{ block }">
            <!-- @TODO Заменить на Cards -->
            <Registry
              v-if="block.contentType === 'registry'"
              :ref="'registry_' + block.guid"
              :id="block.registry ? block.registry.registryId : null"
            ></Registry>
            <component
              v-else-if="checkType(block.guid)"
              v-bind="_components[block.guid].properties"
              :replication-guid="(_components[block.guid].replication || {}).blockGuid"
              :replication-index="(_components[block.guid].replication || {}).index"
              :guid="block.guid"
              :is="_components[block.guid].type"
              :value="isReplicationContainer(_components[block.guid].replication)
              ? getReplicationModel(_components[block.guid].replication)[_components[block.guid].properties.name]
              : model[_components[block.guid].properties.name]"
              @input="isReplicationContainer(_components[block.guid].replication) ?
              null :
              $set(model, _components[block.guid].properties.name, $event)"
              @toggle-hide="toggleHideBlock(block.guid, $event)"
            >
            </component>
          </template>
        </responsive-previewer>
      </el-form>
      <settings-panel
        v-show="!preview"
        class="settings_panel"
        :mainParentBlock="mainParentBlock"
        :properties="propertiesForSettings"
        :tab-settings-service="tabSettingsService"
        :block-settings="blockSettings"
        :registry-fields="registryFields"
        @tab-settings-update="tabSettings = $event"
        @open-modal-window-by-configurator="openModalWindowByConfigurator = $event"
      ></settings-panel>
    </div>
    <settings-window
      v-if="showSettingsWindow"
      :active.sync="showSettingsWindow"
      v-model="settings"
    >
      <template v-slot:settings>
        <slot name="settings"></slot>
      </template>
    </settings-window>
    <conditions-window
      v-if="showConditionsWindow"
      :active.sync="showConditionsWindow"
      :value.sync="conditions"
    >
    </conditions-window>
    <windows-settings-window
      v-if="showWindowsSettingsWindow"
      :active.sync="showWindowsSettingsWindow"
      :value.sync="windows"
    ></windows-settings-window>
    <modal-window
      v-if="!modalWindow.isPopover"
      :active.sync="modalWindow.show"
      :width="modalWindow.width"
      :height="modalWindow.height"
      :title="modalWindow.title"
    >
      <interface-viewer
        :model="modalWindow.model"
        ref="modal_viewer"
      >
      </interface-viewer>
    </modal-window>
    <custom-popover
      v-if="modalWindow.isPopover && modalWindow.show"
      :active.sync="modalWindow.show"
      :position-x="modalWindow.position.x"
      :position-y="modalWindow.position.y"
      :width="modalWindow.width"
      :height="modalWindow.height"
    >
      <interface-viewer
        :model="modalWindow.model"
        ref="modal_viewer_popover"
      >
      </interface-viewer>
    </custom-popover>
    <save-template
      v-if="showSaveTemplate"
      :showSaveTemplate.sync="showSaveTemplate"
      :active-block="activeBlock"
      @save-temlate-to-report-editor="saveTemplateToReportEditor">
    </save-template>
    <modal-window
      v-if="type === 'card'"
      :active.sync="showCardSourceWindow"
      title="Источник карточки"
    >
      <registry-card-source
        style="height: calc(100% - 38px)"
        :registry-id="registryId"
        :card-fields="cardFields"
        :fields.sync="cardSource.fields"
        :is-active.sync="cardSource.isActive"
      ></registry-card-source>
      <div class="button" style="display: inline-block;float: right" @click="showCardSourceWindow = false">Сохранить и закрыть</div>
    </modal-window>
  </div>
</template>

<script>
import { VueContext } from 'vue-context'
import Toolbar from './infrastructure/components/Toolbar/index.vue'
import { InterfaceType } from '@/services/InterfaceEditor/domain/model/InterfaceType'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import RegistryFieldsQueryRepository
  from '@/services/InterfaceEditor/infrastructure/domain/repository/RegistryFieldsQueryRepository'
import RegistryFieldsHandler from '@/services/InterfaceEditor/application/handler/query/RegistryFieldsHandler'

import VueDraggableResponsive from 'vue-draggable-responsive'
import 'vue-draggable-responsive/dist/vue-draggable-responsive.css'
import Vue from 'vue'
import Template from '@/components/EtlEditor/Properties/Template'
import SettingsPanel from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/index.vue'
import propertyTypes from '@/components/InterfaceEditor/property_types.json'
import SettingsWindow from '@/services/InterfaceEditor/infrastructure/components/SettingsWindow/index.vue'
import main from '@/services/InterfaceEditor/mixins/main.js'
import replication from '@/services/InterfaceEditor/mixins/replication'

import Registry from '@/components/Registry/index.vue'

import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'
import ConditionsWindow from '@/services/InterfaceEditor/infrastructure/components/ConditionsWindow'
import WindowsSettingsWindow from '@/services/InterfaceEditor/infrastructure/components/WindowsSettingsWindow'
import ModalWindow from '@/core/infrastructure/components/ModalWindow'
import InterfaceViewer from '@/services/InterfaceViewer'
import CustomPopover from '@/core/infrastructure/components/CustomPopover'
import SaveTemplate from '@/services/InterfaceEditor/infrastructure/components/SaveTemplate/index.vue'

import RegistryCardSource from '@/services/InterfaceEditor/infrastructure/components/RegistryCardSource'
import CardsWrapper from '@/components/Registry/CardsWrapper'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { interfaceTemplateAPI } from '@//services/InterfaceEditor/infrastructure/api/interfaceTemplateAPI'

export default {
  name: 'InterfaceEditor',

  components: {
    RegistryCardSource,
    CustomPopover,
    InterfaceViewer,
    ModalWindow,
    WindowsSettingsWindow,
    ConditionsWindow,
    VueContext,
    SettingsWindow,
    SettingsPanel,
    Template,
    Toolbar,
    ResponsiveDesigner: VueDraggableResponsive.VueDraggableResponsiveDesigner,
    ResponsivePreviewer: VueDraggableResponsive.VueDraggableResponsivePreviewer,
    Registry,
    SaveTemplate,
    CardsWrapper
  },

  mixins: [main, replication],

  inject: {
    /* Зависимости ActionExecutor @TODO убрать ненужные */
    getCard: {
      default: () => {}
    },
    getParentDashboard: {
      default: () => {}
    },
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    }
  },

  provide () {
    return {
      getEventBus: this.getEventBus,
      getQueryBus: this.getQueryBus,
      getComponents: this.getComponentsAsArray,
      getRegistryId: this.getRegistryId,
      getContainersStore: this.getContainersStore,
      getInterfaceWrapper: this.getInterfaceWrapper,
      setActive: this.setActive,
      isEditor: () => {
        return true
      },
      getCurrentViewer: this.getCurrentViewer,
      stickyTo: this.stickyTo
    }
  },

  props: {
    type: {
      type: String,
      default: InterfaceType.CARD
    },
    getAdditionalSettings: {
      type: Function
    },
    registryId: Number,
    registryFields: {
      type: Array,
      default () {
        return []
      }
    },
    structureFromTemplate: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      status: undefined,
      isDragging: false,
      stickyMode: false,
      showHidden: false,
      componentsWithLock: [
        'a-html',
        'a-table',
        'Registry',
        'xref_outer_field',
        'AnalyticTable/index',
        'Map/index'
      ],
      isClickedOnBlock: false,
      model: {},
      activeBlock: null,
      previewBlocks: [],
      preview: false,
      components: {},
      originalComponents: {},
      eventBus: new Vue(),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'RegistryFieldsQuery': new RegistryFieldsHandler(
            new RegistryFieldsQueryRepository()
          )
        })
      ),
      showSettingsWindow: false,
      showConditionsWindow: false,
      showWindowsSettingsWindow: false,
      showSaveTemplate: false,
      showCardSourceWindow: false,
      settings: {},
      originalSettings: {},
      mainParentBlock: {},
      cardSource: {
        isActive: false,
        fields: []
      },
      openModalWindowByConfigurator: false
    }
  },

  computed: {
    designerHeight () {
      if (this.activeWindowGuid) {
        const _window = this.windows.find((item) => item.guid === this.activeWindowGuid)
        if (_window) {
          return `${_window.height.value}${_window.height.type}`
        }
      }

      return '100%'
    },

    designerWidth () {
      if (this.activeConditionGuid) {
        const condition = this.conditions.find((item) => item.guid === this.activeConditionGuid)
        if (condition) {
          return `${condition.maxWidth}px`
        }
      }
      if (this.activeWindowGuid) {
        const _window = this.windows.find((item) => item.guid === this.activeWindowGuid)
        if (_window) {
          return `${_window.width.value}${_window.width.type}`
        }
      }

      return '100%'
    },

    _components () {
      return Object.assign({}, this.components, this.replicationComponents)
    },

    propertiesForSettings () {
      let answer = {
        block: this.activeBlock
      }
      const componentSettings = this.activeBlock ? this.components[this.activeBlock.guid] : undefined
      if (componentSettings && componentSettings.rendered) {
        answer.component = componentSettings
        let componentRef = this.$refs[`component_${componentSettings.guid}`]
        if (componentRef) {
          let properties = componentRef.getProperties()
          let componentProperties = []
          for (let property in properties) {
            if (properties.hasOwnProperty(property)) {
              let type = this.toCamelCase(
                propertyTypes[properties[property].editor ? properties[property].editor : properties[property].type.name].name
              )
              componentProperties.push({
                label: properties[property].description,
                name: property,
                type: type,
                bind: {
                  value: componentRef.$props[property],
                  otherProperties: componentRef.$props,
                  options: properties[property].options,
                  hidden: properties[property].hidden
                }
              })
            }
          }
          answer.componentProperties = componentProperties
        }
      }

      return answer
    },

    cardFields () {
      let answer = []
      for (let key in this.components) {
        if (this.components.hasOwnProperty(key)) {
          const item = this.components[key]
          const name = item.properties?.name
          if (name && /attr_[0-9]+_/i.test(name)) {
            const attributeId = name.match(/\d+/)[0]
            if (!answer.includes(attributeId)) {
              answer.push(attributeId)
            }
          }
          if (item.initialType === 'a-html') {
            const html = item.properties?.html || ''
            const attributes = html.match(/\{{(.*?)\}}/g) || []
            attributes.forEach((attribute) => {
              attribute = attribute.replace('{{', '').replace('}}', '')
              if (attribute && /attr_[0-9]+_/i.test(attribute)) {
                const attributeId = attribute.match(/\d+/)[0]
                if (!answer.includes(attributeId)) {
                  answer.push(attributeId)
                }
              }
            })
          }
        }
      }

      return answer
    }
  },

  watch: {
    cardSource: {
      handler (value) {
        let settings = this.getAdditionalSettings()
        if (!('alternativeSource' in settings)) {
          this.$set(settings, 'alternativeSource', {
            isActive: false,
            fields: []
          })
        }

        settings.alternativeSource.isActive = value.isActive || false
        settings.alternativeSource.fields = value.fields || []

        this.$emit('set-additional-settings', settings)
      },
      deep: true
    },

    activeConditionGuid (value) {
      this.status = undefined
      this.$refs.designer.clearActiveBlock()
      this.activeBlock = undefined
      if (value) {
        const condition = this.conditions.find((item) => item.guid === value)
        if (condition) {
          this.status = {
            title: condition.name,
            subtitle: `${condition.maxWidth}px`
          }
        }
      }
    },

    preview: {
      handler () {
        this.$nextTick(() => {
          this.$set(this, 'currentViewer', this.preview ? this.$refs.previewer : this.$refs.designer)
          this.$set(this, 'tabSettingsService', this.currentViewer?.tabSettingsService)
        })
      },
      immediate: true
    },

    activeBlock (newValue, oldValue) {
      if (oldValue) {
        oldValue.isEditing = false
      }
    },

    conditions () {
      if (!this.activeConditionGuid) {
        return
      }

      if (!this.conditions.find((item) => item.guid === this.activeConditionGuid)) {
        this.openConditionDesigner()
      }
    }
  },

  mounted () {
    this.registerEvents()
    // проверка на пустоту шаблона
    if (this.structureFromTemplate?.blocks) {
      this.loadInterface(this.structureFromTemplate)
      return
    }
    if (this.type === InterfaceType.CARD) {
      const settings = this.getAdditionalSettings()
      if ('alternativeSource' in settings) {
        this.cardSource.isActive = settings.alternativeSource.isActive || false
        this.cardSource.fields = settings.alternativeSource.fields || []
      }
    }
  },

  beforeDestroy () {
    this.unregisterEvents()
  },

  methods: {
    getCurrentViewer () {
      return this.currentViewer
    },
    onBlockRemove (guid) {
      if (this.components.hasOwnProperty(guid)) {
        delete this.components[guid]
      }
      if ((this.blockSettings.hiddenConditions || {}).hasOwnProperty(guid)) {
        delete this.blockSettings.hiddenConditions[guid]
      }
    },

    isReplicationBlock (block) {
      return block.replication?.additionalData?.sourceType && block.replication?.additionalData?.sourceId
    },

    updateComponentProperty (guid, property, value) {
      // console.log('Принял CHANGE-PROPERTY new editor:', guid, property, value)
      let component = this.components[guid]
      if (!component) {
        console.warn(`component <${guid}> not found for update property '${property}'`)
        return
      }
      if (Object.keys(component.properties).length === 0) {
        this.$set(component, 'properties', {})
      }
      // console.log(component.properties)
      this.$set(component.properties, property, value)
    },

    showHiddenBlocks (value) {
      this.showHidden = value
    },

    toggleHideBlock (guid, value) {
      if (!this.$refs.previewer) {
        return
      }
      const store = this.preview ? this.$refs.previewer.getStore() : this.$refs.designer.getStore()
      const block = store.getByGuid(guid)
      if (!block) {
        return false
      }
      block.isHidden = value
    },

    stickyToggle (block) {
      if (block.stickyTo?.guid) {
        this.stickyTo(block.guid)
      } else {
        this.stickyMode = true
      }
    },

    lockToggle () {
      if (this.activeBlock) {
        this.activeBlock.isEditing = !this.activeBlock.isEditing
      }
    },

    clearActive () {
      this.$refs.designer.clearActiveBlock()
      this.activeBlock = undefined
      if (this.stickyMode) {
        this.stickyMode = false
      }
    },

    getRegistryId () {
      return this.registryId
    },

    getComponentsAsArray () {
      let answer = []
      for (let key in this.components) {
        if (this.components.hasOwnProperty(key)) {
          answer.push(Object.assign(this.components[key], {
            name: `${this.components[key].group}/${this.components[key].initialType}`
          }))
        }
      }

      return answer
    },

    stickyTo (fromGuid, toGuid = undefined) {
      try {
        const fromBlock = this.currentViewer.getStore().getByGuid(fromGuid)
        const fromBlockRef = this.currentViewer.getStore().getRefByGuid(fromGuid)
        if (!fromBlock || !fromBlockRef) {
          throw new Error('Не найден блок который прилипают')
        }
        if (!toGuid) {
          this.$set(fromBlock, 'stickyTo', {
            type: 'top',
            guid: undefined
          })
          return
        }
        const toBlockRef = this.currentViewer.getStore().getRefByGuid(toGuid)
        const toBlock = this.currentViewer.getStore().getByGuid(toGuid)
        if (!toBlock || !toBlockRef) {
          throw new Error('Не найден блок куда прилипают')
        }
        if (fromGuid === toGuid) {
          throw new Error('Невозможно прилипить к самому себе')
        }
        if (fromBlock.parentGuid !== toBlock.parentGuid) {
          throw new Error('Блоки находятся на разных уровнях вложенности')
        }
        if (fromBlock.sticky !== 'tl' || toBlock.sticky !== 'tl') {
          throw new Error('Можно прилеплять блоки только с позицицей "Сверху-слева"')
        }
        if (fromBlockRef.$el.getBoundingClientRect().y < (
          toBlockRef.$el.getBoundingClientRect().y + toBlockRef.$el.getBoundingClientRect().height
        )) {
          throw new Error('Прилипаемый блок должен быть ниже, чем блок к которому прилепляют')
        }
        if (fromBlock.stickyTo?.guid) {
          this.$set(fromBlock, 'stickyTo', {
            type: 'top',
            guid: undefined
          })
        }
        this.$nextTick(() => {
          this.$set(fromBlock, 'stickyTo', {
            type: 'top',
            guid: toGuid
          })
        })
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    setActive (block) {
      if (!block) {
        this.clearActive()
        return
      }
      if (this.stickyMode) {
        this.stickyMode = false
        this.stickyTo(this.activeBlock.guid, block.guid)
        return false
      }
      this.activeBlock = block
      this.$refs.designer.setActiveBlock(block.guid)
      this.mainParentBlock = this.$refs.designer.getMainParents(block.guid)
      this.isClickedOnBlock = true
    },

    setActiveBlockByGuid (guid) {
      const block = this.currentViewer.getStore().getByGuid(guid)
      this.setActive(block)
    },

    setActiveTabByGuid (tabContainerGuid, tabGuid, isOpenParentTabs = false) {
      const tabContainer = this.currentViewer.getStore().getByGuid(tabContainerGuid)

      if (!isOpenParentTabs) {
        this.$set(tabContainer, 'activeTabGuid', tabGuid)
        return
      }

      const tabContainersQueue = []

      let currentBlock = null
      let currentTabContainer = tabContainer
      let currentTabGuid = tabGuid

      // Создать очередь раскрытия вкладок контейнеров вкладок
      const stack = [currentTabContainer]
      while (stack.length > 0) {
        currentBlock = stack.pop()

        if (currentBlock.tabs) {
          // Контейнер вкладок
          currentTabContainer = currentBlock
        }

        if (currentTabContainer && currentTabGuid) {
          // Итерируемый блок - это Контейнер вкладок и ранее запомнили вкладку
          tabContainersQueue.push({ tabContainerGuid: currentTabContainer.guid, tabGuid: currentTabGuid })

          currentTabContainer = null
          currentTabGuid = null
        }

        if (currentBlock.parentTabGuid) {
          // Запомнить вкладку в которой находиться итерируемый блок
          currentTabGuid = currentBlock.parentTabGuid
        }

        // Перейти на уровень выше
        const parentBlock = this.currentViewer.getStore().getByGuid(currentBlock.parentGuid)
        if (parentBlock) {
          stack.push(parentBlock)
        }
      }

      if (tabContainersQueue.length === 0) {
        return
      }

      // Раскрыть вкладки в контейнерах вкладок от родителя к дочернему
      for (let i = tabContainersQueue.length; i--;) {
        const { tabContainerGuid, tabGuid } = tabContainersQueue[i]

        // Отложить макрозадачу в очередь, чтобы getRefByGuid получил блок, созданный во время открытия ранее не открытой вкладки
        setTimeout(() => {
          const tabContainer = this.currentViewer.getStore().getRefByGuid(tabContainerGuid)
          this.$set(tabContainer, 'activeTabGuid', tabGuid)
        }, 0 /* (tabContainersQueue.length - 1 - i) * 100 */)
      }
    },

    addChildBlock (event) {
      if (this.activeBlock) {
        this.addBlock({
          event: event,
          parentGuid: this.activeBlock.guid
        })
      }
    },

    async addPaginationBlock ({ limit, settings }) {
      let activeBlock = this.activeBlock.guid
      const guid = await this.$refs.designer.addBlock({
        width: 20,
        height: 100,
        sizeTypes: {
          left: '%',
          top: 'px',
          right: '%',
          bottom: '%',
          width: '%',
          height: 'px'
        },
        event: undefined,
        pagination: {
          replicationGuid: activeBlock,
          limit: limit
        },
        type: 1,
        parentGuid: this.activeBlock.parentGuid
      })
      this.$set(this.components, guid, {
        type: () => import(`@/components/InterfaceEditor/components/basic/pagination.vue`),
        initialType: 'pagination',
        name: 'pagination',
        guid: guid,
        rendered: false,
        properties: {
          name: 'pagination',
          limit: limit,
          settings: settings,
          margin: '0px'
        },
        group: 'basic'
      })
    },

    showContextMenu ({ event, block }) {
      event.preventDefault()
      this.$refs['menu'].open(event, block)
    },

    copyBlock (block) {
      let copiedBlock = JSON.parse(JSON.stringify(block))
      copiedBlock.guid = undefined
      copiedBlock.stickyToGuid = undefined
      copiedBlock.isActive = false
      copiedBlock.isActiveAsParent = false
      copiedBlock.parentGuid = undefined

      let result = {
        block: copiedBlock,
        component: this.components[block.guid]
      }
      this.$copyText(JSON.stringify(result))
    },

    onPasteBlock (event) {
      let clipboardData
      try {
        clipboardData = JSON.parse(event.clipboardData.getData('text'))
      } catch (e) {
        console.warn('Cannot parse clipboard data ', event.clipboardData.getData('text'))
        return
      }

      if (clipboardData.block) {
        // console.log(event)
        event.preventDefault()
        this.deepAddBlock(clipboardData.block, clipboardData.component)
      }
    },

    deepAddBlock (block, component) {
      if (this.activeBlock) {
        if (this.components[this.activeBlock.guid]) {
          block.parentGuid = this.activeBlock.parentGuid
        } else {
          block.parentGuid = this.activeBlock.guid
        }
      }
      let guid = this.generateGuid()
      block.guid = guid
      if (component) {
        this.$set(this.components, guid, Object.assign(component, {
          guid: guid,
          type: () => import(`@/components/InterfaceEditor/components/${component.group}/${component.initialType}.vue`)
        }))
      }
      let childrenCycle = (children, guid) => {
        children.forEach((item) => {
          const newGuid = this.generateGuid()
          item.parentGuid = guid
          if (this.components[item.guid]) {
            let component = JSON.parse(JSON.stringify(this.components[item.guid]))
            this.$set(this.components, newGuid, Object.assign(component, {
              guid: newGuid,
              type: () => import(`@/components/InterfaceEditor/components/${component.group}/${component.initialType}.vue`)
            }))
          }
          item.guid = newGuid
          if (item.children && item.children.length > 0) {
            childrenCycle(item.children, newGuid)
          }
        })
      }
      if (block.children && block.children.length > 0) {
        childrenCycle(block.children, guid)
      }
      const store = this.$refs.designer.getStore()
      if (block.parentGuid) {
        let parent = store.getByGuid(block.parentGuid)
        if (parent.tabs?.use && parent.tabs?.activeGuid) {
          block.parentTabGuid = parent.tabs.activeGuid
        } else {
          block.parentTabGuid = undefined
        }
        parent.children.push(...store.prepareBlocks([block]))
      } else {
        store.blocks.push(...store.prepareBlocks([block]))
      }
    },
    pasteTemplateOut (data) {
      let structure = {
        blocks: [ ...data.blocks, ...this.$refs.designer.getBlocks() ],
        components: { ...data.components, ...this.components }
      }
      this.loadInterface(structure)
    },
    pasteTemplateIn (data) {
      this.activeBlock.children.push(...data.blocks)
      let structure = {
        blocks: [ ...this.activeBlock.children ],
        components: { ...data.components, ...this.components }
      }
      this.loadInterface(structure)
    },
    registerEvents () {
      this.eventBus.$on('addBlock', this.addBlock)
      this.eventBus.$on('addChildBlock', this.addChildBlock)
      this.eventBus.$on('addComponent', this.addComponent)
      this.eventBus.$on('save', this.save)
      this.eventBus.$on('preview', this.initPreview)
      this.eventBus.$on('remove', this.remove)
      this.eventBus.$on('settings', this.openSettingsWindow)
      this.eventBus.$on('import', this.import)
      this.eventBus.$on('export', this.export)
      this.eventBus.$on('card-source-settings', this.openCardSourceWindow)
      this.eventBus.$on('openTemplateDialog', this.openTemplateDialog)
      this.eventBus.$on('showHiddenBlocks', this.showHiddenBlocks)
      this.eventBus.$on('openConditionsWindow', this.openConditionsWindow)
      this.eventBus.$on('openConditionDesigner', this.openConditionDesigner)
      this.eventBus.$on('openWindowsDesigner', this.openWindowsDesigner)
      this.eventBus.$on('openWindowsSettingWindow', this.openWindowsSettingWindow)
      this.eventBus.$on('copyConditionFromTo', this.copyConditionFromTo)
      this.eventBus.$on('addPaginationBlock', this.addPaginationBlock)
      this.eventBus.$on('pasteTemplateOut', this.pasteTemplateOut)
      this.eventBus.$on('pasteTemplateIn', this.pasteTemplateIn)
    },

    unregisterEvents () {
      this.eventBus.$off('addBlock', this.addBlock)
      this.eventBus.$off('addChildBlock', this.addChildBlock)
      this.eventBus.$off('addComponent', this.addComponent)
      this.eventBus.$off('save', this.save)
      this.eventBus.$off('preview', this.initPreview)
      this.eventBus.$off('remove', this.remove)
      this.eventBus.$off('settings', this.openSettingsWindow)
      this.eventBus.$off('import', this.import)
      this.eventBus.$off('export', this.export)
      this.eventBus.$off('card-source-settings', this.openCardSourceWindow)
      this.eventBus.$off('openTemplateDialog', this.openTemplateDialog)
      this.eventBus.$off('openWindowsDesigner', this.openWindowsDesigner)
      this.eventBus.$off('openWindowsSettingWindow', this.openWindowsSettingWindow)
      this.eventBus.$off('openConditionsWindow', this.openConditionsWindow)
      this.eventBus.$off('openConditionDesigner', this.openConditionDesigner)
      this.eventBus.$off('copyConditionFromTo', this.copyConditionFromTo)
      this.eventBus.$off('showHiddenBlocks', this.showHiddenBlocks)
      this.eventBus.$off('addPaginationBlock', this.addPaginationBlock)
      this.eventBus.$off('pasteTemplateOut', this.pasteTemplateOut)
      this.eventBus.$off('pasteTemplateIn', this.pasteTemplateIn)
    },

    openCardSourceWindow () {
      this.showCardSourceWindow = true
    },

    copyConditionFromTo (object) {
      const conditionTo = this.conditions.find((item) => item.guid === object.to)
      if (conditionTo) {
        if (object.from) {
          const conditionFrom = this.conditions.find((item) => item.guid === object.from)
          conditionTo.structure = conditionFrom.structure
        } else {
          conditionTo.structure = JSON.parse(JSON.stringify({
            blocks: this.activeConditionGuid ? this.blocks : this.$refs.designer.getBlocks(),
            settings: this.activeConditionGuid ? this.originalSettings : this.settings,
            components: this.activeConditionGuid ? this.originalComponents : this.components
          }))
        }
      }
      if (this.activeConditionGuid === conditionTo.guid) {
        this.openConditionDesigner(this.activeConditionGuid)
      }
    },

    openWindowsSettingWindow () {
      this.showWindowsSettingsWindow = true
    },

    openWindowsDesigner (guid = null) {
      this.clearActive()
      if (this.activeWindowGuid) {
        if (this.activeWindowGuid !== guid) {
          const _window = this.windows.find((item) => item.guid === this.activeWindowGuid)
          if (_window) {
            _window.structure.blocks = this.$refs.designer.getBlocks()
            _window.structure.settings = this.settings
          }
        } else {
          return false
        }
      } else {
        this.blocks = this.$refs.designer.getBlocks()
        this.originalSettings = this.settings
      }
      if (!guid) {
        this.activeWindowGuid = null
        this.loadInterface({
          blocks: this.blocks,
          settings: this.originalSettings,
          components: this.originalComponents
        })
        return false
      }
      const _window = this.windows.find((item) => item.guid === guid)
      if (!_window) {
        console.warn(`${guid} window not found`)
        return false
      }
      this.activeWindowGuid = guid
      if (!_window.structure || Object.keys(_window.structure).length === 0) {
        this.$set(_window, 'structure', {})
      }
      this.loadInterface(_window.structure, false, true)
    },

    openConditionsWindow () {
      this.showConditionsWindow = true
    },

    openConditionDesigner (guid = null) {
      this.clearActive()
      if (this.activeConditionGuid) {
        if (this.activeConditionGuid !== guid) {
          const condition = this.conditions.find((item) => item.guid === this.activeConditionGuid)
          if (condition) {
            condition.structure.blocks = this.$refs.designer.getBlocks()
            condition.structure.settings = this.settings
          }
        } else {
          return false
        }
      } else {
        this.blocks = this.$refs.designer.getBlocks()
        this.originalSettings = this.settings
      }
      if (!guid) {
        this.activeConditionGuid = null
        this.loadInterface({
          blocks: this.blocks,
          settings: this.originalSettings,
          components: this.originalComponents
        }, true)
        return false
      }
      const condition = this.conditions.find((item) => item.guid === guid)
      if (!condition) {
        console.warn(`${guid} condition not found`)
        return false
      }
      this.activeConditionGuid = guid
      if (!condition.structure || Object.keys(condition.structure).length === 0) {
        this.$set(condition, 'structure', {})
      }
      this.loadInterface(condition.structure, true)
    },

    openSettingsWindow () {
      this.showSettingsWindow = true
    },

    save () {
      // редактор интерфейсов открыт через редактор отчетов
      if (this.structureFromTemplate?.template?.id) {
        this.saveTemplateFromReportEditor(this.structureFromTemplate.template)
        return
      }
      const answer = {
        components: this.originalComponents,
        blocks: this.blocks,
        settings: this.originalSettings,
        conditions: this.conditions,
        tabSettings: this.tabSettings,
        windows: this.windows,
        blockSettings: this.blockSettings
      }

      if (this.activeConditionGuid) {
        const condition = answer.conditions.find((item) => item.guid === this.activeConditionGuid)
        condition.structure.blocks = this.$refs.designer.getBlocks()
        condition.structure.settings = this.settings
      } else if (this.activeWindowGuid) {
        const _window = answer.windows.find((item) => item.guid === this.activeWindowGuid)
        _window.structure.blocks = this.$refs.designer.getBlocks()
        _window.structure.settings = this.settings
      } else {
        answer.blocks = this.$refs.designer.getBlocks()
        answer.settings = this.settings
      }

      this.$emit('save', answer)
    },

    remove () {
      if (!this.activeBlock) {
        return false
      }
      if (this.components.hasOwnProperty(this.activeBlock.guid)) {
        delete this.components[this.activeBlock.guid]
      }

      this.$refs.designer.removeBlock(this.activeBlock.guid)
      // this.blocks = this.$refs.designer.getBlocks()
      this.activeBlock = null
    },

    initPreview (value) {
      this.preview = value
      this.model = {}
      if (value) {
        this.previewBlocks = this.$refs.designer.getBlocks()
        this.$nextTick(() => {
          this.$refs.previewer.setBlocks(this.prepareForReplication(this.previewBlocks))
        })
      } else {
        this.previewBlocks = []
      }
    },

    getEventBus () {
      return this.eventBus
    },

    getQueryBus () {
      return this.queryBus
    },

    getContainersStore () {
      return this.currentViewer.getStore()
    },

    getInterfaceWrapper () {
      return this
    },

    getDashboardComponents () {
      return this.getContainersStore().refs.map(item => [item.element])
    },

    async addComponent (event) {
      let parent
      if (this.activeBlock) {
        if (this.components[this.activeBlock.guid]) {
          parent = this.activeBlock.parentGuid
        } else {
          parent = this.activeBlock.guid
        }
      }
      const guid = await this.$refs.designer.addBlock({
        width: 20,
        height: 100,
        sizeTypes: {
          left: '%',
          top: 'px',
          right: '%',
          bottom: '%',
          width: '%',
          height: 'px'
        },
        event: event.event,
        type: 1,
        parentGuid: parent
      })
      switch (event.type) {
        case 'basic':
          this.$set(this.components, guid, {
            type: () => import(`@/components/InterfaceEditor/components/basic/${event.component}.vue`),
            initialType: event.component,
            name: event.component,
            guid: guid,
            rendered: false,
            properties: {
              margin: '0px'
            },
            group: 'basic'
          })
          break
        case 'registry':
          this.$set(this.components, guid, {
            type: () => import(`@/components/InterfaceEditor/components/registry/${event.component}.vue`),
            initialType: event.component,
            name: event.component,
            guid: guid,
            rendered: false,
            properties: {
              label: event.label,
              name: event.name,
              registry_properties: event.registryProperties,
              margin: '0px'
            },
            group: 'registry'
          })
          break
        default:
          console.error('wrong component type')
      }
      if (this.activeConditionGuid) {
        const condition = this.conditions.find((item) => item.guid === this.activeConditionGuid)
        if (condition) {
          if (Object.keys(condition.structure.components || {}).length === 0) {
            this.$set(condition.structure, 'components', {})
          }
          this.$set(condition.structure.components, guid, this.components[guid])
        }
      } else if (this.activeWindowGuid) {
        const _window = this.windows.find((item) => item.guid === this.activeWindowGuid)
        if (_window) {
          if (Object.keys(_window.structure.components || {}).length === 0) {
            this.$set(_window.structure, 'components', {})
          }
          this.$set(_window.structure.components, guid, this.components[guid])
        }
      } else {
        this.$set(this.originalComponents, guid, this.components[guid])
      }
      // this.blocks = this.$refs.designer.getBlocks()
    },

    addBlock ({ event, parentGuid }) {
      // alias  для лейбла селектора элементов
      let aliasBlockCount = `Контейнер ${this.blocks.length}`
      let aliasBlockParent = ''
      if (parentGuid) {
        aliasBlockParent = `${this.activeBlock?.alias}.`
        aliasBlockCount = this.activeBlock?.children.length
      }
      this.$refs.designer.addBlock({
        width: 20,
        height: 200,
        sizeTypes: {
          left: '%',
          top: 'px',
          right: '%',
          bottom: '%',
          width: '%',
          height: 'px'
        },
        event: event,
        type: 1,
        alias: `${aliasBlockParent}${aliasBlockCount}`,
        parentGuid: parentGuid
      })
      // this.blocks = this.$refs.designer.getBlocks()
    },

    import () {
      this.$refs.json.click()
    },

    onInputChange (e) {
      let me = this
      const files = e.target.files
      if (files[0] !== undefined) {
        const fr = new FileReader()
        fr.readAsText(files[0])
        fr.addEventListener('load', () => {
          let json = JSON.parse(fr.result)
          me.loadInterface(json)
          this.$emit('set-additional-settings', json)
        })
      }
    },

    export () {
      let additionalSettings = {}
      if (typeof this.getAdditionalSettings === 'function') {
        additionalSettings = this.getAdditionalSettings()
      }
      let mainSettings = {
        components: this.components,
        blocks: this.$refs.designer.getBlocks(),
        settings: this.settings,
        tabSettings: this.tabSettings,
        blockSettings: this.blockSettings
      }
      let structure = { ...additionalSettings, ...mainSettings }
      let data = JSON.stringify(structure)
      let a = document.createElement('a')
      let file = new Blob([data], { type: 'text/plain' })
      a.href = URL.createObjectURL(file)
      a.download = 'interface.json'
      a.click()
      a.remove()
    },

    openTemplateDialog () {
      this.showSaveTemplate = true
    },
    async saveTemplateFromReportEditor ({ id, name }) {
      console.log('saveFromReport')
      let mainSettings = {
        components: this.components,
        blocks: this.$refs.designer.getBlocks()
        // blockSettings: this.blockSettings,
        // tabSettings: this.tabSettings,
        // settings: this.settings
      }
      console.log(mainSettings)
      let content = JSON.stringify(mainSettings)
      const params = {
        id,
        content,
        name,
        report_sources: [],
        report_files: {
          image: {
            file_id: null,
            filename: null
          }
        }
      }
      await APIClient.shared.request(new interfaceTemplateAPI.UpdateTemplate(params))
    },
    // сохранить шаблон в редактор отчетов
    async saveTemplateToReportEditor ({ form, container }) {
      let params = {
        name: form.name,
        type: 'interface_template'
      }
      let content
      if (container === 'all') {
        let mainSettings = {
          components: this.components,
          blocks: this.blocks
          // blockSettings: this.blockSettings
          // tabSettings: this.tabSettings
        }
        content = JSON.stringify(mainSettings)
      } else {
        // найти выбранный блок
        let deepSearch = blocks => {
          for (let block of blocks) {
            if (block.guid === this.activeBlock.guid) {
              findedBlock = JSON.parse(JSON.stringify(block))
              findedBlock.parentGuid = undefined
              if ('parentTabGuid' in block) {
                findedBlock.parentTabGuid = undefined
              }
              break
            }
            if (block.children.length && Array.isArray(block.children)) {
              deepSearch(block.children)
            }
          }
        }
        let findedBlock = {}
        // console.log(this.$refs.designer)
        deepSearch(this.blocks)
        let filterComponents = {}
        // найти компоненты в выбранном блоке
        let findComponentsInActiveBlock = item => {
          item.forEach(item => {
            if (this.components[item.guid]) {
              filterComponents = { ...filterComponents, ...{ [item.guid]: this.components[item.guid] } }
            }
            if (item.children.length && Array.isArray(item.children)) {
              findComponentsInActiveBlock(item.children)
            }
          })
        }
        if (Object.keys(findedBlock).length) {
          if (findedBlock.children.length) {
            findComponentsInActiveBlock(findedBlock.children)
          } else {
            findedBlock.parentGuid = undefined
            if (this.components[findedBlock.guid]) {
              filterComponents = { [findedBlock.guid]: this.components[findedBlock.guid] }
            }
          }
        } else {
          console.log('контейнер не найден', findedBlock)
          return
        }

        let mainSettings = {
          components: filterComponents,
          blocks: [findedBlock]
          // blockSettings: this.blockSettings
          // tabSettings: this.tabSettings
        }
        // console.log('что сохраняем', mainSettings)
        content = JSON.stringify(mainSettings)
      }

      try {
        let { id, name } = await APIClient.shared.request(new interfaceTemplateAPI.SaveTemplate(params))
        let paramsForPut = {
          id,
          content,
          name,
          report_sources: [],
          report_files: {
            image: {
              file_id: form.image.file_id || null,
              filename: form.image.filename || null
            }
          }
        }
        await APIClient.shared.request(new interfaceTemplateAPI.UpdateTemplate(paramsForPut))
      } catch (error) {
        console.log(error)
      }
    },

    onClick ({ block, event }) {
      if (!block.interactive) {
        return
      }
      let model = {}
      try {
        if (this.replicationBlocks[block.guid] && this.replicationData[this.replicationBlocks[block.guid].blockGuid]) {
          model = this.replicationData[this.replicationBlocks[block.guid].blockGuid][this.replicationBlocks[block.guid].index]
        }
      } catch (error) {
        console.warn('error in getting replication model', error)
      }
      ActionExecutor.execute(this, {
        readonly: false,
        pluginName: block.interactive.pluginName,
        action: block.interactive.action,
        event: event
      }, model)
    }
  }
}
</script>

<style lang="scss" scoped>
.interface_editor {
  height: 100%;

  .main_block {
    height: calc(100% - 41px);
    width: 100%;
    display: flex;

    &.dragging ::v-deep .block.active_parent {
      background: rgba(50, 184, 77, 0.2);
    }

    .component-placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 102;

      &:hover {
        background: rgba(64, 158, 255, 0.2);
      }

      &:hover + .tools_panel {
        display: block;
      }
    }

    .component-placeholder_highlight {
      background: rgba(64, 158, 255, 0.2);
    }

    .tools_panel {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 103;
      display: none;

      &:hover {
        display: block;
      }
      & > div {
        display: inline-block;
        font-size: 12px;
        background: #539FFF;
        color: white;
        padding: 3px;
        border-radius: 3px;
        cursor: pointer;
      }
      .replication {
        margin-left: 3px;
        background: #32B84D;
      }
      .sticky {
        margin-left: 3px;

        &.isset {
          background: #32B84D;
        }
      }
    }
  }

  .designer {
    height: calc(100% - 5px);
    padding: 1px;
    width: 80%;
    display: inline-block;

    &.preview {
      width: 100%;
    }
  }

  .settings_panel {
    width: 20%;
    height: 100%;
    display: inline-block;
  }
}
</style>
<style scoped src="./main.scss" lang="scss"></style>
