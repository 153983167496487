<template>
  <div v-loading="loading">
    <el-dropdown @command="attachCommand" @visible-change="loadCommands" trigger="click" style="cursor:pointer;color: #409EFF;">
      <span class="el-dropdown-link">
        {{ $locale.logic_editor.command.attach }} <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(command, index) in commandList" :command="command" :key="index">
          {{ command.name }} <b>(id: {{ command.id }})</b>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-button type="text" @click="$emit('add-child-command')">{{ $locale.main.button.add }}</el-button>
    <table class="commands-table">
    <draggable v-model="model" group="people" @start="drag=true" @end="drag=false">
      <tr v-for="(command, index) in model" :key="index" @click="$emit('open-command', command.id)">
        <td style="width: 10%">
          <b>{{ index + 1 }}.</b>
        </td>
        <td style="width: 80%">{{ command.name }} <b>(id: {{ command.id }})</b></td>
        <td style="width: 10%">
          <i class="el-icon-delete"
             style="color: #FD4644"
             @click.stop="detachCommand(index)"
          ></i>
        </td>
      </tr>
    </draggable>
    </table>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Commands from '@/components/LogicEditor/Models/Commands'
export default {
  name: 'child-commands',
  components: {
    draggable
  },
  props: {
    logicId: {
      type: Number
    },
    commands: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      model: [],
      drag: false,
      commandList: [],
      commandListIsLoaded: false,
      loading: true
    }
  },
  mounted () {
    this.loadChildren(this.commands)
  },
  methods: {
    detachCommand (commandIndex) {
      this.model.splice(commandIndex, 1)
    },
    attachCommand (command) {
      this.model.push({
        id: command.id,
        name: command.name
      })
    },
    async loadCommands () {
      if (!this.commandListIsLoaded) {
        this.commandList = await Commands.params({ 'logic_id': this.logicId }).$get()
        this.commandListIsLoaded = true
      }
    },
    getModel () {
      return this.model.map((item, index) => {
        return {
          command_id: item.id,
          row_order: index + 1
        }
      })
    },
    async loadChildren (commands) {
      this.loading = true
      this.model = []
      if (commands.length > 0) {
        const commandsData = await Commands.params({ 'in': { 'id': commands.map(item => item.command_id) } }).$get()
        commandsData.forEach((command) => {
          command.rowOrder = commands.find(item => item.command_id === command.id)['row_order']
          this.model.push(command)
        })
      }
      this.model = this.model.sort((a, b) => (a.rowOrder > b.rowOrder) ? 1 : -1)
      this.loading = false
    }
  },
  watch: {
    commands (value) {
      this.loadChildren(value)
    }
  }
}
</script>

<style scoped>
.commands-table {
  width: 100%;
  min-height: 40px;
}
.commands-table tr {
  width: 100%;
}
.commands-table td {
  border-bottom: 1px solid #dcdfe6;
  padding: 15px;
  cursor: pointer;
}
</style>
