import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RoleProfileRightCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/RoleProfileRightCommandRepositoryInterface'
import RoleProfileRightUpdateCommand from '@/services/AccessEditor/application/command/RoleProfileRightUpdateCommand'
import { RoleProfileRightDTO } from '@/services/AccessEditor/domain/model/Right/RoleProfileRight'

export default class RoleProfileRightUpdateHandler implements CommandHandlerInterface
{
    private repo: RoleProfileRightCommandRepositoryInterface;

    constructor(repo: RoleProfileRightCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: RoleProfileRightUpdateCommand): Promise<void>
    {
        let item: RoleProfileRightDTO = {
            role_guid: command.getRoleGuid(),
            user_field_id: command.getUserFieldId(),
            is_readable: command.getIsReadable(),
            is_editable: command.getIsEditable()
        };
        return this.repo.update(item);
    }
}