import { FluxStandardAction } from '@/core/domain/type/types'

export enum MutationTypes {
    ERROR = 'error',
    STATE_LOADING_EVENT = 'timer_loading_event',
    STATE_LOADED_EVENT = 'timer_loaded_event',
    STATE_CREATED_EVENT = 'timer_created_event',
    STATE_UPDATED_EVENT = 'timer_updated_event',
    STATE_DELETED_EVENT = 'timer_deleted_event'
}

export class TimerLoadingEvent implements FluxStandardAction {
  type = MutationTypes.STATE_LOADING_EVENT
}

export class TimerLoadedEvent implements FluxStandardAction {
  type = MutationTypes.STATE_LOADED_EVENT
}

export class TimerCreatedEvent implements FluxStandardAction {
  type = MutationTypes.STATE_CREATED_EVENT
  constructor (public location: string) {}
}

export class TimerUpdatedEvent implements FluxStandardAction {
  type = MutationTypes.STATE_UPDATED_EVENT
  constructor (public location: string) {}
}

export class TimerDeletedEvent implements FluxStandardAction {
  type = MutationTypes.STATE_DELETED_EVENT
}
