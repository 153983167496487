<template>
  <div class="registry">
    <toolbar
      class="toolbar-box"
      @edit-record="editRecord"
      @add-record="initAddRecord"
      @query-search="$emit('query-search', $event)"
      @export="executeExport"
      :loading="loading"
      :cssQuickSearch="cssQuickSearch"
      :show-button-ag-grid="showButtonAgGrid"
      :is-show-toolbar="isShowToolbar"
      :placeholderQuickSearch="placeholderQuickSearch"
      :export-templates="exportTemplates"
    ></toolbar>
    <table-body
      class="table-box"
      ref="tableBody"
      :key="registryId"
      :registry-id="registryId"
      :custom-columns="customColumns"
      :is-custom-columns-sort="isCustomColumnsSort"
      :is-custom-columns-width="isCustomColumnsWidth"
      :external-filters="externalFilters"
      :sideBar="sideBar"
      :closeToolPanel="closeToolPanel"
      :floatingFilter="floatingFilter"
      :row-double-clicked="rowDoubleClicked"
      :groupUseEntireRow="groupUseEntireRow"
      :is-pivot-mode="isPivotMode"
      :check-box-selection="checkBoxSelection"
      :show-count="showCount"
      :disabled-column-header="disabledColumnHeader"
      :hide-header="hideHeader"
      :wrap-header="wrapHeader"
      :state="state"
      :optimize-options="optimizeOptions"
      :readonly="readonly"
      :theme="theme"
      :context-menu="getContextMenu()"
      @edit-record="$emit('edit-record', $event)"
      @grid-ready="$emit('grid-ready', $event)"
    ></table-body>
    <div class="footer-box"></div>
    <slot></slot>
  </div>
</template>

<script>
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import StructureHandler from '@/services/RegistryTable/application/handler/query/StructureHandler'
import StructureRepository from '@/services/RegistryTable/infrastructure/domain/repository/StructureRepository'
import TableBody from '@/services/RegistryTable/infrastructure/components/TableBody/index.vue'
import Vue from 'vue'
import DataHandler from '@/services/RegistryTable/application/handler/query/DataHandler'
import DataRepository from '@/services/RegistryTable/infrastructure/domain/repository/DataRepository'
import Toolbar from '@/services/RegistryTable/infrastructure/components/Toolbar/index.vue'

import Registry from '@/components/Registry/Models/Registry'
import RegistryCard from '@/components/RegistryCard'
// import { eventBus } from '@/eventBus'
export default {
  name: 'registry-table',
  inject: {
    openRegistryCard: { default: () => {} },
    getModel: { default: () => {} },
    $computedShowCard: { default: () => () => {} }
  },
  props: {
    registryId: {
      type: Number
    },
    loading: {
      type: Boolean
    },
    customColumns: {
      type: Array
    },
    isCustomColumnsSort: {
      type: Boolean,
      default: false
    },
    isCustomColumnsWidth: {
      type: Boolean,
      default: false
    },
    externalFilters: {
      type: Array
    },
    isPivotMode: {
      type: Boolean
    },
    sideBar: {
      type: Boolean
    },
    closeToolPanel: {
      type: Boolean
    },
    floatingFilter: {
      type: Boolean
    },
    rowDoubleClicked: {
      type: Boolean
    },
    groupUseEntireRow: {
      type: Boolean
    },
    cssQuickSearch: {
      type: String
    },
    placeholderQuickSearch: {
      type: String
    },
    showButtonAgGrid: {
      type: Object
    },
    isShowToolbar: {
      type: Boolean,
      default: true
    },
    showCount: {
      type: Boolean
    },
    disabledColumnHeader: {
      type: Boolean
    },
    hideHeader: {
      type: Boolean
    },
    wrapHeader: {
      type: Boolean
    },
    state: {
      type: Object
    },
    checkBoxSelection: {
      type: Boolean
    },
    optimizeOptions: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    isFastCard: {
      type: Boolean
    },
    theme: {
      type: String
    },
    hideExport: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TableBody,
    Toolbar,
    RegistryCard
  },
  watch: {
    computedShowCard: {
      handler: function (val) {
        // принудительно обновить таблицу после закрытия карточки (баг слетает внешний вид таблицы)
        if (!val && this.isLoadTable) {
          this.getEventBus().$emit('load')
          this.isLoadTable = false
        }
      }
    }
  },
  beforeDestroy () {
    this.getEventBus().$off('selectedRows', this.selectedRow)
  },
  mounted () {
    // console.warn('%c%s', 'color: skyblue;', 'registryTable mounted')
    // регистрации события сохранения карточки
    // принудительно обновить таблицу после сохранения карточки (баг слетает внешний вид таблицы)
    // eventBus.$on('registry-card-saved', () => {
    //   this.getEventBus().$emit('load')
    // })
    if (this.getEventBus) {
      this.getEventBus().$on('selectedRows', this.selectedRow)
    }

    this.loadExportTemplates()
  },
  computed: {
    computedShowCard () {
      return this.$computedShowCard()
    }
  },
  data () {
    return {
      eventBus: new Vue(),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'StructureQuery': new StructureHandler(
            new StructureRepository()
          ),
          'DataQuery': new DataHandler(
            new DataRepository()
          )
        })
      ),
      selectedRows: [],
      isLoadTable: false,
      exportTemplates: []
    }
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    selectedRow (selectedRows) {
      this.selectedRows = selectedRows
    },
    editRecord (event) {
      this.isLoadTable = false
      this.$emit('edit-record', event)
    },
    async getCardData (recordid) {
      let dataCard = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/card/${recordid}`)
      return dataCard.data
    },
    loadExportTemplates () {
      if (this.registryId) {
        this.$http
          .get(`${this.$config.api}/etleditor/objects/tasks?extractor_object_id=${this.registryId}&is_visible_in_registry=true`)
          .then((response) => {
            this.exportTemplates = response.data
          })
      }
    },
    getEventBus () {
      // console.log('%c%s', 'color: skyblue;', 'registryTable getEventBus')
      return this.eventBus
    },
    getQueryBus () {
      // console.log('%c%s', 'color: skyblue;', 'registryTable getQueryBus')
      return this.queryBus
    },
    async getCard (recordData = null) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${this.registryId}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    },
    async initAddRecord () {
      this.isLoadTable = true
      let quickAddCard = await this.isQuickAddCard()
      if (quickAddCard) {
        const h = this.$createElement
        let customClass = 'custom_scrollbar '
        if (quickAddCard.value.width) {
          customClass += `window_width_${quickAddCard.value.width}`
        }
        let me = this
        let initialData = {}
        // if (this.outerXref && this.outerXref.id && this.outerXref.value) {
        //   initialData[`attr_${this.outerXref.id}_`] = this.outerXref.value
        // }

        this.$msgbox({
          customClass: customClass,
          message: h('registry-card', {
            props: {
              cardId: quickAddCard.value.card_id,
              registryId: this.registryId,
              parentContext: null,
              model: {},
              quick: true,
              initialData: initialData
            },
            on: {
              'quick-add': async function (data) {
                let card = await me.getCard(data)
                if (card) {
                  me.openRegistryCard({
                    registryId: me.registryId,
                    cardId: card.id,
                    cardName: card.name,
                    recordId: null,
                    initialData: data,
                    registry: me
                  })
                }
                me.$msgbox.close()
              },
              cancelChanges: function () {
                me.$msgbox.close()
              }
            },
            key: this.generateGuid() }),
          showCancelButton: false,
          showConfirmButton: false,
          closeOnClickModal: false
        })
      } else {
        this.$emit('add-record')
      }
    },
    async isQuickAddCard () {
      // Проверка на карточку быстрого добавления
      let registryData = new Registry({ id: this.registryId })
      let me = this
      let structure = await registryData.structure().first()
        .catch(() => { me.error = true })
      if (!structure) {
        return false
      }
      let quickAddCard = (structure.properties || []).find((item) => item.id === 'quick_add_card') || {}
      if (quickAddCard.value && quickAddCard.value.card_id) {
        return quickAddCard
      } else {
        return false
      }
    },
    async updateRecord (recordId) {
      console.log(recordId, 'updateRecord')
      let dataCard = await this.getCardData(recordId)
      this.getEventBus().$emit('edit', dataCard)
    },
    addRecord (recordId) {
      this.getEventBus().$emit('add')
      console.log(recordId, 'addRecord')
    },
    formatFields (columns) {
      return columns.map(({ field, columnType = null, headerName, children = [] }) => {
        let result = {
          name: headerName
        }

        if (children.length > 0) {
          result.children = this.formatFields(children)
        } else {
          result.field = field
          result.type = columnType.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
        }

        return result
      })
    },
    getContextMenu () {
      const subMenu = [
        'csvExport',
        'excelExport'
      ]

      if (!this.hideExport) {
        subMenu.push('separator')
        subMenu.push({
          name: 'Экспорт всей таблицы',
          action: () => {
            this.executeExport()
          }
        })

        if (this.exportTemplates.length > 0) {
          subMenu.push('separator')
          for (const item of this.exportTemplates) {
            subMenu.push({
              name: item.task_name,
              action: () => {
                this.executeExport('task', item)
              }
            })
          }
        }
      }

      return [
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        'chartRange',
        {
          name: 'Экспорт',
          icon: '<span class="el-icon-download"></span>',
          subMenu
        }
      ]
    },
    executeExport (type = 'default', item = undefined) {
      const title = this.$t('registry.message_export.title')
      const message = type === 'default'
        ? this.$t('registry.message_export.message')
        : `${this.$t('registry.message_export.task_message')} "${item.task_name}"?`

      this.$confirm(message, title, {
        confirmButtonText: this.$t('main.button.ok'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        const body = { ids: null, xrefs: null, fields: null, payload: null }

        // ids
        if (this.selectedRows.length > 0) {
          body.ids = this.selectedRows.map(({ id }) => {
            return id
          })
        }

        // fields
        const columns = this.$refs.tableBody.getColumns()
        if (columns.length > 0) {
          body.fields = this.formatFields(columns)
        }

        // payload (filters)
        const { where = null } = this.$refs.tableBody.getDataSourceService().getLastPayload()
        if (where) {
          body.payload = {
            where
          }
        }

        this.$http({
          method: 'post',
          url: type === 'default'
            ? `${this.$config.api}/etleditor/export/${this.registryId}/${this.$store.getters['Authorization/roleId']}`
            : `${this.$config.api}/etleditor/export/${item.task_id}`,
          responseType: 'arraybuffer',
          data: body
        })
          .then(response => {
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            let url = window.URL.createObjectURL(blob)
            window.open(url)
          })
      })
    }
  }
}
</script>

<style scoped src="./RegistryTable.css">

</style>
