import Vue from 'vue'

import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'

import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'

import GroupDTO from '@/services/NotificationEditor/domain/model/GroupDTO'
import GroupByIdQuery from '@/services/NotificationEditor/application/query/GroupByIdQuery'
import GroupByIdHandler from '@/services/NotificationEditor/application/handler/query/GroupByIdHandler'
import GroupQueryRepository from '@/services/NotificationEditor/infrastructure/domain/repository/GroupQueryRepository'
import GroupCommandRepository from '@/services/NotificationEditor/infrastructure/domain/repository/GroupCommandRepository'
import GroupCreateHandler from '@/services/NotificationEditor/application/handler/command/GroupCreateHandler'
import GroupUpdateHandler from '@/services/NotificationEditor/application/handler/command/GroupUpdateHandler'
import GroupDeleteHandler from '@/services/NotificationEditor/application/handler/command/GroupDeleteHandler'
import GroupCreateCommand from '@/services/NotificationEditor/application/command/GroupCreateCommand'
import GroupUpdateCommand from '@/services/NotificationEditor/application/command/GroupUpdateCommand'
import GroupDeleteCommand from '@/services/NotificationEditor/application/command/GroupDeleteCommand'

import NotificationDTO from '@/services/NotificationEditor/domain/model/NotificationDTO'
import NotificationByIdQuery from '@/services/NotificationEditor/application/query/NotificationByIdQuery'
import NotificationByIdHandler from '@/services/NotificationEditor/application/handler/query/NotificationByIdHandler'
import NotificationQueryRepository from '@/services/NotificationEditor/infrastructure/domain/repository/NotificationQueryRepository'
import NotificationCommandRepository from '@/services/NotificationEditor/infrastructure/domain/repository/NotificationCommandRepository'
import NotificationCreateCommand from '@/services/NotificationEditor/application/command/NotificationCreateCommand'
import NotificationUpdateCommand from '@/services/NotificationEditor/application/command/NotificationUpdateCommand'
import NotificationDeleteCommand from '@/services/NotificationEditor/application/command/NotificationDeleteCommand'
import NotificationCreateHandler from '@/services/NotificationEditor/application/handler/command/NotificationCreateHandler'
import NotificationUpdateHandler from '@/services/NotificationEditor/application/handler/command/NotificationUpdateHandler'
import NotificationDeleteHandler from '@/services/NotificationEditor/application/handler/command/NotificationDeleteHandler'

import SourcesQuery from '@/services/NotificationEditor/application/query/SourcesQuery'
import SourcesHandler from '@/services/NotificationEditor/application/handler/query/SourcesHandler'
import SourceByGuidQuery from '@/services/NotificationEditor/application/query/SourceByGuidQuery'
import SourceByIdAndTypeQuery from '@/services/NotificationEditor/application/query/SourceByIdAndTypeQuery'
import SourceByGuidHandler from '@/services/NotificationEditor/application/handler/query/SourceByGuidHandler'
import SourceByIdAndTypeHandler from '@/services/NotificationEditor/application/handler/query/SourceByIdAndTypeHandler'
import SourceQueryRepository from '@/services/NotificationEditor/infrastructure/domain/repository/SourceQueryRepository'

export default Vue.extend({
  data () {
    return {
      defaultModel: {
        group: GroupDTO,
        notification: NotificationDTO
      },

      form: {
        group: 'GroupForm',
        notification: 'NotificationForm'
      },

      queryByGuid: {
        group: GroupByIdQuery,
        notification: NotificationByIdQuery
      },

      deleteCommands: {
        group: GroupDeleteCommand,
        notification: NotificationDeleteCommand
      },

      eventBus: new Vue(),

      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'GroupCreateCommand': new GroupCreateHandler(
            new GroupCommandRepository()
          ),
          'GroupUpdateCommand': new GroupUpdateHandler(
            new GroupCommandRepository()
          ),
          'GroupDeleteCommand': new GroupDeleteHandler(
            new GroupCommandRepository()
          ),

          'NotificationCreateCommand': new NotificationCreateHandler(
            new NotificationCommandRepository()
          ),
          'NotificationUpdateCommand': new NotificationUpdateHandler(
            new NotificationCommandRepository()
          ),
          'NotificationDeleteCommand': new NotificationDeleteHandler(
            new NotificationCommandRepository()
          )
        })
      ),

      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'GroupByIdQuery': new GroupByIdHandler(
            new GroupQueryRepository()
          ),
          'NotificationByIdQuery': new NotificationByIdHandler(
            new NotificationQueryRepository()
          ),
          'SourcesQuery': new SourcesHandler(
            new SourceQueryRepository()
          ),
          'SourceByGuidQuery': new SourceByGuidHandler(
            new SourceQueryRepository()
          ),
          'SourceByIdAndTypeQuery': new SourceByIdAndTypeHandler(
            new SourceQueryRepository()
          )
        })
      )
    }
  },

  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },

  methods: {
    getEventBus (): Vue {
      return this.eventBus
    },

    getCommandBus (): CommandBus {
      return this.commandBus
    },

    getQueryBus (): QueryBus {
      return this.queryBus
    }
  }
})
