































































import mixins from 'vue-typed-mixins'
import ApprovalStagesByApprovalIdQuery from '@/services/LogicEditor/application/query/ApprovalStagesByApprovalIdQuery'
import ApprovalStageTypesQuery from '@/services/LogicEditor/application/query/ApprovalStageTypesQuery'
import ApprovalStageForm from './ApprovalStageForm.vue'
import Approval, { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/Approval'
import ApprovalCreateCommand from '@/services/LogicEditor/application/command/ApprovalCreateCommand'
import ApprovalUpdateCommand from '@/services/LogicEditor/application/command/ApprovalUpdateCommand'
import ApprovalByGuidQuery from '@/services/LogicEditor/application/query/ApprovalByGuidQuery'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'

export default mixins(FormMixin).extend({
  name: 'ApprovalForm',

  components: {
    // LogicTreeElements,
    ApprovalStageForm
  },

  props: {
    fromBpmn: {
      type: Boolean,
      default: false
    }
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['Approval/isApprovalLoading']
    },

    approvalCreated () {
      return this.$store.getters['Approval/getApprovalLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    approvalCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new ApprovalByGuidQuery(
            location.replace('/approvals/', '')
          )
        ).then(response => {
          this.model = response
          this.$emit('created', response)
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      accordionActive: null,

      buttons: [],
      approvalStages: [],
      approvalStageTypes: [],
      users: [],
      userObjectId: null,

      allowLoadingStages: false
    }
  },

  async mounted () {
    await this.loadingStages()

    if (!this.approvalStageTypes.length) {
      this.approvalStageTypes = await this.getQueryBus().execute(new ApprovalStageTypesQuery())
    }

    await this.$http
      .get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true`)
      .then((response) => {
        this.userObjectId = response.data.data[0].id
      })
      .catch(error => console.log(error))

    // TODO: Переписать на QueryBus
    if (!this.buttons.length && !this.fromBpmn) {
      await this.$http
        .get(`${this.$config.api}/objecteditor/entities?properties[is_approval_button]=true&object_id=${this.objectId}`)
        .then((response) => {
          response.data.data.forEach(item => {
            this.buttons.push({
              id: item.id,
              name: item.name
            })
          })
        })
        .catch((error) => console.error(error))
    }

    // TODO: Переписать на QueryBus
    if (!this.users.length) {
      // await this.$http
      //   .get(`${this.$config.api}/accesseditor/users?fields=id,name,surname,midname`)
      //   .then((response) => {
      //     this.users.push(...response.data)
      //   })
      //   .catch((error) => console.error(error))
      try {
        this.users = await APIClient.shared.request(new UserAPI.GetUsers([]))
      } catch (error) {
        console.log({ error })
      }
    }

    this.allowLoadingStages = true
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid && this.approvalStageValid()) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              if (typeof callback !== 'undefined') {
                callback()
              }
              this.loadingStages()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let approval = Approval.create(this.value)

      if (this.isNew) {
        return new ApprovalCreateCommand(
          approval.getName(),
          approval.getDescription(),
          approval.getApprovalStates(),
          approval.getApprovalStages(),
          approval.getLogicId(),
          approval.getAlias(),
          approval.getParentTreeElementId(),
          approval.getIsEnabled()
        )
      }

      return new ApprovalUpdateCommand(
        approval.getGuid(),
        approval.getName(),
        approval.getDescription(),
        approval.getApprovalStates(),
        approval.getApprovalStages(),
        approval.getAlias(),
        approval.getIsEnabled()
      )
    },

    async loadingStages () {
      if (this.isNew !== true) {
        this.approvalStages = await this.getQueryBus().execute(
          new ApprovalStagesByApprovalIdQuery(this.model.id, { fields: 'id,name' })
        )
      }
    },

    approvalStageValid () {
      if (this.$refs.approvalStageForm) {
        let cntx = 0

        this.$refs.approvalStageForm.forEach((form) => {
          if (form.validate()) {
            cntx++
          }
        })

        return cntx === this.$refs.approvalStageForm.length
      }

      return true
    },

    addApprovalStage () {
      this.model.approval_stages.push(
        ApprovalStageDTO.create(this.$t('logic_editor_v2.default_values.new_approval_stage'))
      )
    },

    removeApprovalStage (index) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.model.approval_stages.splice(index, 1)
      })
    }
  }
})
