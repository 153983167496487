






























































import mixins from 'vue-typed-mixins'
import TimerCommandForm from './TimerCommandForm.vue'
import QueryBuilder from '@/components/Common/QueryBuilder/index.vue'
import Timer, { TimerCommandDTO } from '@/services/LogicEditor/domain/model/Timer'
import TimerCreateCommand from '@/services/LogicEditor/application/command/TimerCreateCommand'
import TimerUpdateCommand from '@/services/LogicEditor/application/command/TimerUpdateCommand'
import TimerByGuidQuery from '@/services/LogicEditor/application/query/TimerByGuidQuery'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'
import draggable from 'vuedraggable'

export default mixins(FormMixin).extend({
  name: 'TimerForm',

  components: {
    QueryBuilder,
    TimerCommandForm,
    draggable
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    filterCommands () {
      return this.commands.filter((c) => {
        return !this.model.commands.some((mc) => mc.command_id === c.id)
      })
    },

    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['Timer/isTimerLoading']
    },

    timerCreated () {
      return this.$store.getters['Timer/getTimerLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ],
        period: [
          { required: true, message: this.$t('logic_editor_v2.rules.period'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    timerCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new TimerByGuidQuery(
            location.replace('/timers/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      commandSelected: null,

      commands: [],

      periodTypes: ['5_minutes', '30_minutes', '1_hour', '12_hours', 'day']
    }
  },

  async mounted () {
    await this.$http.get(`${this.$config.api}/v2/logiceditor/commands?logic_id=${this.logicId}`)
      .then((response) => {
        this.commands = response?.data || []
      })
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let timer = Timer.create(this.value)

      if (this.isNew) {
        return new TimerCreateCommand(
          timer.getName(),
          timer.getAlias(),
          timer.getDescription(),
          timer.getPeriod(),
          timer.getCommands(),
          timer.getLogicId(),
          timer.getParentTreeElementId()
        )
      }

      return new TimerUpdateCommand(
        timer.getGuid(),
        timer.getName(),
        timer.getAlias(),
        timer.getDescription(),
        timer.getPeriod(),
        timer.getCommands()
      )
    },

    attachCommand () {
      if (!this.commandSelected) {
        return
      }

      const command = this.commands.find(c => c.id === this.commandSelected)
      if (!command) {
        return
      }

      this.model.commands.push({
        command_id: command.id,
        row_order: (this.model.commands.length + 1) * 1000,
        command: {
          id: command.id,
          name: command.name
        }
      })

      this.commandSelected = null
    },

    onDraggable () {
      this.model.commands.forEach((command, index) => {
        command.row_order = (index + 1) * 1000
      })
    },

    // Добавить условие
    addTimerCommand () {
      this.model.commands.push(
        TimerCommandDTO.create(
          (this.model.length + 1) * 1000
        )
      )
    },

    // Удалить условие
    removeTimerCommand (index) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.model.commands.splice(index, 1)
      })
    }
  }
})
