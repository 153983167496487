<template>
  <div class="role-panel">
    <div v-show="visiblePanel.list">
      <el-container class="tools">
        <div class="controls">
          <el-button icon="icon-plus-blue" @click="createRole"></el-button>
          <el-button icon="icon-edit-blue" @click="updateRole"></el-button>
          <el-button icon="icon-delete-blue" @click="deleteRole"></el-button>
        </div>
        <div class="filters">
          <div class="text-filter">
            <el-input v-model="rolesFilter" clearable @change="applyFilter"></el-input>
          </div>
        </div>
      </el-container>
      <el-row class="main-table role-table">
        <el-col class="wrapper">
          <el-table
              :indent="0"
              class="registry custom_scrollbar"
              v-loading="loading"
              :data="roles"
              stripe
              border
              ref="roles_table"
              row-key="guid"
              current-row-key="guid"
              highlight-current-row
              @current-change="changeRole"
          >
            <el-table-column
                prop="id"
                :label="$locale.main.fields.id"
                width="100"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="name"
                :label="$locale.access_editor.roles_table.name_role"
                align="left"
            ></el-table-column>
            <el-table-column
                prop="is_readable_default"
                :label="$locale.access_editor.roles_table.read"
                width="150"
                align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.is_readable_default ? 'checkbox-filled' : 'checkbox-empty'"></span>
              </template>
            </el-table-column>
            <el-table-column
                prop="is_addable_default"
                :label="$locale.access_editor.roles_table.add"
                width="150"
                align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.is_addable_default ? 'checkbox-filled' : 'checkbox-empty'"></span>
              </template>
            </el-table-column>
            <el-table-column
                prop="is_editable_default"
                :label="$locale.access_editor.roles_table.edit"
                width="150"
                align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.is_editable_default ? 'checkbox-filled' : 'checkbox-empty'"></span>
              </template>
            </el-table-column>
            <el-table-column
                prop="is_deletable_default"
                :label="$locale.access_editor.roles_table.delete"
                width="150"
                align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.is_deletable_default ? 'checkbox-filled' : 'checkbox-empty'"></span>
              </template>
            </el-table-column>
          </el-table>
          <el-footer>
            <el-pagination
                class="role-pagination"
                :page-size="rolesPageSize"
                :layout="'total, prev, pager, next'"
                :total="rolesCount"
                @current-change="handleRolesPageChange"
            ></el-pagination>
          </el-footer>
        </el-col>
      </el-row>
    </div>
    <div v-show="visiblePanel.edit">
      <RoleMainEditPanel
        :menus="menus"
        :dashboards="dashboards"
      ></RoleMainEditPanel>
      <RoleExtendEditPanel v-if="extendedView" />
    </div>
  </div>
</template>

<script>
import Role, { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'
import RolesQuery from '@/services/AccessEditor/application/query/RolesQuery'
import RolesCountQuery from '@/services/AccessEditor/application/query/RolesCountQuery'
import MenuQuery from '@/services/AccessEditor/application/query/MenuQuery'
import DashboardsQuery from '@/services/AccessEditor/application/query/DashboardsQuery'
import RoleDeleteCommand from '@/services/AccessEditor/application/command/RoleDeleteCommand'
import RoleMainEditPanel from '@/services/AccessEditor/infrastructure/components/RolePanel/RoleMainEditPanel/index.vue'
import RoleExtendEditPanel from '@/services/AccessEditor/infrastructure/components/RolePanel/RoleExtendEditPanel/index.vue'


export default {
  name: 'RolePanel',
  components: {
    RoleMainEditPanel,
    RoleExtendEditPanel
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data() {
    return {
      visiblePanel: {
        "list": true,
        "edit": false
      },
      loading: false,
      roles: [],
      role: null,
      roleDto: null,
      rolesPageSize: 0,
      rolesCurrentPage: 0,
      rolesPageLimit: 100,
      rolesCount: 0,
      allRolesCount: 0,
      menus: [],
      roleMenu: [],
      dashboards: [],
      roleDashboard: [],
      rolesFilter: ''
    }
  },
  computed: {
    roleCreated() {
      return this.$store.getters['Role/getLocation'];
    },
    getSaveRoleError() {
      return this.$store.getters['Role/getError'];
    },
    extendedView() {
      if (this.roleDto != null) {
        if (this.roleDto.guid != null) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    roleCreated: function (location) {
      this.rolesCurrentPage = 0;
      this.loadPageRoles().then(async () => {
        if (this.roles.length > 0) {
          this.$refs.roles_table.setCurrentRow(this.roles[0]);
        }
      });
    },
    getSaveRoleError: function (err) {

    }
  },
  methods: {
    async loadMenu() {
      await this.getQueryBus().execute(
        new MenuQuery()
      ).then(data => {
        this.menus = data;
      });
    },
    async loadDashboards() {
      await this.getQueryBus().execute(
        new DashboardsQuery({})
      ).then(data => {
        this.dashboards = data;
      });
    },
    loadRoles(callback) {
      if (this.allRolesCount == 0) {
        this.countAndLoadRoles(callback);
      }
      else {
        this.loadPageRoles(callback);
      }
    },
    async countAndLoadRoles(callback) {
      await this.getQueryBus().execute(
        new RolesCountQuery({
          name: this.rolesFilter,
          spec: 'contains_string'
        })
      ).then(data => {
        this.rolesCount = data[0].count;
        this.loadPageRoles(callback);
      });
    },
    async loadPageRoles(callback) {
      await this.getQueryBus().execute(
        new RolesQuery({
          limit: this.rolesPageLimit,
          offset: this.rolesCurrentPage,
          name: this.rolesFilter,
          spec: 'contains_string'
        })
      ).then(data => {
        this.rolesPageSize = this.rolesPageLimit;
        this.roles = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    handleRolesPageChange(val) {
      val--;
      this.rolesCurrentPage = (val * this.rolesPageLimit);
      this.loadRoles();
    },
    changeRole(selectedRole) {
      this.roleDto = selectedRole;
      this.role = Role.create(selectedRole);
    },
    createRole() {
      this.roleDto = null;
      this.getEventBus().$emit('editRoleEvent', null);
      this.visiblePanel.list = false;
      this.visiblePanel.edit = true;
    },
    async updateRole() {
      if (this.role !== null) {
        this.getEventBus().$emit('editRoleEvent', this.roleDto);
        this.visiblePanel.list = false;
        this.visiblePanel.edit = true;
      }
      else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    deleteRole() {
      if (this.role == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
      else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new RoleDeleteCommand(
              this.role.getGuid()
            )
          ).then(async () => {
            this.role = null;
            this.roleDto = new RoleDTO({});
            this.loadRoles();
            this.getEventBus().$emit('rolesListIsChanged', true);
          });
        }).catch((error) => { console.log(error); })
      }
    },
    applyFilter() {
      this.loadRoles();
    }
  },
  mounted() {
    this.loadMenu();
    this.loadDashboards();
    this.loadRoles();
    this.getEventBus().$on('returnToRoleListEvent', (data) => {
      this.visiblePanel = data
    });
    this.getEventBus().$on('roleIsUpdated', (data) => {
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].guid == data.guid) {
          this.roles[i] = data;
          break;
        }
      }
    });
  }
}
</script>
