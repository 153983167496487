<template>
  <div>
    <el-input
      class="sql"
      type="textarea"
      :autosize="{ minRows: 6 }"
      v-model="sql"
      :disabled="isEdit">
    </el-input>
    <div class="help">
      <el-tooltip class="item" effect="dark">
        <div slot="content">
          Плейсхолдеры:
          <ul style="padding-left: 20px">
            <li>{user_id} - текущий пользователь</li>
            <li>{parameter} - другие переданные параметры</li>
          </ul>
        </div>
        <i class="el-icon-question"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sql',
  props: {
    state: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  data () {
    return {
      sql: null
    }
  },
  methods: {
    getValue () {
      return this.sql
    }
  },
  mounted () {
    if (this.state) {
      this.sql =
        this.state.properties && this.state.properties.sql
          ? this.state.properties.sql
          : null
    }
  }
}
</script>

<style scoped>
.help {
  width: 20px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.sql {
  width: calc(100% - 20px);
  display: inline-block;
}
</style>
