

































import Vue from 'vue'
import TreeElementLayout from '@/services/LogicEditor/infrastructure/components/TreeElementLayout.vue'
import EditorElementLayout from '@/services/LogicEditor/infrastructure/components/EditorElementLayout.vue'
import MainMixin from '@/services/LogicEditor/infrastructure/mixins/MainMixin'

export default Vue.extend({
  name: 'LogicEditor',

  mixins: [MainMixin],

  components: {
    TreeElementLayout,
    EditorElementLayout
  },

  data () {
    return {
      editor: {
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      }
    }
  },

  provide () {
    return {
      getTreeElementLayout: this.getTreeElementLayout,
      getEditorNode: this.getEditorNode
    }
  },

  methods: {
    closeEditor () {
      this.editor = {
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      }
    },

    createElement (elementType, parentNode) {
      this.editor.form = this.form[elementType]
      this.editor.title = this.$t('logic_editor_v2.element_types.' + elementType)
      this.editor.node = parentNode
      this.editor.elementType = elementType
      this.editor.model = this.defaultModel[elementType].create()

      if (['view_constraint', 'disable_constraint'].indexOf(elementType) !== -1) {
        this.editor.model.constraint_type_id = elementType
      }

      if (elementType !== 'logic') {
        if (parentNode.data.element_type) {
          this.editor.model.logic_id = parentNode.data.logic_id
          this.editor.model.parent_tree_element_id = parentNode.data.id
        } else {
          this.editor.model.logic_id = parentNode.data.id
        }
      }
    },

    async updateElement (node, data) {
      let elementType = data.element_type ? data.element_type : 'logic'
      let elementGuid = elementType === 'logic' ? data.guid : data.element_guid
      this.editor.model = await this.queryBus.execute(new this.queryByGuid[elementType](elementGuid))
      this.editor.form = this.form[elementType]
      this.editor.title = `${data.name} (id: ${data.element_id || data.id})`
      this.editor.node = node
      this.editor.elementType = elementType
    },

    async deleteElement (node, data) {
      let elementType = data.element_type ? data.element_type : 'logic'
      let elementGuid = elementType === 'logic' ? data.guid : data.element_guid

      await this.getCommandBus().execute(
        new this.deleteCommands[elementType](elementGuid)
      )
    },

    getEditorNode () {
      return this.editor.node
    },

    getTreeElementLayout () {
      return this.$refs.treeElement
    }
  }
})
