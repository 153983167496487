import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { LogicDTO } from '@/services/LogicEditor/domain/model/Logic'

export namespace LogicAPI {
  export class GetLogic implements APIRequest<LogicDTO> {
    response: LogicDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = `/v2/logiceditor/logic?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetLogicByGuid implements APIRequest<LogicDTO> {
    response: LogicDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/logic/${guid}`
    }
  }

  export class CreateLogic implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/logic';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location
    constructor (public params: LogicDTO) {}
  }

  export class UpdateLogic implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: LogicDTO) {
      this.path = `/v2/logiceditor/logic/${params.guid}`
    }
  }

  export class DeleteLogic implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/logic/${guid}`
    }
  }
}
