export default class RoleProfileRightUpdateCommand
{
    private roleGuid: string;
    private userFieldId: string;
    private isReadable: boolean;
    private isEditable: boolean;

    constructor(
        roleGuid: string,
        userFieldId: string,
        isReadable: boolean,
        isEditable: boolean
    ) {
        this.roleGuid = roleGuid;
        this.userFieldId = userFieldId;
        this.isReadable = isReadable;
        this.isEditable = isEditable;
    }

    getClassName(): string
    {
        return 'RoleProfileRightUpdateCommand';
    }

    getRoleGuid(): string
    {
        return this.roleGuid;
    }

    getUserFieldId(): string
    {
        return this.userFieldId;
    }

    getIsReadable(): boolean
    {
        return this.isReadable;
    }

    getIsEditable(): boolean
    {
        return this.isEditable;
    }
}