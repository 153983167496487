<template>
  <el-select
    clearable
    :value="value"
    @input="$emit('input', $event)"
    size="mini"
  >
    <el-option
      v-for="item in topics"
      :key="item.code"
      :label="item.name"
      :value="item.code"
    >
      <span>{{ item.name }} <b>{{ item.code }}</b></span>
    </el-option>
  </el-select>
</template>

<script>
import Topic from '@/components/LogicEditor/Models/databusservice/Topic'
export default {
  name: 'Topic',
  props: ['value'],
  data () {
    return {
      topics: []
    }
  },
  async mounted () {
    this.topics = await Topic.get()
  }
}
</script>

<style scoped>

</style>
