<template>
  <div class="editor-card-autocomplete">
    <el-form-item>
      <span slot="label" class="property_label">Сервис данных</span>
      <el-select v-model="model.service_type" placeholder="Сервис данных" @change="serviceChange">
        <el-option
          v-for="item in serviceTypes"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>

    <template v-if="model.service_type === 'da_data'">
      <el-form-item @change="dataChange">
        <span slot="label" class="property_label" style="display: inline-block">Тип данных</span>
        <el-select v-model="model.data_type" placeholder="Тип данных">
          <el-option
            v-for="item in daDataTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <template v-slot:label>
          <span class="property_label">Поля</span>

          <el-button type="success" icon="el-icon-plus" @click="addField()" circle></el-button>
        </template>

        <el-row v-for="(field, index) in model.fields" :key="index" :gutter="5">
          <el-col :span="10">
            <el-select v-model="model.fields[index].property" placeholder="Свойство">
              <el-option
                v-for="(item, propIndex) in properties[model.data_type]"
                :key="propIndex"
                :label="labels.properties[item.property]"
                :value="item.property">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="11">
            <el-select v-model="model.fields[index].attribute" placeholder="Атрибут">
              <el-option
                v-for="(item, cmpIndex) in components.filter((cmp) => cmp.properties.name)"
                :key="cmpIndex"
                :label="`${item.name} (${item.properties.name})`"
                :value="item.properties.name">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="danger" icon="el-icon-delete" circle @click="deleteField(index)"></el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </template>

    <template v-else>
      <el-form-item>
        <span slot="label" class="property_label">Реестр</span>
        <registry-select-tree v-model="model.object_id" type="registry" value-as="number" @change="objectChange"></registry-select-tree>
      </el-form-item>

      <div v-if="model.object_id" :key="`object_${model.object_id}`">
        <el-form-item>
          <span slot="label" class="property_label">Надпись элемента выпадающего списка</span>
          <registry-select-tree
            v-model="model.label_field_id"
            type="field"
            value-as="number"
            :parent-id="model.object_id">
          </registry-select-tree>
        </el-form-item>

        <el-form-item>
          <span slot="label" class="property_label">Поисковые поля</span>
          <ObjectTree v-model="model.search_fields" multiple object-type="field" :parent-id="model.object_id"></ObjectTree>
          <!--<registry-select-tree
            v-model="model.search_fields"
            type="field"
            value-as="number"
            :multiple="true"
            :parent-id="model.object_id">
          </registry-select-tree>-->
        </el-form-item>

        <el-form-item>
          <template v-slot:label>
            <span class="property_label">Поля</span>

            <el-button type="success" icon="el-icon-plus" @click="addField()" circle></el-button>
          </template>

          <div class="field-item" v-for="(field, index) in model.fields" :key="index">
            <el-row :gutter="5">
              <el-col :span="12">
                <registry-select-tree
                  v-model="model.fields[index].property"
                  type="field"
                  value-as="number"
                  :parent-id="model.object_id">
                </registry-select-tree>
              </el-col>

              <el-col :span="12">
                <el-select v-model="model.fields[index].attribute" placeholder="Атрибут">
                  <el-option
                    v-for="(item, cmpIndex) in components.filter((cmp) => cmp.properties.name)"
                    :key="cmpIndex"
                    :label="`${item.name} (${item.properties.name})`"
                    :value="item.properties.name">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">
                <el-checkbox v-model="model.fields[index].isXref" >Ссылка</el-checkbox>
              </el-col>

              <el-col :span="12">
                <div style="display: flex;justify-content: flex-end;width: 100%;">
                  <el-button type="danger" icon="el-icon-delete" circle @click="deleteField(index)"></el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
      </div>
    </template>
  </div>
</template>

<script>
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree'
import ObjectTree from '@/components/Common/ObjectTree'

const defaultModel = {
  service_type: 'da_data',
  data_type: 'party',
  object_id: null,
  label_field_id: null,
  search_fields: [],
  fields: []
}

export default {
  name: 'editor-card-autocomplete',
  components: {
    RegistrySelectTree,
    ObjectTree
  },
  inject: ['getComponents'],
  props: {
    value: Object
  },
  data () {
    return {
      model: Object.assign(defaultModel, this.value),

      serviceTypes: [
        { id: 'da_data', name: 'DaData.ru' },
        { id: 'accent', name: 'Акцент 2.0' }
      ],

      daDataTypes: [
        { id: 'party', name: 'Организации' },
        { id: 'bank', name: 'Банки' }
      ],

      properties: {
        party: [
          { property: 'name.full_with_opf', attribute: null },
          { property: 'name.short_with_opf', attribute: null },
          { property: 'ogrn', attribute: null },
          { property: 'inn', attribute: null },
          { property: 'kpp', attribute: null },
          { property: 'address.unrestricted_value', attribute: null },
          { property: 'address.value', attribute: null },
          { property: 'management.name', attribute: null },
          { property: 'management.post', attribute: null },
          { property: 'phones', attribute: null },
          { property: 'emails', attribute: null },
          { property: 'state.status', attribute: null },
          { property: 'state.actuality_date', attribute: null },
          { property: 'state.registration_date', attribute: null },
          { property: 'state.liquidation_date', attribute: null }
        ],
        bank: [
          { property: 'name.payment', attribute: null },
          { property: 'bic', attribute: null },
          { property: 'inn', attribute: null },
          { property: 'kpp', attribute: null },
          { property: 'correspondent_account', attribute: null },
          { property: 'address.unrestricted_value', attribute: null },
          { property: 'address.value', attribute: null },
          { property: 'state.status', attribute: null },
          { property: 'state.actuality_date', attribute: null },
          { property: 'state.registration_date', attribute: null },
          { property: 'state.liquidation_date', attribute: null }
        ]
      },

      labels: {
        properties: {
          'name.full_with_opf': 'Наименование (Полное)',
          'name.short_with_opf': 'Наименование (Краткое)',
          'name.payment': 'Наименование',
          inn: 'ИНН',
          bic: 'БИК',
          kpp: 'КПП',
          ogrn: 'ОГРН',
          correspondent_account: 'Корсчет',
          'address.unrestricted_value': 'Адресс (Полный)',
          'address.value': 'Адресс (Краткий)',
          'management.name': 'Директор',
          'management.post': 'Должность',
          'phones': 'Тел. номер',
          'emails': 'Эл. почта',
          'state.status': 'Статус',
          'state.actuality_date': 'Дата актуальности сведений',
          'state.registration_date': 'Дата регистрации',
          'state.liquidation_date': 'Дата ликвидации'
        }
      },

      components: this.getComponents()
    }
  },
  watch: {
    value () {
      this.model = Object.assign(defaultModel, this.value)
    },

    model: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  mounted () {
    console.log(defaultModel, this.model, Object.assign(defaultModel, this.value))

    this.model = Object.assign(defaultModel, this.value)
  },
  methods: {
    serviceChange (value) {
      if (value === 'da_data') {
        this.model.data_type = this.daDataTypes[0].id
      }

      this.model.fields = []
    },

    dataChange (value) {
      this.model.fields = []
    },

    objectChange (value) {
      this.model.label_field_id = null
      this.model.search_fields = []
      this.model.fields = []
    },

    addField () {
      this.model.fields.push({
        property: null,
        attribute: null,
        isXref: false
      })
    },

    deleteField (index) {
      this.model.fields.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .editor-card-autocomplete .el-form-item__label {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;

    .property_label {
      display: inline-block;
    }
  }
</style>
