import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'

export namespace ApprovalStageAPI {
  export class GetApprovalStageByGuid implements APIRequest<ApprovalStageDTO> {
    response: ApprovalStageDTO;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    constructor (guid: string) {
      this.path = `/v2/logiceditor/approval_stages/${guid}`
    }
  }

  export class CreateApprovalStage implements APIRequest<string> {
    response: string;
    path = '/v2/logiceditor/approval_stages';
    method = HTTPMethod.POST;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.headers.location;
    constructor (public params: ApprovalStageDTO) {}
  }

  export class UpdateApprovalStage implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data;
    constructor (public params: ApprovalStageDTO) {
      this.path = `/v2/logiceditor/approval_stages/${params.guid}`
    }
  }

  export class DeleteApprovalStage implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.DELETE;
    hideNotification = false;
    parse = (data: AxiosResponse) => data.data
    constructor (guid: string) {
      this.path = `/v2/logiceditor/approval_stages/${guid}`
    }
  }
}
