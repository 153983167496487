<template>
  <component class="property" :is="toCamelCase(type)" @input="onChange" :value="property.value" :logic-id="logicId"></component>
</template>

<script>
import Report from './Types/Report'
import Format from './Types/Format'
import OperationType from './Types/OperationType'
import Topic from './Types/Topic'
import FieldArray from './Types/FieldArray'
import Command from './Types/Command'
export default {
  name: 'Property',
  components: {
    Report,
    Format,
    OperationType,
    Topic,
    FieldArray,
    Command
  },
  props: {
    type: String,
    property: Object,
    logicId: Number
  },
  methods: {
    onChange (value) {
      this.$set(this.property, 'value', value)
    }
  }
}
</script>

<style scoped>
.property {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px;
  margin-top: 0px;
}
</style>
