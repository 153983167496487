import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
import App from '@/App.vue'
import store from './store'
import axios, { AxiosInstance } from 'axios'
import 'moment/locale/ru'
import router from './router/index.js'
import locale from './locale/ru.json'
import VueClipboard from 'vue-clipboard2'
import ElTreeSelect from 'el-tree-select'
import VueAgile from 'vue-agile'
import VueSplit from 'vue-split-panel'
import { Model } from 'vue-api-query'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/base16-light.css'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/twig/twig.js'
import 'codemirror/addon/merge/merge.js'
import 'codemirror/addon/merge/merge.css'
import 'codemirror/addon/edit/closetag.js'
import VueMask from 'v-mask'
import i18n from '@/i18n/i18n'
import LogicTreeElements from '@/core/infrastructure/components/LogicTreeElements.vue'
import EditorLayout from '@/core/infrastructure/components/EditorLayout.vue'
import Card from '@/core/infrastructure/components/Card.vue'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import DialogForm from '@/components/EtlEditor/render/DialogForm.vue'

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

const moment = require('moment')
require('moment/locale/ru')
Vue.use(require('vue-moment'), {
  moment
})

VueClipboard.config.autoSetContainer = true

Vue.use(VueClipboard)
Vue.use(ElTreeSelect)
Vue.use(VueAgile)
Vue.use(VueSplit)
Vue.use(VueCodemirror)
Vue.component('logic-tree-elements', LogicTreeElements)
Vue.component('editor-layout', EditorLayout)
Vue.component('dialog-form', DialogForm)
Vue.component('card', Card)
Vue.use(VueMask, {
  placeholders: {
    Я: /[а-яА-Я]/
  }
})
let initStore = function () {
  if (typeof this.$store === 'undefined') {
    this.$store = store
  }
}
Vue.mixin({
  beforeCreate: initStore,
  methods: {
    generateGuid () {
      let d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now()
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      })
    },
    toCamelCase (s: string): string {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    toSnakeCase (s: string): string {
      return s.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    }
  },
  filters: {
    xref (value) {
      try {
        value = JSON.parse(value)
      } catch (e) {

      }

      if (Array.isArray(value)) {
        value = value.map((item) => {
          return (typeof item.name !== 'undefined' ? item.name : item)
        }).join(',')
      }

      return value
    }
  }
})

const interceptorsAttach: (axiosInstance: AxiosInstance) => void = function (axiosInstance: AxiosInstance):void {
  axiosInstance.interceptors.request.use(function (config) {
    let token = localStorage.getItem('user-token')
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  axiosInstance.interceptors.response.use(function (response) {
    if (response.headers['x-auth-token']) {
      localStorage.setItem('user-token', response.headers['x-auth-token'])
    }
    return response
  }, function (error) {
    if (error.response.headers['x-auth-token']) {
      localStorage.setItem('user-token', error.response.headers['x-auth-token'])
    }
    return Promise.reject(error)
  })
}

interceptorsAttach(axios)

Model.$http = axios
Vue.prototype.$http = axios
Vue.prototype.$daData = axios.create()
Vue.prototype.$daData.interceptors.request.use(function (config) {
  config.headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Token 03fab14d0555d6f7a9ea39754dbdc4771b4c3a8a'
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

if (config.same_api_host === 1) {
  config.api = window.location.origin
  APIClient.shared = new APIClient(config.api)
}
Vue.prototype.$config = config
Vue.prototype.$locale = locale

let VueApp: any = Vue
export default new VueApp({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App),
  mounted () {
    let lang = this.$route.query.lang
    if (!lang) {
      lang = 'ru'
    }
    import(`./locale/${lang}.json`).then((locale) => {
      Vue.prototype.$locale = locale
    })
    document.title = this.$config.title
    if (this.$config.project === 'Rgis') {
      const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
      link.href = `/homepage/${this.$config.project}/favicon.png`
    }
  }
})
