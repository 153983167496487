<template>
  <div>
    <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>

    <el-dialog :title="$locale.interface_editor.component_editor.map_tools.title" :modal="false"
               :visible.sync="dialogSettingsVisible"
               class="window">
      <el-row>
        <el-col :span="12">
          <table width="100%" style="border-spacing: 0px;">
            <draggable v-model="tools" group="tools" style="display:contents;">
              <tr v-for="(tool, index) in tools" :key="index" @click="activeTool = tool">
                <td style="width: 10%">
                  <font-awesome-icon :icon="tool.icon"/>
                </td>
                <td style="width: 80%" :class="{'active-tool': activeTool && activeTool.id === tool.id}">
                  {{ $locale.interface_editor.component_editor.map_tools[tool.id] }}
                </td>
                <td style="width: 10%">
                  <el-tag type="info" size="mini" v-if="tool.hidden">Скрыто</el-tag>
                </td>
              </tr>
            </draggable>
          </table>
        </el-col>
        <el-col :span="12" v-if="activeTool" :key="activeTool.id">
          <div class="title">Настройки</div>
          <el-form size="mini" style="padding: 10px">
            <span style="margin-right: 10px">Скрыть</span>
            <el-checkbox v-model="activeTool.hidden"></el-checkbox>
            <br>
            <component v-if="activeTool.editor" :is="activeTool.editor" v-model="activeTool"></component>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLayerGroup, faSearch, faRulerCombined, faPen, faGlobe, faExpandArrowsAlt, faCompressArrowsAlt, faMapMarkerAlt, faTrashAlt, faVectorSquare, faCamera, faFileImport, faFileExport } from '@fortawesome/free-solid-svg-icons'
import draggable from 'vuedraggable'
import PositionOnEditor from './PositionOnEditor'
import BindingRadius from './BindingRadius'
import SearchLongFieldEditor from './SearchLongFieldEditor'

library.add(faLayerGroup, faSearch, faRulerCombined, faPen, faGlobe, faExpandArrowsAlt, faCompressArrowsAlt, faMapMarkerAlt, faTrashAlt, faVectorSquare, faCamera, faFileImport, faFileExport)

export default {
  components: {
    FontAwesomeIcon,
    draggable,
    PositionOnEditor,
    BindingRadius,
    SearchLongFieldEditor
  },
  name: 'map-tools',
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      dialogSettingsVisible: false,
      activeTool: undefined,
      tools: [
        { id: 'layers', hidden: false, icon: 'layer-group', index: 1 },
        { id: 'search', hidden: false, icon: 'search', index: 2 },
        { id: 'change-selection-type', hidden: false, icon: 'vector-square', index: 3 },
        { id: 'measurement', hidden: false, icon: 'ruler-combined', index: 4 },
        { id: 'position-on', hidden: false, icon: 'map-marker-alt', index: 5, editor: 'PositionOnEditor', properties: {} },
        { id: 'drawing-geometry', hidden: false, icon: 'pen', index: 6, editor: 'BindingRadius', properties: {} },
        { id: 'delete-features', hidden: false, icon: 'trash-alt', index: 7 },
        { id: 'google-street-view', hidden: false, icon: 'globe', index: 8 },
        { id: 'print', hidden: false, icon: 'camera', index: 9 },
        { id: 'import-features', hidden: false, icon: 'file-import', index: 10 },
        { id: 'export-features', hidden: false, icon: 'file-export', index: 11 },
        { id: 'expand', hidden: false, icon: 'expand-arrows-alt', index: 12 },
        { id: 'search-component', hidden: true, icon: 'pen', index: 13, component: 'SearchLongField', editor: 'SearchLongFieldEditor', properties: {}, width: 3 },
      ]
    }
  },
  mounted () {
    this.tools.forEach((item) => {
      if (this.value[item.id]) {
        this.$set(item, 'hidden', this.value[item.id].hidden)
        this.$set(item, 'index', this.value[item.id].index)
        if (this.value[item.id].properties && Object.keys(this.value[item.id].properties).length > 0) {
          this.$set(item, 'properties', this.value[item.id].properties)
        }
        if (this.value[item.id].width) {
          this.$set(item, 'width', this.value[item.id].width)
        }
      }
    })
    this.tools = this.tools.sort((a, b) => {
      return a.index - b.index
    })
  },
  computed: {
    result () {
      let object = {}
      this.tools.forEach((item, index) => {
        object[item.id] = {
          hidden: item.hidden,
          index: index + 1,
          properties: item.properties,
          width: item.width ?? undefined
        }
      })
      return object
    }
  },
  watch: {
    tools: {
      deep: true,
      handler () {
        this.$emit('change', this.result)
      }
    }
  },
  methods: {
    addPropertiesRecursively (object, property) {
      Object.entries(property).forEach((item) => {
        // item[0] - key item[1] - value
        console.log(object.toString(), item[0], item[1])
        if (typeof item[1] === 'object' && item[1] !== null && Object.keys(item[1]).length > 0) {
          object[item[0]] = this.addPropertiesRecursively(object[item[0]], item[1])
        } else {
          object[item[0]] = item[1]
        }
      })
      return object
    }
  }
}
</script>

<style scoped>
.window > /deep/ .el-dialog {
  width: 60%;
}
td {
  border-bottom: 1px solid #dcdfe6;
  padding: 8px;
  cursor: pointer;
}
td:last-child {
  border-right: 1px solid #dcdfe6;
}
.active-tool {
  font-weight: bold;
}
.active-tool ~ td {
  border-right: none;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #0D0F11;
  padding-left: 15px;
  padding-top: 2px;
  padding-bottom: 4px;
}
</style>
