
import { codemirror } from 'vue-codemirror'
import MapManager from '@bingo_soft/mapmanager'
import FeatureCollection from '@bingo_soft/mapmanager/src/Domain/Model/Feature/FeatureCollection'
import ElForm from 'element-ui/packages/form/src/form.vue'
import ElFormItem from 'element-ui/packages/form/src/form-item.vue'
import Treeselect from '@bingosoftnn/vue-treeselect'
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem.vue'
import 'codemirror/lib/codemirror.css'

export default {
  name: 'GeometryTextEditorForm',
  props: ['formId', 'type', 'layer', 'feature'],
  inject: ['getEventBus'],
  data() {
    return {
      dto: {
        native_coordinate_system_id: null,
        text_format: 'GeoJSON',
        text_value: ''
      },
      cmOption: {
        connect: 'align',
        mode: 'text/xml',
        lineNumbers: true,
        lineWrapping: true,
        collapseIdentical: false,
        highlightDifferences: true
      },
      textTypeOptions: [{
        id: 'GeoJSON',
        name: 'GeoJSON'
      },{
        id: 'WKT',
        name: 'WKT'
      }]
    }
  },
  methods: {
    createForm() {
      return this.$createElement(ElForm, {
        props: {
          model: this.dto
        },
        class: {
          "layer-form-inner-body": true,
          "custom_scrollbar": true
        },
        style: {
          "overflow-y": "auto"
        },
        ref: this.formId
      }, this.createFormItems());
    },
    createFormItems() {
      let me = this;
      return [
        this.$createElement(ElFormItem, {
          props: {
            prop: 'text_format',
            label: this.$locale.map_editor.entities.layer,
          }
        },[
          this.$createElement('div', {
          }, [
            this.layer.getProperties()['name']
          ])
        ]),
        this.$createElement(ElFormItem, {
          props: {
            prop: 'text_format',
            label: this.$locale.main.fields.type,
          }
        },[
          this.$createElement(Treeselect, {
            props: {
              placeholder: this.$locale.main.fields.select,
              noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
              loadingText: this.$locale.main.fields.loading_text,
              noResultsText: this.$locale.main.fields.no_results_text,
              normalizer: (node) => {return {id: node.id, label: node.name}},
              searchable: false,
              cacheOptions: true,
              appendToBody: true,
              options: this.textTypeOptions,
              value: this.dto.text_format,
              clearable: false,
              deleteRemoves: false,
              backspaceRemoves: false
            },
            on: {
              input: (id) => {
                this.dto.text_format = id;
              }
            }
          })
        ]),
        this.$createElement(ElFormItem, {
          props: {
            prop: 'text_format',
            label: this.$locale.map_editor.entities.coordinate_system,
          }
        },[
          this.$createElement(CoordinateSystem, {
            props: {
              options: []
            },
            on: {
              "change-cs": (id) => {
                this.dto.native_coordinate_system_id = id;
              }
            }
          })
        ]),
        this.$createElement(ElFormItem, {
          props: {
            prop: 'text_value',
            value: this.dto.text_value
          },
          class: {
            "codemirror-component": true
          }
        },[
          this.$createElement(codemirror, {
            props: {
              options: this.cmOption
            },
            on: {
              input: (value) => {
                this.dto.text_value = value;
              },
              ready: (cm) => {
                if (this.type == "edit") {
                  const srsId = MapManager.getSRSId(this.layer);
                  cm.setValue(MapManager.getGeometryAsText(this.feature, this.dto.text_format, srsId));
                }
              }
            }
          })
        ])
      ]
    },
    submit(callback) {
      if (this.type == "add") {
        const map = this.layer.getMap();
        const feature = MapManager.createFeatureFromText(
          this.dto.text_value,
          this.dto.text_format,
          this.dto.native_coordinate_system_id,
          map.getSRSId()
        );
        if (feature) {
            feature.setLayer(this.layer);
            MapManager.addFeatures(map, this.layer, new FeatureCollection([feature]));
        } else {
            this.$message.error(this.$locale.main.message.error);
        }
      } else if (this.type == "edit") {
        MapManager.updateFeatureFromText(
          this.feature,
          this.dto.text_value,
          this.dto.text_format,
          this.dto.native_coordinate_system_id
        );
      }
      if (typeof callback == "function") {
        callback();
      }
    }
  },
  render(createElement) {
    return createElement("div", {
      class: {
        "layer-form": true,
        "geometry-text-editor": true
      }
    }, [
      this.createForm()
    ]);
  },
  mounted() {
    if (this.type == "edit") {
      const srsId = MapManager.getSRSId(this.layer);
      this.dto.native_coordinate_system_id = srsId;
      this.dto.text_value = MapManager.getGeometryAsText(this.feature, this.dto.text_format, srsId);
    } else {
        this.textTypeOptions.unshift({
          id: 'Text',
          name: 'Text'
        });
        this.dto.text_format = 'Text';
    }
  }
}
