<template>
  <el-select
    v-model="localStateId"
    clearable
  >
   <el-option
     v-for="option in options"
     :key="option.id"
     :label="option.name"
     :value="option.id"
   ></el-option>
  </el-select>
</template>

<script>
  import { APIClient, HTTPMethod } from '@/core/infrastructure/api/APIClient'
  import { LogicEditorAPI } from '@/core/infrastructure/api/modules/LogicEditorAPI'

  export default {
    name: 'StateFilter',
    props: {
      registryId: {
        type: Number
      },
      stateId: {
        type: Number,
        default: null
      }
    },
    watch: {
      localStateId: {
        handler: function (newValue) {
          this.$emit('change', newValue)
        }
      }
    },
    data: function () {
      return {
        localStateId: null,
        options: []
      }
    },
    async mounted () {
      let a = await APIClient.shared.request(new LogicEditorAPI.getStatesByRegistryId(this.registryId))
      this.options = a.data
      this.localStateId = this.stateId
    }
  }
</script>

<style scoped>

</style>
