export interface TimerState {
  isLoading: boolean
  location: string
  deletedTimerGuid: string
  error: object
}

export const initialState = (): TimerState => {
  return {
    isLoading: false,
    location: null,
    deletedTimerGuid: null,
    error: null
  }
}
