
























































































































































































import Vue from 'vue'
import QueryBus from '@/core/application/query/service/QueryBus'
import CommandBus from '@/core/application/command/service/CommandBus'
import ApprovalStageByGuidQuery from '@/services/LogicEditor/application/query/ApprovalStageByGuidQuery'
import ApprovalStageByGuidHandler from '@/services/LogicEditor/application/handler/query/ApprovalStageByGuidHandler'
import ApprovalStage, { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'
import ApprovalStageCreateCommand from '@/services/LogicEditor/application/command/ApprovalStageCreateCommand'
import ApprovalStageUpdateCommand from '@/services/LogicEditor/application/command/ApprovalStageUpdateCommand'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import ApprovalStageQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ApprovalStageQueryRepository'
import ApprovalStageCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/ApprovalStageCommandRepository'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import ApprovalStageCreateHandler from '@/services/LogicEditor/application/handler/command/ApprovalStageCreateHandler'
import ApprovalStageDeleteCommand from '@/services/LogicEditor/application/command/ApprovalStageDeleteCommand'

import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import ApprovalStagesByApprovalIdQuery from '@/services/LogicEditor/application/query/ApprovalStagesByApprovalIdQuery'
import ApprovalStageTypesQuery from '@/services/LogicEditor/application/query/ApprovalStageTypesQuery'
import NotificationDTO from '@/services/NotificationEditor/domain/model/NotificationDTO'
import { Nullable } from '@/core/domain/type/types'
import CommandInterface from '@/core/application/command/CommandInterface'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'

interface IData {
  model: NotificationDTO;
  localLoading: boolean;

  local: {
    targetObjectId: Nullable<number>;
    users: object[];
    userObjectId: Nullable<number>;
    buttons: object[];
    approvalStages: object[];
    approvalStageTypes: object[];
  }
}

export default Vue.extend({
  name: 'ApprovalStageForm',

  components: {
    RegistrySelectTree
  },

  inject: {
    getQueryBus: {
      default: () => {
        return new QueryBus(
          new QueryBusHandlerLocator({
            [ApprovalStageByGuidQuery.name]: new ApprovalStageByGuidHandler(new ApprovalStageQueryRepository())
          })
        )
      }
    },

    getCommandBus: {
      default: () => {
        new CommandBus(
          new CommandBusHandlerLocator({
            [ApprovalStageCreateCommand.name]: new ApprovalStageCreateHandler(new ApprovalStageCommandRepository()),
            [ApprovalStageUpdateCommand.name]: new ApprovalStageCreateHandler(new ApprovalStageCommandRepository()),
            [ApprovalStageDeleteCommand.name]: new ApprovalStageCreateHandler(new ApprovalStageCommandRepository())
          })
        )
      }
    }
  },

  props: {
    value: {
      type: Object,
      required: true,
      default () {
        return ApprovalStageDTO.create()
      }
    },

    interactive: {
      type: Boolean,
      default: false
    },

    approvalId: {
      type: Number,
      default: null
    },

    targetObjectId: {
      type: Number,
      default: null
    },

    logicId: {
      type: Number,
      required: true
    },

    users: {
      type: Array,
      default () {
        return []
      }
    },

    userObjectId: {
      type: Number,
      default: null
    },

    objectId: {
      type: Number,
      default: null
    },

    buttons: {
      type: Array,
      default () {
        return []
      }
    },

    approvalStages: {
      type: Array,
      default () {
        return []
      }
    },

    approvalStageTypes: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    approvalStageCreated (location) {
      if (location !== null) {
        this.getQueryBus()
          .execute(
            new ApprovalStageByGuidQuery(location.replace('/approval_stages/', ''))
          ).then(response => {
            this.model = response
            this.$emit('created', response)
          })
      }
    }
  },

  computed: {
    isNew (): boolean {
      return !this.model.guid
    },

    isLoading (): boolean {
      return this.$store.getters['ApprovalStage/isApprovalStageLoading'] || this.localLoading
    },

    approvalStageCreated (): string {
      return this.$store.getters['ApprovalStage/getApprovalStageLocation']
    },

    rules (): Object {
      const rules: { [formField: string]: any } = {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ],
        member_state_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.state'), trigger: 'change' }
        ],
        approval_stage_members: [
          { required: true, message: this.$t('logic_editor_v2.rules.members'), trigger: 'change' }
        ]
      }

      if (this.model.approval_stage_type_id === 'field') {
        rules.member_field_id = [
          { required: true, message: this.$t('logic_editor_v2.rules.member_field'), trigger: 'change' }
        ]
      }

      return rules
    }
  },

  data (): IData {
    return {
      model: this.value,

      localLoading: false,

      local: {
        targetObjectId: null,
        users: [],
        userObjectId: null,
        buttons: [],
        approvalStages: [],
        approvalStageTypes: []
      }
    }
  },

  async mounted (): Promise<void> {
    if (this.interactive) {
      this.localLoading = true

      if (!this.targetObjectId && this.model.object_id) {
        this.local.targetObjectId = this.model.object_id
      }

      if (!this.userObjectId) {
        await this.$http
          .get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true`)
          .then((response) => {
            this.local.userObjectId = response.data.data[0].id
          })
          .catch(error => console.log(error))
      }

      if (!this.users.length) {
        // await this.$http
        //   .get(`${this.$config.api}/accesseditor/users?fields=id,name,surname,midname`)
        //   .then((response) => {
        //     this.local.users.push(...response.data)
        //   })
        //   .catch((error) => console.error(error))
        try {
          // let response = await APIClient.shared.request(new UserAPI.GetUsers([]))
          // Переменная resInterator чтобы убрать ошибку '[Symbol.iterator]()' method that returns an iterator
          // let resInterator = [...[response]]
          this.local.users = await APIClient.shared.request(new UserAPI.GetUsers([]))
        } catch (error) {
          console.log({ error })
        }
      }

      if (!this.buttons.length) {
        await this.$http
          .get(`${this.$config.api}/objecteditor/entities?properties[is_approval_button]=true&object_id=${this.objectId}`)
          .then((response) => {
            response.data.data.forEach(item => {
              this.local.buttons.push({
                id: item.id,
                name: item.name
              })
            })
          })
          .catch((error) => console.error(error))
      }

      if (!this.approvalStages.legth) {
        await this.getQueryBus().execute(
          new ApprovalStagesByApprovalIdQuery(this.approvalId, { fields: 'id,name' })
        ).then((response) => {
          this.local.approvalStages = response
        })
      }

      if (!this.approvalStageTypes.length) {
        await this.getQueryBus()
          .execute(new ApprovalStageTypesQuery())
          .then((response) => {
            this.local.approvalStageTypes = response
          })
      }

      this.localLoading = false
    } else {
      this.$nextTick(() => {
        this.local.targetObjectId = this.targetObjectId
        this.local.userObjectId = this.userObjectId
        this.local.users = this.users
        this.local.buttons = this.buttons
        this.local.approvalStages = this.approvalStages
        this.local.approvalStageTypes = this.approvalStageTypes
        this.local.logicId = this.logicId
      })
    }
  },

  methods: {
    changeApprovalStageType (value): void {
      this.model.member_state_id = null
      this.model.approval_stage_members = null
      this.model.member_field_id = null
    },

    validate (): boolean {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    },

    changeFieldType (value): void {
      this.model.users_field_id = null
      this.model.properties = {
        name: null,
        value: null
      }
    },

    submit (callback = undefined): void {
      if (this.approvalId) {
        this.model.approvalId = this.approvalId
      } else {
        console.warn('Field "approvalId" is not input!')

        return
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(this.getCommand())
            .then(() => {
              if (typeof callback !== 'undefined') {
                callback()
              }

              // this.loadingStages()
            })
        } else {
          return false
        }
      })
    },

    getCommand (): CommandInterface {
      let approval = ApprovalStage.create(this.value)

      if (this.isNew) {
        return new ApprovalStageCreateCommand(
          approval.getName(),
          approval.getDescription(),
          approval.getApprovalId() || this.approvalId,
          approval.getMemberFieldId(),
          approval.getMemberStateId(),
          approval.getApprovalStageTypeId(),
          approval.getApproveText(),
          approval.getCancelText(),
          approval.getApproveButtonId(),
          approval.getCancelButtonId(),
          approval.getIsApprovableByAll(),
          approval.getApproveCommands(),
          approval.getCancelCommands(),
          approval.getCloseCommands(),
          approval.getApprovalStageMembers(),
          approval.getBlockingStages(),
          approval.getCancelStages(),
          approval.getReturnStageId()
        )
      }

      return new ApprovalStageUpdateCommand(
        approval.getGuid(),
        approval.getName(),
        approval.getDescription(),
        approval.getMemberFieldId(),
        approval.getMemberStateId(),
        approval.getApprovalStageTypeId(),
        approval.getApproveText(),
        approval.getCancelText(),
        approval.getApproveButtonId(),
        approval.getCancelButtonId(),
        approval.getIsApprovableByAll(),
        approval.getApproveCommands(),
        approval.getCancelCommands(),
        approval.getCloseCommands(),
        approval.getApprovalStageMembers(),
        approval.getBlockingStages(),
        approval.getCancelStages(),
        approval.getReturnStageId()
      )
    }
  }
})
