<template>
  <div :style="css">
    <span>
      <el-link
        style="white-space: pre;"
        icon="el-icon-document"
        @click="download(value)"
        v-for="(value, index) in localValue"
        type="primary" :key="index">
        {{ value.name }}
      </el-link>
    </span>
  </div>
</template>

<script>
export default {
  props: ['value', 'css'],
  name: 'file_field',
  computed: {
    localValue () {
      let parsed = JSON.parse(this.value || '[]')
      if (parsed instanceof Array && parsed.length > 0) {
        return parsed.map(file => {
          return {
            name: `${file.name}.${file.extension}`,
            url: `${this.$config.api}/files/${file.directory ? file.directory + '/' : ''}${file.guid}.${file.extension}`
          }
        })
      }

      return []
    }
  },
  methods: {
    download (file) {
      let me = this
      this.$http({
        method: 'get',
        url: file.url,
        responseType: 'blob'
      }).then(function (response) {
        let blob = new Blob([response.data])
        me.downloadBlob(blob, file.name)
      })
    },
    downloadBlob (blob, filename) {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style scoped>

</style>
