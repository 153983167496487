






































































































































































































import ActivitiesQuery from '@/services/ActivityService/application/query/ActivitiesQuery'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select.vue'

import UsersQuery from '@/services/AccessEditor/application/query/UsersQuery'
import RolesQuery from '@/services/AccessEditor/application/query/RolesQuery'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faWindowMinimize, faFilter, faTrash, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
library.add(faWindowMinimize, faFilter, faTrash, faWindowMaximize)

export default {
  name: 'SnapshotPanel',
  components: {
    FontAwesomeIcon,
    EditorSelect
  },
  data () {
    return {
      loading: false,
      activitiesPageSize: 0,
      defaultActivitiesPageSize: 100,
      activitiesCurrentPage: 0,
      activitiesPageLimit: 100,
      activitiesCount: 0,
      allActivitiesCount: 0,
      activities: [],
      activity: null,
      informationPanelHeight: 0,
      filterOptions: {
        requestMethods: {
          data: [
            { id: 'POST', name: 'POST' },
            { id: 'PUT', name: 'PUT' },
            { id: 'DELETE', name: 'DELETE' },
            { id: 'GET', name: 'GET' },
            { id: 'LOGIN', name: 'LOGIN' },
            { id: 'LOGOUT', name: 'LOGOUT' },
            { id: 'FILTER', name: 'FILTER' }
          ],
          value: []
        },
        users: {
          data: [],
          value: []
        },
        roles: {
          data: [],
          value: []
        },
        dateRange: {
          value: null
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus'],
  methods: {
    loadActivities () {
      this.loadAllActivities()
    },
    async loadAllActivities () {
      this.loading = true
      await this.getQueryBus().execute(
        new ActivitiesQuery({
          limit: this.activitiesPageLimit,
          offset: this.activitiesCurrentPage
        })
      ).then(data => {
        this.activitiesPageSize = this.activitiesPageLimit
        this.activities = data.data
        this.activitiesCount = data.count
        this.loading = false
      })
    },
    handleActivitiesPageChange (val) {
      val--
      this.activitiesCurrentPage = (val * this.activitiesPageLimit)
      if (this.filterOptions.requestMethods.value.length > 0 ||
        this.filterOptions.users.value.length > 0 ||
        this.filterOptions.roles.value.length > 0 ||
        this.filterOptions.dateRange.value
      ) {
        this.filterActivities()
      } else {
        this.loadActivities()
      }
    },
    parseDump (record, service, resource) {
      let res = []
      if (typeof record.id !== 'undefined') {
        res.push('<strong>id</strong>: ' + record.id)
      }
      if (typeof record.name !== 'undefined') {
        res.push('<strong>' + this.$locale.main.fields.name + '</strong>: ' + record.name)
      }

      if (service == 'reporteditor') {
        res.push('<strong>' + this.$locale.main.fields.type + '</strong>: ' + this.$locale.report_editor.types[record.report_type])
      }

      if (service == 'menueditor') {
        if (resource == 'menu') {
          res.push('<strong>' + this.$locale.main.fields.type + '</strong>: ' + this.$locale.menu_editor.label[record.menu_type_id])

          for (const [key, value] of Object.entries(record)) {
            if (key !== 'name' && this.$locale.menu_editor.label.hasOwnProperty(key)) {
              res.push('<strong>' + this.$locale.menu_editor.label[key] + '</strong>: ' + (value ?? ''))
            }
          }

          if (record.properties) {
            for (const [key, value] of Object.entries(record.properties)) {
              if (this.$locale.menu_editor.label.hasOwnProperty(key)) {
                res.push('<strong>' + this.$locale.menu_editor.label[key] + '</strong>: ' + (value ?? ''))
              }
            }
          }
        }
      }

      if (service == 'objecteditor') {
        if (resource == 'entities') {
          let props = record.properties
          if (props != null) {
            props.forEach(prop => {
              if (this.$locale.object_editor.settings.properties.hasOwnProperty(prop.id)) {
                let val = '<strong>' + this.$locale.object_editor.settings.properties[prop.id] + '</strong>: '
                if (prop.value == true) {
                  val += this.$locale.main.fields.true
                } else if (prop.value == false) {
                  val += this.$locale.main.fields.false
                } else {
                  val += (prop.value ?? '')
                }

                res.push(val)
              }
            })
          }
        }
      }

      return res.join(', ')
    },
    async filterActivities () {
      this.loading = true
      let payload = {
        limit: JSON.stringify(this.activitiesPageLimit),
        offset: JSON.stringify(this.activitiesCurrentPage)
      }
      if (this.filterOptions.requestMethods.value.length > 0) {
        Object.assign(payload, {
          request_method: JSON.stringify(this.filterOptions.requestMethods.value)
        })
      }
      if (this.filterOptions.users.value.length > 0) {
        Object.assign(payload, {
          user_id: JSON.stringify(this.filterOptions.users.value)
        })
      }
      if (this.filterOptions.roles.value.length > 0) {
        Object.assign(payload, {
          role_id: JSON.stringify(this.filterOptions.roles.value)
        })
      }
      if (this.filterOptions.dateRange.value) {
        Object.assign(payload, {
          time_local: JSON.stringify(this.filterOptions.dateRange.value)
        })
      }
      await this.getQueryBus().execute(
        new ActivitiesQuery(payload)
      ).then(data => {
        this.activitiesCount = data.count
        this.defaultActivitiesPageSize = this.activitiesPageLimit
        this.activities = data.data
        this.loading = false
      })
    },
    clearFilters () {
      this.$refs.request_method_select.clearValue()
      this.$refs.users_select.clearValue()
      this.$refs.roles_select.clearValue()
      this.$set(this.filterOptions.requestMethods, 'value', [])
      this.$set(this.filterOptions.users, 'value', [])
      this.$set(this.filterOptions.roles, 'value', [])
      this.$set(this.filterOptions.dateRange, 'value', null)

      this.loadActivities()
    },
    async loadFilterData () {
      await this.getQueryBus().execute(
        new UsersQuery()
      ).then(data => {
        this.filterOptions.users.data = data.map((user) => {
          return { id: user.id, name: user.full_name }
        })
      })

      await this.getQueryBus().execute(
        new RolesQuery()
      ).then(data => {
        this.filterOptions.roles.data = data
      })
    }
  },
  mounted () {
    this.loadActivities()
    this.loadFilterData()
  }
}
