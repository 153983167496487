import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'
import ApprovalUpdateCommand from '@/services/LogicEditor/application/command/ApprovalUpdateCommand'
import ApprovalCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalCommandRepositoryInterface'

export default class ApprovalUpdateHandler implements CommandHandlerInterface {
  private repository: ApprovalCommandRepositoryInterface

  constructor (repository: ApprovalCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ApprovalUpdateCommand): Promise<void> {
    let item: ApprovalDTO = {
      guid: command.getGuid(),
      name: command.getName(),
      description: command.getDescription(),
      approval_states: command.getApprovalStates(),
      approval_stages: command.getApprovalStages(),
      alias: command.getAlias(),
      is_enabled: command.getIsEnabled()
    }

    return this.repository.update(item)
  }
}
