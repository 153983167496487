import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import TimerDeleteCommand from '@/services/LogicEditor/application/command/TimerDeleteCommand'
import TimerCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/TimerCommandRepositoryInterface'

export default class TimerDeleteHandler implements CommandHandlerInterface {
    private repository: TimerCommandRepositoryInterface;

    constructor (repository: TimerCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TimerDeleteCommand): Promise<void> {
      return this.repository.delete(command.guid)
    }
}
