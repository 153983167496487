import openCardMixin from '@/components/InterfaceEditor/components/openCard_mixin'
import Dashboard from '@/components/Dashboard'
import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'
export default {
  components: {
    Dashboard
  },
  mixins: [openCardMixin],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async btnClickedHandler () {
      this.loading = true
      let action = this.params.colDef.action
      if (typeof action !== 'undefined') {
        let recordId
        let registryId
        let cardId
        if (action?.card?.type === 'update' || action?.card?.type === 'read') {
          if (action?.card.queryRegistry) {
            try {
              recordId = JSON.parse(this.params.data[action.card.queryRecord])[0].id
              registryId = JSON.parse(this.params.data[action.card.queryRegistry])[0].id
              cardId = JSON.parse(this.params.data[action.card.queryCard])[0].id
            } catch (error) {
              recordId = this.params.data[action.card.queryRecord]
              registryId = this.params.data[action.card.queryRegistry]
              cardId = this.params.data[action.card.queryCard]
            }
            this.getModel()[action.card.queryRecord] = recordId
            this.getModel()[action.card.queryRegistry] = registryId
            if (cardId) {
              this.getModel()[action.card.queryCard] = cardId
            }
          } else {
            try {
              recordId = JSON.parse(this.params.data[action.card.fieldId])[0].id
            } catch (error) {
              recordId = this.params.data[action.card.fieldId]
            }
            if (!recordId) {
              console.warn(`recordId doesn't set = ${action.card.fieldId}`)
              return false
            }
            this.getModel()[action.card.fieldId] = recordId
            action.card.recordId = recordId
          }
        }
        await ActionExecutor.execute(this, { readonly: this.params.colDef.action.readonly, pluginName: this.params.colDef.action.pluginName, action: this.params.colDef.action, event: event })
      } else if (this.params.colDef.clickType === 'open_card') {
        await this.openCard(this.params.colDef.card, this.params.data)
      } else if (this.params.colDef.clickType === 'open_dashboard') {
        this.openDashboard(this.params.colDef.dashboard, this.params.data)
      }
      this.loading = false
    }
  },
  computed: {
    clickTable () {
      if (typeof this.params.colDef.action !== 'undefined' && this.params.colDef.action.type) {
        return true
      }
      return typeof this.params.colDef.clickType !== 'undefined' && this.params.colDef.clickType !== 'none' && !this.params.context.componentParent.readonly
    }
  }
}
