<template>
  <div class="source-form">
    <el-form ref="form" :model="value" :rules="formRules" size="mini" label-position="top">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item :label="$t('report_editor.form.select_source_type')" prop="report_source_type_id">
            <el-select
              v-model="value.report_source_type_id"
              :placeholder="$t('report_editor.form.select_source_type')"
              @change="sourceTypeChange"
            >
              <el-option
                v-for="item in reportSourceTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="$t('report_editor.form.source')" prop="properties.id">
            <template v-if="value.report_source_type_id === 'extended_object'">
              <el-select
                v-model="value.properties.id"
                :placeholder="$t('report_editor.object_types.extended_object')"
                @change="selectedExtendedObject"
              >
                <el-option
                  v-for="item in extendedObjectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>

            <template v-if="value.report_source_type_id === 'query'">
              <el-select
                v-model="value.properties.id"
                :placeholder="$t('report_editor.object_types.query')"
                @change="selectedQuery"
              >
                <el-option
                  v-for="item in queryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>

            <template v-if="value.report_source_type_id === 'registry'">
              <registry-select-tree
                type="registry"
                v-model="value.properties"
                :placeholder="$t('report_editor.object_types.registry')"
              ></registry-select-tree>
            </template>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="$t('report_editor.form.alias')" prop="properties.alias">
            <el-input
              :placeholder="$t('report_editor.form.alias')"
              v-model="value.properties.alias"
              @input="aliasChange">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item v-if="value.parent_id !== null" prop="properties.relation">
        <template slot="label">
          {{ $t('report_editor.form.relations') }}

          <el-tooltip class="item" effect="dark" :content="$t('report_editor.relation.add')" placement="top">
            <el-button
              type="success"
              icon="el-icon-plus"
              circle
              class="el-button--plus"
              @click="addRelation()"
            ></el-button>
          </el-tooltip>
        </template>

        <el-form-item v-for="(item, index) in value.properties.relation" :key="index" class="multi-form">
          <relation-form
            ref="relationFrom"
            v-model="value.properties.relation[index]"
            :index="index"
            :from-field-list="fields.from"
            :to-field-list="fields.to"
            :remove="removeRelation"
          ></relation-form>
        </el-form-item>
      </el-form-item>

      <el-form-item>
        <template slot="label">
          {{ $t('report_editor.form.filters') }}

          <el-tooltip class="item" effect="dark" :content="$t('report_editor.filter.add')" placement="top">
            <el-button
              type="success"
              icon="el-icon-plus"
              circle
              class="el-button--plus"
              @click="addFilter()"
            ></el-button>
          </el-tooltip>
        </template>

        <el-form-item v-for="(filter, index) in value.report_filters" :key="filter.id" class="multi-form">
          <filter-form
            ref="filterForm"
            v-model="value.report_filters[index]"
            :index="index"
            :field-list="fieldList"
            :filter-type-list="filterTypeList"
            :remove="removeFilter"
          ></filter-form>
        </el-form-item>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import FilterForm from './FilterForm'
import RelationForm from './RelationForm'
import ExtendedObjectField from '../../BIEditor/Models/ExtendedObjectField'

export default {
  name: 'SourceForm',

  props: ['value', 'sources', 'reportSourceTypes', 'extendedObjectList', 'queryList', 'filterTypeList'],

  components: {
    RegistrySelectTree,
    FilterForm,
    RelationForm
  },

  watch: {
    value () {
      this.localValue = this.value
    },

    'value.properties': (value, oldValue) => {
      if (value.id !== oldValue.id) {
        value.alias = !value.name ? null : value.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
        value.relation = []
      }
    }
  },

  data () {
    return {
      localValue: this.value,
      formRules: {
        report_source_type_id: [{ required: true, message: 'Выберите тип источника', trigger: 'change' }],
        'properties.id': [{ required: true, message: 'Выберите источник', trigger: 'change' }],
        'properties.alias': [{ required: true, message: 'Введите псевдоним источника', trigger: 'blur' }],
        'properties.relation': [{ required: true, message: 'Укажите связи для источников', trigger: 'change' }]
      },
      fields: {
        from: [],
        to: []
      },
      fieldList: []
    }
  },

  async mounted () {
    if (this.value.report_source_type_id === 'extended_object') {
      let response = await new ExtendedObjectField().params({ extended_object_id: this.value.properties.id }).$get()

      response.forEach(item => {
        this.fieldList.push({
          id: item.name,
          name: item.name,
          entity_type_id: item.entity_type_id
        })
      })
    } else if (this.value.report_source_type_id === 'query') {
      await this.$http.get(`${this.$config.api}/bieditor/queries/fields/${this.value.properties.id}`).then((response) => {
        response.data.forEach(item => {
          this.fieldList.push({
            id: item.name,
            name: item.name,
            entity_type_id: null
          })
        })
      })
    } else {
      await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${this.value.properties.id}&is_field=true`).then((response) => {
        this.fieldList.push({
          id: 'id',
          name: 'id',
          entity_type_id: null
        })
        response.data.data.forEach(item => {
          let attr = `attr_${item.id}_`
          this.fieldList.push({
            id: attr,
            name: `${item.name} (${attr})`,
            entity_type_id: item.entity_type_id
          })
        })
      })
    }

    if (this.value.parent_id !== null) {
      await this.loadFields(this.value.parent_id, 'from')
      await this.loadFields(this.value.id, 'to')
    }
  },

  methods: {
    isValid () {
      let validForm = true
      this.$refs.form.validate((valid) => {
        validForm = valid
      })

      if (this.$refs.filterForm) {
        const filtersValid = []
        this.$refs.filterForm.forEach((form) => {
          filtersValid.push(form.isValid())
        })
        validForm = filtersValid.includes(false) !== true
      }

      if (this.$refs.relationFrom) {
        const relationsValid = []
        this.$refs.relationFrom.forEach((form) => {
          relationsValid.push(form.isValid())
        })
        validForm = relationsValid.includes(false) !== true
      }

      return validForm
    },

    selectedExtendedObject (value) {
      let extendedObject = this.extendedObjectList.find(item => value === item.id)

      this.localValue.properties.name = extendedObject.name
      this.localValue.properties.alias = extendedObject.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
    },

    selectedQuery (value) {
      let query = this.queryList.find(item => value === item.id)

      this.localValue.properties.name = query.name
      this.localValue.properties.alias = query.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
    },

    sourceTypeChange () {
      let relation = this.localValue.properties.relation

      this.localValue.properties = {
        id: null,
        name: null,
        alias: null
      }

      if (this.localValue.parent_id && relation) {
        this.localValue.properties.relation = relation
      }
    },

    addFilter () {
      this.localValue.report_filters.push({
        report_source_id: this.localValue.id,
        report_filter_type_id: null,
        operation_type_id: null,
        properties: {
          field: null,
          type: null,
          value: null
        }
      })

      this.$emit('input', this.localValue)
    },

    addRelation () {
      if (!this.localValue.properties.relation) {
        this.localValue.properties.relation = []
      }

      this.localValue.properties.relation.push({
        from: null,
        to: null
      })

      this.$emit('input', this.localValue)
    },

    removeRelation (index) {
      this.localValue.properties.relation.splice(index, 1)

      this.$emit('input', this.localValue)
    },

    removeFilter (index) {
      this.localValue.report_filters.splice(index, 1)

      this.$emit('input', this.localValue)
    },

    async loadFields (value, listId = 'from') {
      let source = this.sources.find(item => item.id === value)
      let response = []
      if (source.report_source_type_id === 'extended_object') {
        response = await new ExtendedObjectField().params({ extended_object_id: source.properties.id }).$get()
      } else if (source.report_source_type_id === 'query') {
        await this.$http.get(`${this.$config.api}/bieditor/queries/fields/${source.properties.id}`).then((res) => {
          response.push(...res.data)
        })
      }
      response.forEach((item) => {
        this.fields[listId].push({
          id: item.name,
          label: item.name,
          disabled: false
        })
      })
    },

    aliasChange (value) {
      value = value.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.localValue.properties.alias = value
    }
  }
}
</script>
