import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class ApprovalStageUpdateCommand implements CommandInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    private readonly guid: string,
    private readonly name: string,
    private readonly description: Nullable<string>,
    private readonly memberFieldId: Nullable<number>,
    private readonly memberStateId: Nullable<number>,
    private readonly approvalStageTypeId: string,
    private readonly approveText: Nullable<string>,
    private readonly cancelText: Nullable<string>,
    private readonly approveButtonId: Nullable<number>,
    private readonly cancelButtonId: Nullable<number>,
    private readonly isApprovableByAll: boolean,
    private readonly approveCommands: number[],
    private readonly cancelCommands: number[],
    private readonly closeCommands: number[],
    private readonly approvalStageMembers: number[],
    private readonly blockingStages: number[],
    private readonly cancelStages: number[],
    private readonly returnStageId: Nullable<number>
  ) {
  }

  getClassName (): string {
    return 'ApprovalStageUpdateCommand'
  }

  getGuid (): string {
    return this.guid
  }

  /**
   * @return string
   */
  getName (): string {
    return this.name
  }

  /**
   * @return string
   */
  getDescription (): Nullable<string> {
    return this.description
  }

  /**
   * @return string
   */
  getApprovalStageTypeId (): string {
    return this.approvalStageTypeId
  }

  /**
   * @return string|null
   */
  getCancelText (): Nullable<string> {
    return this.cancelText
  }

  /**
   * @return number|null
   */
  getApproveButtonId (): Nullable<number> {
    return this.approveButtonId
  }

  /**
   * @return string|null
   */
  getApproveText (): Nullable<string> {
    return this.approveText
  }

  /**
   * @return number|null
   */
  getMemberFieldId (): Nullable<number> {
    return this.memberFieldId
  }

  /**
   * @return number|null
   */
  getMemberStateId (): Nullable<number> {
    return this.memberStateId
  }

  /**
   * @return number|null
   */
  getCancelButtonId (): Nullable<number> {
    return this.cancelButtonId
  }

  /**
   * @return number[]
   */
  getApprovalStageMembers (): number[] {
    return this.approvalStageMembers
  }

  /**
   * @return number[]
   */
  getApproveCommands (): number[] {
    return this.approveCommands
  }

  /**
   * @return number[]
   */
  getBlockingStages (): number[] {
    return this.blockingStages
  }

  /**
   * @return number[]
   */
  getCancelCommands (): number[] {
    return this.cancelCommands
  }

  /**
   * @return number[]
   */
  getCloseCommands (): number[] {
    return this.closeCommands
  }

  /**
   * @return number[]
   */
  getCancelStages (): number[] {
    return this.cancelStages
  }

  /**
   * @return bool
   */
  getIsApprovableByAll (): boolean {
    return this.isApprovableByAll
  }

  getReturnStageId (): Nullable<number> {
    return this.returnStageId
  }
}
