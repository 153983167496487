export default {
  methods: {
  // проверить данные, если верны, то вернуть их
    async validationData (data, dataRegistryByRecord) {
      this.loading = true
      let openCard = data.notification[0]
      let readOnly = openCard.card_operation_type === 'read'
      // id реестра вокруг которого настроены уведомления
      let registryId = openCard.object_id
      // id записи на котором сработало уведомление
      const recordId = data.record_id
      let cardId = openCard.external_card_id
      let fieldRecord = dataRegistryByRecord
      // id записи на которую ссылается ссылка
      let recordXref = fieldRecord[`attr_${openCard.current_field_id}_id`]
      if (!recordXref) {
        console.warn(`Ссылка не обнаружена attr_${openCard.current_field_id}_id`)
        this.loading = false
        return true
      }

      return { openCard, readOnly, cardId, recordXref }
    },
    // данные реестра по id записи
    async getDataRegistryByRecord ({ registryId, recordId }) {
      return (await this.$http.get(`${this.$config.api}/registryservice/registry/${registryId}/data/${recordId}`)).data
    }
  }
}
