<template>
  <div>
    <div>
      <span class="label">{{ $locale.logic_editor.command.create_report.report }}</span>
      <property type="report" :property="properties.find((item) => item.id === 'report_id')"></property>
      <span class="label">{{ $locale.logic_editor.command.create_report.format }}</span>
      <property type="format" :property="properties.find((item) => item.id === 'format')"></property>
      <span class="label">{{ $locale.logic_editor.command.create_report.operation_type }}</span>
      <property type="operation_type" :property="properties.find((item) => item.id === 'operation_type')"></property>
      <template v-if="(properties.find((item) => item.id === 'operation_type') || {}).value === 'send_to_databus'">
        <span class="label">{{ $locale.logic_editor.command.create_report.topic }}</span>
        <property type="topic" :property="properties.find((item) => item.id === 'topic_code')"></property>
        <span class="label">{{ $locale.logic_editor.command.create_report.field_array }}</span>
        <property type="field_array" :logic-id="logicId" :property="properties.find((item) => item.id === 'fields')"></property>
      </template>
    </div>
  </div>
</template>

<script>
import Property from '@/components/LogicEditor/components/CommandProperties/Property'
export default {
  name: 'CreateReportEvent',
  components: { Property },
  props: {
    logicId: Number,
    properties: {
      type: Array,
      default: () => {
        return [{ 'id': 'fields', 'type': 'field_array', 'value': null }, {
          'id': 'format',
          'type': 'format',
          'value': null
        }, { 'id': 'operation_type', 'type': 'operation_type', 'value': null }, {
          'id': 'report_id',
          'type': 'report',
          'value': null
        }, { 'id': 'topic_code', 'type': 'topic', 'value': null }]
      }
    }
  },
  data () {
    return {
      reports: [],
      topics: []
    }
  },
  watch: {
    properties: {
      deep: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>
.label {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 8px;
}
</style>
