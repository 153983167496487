


























































import Vue from 'vue'

// Components
import TreeElementLayout from '@/services/TaskEditor/infrastructure/components/TreeElementLayout.vue'
import EditorElementLayout from '@/services/TaskEditor/infrastructure/components/EditorElementLayout.vue'
import TaskRegistryForm from '@/services/TaskEditor/infrastructure/components/forms/TaskRegistryForm.vue'

// Queries and Commands
import RuleByGuidQuery from '@/services/TaskEditor/application/query/RuleByGuidQuery'
import RuleGroupByGuidQuery from '@/services/TaskEditor/application/query/RuleGroupByGuidQuery'
import TaskRegistryEntitiesQuery from '@/services/TaskEditor/application/query/TaskRegistryEntitiesQuery'

import RuleDeleteCommand from '@/services/TaskEditor/application/command/RuleDeleteCommand'
import RuleGroupDeleteCommand from '@/services/TaskEditor/application/command/RuleGroupDeleteCommand'

// Models
import Rule from '@/services/TaskEditor/domain/model/Rule'
import RuleGroup from '@/services/TaskEditor/domain/model/RuleGroup'
import {Nullable} from "@/core/domain/type/types";

export default Vue.extend({
  name: 'MainEditorLayout',

  components: {
    TreeElementLayout,
    EditorElementLayout,
    TaskRegistryForm
  },

  computed: {
    isConfiguredTaskRegistry () {
      return this.taskRegistryId !== null
    },

    isLoading (): boolean {
      return this.$store.getters['TaskRegistryEntity/isTaskRegistryEntitiesLoading']
    },

    taskRegistryId (): Nullable<number> {
      const registry = this.taskRegistry.find(item => item.id === 'registry')

      if (registry) {
        return registry.entity_id
      }

      return null
    }
  },

  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],

  provide () {
    return {
      getTreeElementLayout: this.getTreeElementLayout,
      getEditorNode: this.getEditorNode
    }
  },

  mounted () {
    this.getQueryBus()
      .execute(
        new TaskRegistryEntitiesQuery()
      )
      .then((response) => {
        this.taskRegistry = response
      })
  },

  data () {
    return {
      editor: {
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      },

      queryByGuid: {
        rule: RuleByGuidQuery,
        group: RuleGroupByGuidQuery
      },

      deleteCommands: {
        rule: RuleDeleteCommand,
        group: RuleGroupDeleteCommand
      },

      defaultModel: {
        rule: Rule,
        group: RuleGroup
      },

      form: {
        rule: 'RuleForm',
        group: 'RuleGroupForm'
      },

      taskRegistry: [],
      isTaskRegistry: false
    }
  },

  methods: {
    cancelEditor () {
      this.editor = {
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      }
    },

    createElement (elementType, parentNode) {
      this.editor.form = this.form[elementType]
      this.editor.title = this.$t('task_editor.title.' + elementType)
      this.editor.node = parentNode
      this.editor.elementType = elementType
      this.editor.model = this.defaultModel[elementType].create()
      this.editor.model.parent_tree_element_id = parentNode ? parentNode.data.id : null
    },

    async updateElement (node, data) {
      this.editor.model = await this.getQueryBus().execute(new this.queryByGuid[data.element_type](data.element_guid))
      this.editor.form = this.form[data.element_type]
      this.editor.title = this.$t('task_editor.title.' + data.element_type)
      this.editor.node = node
      this.editor.elementType = data.element_type
    },

    async deleteElement (node, data) {
      await this.getCommandBus().execute(
        new this.deleteCommands[data.element_type](data.element_guid)
      )
    },

    editTaskRegistry () {
      this.isTaskRegistry = true
    },

    saveTaskRegistry () {
      this.$refs.taskRegistryForm.submit(() => {
        let registry = this.taskRegistry.find(item => item.id === 'registry')

        this.taskRegistryId = registry.entity_id
      })
    },

    getEditorNode () {
      return this.editor.node
    },

    getTreeElementLayout () {
      return this.$refs.treeElement
    }
  }
})
