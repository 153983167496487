  <template>
  <div style="height: 100%;">
    <div style="padding: 0px 0px 10px 10px">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$locale.access_editor.add_user"
        placement="bottom"
      >
        <el-button
          style="display: block"
          size="small"
          icon="el-icon-plus"
          circle
          @click="open"
        ></el-button>
      </el-tooltip>
    </div>
    <el-table :data="listUsers" v-loading="loading" class="custom_scrollbar" style="width: 100%" border height="calc(100vh - 228px)">
      <el-table-column prop="login" :label="$locale.access_editor.users_item.login" width="180"></el-table-column>
      <el-table-column prop="name" :label="$locale.access_editor.users_item.name" width="180"></el-table-column>
      <el-table-column prop="midname" :label="$locale.access_editor.users_item.midname" width="180"></el-table-column>
      <el-table-column prop="surname" :label="$locale.access_editor.users_item.surname" width="180"></el-table-column>
      <el-table-column prop="role.name" :label="$locale.access_editor.users_item.role" width="180"></el-table-column>
      <el-table-column prop="email" :label="$locale.access_editor.users_item.email" width="180"></el-table-column>
      <el-table-column prop="isAdmin" :label="$locale.access_editor.users_item.admin" width="180">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.isAdmin ? 'success' : 'danger'"
          >{{scope.row.isAdmin ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="isBlocked" :label="$locale.access_editor.users_item.block" width="180">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.isBlocked ? 'success' : 'danger'"
          >{{scope.row.isBlocked ? $locale.access_editor.yes : $locale.access_editor.no}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" min-width="100px" :label="$locale.access_editor.users_item.action">
        <template slot-scope="scope">
          <el-button
            @click="editRow(scope.$index, scope.row)"
            icon="el-icon-edit"
            circle
            size="mini"
          ></el-button>
          <el-button
            @click="deleteRow(scope.$index, scope.row)"
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="users_pagination__wth"
        @size-change="loadData()"
        @current-change="loadData()"
        :current-page.sync="currentPage"
        :page-sizes="[5, 10, 20, 30]"
        :page-size.sync="currentPageSize"
        layout="sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    <!-- ----- -->
    <el-dialog
      v-if="isDialog"
      width="45%"
      :title="$locale.access_editor.roles_table.add_role"
      :visible.sync="isDialog"
    >
      <el-form
        style="display: flex; flex-wrap: wrap;"
        :model="form"
        status-icon
        :rules="rules"
        ref="form"
        label-width="120px"
        class="demo-ruleForm"
      >
        <div>
          <el-form-item
            :label="$locale.access_editor.users_item.login"
            :error="errorLogin ? $locale.access_editor.users_item.error_login : ''"
            prop="login"
            :rules="[rules.required, rules.minFiveSymbols]"
          >
            <el-input v-model="form.login" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            :label="$locale.access_editor.users_item.name"
            prop="name"
            :rules="[rules.required]"
          >
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$locale.access_editor.users_item.midname" prop="midname">
            <el-input v-model="form.midname"></el-input>
          </el-form-item>
          <el-form-item :label="$locale.access_editor.users_item.surname" prop="surname">
            <el-input v-model="form.surname"></el-input>
          </el-form-item>
          <el-form-item prop="isBlocked">
            <el-checkbox v-model="form.isBlocked" :label="$locale.access_editor.users_item.block" name="isBlocked"></el-checkbox>
          </el-form-item>
        </div>
        <div>
          <el-form-item
            :label="$locale.access_editor.users_item.email"
            prop="email"
            :rules="[rules.email]"
          >
            <el-input v-model="form.email" autocomplete="off"></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="21">
              <!-- <el-form-item
                :label="$locale.access_editor.users_item.password"
                prop="password"
                :rules="!isEditUser ? [rules.required, rules.password, rules.minEightSymbols] : {}">
                <el-input disabled v-model="form.password" autocomplete="off"></el-input>
              </el-form-item> -->
              <el-form-item
                :label="$locale.access_editor.users_item.password"
                prop="password"
                :rules="!isEditUser ? [rules.required, rules.minEightSymbols] : {}">
                <el-input readonly v-model="form.password" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-tooltip
                class="item"
                effect="dark"
                :content="$locale.access_editor.users_item.generatPass"
                placement="bottom"
              >
                <el-button
                  @click="generatePass"
                  :disabled="isEditUser"
                  style="margin-top: 5px"
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="mini"
                ></el-button>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-form-item  :label="$locale.access_editor.users_item.role" :rules="[rules.required]">
            <el-select v-model="form.role.id">
              <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="isAdmin">
            <el-checkbox v-model="form.isAdmin" :label="$locale.access_editor.users_item.admin" name="isAdmin"></el-checkbox>
          </el-form-item>
          <div style="text-align: center;">
            <span>{{$locale.access_editor.users_item.avatar}}</span>
            <el-upload
              :headers="getHeaders()"
              class="users upload-demo"
              ref="upload"
              action=""
               list-type="picture-card"
              :http-request="save"
              :on-change="numberFiles"
              :on-remove="handleRemove"
              :file-list="fileList"
              :auto-upload="false"
            >
            <i class="el-icon-plus" :title="$locale.access_editor.users_item.download_avatar"></i>
            </el-upload>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-show="showBtnInModal" @click="isEditUser = !isEditUser">{{$locale.access_editor.users_item.changePass}}</el-button>
        <el-button @click="close">{{$locale.main.button.cancel}}</el-button>
        <el-button type="primary" :disabled="form.role.id ===''" @click="save">{{$locale.main.button.save}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import User from '../Models/User'
import Role from '../Models/Role'
export default {
  name: 'Users',
  data () {
    return {
      // правила валидации
      rules: {
        required: {
          required: true,
          message: this.$locale.access_editor.users_item.rules_form.inputField,
          trigger: ['blur', 'change']
        },
        minFiveSymbols: {
          min: 5,
          message: this.$locale.access_editor.users_item.rules_form.fiveSymbols,
          trigger: ['blur', 'change']
        },
        minEightSymbols: {
          min: 10,
          message: this.$locale.access_editor.users_item.rules_form.symbols,
          trigger: ['blur', 'change']
        },
        email: {
          type: 'email',
          message: this.$locale.access_editor.users_item.rules_form.errorEmail,
          trigger: ['blur', 'change']
        }
        // password: {
        //   validator: function (rules, value, callback) {
        //     if (value) {
        //       const pattern = /(?=.*[0-9])/g
        //       const pattern1 = /(?=.*[A-Z])/g
        //       const pattern2 = /(?=.*[a-z])/g
        //       const pattern3 = /(?=.*[!@#$%^&*])/g
        //       if (!pattern.test(value)) {
        //         callback(new Error('Добавьте цифру'))
        //       } else if (!pattern1.test(value)) {
        //         callback(new Error('Добавьте букву в верхнем регистре'))
        //       } else if (!pattern2.test(value)) {
        //         callback(new Error('Добавьте букву в нижнем регистре'))
        //       } else if (!pattern3.test(value)) {
        //         callback(new Error('Добавьте спец. символ !@#$%^&*'))
        //       } else callback()
        //     }
        //   },
        //   trigger: ['blur', 'change']
        // }
      },
      // блокированка инпута
      isEditUser: false,
      errorLogin: false,
      fileList: [],
      isDialog: false,
      editedIndex: -1,
      form: {
        login: '',
        name: '',
        surname: null,
        midname: null,
        role: { id: '', name: '' },
        email: null,
        password: '',
        isBlocked: false,
        isAdmin: true,
        avatar_id: null
      },
      defaulForm: {
        login: '',
        name: '',
        surname: null,
        midname: null,
        role: { id: '', name: '' },
        email: null,
        password: '',
        isBlocked: false,
        isAdmin: true,
        avatar_id: null
      },
      // выпадающее меню ролей
      roles: [],
      // данные таблицы
      listUsers: [],
      loading: false,
      currentPage: 1,
      currentPageSize: 5,
      total: 100
    }
  },
  computed: {
    // показать/скрыть кнопку в модальном окне
    showBtnInModal () {
      return this.editedIndex > -1
    }
  },
  watch: {
    isDialog (val) {
      if (val) {
        if (this.editedIndex === -1) {
          this.isEditUser = false
          if (this.$refs['form'] !== undefined) this.$refs['form'].resetFields()
        }
      } else {
        this.close()
      }
    }
  },
  methods: {
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },
    handleRemove (file, fileList) {
      this.form.avatar_id = null
    },
    // запись данных в таблицу
    loadData () {
      console.log(this.currentPage, this.currentPageSize)
      // this.loading = true
      // axios.get(`${url}grid/${this.id}/data&page=${this.currentPage}&limit=${this.currentPageSize}`)
      //   .then(response => {
      //       this.data = response.data.data;
      //       this.total = response.data.total;
      //       this.loading = false;
      //     }
      // )}
    },
    open () {
      this.isDialog = true
      this.fileList = []
      this.form = Object.assign({}, this.defaulForm)
    },
    // сгенерировать пароль
    async generatePass (item) {
      let genPass = await User.custom('users/password/generate').first()
      this.$set(this.form, 'password', genPass.password)
    },
    saveUser () {
      let user = new User({ id: this.form.id }, this.form)
      user.role_id = this.form.role.id
      user.is_admin = this.form.isAdmin
      user.is_blocked = this.form.isBlocked
      user.save().then(res => {
        if (this.editedIndex > -1) {
          this.listUsers.splice(this.editedIndex, 1, res)
        } else {
          this.listUsers.push(res)
        }
        this.close()
      })
        .catch((error) => {
          if (error.response.data.error === 'user_login_already_exist') this.errorLogin = true
        })
    },
    saveUserWithAvatar () {
      let fileObj = this.$refs.upload.uploadFiles[0].raw
      const fd = new FormData()
      fd.append('file', fileObj)
      this.$http.post(this.$config.api + '/registryservice/files', fd, { hideNotification: true })
        .then(res => { this.form.avatar_id = res.data.id })
        .then(() => { this.saveUser() })
    },
    save () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          // редактировать
          if (this.editedIndex > -1) {
            Object.assign(this.listUsers[this.editedIndex], this.form)
            if (this.$refs.upload.uploadFiles.length && this.$refs.upload.uploadFiles[0].raw) {
              this.saveUserWithAvatar()
            } else {
              this.saveUser()
            }
          } else {
            if (this.$refs.upload.uploadFiles.length) {
              if (this.$refs.upload.uploadFiles[0].raw) this.saveUserWithAvatar()
            } else {
              this.saveUser()
            }
          }
        }
      })
    },
    close () {
      this.isDialog = false
      this.isEditUser = false
      this.form = Object.assign({}, this.defaulForm)
      this.$refs['form'].resetFields()
      this.editedIndex = -1
    },
    async editRow (index, row) {
      this.fileList = []
      this.isDialog = true
      this.isEditUser = true
      this.editedIndex = this.listUsers.indexOf(row)
      if (row.password !== '') row.password = ''
      if (row.avatar_id) {
        try {
          let image = await this.$http.get(`${this.$config.api}/registryservice/files/${row.avatar_id}`)
          this.fileList.push({
            name: `${image.data.name}`,
            url: `${this.$config.api}/files/${image.data.directory ? image.data.directory + '/' : ''}${image.data.guid}.${image.data.extension}`
          })
        } catch (error) {
          console.log(error)
        }
      }
      this.form = Object.assign({}, row)
    },
    deleteRow (index, row) {
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        let user = new User({ id: row.id })
        await user.delete()
        const ind = this.listUsers.indexOf(row)
        this.listUsers.splice(ind, 1)
      }).catch((error) => { console.log(error) })
    },
    numberFiles (file, fileList) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
        this.$refs.upload.uploadFiles = []
        this.$message.error(this.$locale.access_editor.users_item.error_avatar)
        return false
      }
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1)
      }
    }
  },
  async mounted () {
    try {
      this.loading = true
      let getRolesShortList = await Role.custom('roles/?fields=id,name').get()
      this.roles = getRolesShortList
      let users = await User.get()
      this.listUsers = users
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }
}
</script>

<style >
.users_pagination__wth .el-pagination__sizes .el-select .el-input  {
  width: 138px;
}
.users.upload-demo {
  margin-top: 10px;
}
.users.upload-demo .el-upload--picture-card {
  width: 70px;
  height: 70px;
  line-height: 80px;
}
.users.upload-demo .el-upload-list--picture-card .el-upload-list__item {
  width: 70px;
  height: 70px;
}
</style>
